import Constants from 'constants/index';
import InputDialog from '@/components/Dialogs/InputDialog';
import useBugsnagNotify from '@/hooks/useBugsnagNotify';
import toast from 'react-hot-toast';
import { selectUserPermissions } from '@/store/user/selectors';
import { LanguageObject } from '@/types';
import { getNameFromLanguage } from '@/utils/language';
import { ChecklistDTO } from '@cfacorp-pathway/xp-api-typescript-client';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ToastMessageBlock from '@/components/Toasts/SuccessToast';
import { useState } from 'react';
import ConfirmationModal from '@/components/popups/ConfirmationModal';
import { useDuplicateTrainingPlanMutation } from '@/services/pathwayApi';

interface DuplicatePlanProps {
  plan: ChecklistDTO;
  refetchPlans: () => void;
  setShowDuplicatePlan: (show: boolean) => void;
  showDuplicatePlan: boolean;
}

const DuplicatePlan: React.FC<DuplicatePlanProps> = ({
  plan,
  refetchPlans,
  setShowDuplicatePlan,
  showDuplicatePlan,
}) => {
  const { t } = useTranslation();
  const { notifyBugsnag } = useBugsnagNotify();
  const [duplicateTrainingPlan] = useDuplicateTrainingPlanMutation();
  const userPermissions = useSelector(selectUserPermissions);
  const userIsStakeholder = userPermissions.hasOwnProperty(
    Constants.USER_PERMISSIONS.STAKEHOLDER,
  );
  const [showInvalidNameError, setShowInvalidNameError] = useState(false);

  const isValidPlanName = (planName: string, assignedOperator: string) => {
    const noAssignedOperatorAndUserIsNotStakeholder =
      !assignedOperator && !userIsStakeholder;

    if (!planName || noAssignedOperatorAndUserIsNotStakeholder) {
      setShowInvalidNameError(true);
      console.error('invalid name or missing operator');
      notifyBugsnag(
        new Error(
          `Build plans error - no plan name or no operator: ${JSON.stringify({
            planName,
            assignedOperator,
          })}`,
        ),
      );
      return false;
    }
    return true;
  };

  const onDuplicatePlan = (
    planId: string,
    operatorId: string,
    name: string,
  ) => {
    const trimmedName = name?.trim();

    if (!isValidPlanName(trimmedName, operatorId)) {
      return;
    }

    duplicateTrainingPlan({
      ownerId: operatorId,
      checklistId: planId,
      name: {
        en: trimmedName,
        es: trimmedName,
      },
    })
      .unwrap()
      .then(() => {
        refetchPlans();
        toast.custom(toastObj => (
          <ToastMessageBlock id={toastObj.id}>
            {`${trimmedName} ${t('TrainingPlans.toastMessage.duplicated')}`}
          </ToastMessageBlock>
        ));
      })
      .catch(err => {
        notifyBugsnag(err);
      });
  };

  return (
    <>
      <InputDialog
        headerText={t('Generic.giveName', { object: t('Generic.plan') })}
        isDuplicatePlan={showDuplicatePlan}
        isOpen={showDuplicatePlan}
        onClose={() => setShowDuplicatePlan(false)}
        primaryButtonHandler={inputValue => {
          setShowDuplicatePlan(false);
          onDuplicatePlan(plan?.id!, plan?.ownerId!, inputValue);
        }}
        primaryButtonText={t('Button.create')}
        primaryButtonVariant="filled"
        secondaryButtonHandler={() => setShowDuplicatePlan(false)}
        secondaryButtonText={t('Button.cancel')}
        textInputDefaultValue={
          getNameFromLanguage(plan?.name as LanguageObject) +
          ` ${t('TrainingPlans.duplicatePlanInputCopy')}`
        }
        textInputPlaceholder={t('TrainingPlans.planNamePlaceholder')}
      />
      <ConfirmationModal
        bodyText={t('InvalidPlanName.paragraphText')}
        headerText={t('InvalidPlanName.errorHeader')}
        isError={true}
        isOpen={!!showInvalidNameError}
        onClose={() => setShowInvalidNameError(false)}
        primaryButtonHandler={() => setShowInvalidNameError(false)}
        primaryButtonText={t('Button.close')}
      />
    </>
  );
};
export default DuplicatePlan;
