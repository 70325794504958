import { Document } from '@cfacorp-pathway/xp-api-typescript-client';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Card,
  LoadingIndicator,
  Typography,
} from 'cfa-react-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import first from 'lodash/first';
import styled from 'styled-components';
import StyledDocumentCheckbox from './StyledDocumentCheckbox';
import { PopupDocument } from './stepTypes';
import Constants from '@/constants';
import SubcategoryDocument from '@/pages/Explore/Operations/components/SubcategoryDocument';
import useBugsnagNotify from '@/hooks/useBugsnagNotify';
import { getCurrentLanguage } from '@/i18n/language';
import { useGetSearchResultsQuery } from '@/services/pathwayApi';
import { useGetSearchResultsQuery as xpAPIUseGetSearchResultsQuery } from '@/services/xpApi';
import {
  addSearchResults,
  ReduxSearch,
  resetSearchState,
  setCurrentPage,
  setHasNextPage,
  setNumFound,
  setPageSize,
  setQueryId,
  setResult,
  setSearchResults,
  setShowingResults,
  setStoredQuery,
} from '@/store/search/slice';
import { selectUserCountry } from '@/store/user/selectors';
import {
  selectCurrentPage,
  selectHasNextPage,
  selectNumFound,
  selectPageSize,
  selectQueryId,
  selectResult,
  selectSearchResults,
  selectShowingResults,
  selectStoredQuery,
} from '@/store/search/selectors';
import LoadingOverlay from '@/components/LoadingOverlay/LoadingOverlay';
import GenericError from '@/components/Error/GenericError';
import { pluralize } from '@/utils/language';
import { isApiError } from '@/utils/request';

interface SearchResultsProps {
  searchTermQuery: string;
  selectedDocuments: any[];
  selectedCountryCode?: string;
  onDocumentClick: (document: Document) => void;
  onSearchTermSelect: (
    suggestion: string | null,
    spellingSuggestion: string,
  ) => void;
  setSelectedDocuments: (documents: PopupDocument[]) => void;
}

export const StepThreeSearchResults: React.FC<SearchResultsProps> = ({
  searchTermQuery,
  selectedDocuments,
  selectedCountryCode,
  onDocumentClick,
  onSearchTermSelect,
  setSelectedDocuments,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { xpApi: xpApiFeatureFlag } = useFlags();
  const { notifyBugsnag } = useBugsnagNotify();

  const [misspellingSuggestion, setMisspellingSuggestion] = useState('');

  const userSettingCountry: { id?: string } = useSelector(selectUserCountry);
  const queryId: ReduxSearch['queryId'] = useSelector(selectQueryId);
  const storedQuery: ReduxSearch['query'] = useSelector(selectStoredQuery);
  const searchResults: ReduxSearch['searchResults'] =
    useSelector(selectSearchResults);
  const result: ReduxSearch['result'] = useSelector(selectResult);
  const hasNextPage: ReduxSearch['hasNextPage'] =
    useSelector(selectHasNextPage);
  const currentPage: ReduxSearch['currentPage'] =
    useSelector(selectCurrentPage);
  const pageSize: ReduxSearch['pageSize'] = useSelector(selectPageSize);
  const numFound: ReduxSearch['numFound'] = useSelector(selectNumFound);
  const showingResults: ReduxSearch['showingResults'] =
    useSelector(selectShowingResults);

  const languageCode = getCurrentLanguage();

  const maxResults = Constants.MAX_SEARCH_RESULTS;

  useEffect(() => {
    if (searchTermQuery !== storedQuery) {
      dispatch(resetSearchState());
      dispatch(setStoredQuery(searchTermQuery));
    }
  }, [dispatch, searchTermQuery, storedQuery]);

  const {
    data: xpAPIResult,
    isFetching: xpAPIIsFetching,
    error: xpAPIError,
  } = xpAPIUseGetSearchResultsQuery(
    {
      country: selectedCountryCode || userSettingCountry?.id,
      language: languageCode,
      pageNumber: currentPage,
      query: searchTermQuery ?? '',
    },
    {
      refetchOnMountOrArgChange: false,
      skip:
        !xpApiFeatureFlag || xpApiFeatureFlag === undefined || !searchTermQuery,
    },
  );

  const {
    data: coreResult,
    isFetching: coreIsFetching,
    error: coreError,
  } = useGetSearchResultsQuery(
    {
      language: languageCode,
      page: 1,
      pageSize: pageSize,
      query: searchTermQuery,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: xpApiFeatureFlag || !searchTermQuery,
    },
  );

  const isFetching = xpAPIIsFetching || coreIsFetching;
  const error = xpAPIError ?? coreError;

  useEffect(() => {
    dispatch(setHasNextPage(searchResults?.length < numFound));
  }, [dispatch, numFound, searchResults?.length]);

  useEffect(() => {
    if (
      xpApiFeatureFlag &&
      !!xpAPIResult &&
      xpAPIResult?.results !== searchResults
    ) {
      if (
        xpAPIResult.page === currentPage &&
        currentPage > 1 &&
        xpAPIResult.queryId !== queryId
      ) {
        if (xpAPIResult.page === currentPage) {
          dispatch(setQueryId(xpAPIResult.queryId));
          dispatch(addSearchResults(xpAPIResult.results));
        }
      } else {
        dispatch(setQueryId(xpAPIResult.queryId));
        dispatch(setSearchResults(xpAPIResult.results));
      }
      setMisspellingSuggestion(first(xpAPIResult.spellingSuggestions) ?? '');
      dispatch(setResult(xpAPIResult));
    } else if (!xpApiFeatureFlag && !!coreResult) {
      dispatch(setSearchResults(coreResult.results));
      setMisspellingSuggestion(
        // @ts-ignore (not sure how to resolve this one - similar to SearchResultsPage.tsx line 175)
        first(coreResult.spellCorrectedQueryResults)?.suggestedQueryText,
      );
      dispatch(setResult(coreResult));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coreResult, xpAPIResult]);

  useEffect(() => {
    const oldShowingResults = Math.min(
      Math.ceil(pageSize * currentPage),
      maxResults,
    );
    const xpApiShowingResults = Math.min(
      Math.ceil(Constants.DEFAULT_PAGING_SIZE * currentPage),
      maxResults,
    );
    const newShowingResults = !!xpApiFeatureFlag
      ? xpApiShowingResults
      : oldShowingResults;

    dispatch(
      setShowingResults(
        hasNextPage ? newShowingResults : numFound > 0 ? numFound : null,
      ),
    );
  }, [
    currentPage,
    dispatch,
    hasNextPage,
    maxResults,
    numFound,
    pageSize,
    showingResults,
    xpApiFeatureFlag,
  ]);

  useEffect(() => {
    if (result && (!!result.totalResults || !!(result as any).numberFound)) {
      dispatch(
        setNumFound(
          xpApiFeatureFlag
            ? Math.min(result.totalResults, maxResults)
            : Math.min((result as any)?.numberFound!, maxResults),
        ),
      );
    }
  }, [dispatch, maxResults, result, xpApiFeatureFlag]);

  const fetchNextPage = (num: number) => {
    if (!!xpApiFeatureFlag) {
      dispatch(setCurrentPage(num));
    } else {
      dispatch(setPageSize(num * 12 > 100 ? 100 : num * 12));
    }
  };

  if (isApiError(error)) {
    notifyBugsnag(error);
    return <GenericError />;
  }

  return (
    <>
      <LoadingOverlay isOpen={!!isFetching && !(searchResults.length > 0)} />
      {misspellingSuggestion && !isFetching && (
        <StyledMisspellingSuggestionWrapper data-testid="MisspellingSuggestionWrapper">
          {t('Search.showingResultsSuggestion')}
          {': '}
          <StyledMisspellingSuggestion
            data-testid="MisspellingSuggestion"
            onClick={() => onSearchTermSelect(null, misspellingSuggestion)}
          >
            {misspellingSuggestion}
          </StyledMisspellingSuggestion>
        </StyledMisspellingSuggestionWrapper>
      )}
      <StyledResultsText variant="body1">
        {` ${numFound} ${t('Search.numberOfResultsFor')} `}
        {<strong>{searchTermQuery}</strong>}
      </StyledResultsText>
      <div className="list-results">
        {!searchResults?.length ? (
          <StyledNoResults>{t('Search.noResults')}</StyledNoResults>
        ) : (
          searchResults?.map(document => {
            const newId = document.id ?? '';
            const newDocument = {
              id: newId,
              type: Constants.STEP_TYPES.DOCUMENT,
              reference: {
                id: newId,
              },
              name: {
                en: languageCode === 'en' ? document.name : null,
                es: languageCode === 'es' ? document.name : null,
              },
            } as PopupDocument;

            return (
              <StyledCard key={newId} title={document.name}>
                <SubcategoryDocument
                  icon={
                    document.icon === null || document.icon === 'placeholder'
                      ? 'Chickfila'
                      : document.icon
                  }
                  id={newId}
                  name={document.name ?? ''}
                  onClick={() => onDocumentClick(document)}
                />
                <StyledDocumentCheckbox
                  documentId={newId}
                  newDocument={newDocument}
                  selectedDocuments={selectedDocuments}
                  setSelectedDocuments={setSelectedDocuments}
                />
              </StyledCard>
            );
          })
        )}
      </div>
      {result && hasNextPage && (
        <LoadMoreButton
          color="secondary"
          data-testid="LoadMore"
          disabled={isFetching}
          onClick={() =>
            fetchNextPage(
              Math.trunc(showingResults / Constants.DEFAULT_PAGING_SIZE) + 1,
            )
          }
          size="lg"
          variant="filled"
        >
          <>
            {(xpAPIIsFetching || coreIsFetching) && (
              <LoadMoreLoadingIndicator disabled size="sm" variant="inline" />
            )}
            {t('Button.loadMore')}
          </>
        </LoadMoreButton>
      )}
      {showingResults && (
        <Typography variant="body1">
          {t('Search.showingResultsFooter', {
            showingResults,
            numberOfResults: numFound <= 100 ? numFound : '100+',
            resultsText: pluralize(
              numFound,
              t('Search.resultText'),
              t('Search.resultsText'),
            ),
          })}
        </Typography>
      )}
    </>
  );
};

const LoadMoreButton = styled(Button)`
  margin: 0 auto;
  margin-top: 32px;
  display: block;
`;

const LoadMoreLoadingIndicator = styled(LoadingIndicator)`
  margin-right: 10px;
  margin-top: -5px;
`;

const StyledMisspellingSuggestionWrapper = styled.span`
  display: block;
  font-weight: normal;
  color: ${props => props.theme.grayScale.gray7};
  margin-bottom: 16px;
`;

const StyledMisspellingSuggestion = styled.strong`
  color: ${props => props.theme.primaryPalette.navyBlue};
  font-weight: 700;
  cursor: pointer;
`;

const StyledResultsText = styled(Typography)`
  text-align: left;
`;

const StyledNoResults = styled.div`
  padding: 32px 24px;
`;

const StyledCard = styled(Card)`
  margin: 16px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  animation: fadeIn linear 0.3s;
  -webkit-animation: fadeIn linear 0.3s;
  -moz-animation: fadeIn linear 0.3s;
  -o-animation: fadeIn linear 0.3s;
  -ms-animation: fadeIn linear 0.3s;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export default StepThreeSearchResults;
