import {
  AuthResponse,
  LocationDetails,
  PermittedLocation,
} from '@cfacorp-pathway/xp-api-typescript-client';
import { AuthState } from '@okta/okta-auth-js';
import { initializeUserFromAuth, setDataFromAuth } from 'store/user/slice';
import Constants from '@/constants';
import { parseAccessTokenClaims } from '@/okta/okta-utils';
import { ConceptTypeLocations } from '@/services/content-api-types';
import { pathwayApi } from '@/services/pathwayApi';
import store from '@/store';
import { OktaUserRaw } from '@/types';
import { NativeAuth } from '@/utils/auth';
import { bugsnagNotify, setupBugsnag } from '@/utils/bugsnag';
import { getFeaturePermission } from '@/utils/permissions';

export const initializeWebUser = async (
  authState: AuthState,
): Promise<void> => {
  const token = authState.accessToken?.accessToken;
  if (!token) {
    return;
  }

  const auth = await getAuth(token);
  if (!auth) {
    return;
  }

  const isInternationalUser =
    auth.user?.country === Constants.SUPPORTED_COUNTRIES.GB.id;

  const accessTokenUser = parseAccessTokenClaims(
    authState?.accessToken?.claims,
  );

  if (!accessTokenUser || !authState?.accessToken?.accessToken) {
    return;
  }

  store.dispatch(setDataFromAuth(auth));

  store.dispatch(
    initializeUserFromAuth({
      firstName: accessTokenUser.nickname,
      fullName: `${accessTokenUser.nickname} ${accessTokenUser.familyName}`,
      email: accessTokenUser.email,
      userType: accessTokenUser.userType,
      isInternationalUser,
    }),
  );

  await commonSetup(
    auth,
    authState?.accessToken?.accessToken,
    authState.accessToken.claims as unknown as OktaUserRaw,
  );
};

export const initializeNativeUser = async ({
  accessToken,
  nativeUser,
}: NativeAuth) => {
  if (!accessToken || !nativeUser) {
    console.error('No access token or native user');
    return;
  }

  const auth = await getAuth(accessToken);
  if (!auth) {
    return;
  }

  const isInternationalUser =
    auth.user?.country === Constants.SUPPORTED_COUNTRIES.GB.id;

  store.dispatch(setDataFromAuth(auth));

  store.dispatch(
    initializeUserFromAuth({
      firstName: nativeUser.nickname,
      fullName: `${nativeUser.nickname} ${nativeUser.family_name}`,
      email: nativeUser.email,
      userType: nativeUser.userType,
    }),
  );

  store.dispatch(initializeUserFromAuth({ isInternationalUser }));

  await commonSetup(auth, accessToken, nativeUser);
};

const commonSetup = async (
  auth: AuthResponse,
  accessToken: string,
  user: OktaUserRaw,
): Promise<void> => {
  store.dispatch(pathwayApi.endpoints.getDocumentCookie.initiate());

  const monitoringEnabled = getFeaturePermission('monitoring');

  if (monitoringEnabled) {
    setupBugsnag();
  }

  const licenseeLocations = getLocations(
    auth.user?.locations,
    LocationDetails.businessModel.LIC,
  );

  const franchiseeLocations = getLocations(
    auth.user?.locations,
    LocationDetails.businessModel.FRANCHISEE,
  );

  const supportCenterLocations = getLocations(
    auth.user?.locations,
    LocationDetails.businessModel.CORP,
  );

  store.dispatch(
    initializeUserFromAuth({
      licenseeLocations,
      franchiseeLocations,
      supportCenterLocations,
    }),
  );

  if (isLicenseeStaff(user)) {
    await getLocationsForLicenseeStaff(accessToken);
  }
};

const getLocations = (
  locations: PermittedLocation[] | undefined,
  by: LocationDetails.businessModel,
) =>
  locations
    ?.map(loc => loc.location!)
    .filter(loc => loc?.businessModel === by) ?? [];

const getAuth = async (
  accessToken: string,
): Promise<AuthResponse | undefined> => {
  try {
    const resp = await fetch(`${Constants.XP_API_BASE_URL}/auth`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      credentials: 'include',
    });

    if (!resp.ok) {
      throw new Error('Failed to get auth');
    }

    return await resp.json();
  } catch (err) {
    console.warn(err);
  }
};

const getLocationsForLicenseeStaff = async (accessToken: string) => {
  try {
    const resp = await fetch(
      `${Constants.PATHWAY_API.BASE_URL}/locations?type=${Constants.LOCATION_TYPES.LIC}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    const locations: ConceptTypeLocations[] = await resp.json();

    store.dispatch(
      initializeUserFromAuth({
        licenseeLocations:
          locations?.find(
            type => type.conceptType === Constants.LOCATION_TYPES.LIC,
          )?.locations ?? [],
      }),
    );
  } catch (err) {
    bugsnagNotify(err);
    console.error(err);
  }
};

const isLicenseeStaff = (user: OktaUserRaw) => {
  return (user['cfa_perms']?.['P20']?.LICENSEESTAFF?.length ?? 0) > 0;
};
