import 'bootstrap/dist/css/bootstrap.min.css';
import 'cfa-react-components/dist/styles.min.css';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './app/App';
import './styles/bootstrap-override.css';
import './styles/cfa-design-system-override.css';
import './styles/index.css';

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <App />
  </StrictMode>,
);
