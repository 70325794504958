import Constants from 'constants/index';
import { useTranslation } from 'react-i18next';
import { Ribbon } from 'icons';
import styled from 'styled-components';
import { ChecklistDTO } from '@cfacorp-pathway/xp-api-typescript-client';
import PlanCard from '../PlanCard';
import PlanCardIcon from '../PlanCardComponents/PlanCardIcon';
import PlanCardTag from '../PlanCardComponents/PlanCardTag';
import PlanCardContent from '../PlanCardContent/PlanCardContent';
import PlanCardTitle from '../PlanCardComponents/PlanCardTitle';
import PlanCardLabel from '../PlanCardComponents/PlanCardLabel';
import PlanCardSubHeader from '../PlanCardComponents/PlanCardSubHeader';
import { getDueDateColor, getDueDateString } from '@/utils/dueDate';
import { ChecklistStatus, LanguageObject } from '@/types/types';
import { getTimeSpentOnPlan } from '@/utils/time';
import { getNameFromLanguage } from '@/utils/language';
import { generateProgressText } from '@/utils/generateProgressText';
import { getProgressBarText } from '@/utils/generateProgressBarText';
import { mapCategoryToAliasTranslation } from '@/utils/categoryUtils';

interface ReportsTeamMemberPlanCardProps {
  plan: ChecklistDTO;
  status: ChecklistStatus[];
  teamMemberId: string;
}

const ReportsTeamMemberPlanCard: React.FC<ReportsTeamMemberPlanCardProps> = ({
  plan,
  status,
  teamMemberId,
}) => {
  const { t } = useTranslation();
  const teamMemberPlanStatus = status?.find(
    planStatus => planStatus?.userId === teamMemberId,
  );
  const isComplete = status?.[0]?.status === Constants.TRAINING_PLANS.COMPLETED;
  const dueDate = new Date(status?.[0]?.dueDate!).getTime();
  const stepsComplete = status?.[0]?.stepsComplete;
  const percentComplete =
    plan.stepsTotal && Math.round((stepsComplete! / plan?.stepsTotal) * 100);
  const timeSpentOnPlan = getTimeSpentOnPlan(
    teamMemberPlanStatus as ChecklistStatus,
  );
  const stepCount = plan?.stepsTotal;

  return (
    <>
      {!!plan && (
        <>
          <PlanCard
            category={plan?.category}
            dataTestId={'TeamMemberPlan'}
            link={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${plan?.id}/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PROGRESS_PATH_NAME}/${teamMemberId}`}
            progress={!isComplete ? percentComplete : null}
          >
            <PlanCardContent>
              <PlanCardLabel>
                {t(mapCategoryToAliasTranslation(plan?.category))}
              </PlanCardLabel>
              <PlanCardTitle>
                {getNameFromLanguage(plan?.name as LanguageObject)}
              </PlanCardTitle>
              {!!isComplete ? (
                <PlanCardSubHeader
                  className="success"
                  dataTestId="PlanTaskCount"
                >
                  {generateProgressText({
                    completedStepCount: stepsComplete,
                    isComplete,
                    timeSpentOnPlan: timeSpentOnPlan!,
                    stepCount,
                  })}
                </PlanCardSubHeader>
              ) : (
                <PlanCardSubHeader dataTestId="PlanTaskCount">
                  {getProgressBarText({
                    completedStepCount: stepsComplete,
                    isComplete,
                    percentComplete,
                    stepCount,
                    timeSpentOnPlan: timeSpentOnPlan!,
                  })}
                </PlanCardSubHeader>
              )}
            </PlanCardContent>
            {!isComplete && !!status?.[0]?.dueDate && (
              <PlanCardTag
                color={getDueDateColor(dueDate)}
                text={getDueDateString(dueDate)}
                tooltip={t('TrainingPlans.accountability.due.dueDate', {
                  dueDate: new Date(dueDate).toLocaleDateString(),
                })}
              />
            )}
            {!!isComplete && (
              <PlanCardIcon
                icon={<CompleteRibbon />}
                tooltip={
                  <>
                    <strong>
                      {t('TrainingPlans.statusOptions.completed')}:
                    </strong>
                    {new Date(
                      status?.[0]?.completionDate!,
                    ).toLocaleDateString()}
                  </>
                }
              />
            )}
          </PlanCard>
        </>
      )}
    </>
  );
};

const CompleteRibbon = styled(Ribbon)`
  height: 28px;
  width: 28px;
`;

export default ReportsTeamMemberPlanCard;
