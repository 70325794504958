import Constants from 'constants/index';
import { getCurrentLanguage } from '@/i18n/language';
import { LanguageObject } from '@/types/types';

export const getNameFromLanguage = (
  name: LanguageObject,
  selected?: string,
) => {
  const language = (() => {
    switch (true) {
      case selected === Constants.LANGUAGE_OPTIONS.ENGLISH:
        return name?.en;
      case selected === Constants.LANGUAGE_OPTIONS.SPANISH:
        // Not all names have a Spanish counterpart so we return English in those cases
        return name?.es === undefined ? name?.en : name?.es;
      case selected === Constants.ADMIN_MANAGING_COUNTRIES.PR.LABEL_KEY:
        // Not all names have an English counterpart so we return Spanish in those cases
        return name?.en === undefined ? name?.es : name?.en;
      default:
        return getCurrentLanguage() !==
          Constants.LANGUAGE.ENGLISH_LANGUAGE_CODE && name?.es
          ? name?.es
          : name?.en;
    }
  })();
  if (!language) {
    return '';
  }
  return language;
};

export function itemByLanguage<T>(
  name: Record<string, T> | undefined,
  fallback: NoInfer<T>,
  selected?: string,
): T;
export function itemByLanguage<T>(
  name: Record<string, T> | undefined,
  fallback?: undefined,
  selected?: string,
): T | undefined;
export function itemByLanguage<T>(
  name: Record<string, T> | undefined,
  fallback?: NoInfer<T>,
  selected?: string,
): T | undefined {
  if (!name) {
    return fallback;
  }

  const item = (() => {
    switch (true) {
      case selected === Constants.LANGUAGE_OPTIONS.ENGLISH:
        return name.en;
      case selected === Constants.LANGUAGE_OPTIONS.SPANISH:
        // Not all names have a Spanish counterpart so we return English in those cases
        return name.es === undefined ? name.en : name.es;
      case selected === Constants.ADMIN_MANAGING_COUNTRIES.PR.LABEL_KEY:
        // Not all names have an English counterpart so we return Spanish in those cases
        return name.en === undefined ? name.es : name.en;
      default:
        return getCurrentLanguage() !==
          Constants.LANGUAGE.ENGLISH_LANGUAGE_CODE && name.es
          ? name.es
          : name.en;
    }
  })();

  return item ?? fallback;
}

export const getLanguageCode = (
  language = Constants.LANGUAGE_OPTIONS.ENGLISH,
) => {
  switch (language) {
    case Constants.LANGUAGE_OPTIONS.ESPANOL:
    case Constants.LANGUAGE_OPTIONS.SPANISH:
      return Constants.LANGUAGE.SPANISH_LANGUAGE_CODE;
    default:
      return Constants.LANGUAGE.ENGLISH_LANGUAGE_CODE;
  }
};

export const pluralize = (
  quantity: number,
  singular: string,
  plural: string,
) => (quantity === 1 ? `${singular}` : `${plural}`);
