import { useTranslation } from 'react-i18next';
import { FailedIconAlertTriangleFilled } from '../Cards/PlanCard/PlanCards/MyPlansComplianceCard';

interface FailedAlertMessageProps {
  children: React.ReactNode;
}

const FailedAlertMessage: React.FC<FailedAlertMessageProps> = ({
  children,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <FailedIconAlertTriangleFilled />{' '}
      {t('TrainingPlans.compliancePlanNotPassed')}
      {children}
    </>
  );
};

export default FailedAlertMessage;
