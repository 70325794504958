import Constants from '@/constants';
import { useGetDocumentCookieQuery } from '@/services/pathwayApi';

const useDocumentCookieRefresh = (skip = false) => {
  const { isSuccess, isFetching, error } = useGetDocumentCookieQuery(
    undefined,
    {
      pollingInterval: Constants.DOCUMENT_COOKIE_REFRESH.POLL_TIME_DEFAULT,
      skip: skip,
    },
  );

  return {
    isFetching,
    isSuccess,
    error,
  };
};

export default useDocumentCookieRefresh;
