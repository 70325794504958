import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import RadioButtonList from 'components/RadioButton/RadioButtonList';
import { TextField, Typography } from 'cfa-react-components';
import StepWizardPopUp from 'components/popups/StepWizardPopUp';
import { OperatorDTO } from '@cfacorp-pathway/xp-api-typescript-client';

interface AddQuizPopUpProps {
  isLicenseeUser: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
  operators: OperatorDTO[];
  submitHandler: ({
    operator,
    quizNameInput,
  }: {
    operator: string;
    quizNameInput: string;
  }) => void;
}

const AddQuizPopUp: React.FC<AddQuizPopUpProps> = ({
  isLicenseeUser,
  open,
  setOpen,
  operators,
  submitHandler,
}) => {
  const isMultiOperator = operators?.length > 1 && !isLicenseeUser;
  const initialStep = isMultiOperator ? 1 : 2;
  const [operator, setOperator] = useState({}) as any;
  const [operatorSelected, setOperatorSelected] = useState(false);
  const { t } = useTranslation();
  const [stepIndex, setStepIndex] = useState(initialStep);
  const [stepWizard, setStepWizard] = useState() as any;
  const [quizNameInput, setQuizNameInput] = useState('');

  const operatorsNameArray = operators?.flat().map(({ name }) => name);

  useEffect(() => {
    if (operators?.length && operators?.length === 1) {
      setOperator(operators[0]);
    }
  }, [operators]);

  useEffect(() => {
    if (isMultiOperator) {
      setOperator({});
    }
    setStepIndex(initialStep);
    setOperatorSelected(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialStep, open]);

  const closePopup = () => {
    setStepIndex(initialStep);
    setOpen(false);
  };

  const selectOperator = (operatorName: string) => {
    const selectedOperator = operators.find(
      ({ name }) => name === operatorName,
    );
    setOperator(selectedOperator);
    setOperatorSelected(true);
  };

  const Step1 = () => (
    <>
      <DialogHeader gutterBottom variant="h3">
        {t('TrainingPlans.buildQuizzes.chooseOperator')}
      </DialogHeader>
      <RadioButtonList
        defaultValue={''}
        listItems={operatorsNameArray as string[]}
        name={'operatorList'}
        setValue={selectOperator}
      />
    </>
  );

  const Step2 = () => (
    <>
      <DialogHeader variant="h3">
        {t('Generic.giveName', { object: t('Generic.quiz') })}
      </DialogHeader>
      <TextField
        data-testid="InputQuizName"
        fullWidth
        onChange={e => setQuizNameInput(e.target.value)}
        onKeyDown={e =>
          e.key === 'Enter' && submitHandler({ operator, quizNameInput })
        }
        placeholder={t('TrainingPlans.buildQuizzes.quizNamePlaceholder')}
        value={quizNameInput}
      />
    </>
  );

  const stepsData = {
    1: {
      labelButtonDeny: t('Button.cancel'),
      labelButtonNext: t('Button.next'),
      onClickBack: () => {
        closePopup();
      },
      onClickNext: () => {
        stepWizard?.nextStep();
      },
      isDisabledButtonNext: !operatorSelected,
      ContentData: Step1,
    },
    2: {
      labelButtonDeny: t('Button.back'),
      labelButtonNext: t('Button.create'),
      onClickBack: () => {
        isMultiOperator ? stepWizard?.previousStep() : closePopup();
      },
      onClickNext: () => {
        submitHandler({ operator, quizNameInput });
      },
      isDisabledButtonNext: !quizNameInput,
      ContentData: Step2,
    },
  };

  const step = (stepsData as any)[stepIndex];

  return (
    <StepWizardPopUp
      closePopup={closePopup}
      initialStep={initialStep}
      open={open}
      setStepIndex={setStepIndex}
      setStepWizard={setStepWizard}
      step={step}
      steps={stepsData}
    />
  );
};

const DialogHeader = styled(Typography)`
  margin-bottom: 16px;
`;

export default AddQuizPopUp;
