import styled from 'styled-components';
import { Card, Divider, Typography } from 'cfa-react-components';
import { PropsWithChildren } from 'react';

type PlanItemCardProps = PropsWithChildren<{
  disabled?: boolean;
  sectionName?: string;
}>;

const PlanItemCard: React.FC<PlanItemCardProps> = ({
  sectionName,
  children,
}) => {
  return (
    <PlanItemCardContainer>
      {sectionName && (
        <>
          <StyledTitleWrapper>
            <StyledTitle
              data-testid={`Title-${sectionName}`}
              fontWeight="bold"
              variant="body1"
            >
              {sectionName}
            </StyledTitle>
          </StyledTitleWrapper>
          <SectionDivider variant="fullLength" />
        </>
      )}
      <CardBody>{children}</CardBody>
    </PlanItemCardContainer>
  );
};

const PlanItemCardContainer = styled(Card)`
  margin-bottom: 24px;
  padding: 16px;
`;

const SectionDivider = styled(Divider)`
  margin: 16px 0;
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  overflow: hidden;
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const StyledTitle = styled(Typography)`
  color: ${props => props.theme.grayScale.gray6};
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
`;

export default PlanItemCard;
