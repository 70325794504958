import { createSlice } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import { LanguageObject } from '@/types/types';

const filterSection = (
  sections: [
    {
      id: string;
      name: LanguageObject | any;
    },
  ],
  sectionId: string,
) => sections.find(section => section.id === sectionId);

const filterStep = (
  steps: [
    {
      id: string;
      name: LanguageObject | any;
      note: LanguageObject | any;
      urls: any;
    },
  ],
  id: string,
) => steps.find(step => step.id === id);

const initializeMissingProperties = (payload: any) => {
  const initializedState = cloneDeep(payload);

  if (!initializedState?.name?.es) {
    if (initializedState?.name) {
      initializedState.name.es = '';
    }
  }

  initializedState?.steps?.map((step: any) => {
    if (step.type === 'task') {
      if (!step.name.es) {
        step.name.es = '';
      }
      if (step.note) {
        if (!step.note.es) {
          step.note.es = '';
        }
      }
    }
  });

  initializedState?.sections?.map((section: any) => {
    if (!section.name.es) {
      section.name.es = '';
    }

    section.steps.map((step: any) => {
      if (step.type === 'task') {
        if (!step.name.es) {
          step.name.es = '';
        }
        if (step.note) {
          if (!step.note.es) {
            step.note.es = '';
          }
        }
      }
    });
  });
  return initializedState;
};

export const slice = createSlice({
  name: 'manageTranslations',
  initialState: { mutable: {}, initial: {} },
  reducers: {
    initializeTrainingPlanTranslations: (_, action) => {
      const initializedState = initializeMissingProperties(action.payload);
      return {
        mutable: { ...initializedState },
        initial: { ...initializedState },
      };
    },
    updatePlanNameTranslation: (state, action) => {
      const { id, value, targetLanguage } = action.payload;
      // @ts-ignore
      if (state.mutable.id === id) {
        // @ts-ignore
        state.mutable.name[targetLanguage] = value;
      }
    },
    updateSectionTranslation: (state, action) => {
      const { id, value, targetLanguage } = action.payload;
      // @ts-ignore
      const section = filterSection(state.mutable.sections, id);
      if (section) {
        section.name[targetLanguage] = value;
      }
    },
    updateTaskNameTranslation: (state, action) => {
      const { id, value, sectionId, targetLanguage } = action.payload;
      // @ts-ignore
      let steps = state.mutable.steps;
      if (sectionId) {
        // @ts-ignore
        steps = filterSection(state.mutable.sections, sectionId)?.steps;
      }
      const step = filterStep(steps, id);
      if (step) {
        step.name[targetLanguage] = value;
      }
    },
    updateTaskNotesTranslation: (state, action) => {
      const { id, value, sectionId, targetLanguage } = action.payload;
      // @ts-ignore
      let steps = state.mutable.steps;
      if (sectionId) {
        // @ts-ignore
        steps = filterSection(state.mutable.sections, sectionId)?.steps;
      }
      const step = filterStep(steps, id);
      if (step) {
        step.note[targetLanguage] = value;
      }
    },
    updateTaskUrlTranslation: (state, action) => {
      const { id, value, sectionId, index, targetLanguage } = action.payload;
      // @ts-ignore
      let steps = state.mutable.steps;
      if (sectionId) {
        // @ts-ignore
        steps = filterSection(state.mutable.sections, sectionId)?.steps;
      }
      const step = filterStep(steps, id);
      if (step) {
        const url = step.urls[index]?.urlSet;
        if (url) {
          url[targetLanguage] = value;
        }
      }
    },
    updateQuizNameTranslation: (state, action) => {
      const { id, value, targetLanguage } = action.payload;
      // @ts-ignore
      if (state.mutable.quizId === id) {
        // @ts-ignore
        state.mutable.name[targetLanguage] = value;
      }
    },
    updateQuestionTranslation: (state, action) => {
      const { value, questionIndex, targetLanguage } = action.payload;
      // @ts-ignore
      const questions = state.mutable.questions;
      questions[questionIndex].question[targetLanguage] = value;
    },
    updateAnswerTranslation: (state, action) => {
      const { value, questionIndex, answerIndex, targetLanguage } =
        action.payload;
      // @ts-ignore
      const answers = state.mutable.questions[questionIndex].answers;
      answers[answerIndex].answer[targetLanguage] = value;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  initializeTrainingPlanTranslations,
  updatePlanNameTranslation,
  updateSectionTranslation,
  updateTaskNameTranslation,
  updateTaskNotesTranslation,
  updateTaskUrlTranslation,
  updateQuizNameTranslation,
  updateQuestionTranslation,
  updateAnswerTranslation,
} = slice.actions;

export default slice.reducer;
