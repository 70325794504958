import { useEffect, useState } from 'react';
import packagejson from '../../../package.json';
import Constants from '@/constants';
import { isCurrentEnvironment } from '@/utils/environment';
import { sendNativeMessage } from '@/hooks/useNativeListener';
import { subscribeToAuthStateChange } from '@/okta/oktaConfig';
import { OktaUserRaw } from '@/types';
import { getAuthFromNative } from '@/utils/auth';

export const useUserAuth = () => {
  const [userAuthenticated, setUserAuthenticated] = useState(false);
  const [user, setUser] = useState<OktaUserRaw | undefined>();

  useEffect(() => {
    if (!isCurrentEnvironment(Constants.DEV)) {
      console.log(`ver. ${packagejson.version}`);
    }

    const { nativeUser } = getAuthFromNative();
    if (nativeUser) {
      setUserAuthenticated(true);
      setUser(nativeUser);
    }

    const { unsubscribe } = subscribeToAuthStateChange(({ authState }) => {
      sendNativeMessage({
        type: 'authStateManagerEvent',
        payload: {
          isAuthenticated: authState.isAuthenticated,
        },
      });

      if (authState.isAuthenticated) {
        setUserAuthenticated(true);
        setUser(authState?.idToken?.claims as unknown as OktaUserRaw);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return { userAuthenticated, user };
};
