import Constants from 'constants/index';
import { isTrainingModePath, isTrainingToCompletePath } from 'utils/url';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsTrainingMode } from 'store/trainingMode/selectors';
import ConfirmationModal from 'components/popups/ConfirmationModal';
import { useTranslation } from 'react-i18next';
import {
  setHeader,
  setHeaderLabel,
  setHeaderSubtext,
} from 'store/header/slice';
import {
  exitTrainingMode,
  resetTrainingModeData,
} from 'store/trainingMode/slice';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface TrainingModeWrapperProps {
  children: React.ReactNode;
}

interface TrainingModeWrapperStyleProps {
  $isTrainingMode: boolean;
}

interface BlurWrapperStyleProps {
  $blurFilter: boolean;
}

const TrainingModeWrapper: React.FC<TrainingModeWrapperProps> = ({
  children,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();
  const { plansRedesign: plansRedesignFeatureFlag } = useFlags();
  const isTrainingMode = useSelector(selectIsTrainingMode);

  const isTrainingModeView =
    (!!isTrainingModePath(location) || !!isTrainingToCompletePath(location)) ??
    false;

  const backToTrainingMode = () => {
    history.push({
      pathname: `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.TRAINING_MODE_PATH_NAME}`,
    });
  };

  const onExitTrainingMode = () => {
    dispatch(setHeader(''));
    dispatch(setHeaderLabel(''));
    dispatch(setHeaderSubtext(''));
    dispatch(exitTrainingMode());
    dispatch(resetTrainingModeData());

    history.push({
      pathname: '/',
    });
  };

  return (
    <StyledTrainingModeWrapper $isTrainingMode={isTrainingMode}>
      <BlurWrapper $blurFilter={isTrainingMode && !isTrainingModeView}>
        {children}
      </BlurWrapper>
      <ConfirmationModal
        bodyText={
          plansRedesignFeatureFlag
            ? t('TrainingPlans.trainTogether.exitModalbody')
            : t('TrainingPlans.trainingMode.exitingModal')
        }
        headerText={
          plansRedesignFeatureFlag
            ? t('TrainingPlans.trainTogether.exitModalHeader')
            : t('TrainingPlans.trainingMode.exitingTrainingMode')
        }
        isOpen={isTrainingMode && !isTrainingModeView}
        primaryButtonColor={'secondary'}
        primaryButtonHandler={onExitTrainingMode}
        primaryButtonText={t('Button.exit')}
        secondaryButtonHandler={backToTrainingMode}
        secondaryButtonText={t('Button.backToTrainingMode')}
        showCloseButton={false}
      />
    </StyledTrainingModeWrapper>
  );
};

const StyledTrainingModeWrapper = styled.div<TrainingModeWrapperStyleProps>`
  ${({ $isTrainingMode, theme }) =>
    $isTrainingMode &&
    `
    height: 100%;
    border: 8px solid ${theme.primaryPalette.teal};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    overflow: auto;
  `}
`;
const BlurWrapper = styled.span<BlurWrapperStyleProps>`
  ${({ $blurFilter }) =>
    $blurFilter &&
    `
    filter: blur(12px);
  `}
`;

export default TrainingModeWrapper;
