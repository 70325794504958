import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';
import { useFeaturePermission } from './permissions';
import { OktaUserRaw } from '@/types';
import Constants from '@/constants';

const setupDataDogRum = (user: OktaUserRaw) => {
  const userId = user?.['cfa-guid'] || 'unknown';
  const userCountry = user?.['cfa-loc-iso_country'] || 'unknown';
  const userAudience = user?.['cfa_aud'] || 'unknown';
  const userLocations = user?.['cfa-locations'] || 'unknown';

  const shouldStartDatadog =
    !Constants.IS_LOCAL_DEPLOYMENT &&
    !Constants.IS_IN_CYPRESS_TEST &&
    Constants.DATADOG_APP_ID !== undefined &&
    Constants.DATADOG_CLIENT_TOKEN !== undefined;

  if (!shouldStartDatadog) {
    console.info(`Not starting DD integration...`);
    return;
  }

  datadogRum.init({
    applicationId: Constants.DATADOG_APP_ID!,
    clientToken: Constants.DATADOG_CLIENT_TOKEN!,
    site: 'datadoghq.com',
    service: Constants.DATADOG_SERVICE,
    env: Constants.ENV,
    version: Constants.APP_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'allow',
    // allowedTracingUrls: [
    //   {
    //     match: `${getParentUrl()}`,
    //     propagatorTypes: ["datadog"],
    //   },
    // ],
    compressIntakeRequests: true,
  });

  datadogLogs.init({
    clientToken: Constants.DATADOG_CLIENT_TOKEN!,
    site: 'datadoghq.com',
    service: Constants.DATADOG_SERVICE,
    env: Constants.ENV,
    version: Constants.APP_VERSION,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: ['log', 'info', 'warn', 'error'], //"all" or an Array of "log" "debug" "info" "warn" "error"
    sessionSampleRate: 100,
    // beforeSend: (log) => { // Discard specific logs. https://docs.datadoghq.com/logs/log_collection/javascript/#discard-specific-logs
    //   // discard 404 network errors
    //   if (log.http && log.http.status_code === 404) {
    //     return false
    //   }
    //   return true
    // },
  });

  datadogRum.setUser({
    id: userId,
    audience: userAudience,
    country: userCountry,
    locations: userLocations,
  });

  datadogLogs.setGlobalContext({
    service: Constants.DATADOG_SERVICE,
    userId: userId,
    userAudience: userAudience,
    userCountry: userCountry,
    userLocations: userLocations,
    system_tag: 'pathway',
    team: 'OTS - Pathway 2.0',
    sub_dept: 'OTS',
    org_id: '221067',
  });

  datadogRum.setGlobalContext({
    system_tag: 'pathway',
    team: 'OTS - Pathway 2.0',
    sub_dept: 'OTS',
    org_id: '221067',
  });
};

export const DatadogRumSetup: React.FC<{ user?: OktaUserRaw }> = ({ user }) => {
  const rumEnabled = useFeaturePermission('rum');

  useEffect(() => {
    if (user && rumEnabled) {
      setupDataDogRum(user);
    }
  }, [rumEnabled, user]);

  return null;
};
