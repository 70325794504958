import Constants from 'constants/index';
import { ApiError } from '@cfacorp-pathway/xp-api-typescript-client';
import oktaAuth from 'okta/oktaConfig';
import { setWindowObjectToConnectAppToCypress } from '@/utils/environment';
import { CustomError, NativeAndroidUser, OktaUserRaw } from '@/types';

export const getRefreshTokenWithRedirect = () => {
  if (Constants.IS_IN_CYPRESS_TEST) {
    setWindowObjectToConnectAppToCypress('signInWithRedirect', 'true');
    return;
  }
  return oktaAuth.signInWithRedirect();
};

export const isUnAuthorizedError = (
  error: CustomError | ApiError | undefined,
) => error && error?.status === 401;

interface NativeAuthNative {
  /** Whether or not the app is currently running in a native WebView. */
  isNative: true;
  /** The access token of the user from native. */
  accessToken: string;
  /** The auth user from native. */
  nativeUser: OktaUserRaw;
}

interface NativeAuthNonNative {
  isNative: false;
  accessToken: undefined;
  nativeUser: undefined;
}

export type NativeAuth = NativeAuthNative | NativeAuthNonNative;

/** Get auth from the native app. */
export const getAuthFromNative = (): NativeAuth => {
  const androidUser = getAndroidUser();

  const user = window.userFromToken
    ? (JSON.parse(window.userFromToken) as OktaUserRaw)
    : androidUser?.userFromToken;

  const token = window.accessToken ?? androidUser?.accessToken;

  const isNative = user !== undefined;

  if (isNative) {
    return {
      isNative: true,
      accessToken: token!,
      nativeUser: user,
    };
  }

  return {
    isNative: false,
    accessToken: undefined,
    nativeUser: undefined,
  };
};

const getAndroidUser = (): NativeAndroidUser | undefined => {
  if (window.ReactNativeWebView?.injectedObjectJson) {
    return JSON.parse(window.ReactNativeWebView.injectedObjectJson());
  }
  return undefined;
};
