import Constants from 'constants/index';
import { isApiError } from 'utils/request';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import {
  useGetQuizzesQuery,
  useUpdateTrainingPlanMutation,
} from 'services/pathwayApi';
import { setBuildQuizzes, loadMoreQuizzes } from 'store/quizzes/slice';
import {
  selectPaginatedQuizzes,
  selectPagination,
} from 'store/quizzes/selector';
import LoadMorePaginator from 'components/LoadMorePaginator/LoadMorePaginator';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';
import GenericError from 'components/Error/GenericError';
import { Modal, ModalBody, ModalHeader } from 'cfa-react-components';
import useBugsnagNotify from 'hooks/useBugsnagNotify';
import {
  ChecklistDTO,
  CustomQuizDTO,
  UserChecklistResponse,
} from '@cfacorp-pathway/xp-api-typescript-client';
import QuizLibraryCard from '../Cards/QuizLibraryCard';
import NoMessage from '@/components/NoMessage/NoMessage';

interface QuizLibraryPopUpProps {
  isOpen: boolean;
  onClose: ({
    withPreview,
    quiz,
  }: {
    withPreview?: boolean;
    quiz?: CustomQuizDTO;
  }) => void;
  sectionId: string;
  refetch: () => void;
  trainingPlan: ChecklistDTO | UserChecklistResponse | ChecklistDTO[];
}

const QuizLibraryPopUp: React.FC<QuizLibraryPopUpProps> = ({
  isOpen,
  onClose,
  sectionId,
  refetch,
  trainingPlan,
}) => {
  const { notifyBugsnag } = useBugsnagNotify();
  const { t } = useTranslation();
  const { showing, total } = useSelector(selectPagination);
  const paginatedQuizzes = useSelector(selectPaginatedQuizzes);
  const dispatch = useDispatch();

  const {
    data: quizzes,
    error: errorGettingQuizzes,
    isFetching: isFetchingQuizzes,
  } = useGetQuizzesQuery();

  useEffect(() => {
    dispatch(setBuildQuizzes({ quizzes: quizzes ?? [] }));
  }, [dispatch, quizzes]);

  const [updateTrainingPlan] = useUpdateTrainingPlanMutation();

  if (isApiError(errorGettingQuizzes)) {
    notifyBugsnag(errorGettingQuizzes);
    return <GenericError />;
  }

  const stepType = Constants.STEP_TYPES.QUIZ;

  const withinSection = !!sectionId?.length;

  const getQuizStep = (quiz: CustomQuizDTO) => ({
    id: uuidv4(),
    name: {
      en: quiz.name?.en ?? quiz.name?.es,
      es: quiz.name?.es ?? quiz.name?.en,
    },
    type: stepType,
    quizId: quiz.typeFormId,
    reference: null,
    note: null,
    urls: [],
    available: true,
  });

  const updatePlanAndRefetchThenRunOnClose = (payload: any) => {
    updateTrainingPlan(payload)
      .unwrap()
      .then(() => {
        refetch();
        onClose({ withPreview: false });
      })
      .catch(err => {
        notifyBugsnag(err);
      });
  };

  const buildPayloadForPlanUpdateWithQuiz = ({ quizStep, newSteps }: any) => ({
    locations: (trainingPlan as ChecklistDTO).locations,
    checklist: withinSection
      ? {
          ...trainingPlan,
          sections: [
            ...(trainingPlan as ChecklistDTO)?.sections!.map((section: any) =>
              section.id === sectionId
                ? {
                    ...section,
                    steps: [...section?.steps!, quizStep],
                  }
                : section,
            ),
          ],
        }
      : {
          ...trainingPlan,
          steps: newSteps,
        },
  });

  const onAddQuiz = (quiz: CustomQuizDTO) => {
    const quizStep = getQuizStep(quiz);
    const newSteps = [...(trainingPlan as ChecklistDTO)?.steps!, quizStep];
    const payload = buildPayloadForPlanUpdateWithQuiz({ quizStep, newSteps });
    updatePlanAndRefetchThenRunOnClose(payload);
  };

  return (
    <>
      <LoadingOverlay isOpen={isFetchingQuizzes} />
      <StyledModal
        // @ts-ignore
        onClose={onClose}
        scrollMode="modal-body"
        show={isOpen}
        size="lg"
      >
        <ModalHeader>{t('TrainingPlans.addQuiz.title')}</ModalHeader>
        <ModalBody>
          <LoadingOverlay isOpen={isFetchingQuizzes} />
          {!!paginatedQuizzes?.length && !isFetchingQuizzes && (
            <>
              {paginatedQuizzes?.map((quiz, index) => {
                return (
                  <QuizLibraryCard
                    key={index}
                    onAddQuiz={onAddQuiz}
                    onPreviewQuiz={quizToPreview =>
                      onClose({ withPreview: true, quiz: quizToPreview })
                    }
                    quiz={quiz}
                    quizAddButtonTestId="QuizLibraryAddButton"
                    quizNameTestId="QuizLibraryName"
                  />
                );
              })}
              <LoadMorePaginator
                onClick={() => dispatch(loadMoreQuizzes())}
                showing={showing}
                showingText={t('TrainingPlans.showingXOfYQuizzes', {
                  showing,
                  total,
                })}
                total={total}
              />
            </>
          )}
          {!paginatedQuizzes?.length && !isFetchingQuizzes && (
            <NoMessage
              message={
                <Trans i18nKey={'TrainingPlans.buildQuizzes.noQuizzesPopup'} />
              }
            />
          )}
        </ModalBody>
      </StyledModal>
    </>
  );
};

const StyledModal = styled(Modal)`
  .cfa-modal-header {
    padding-bottom: 2rem;
  }
`;

export default QuizLibraryPopUp;
