import Constants from 'constants/index';
import { getNameFromLanguage } from 'utils/language';
import { getClickableLink } from 'utils/url';
import { isValidUrl } from 'utils/isValidUrl';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import {
  IconAlertTriangleFilled,
  IconCertificate,
  IconDeviceGamepad2,
  IconEdit,
  IconLink,
  IconNote,
  IconTrash,
} from '@tabler/icons-react';
import { IconButton, Typography } from 'cfa-react-components';
import PopoverMenuButton from 'components/PopoverMenuButton/PopoverMenuButton';
import PopoverMenuButtonItem from 'components/PopoverMenuButton/PopoverMenuButtonItem';
import { ChecklistSectionStepDTO } from '@cfacorp-pathway/xp-api-typescript-client';
import EditButtons from './EditButtons';
import EditTask from './EditTask';
import SixDotIcon from './SixDotIcon';
import { LanguageObject, TaskProps } from '@/types/types';

export interface SectionFunctionProps {
  id: string;
  name: string;
  sectionId: string;
  type: string;
}

interface TaskContainerProps {
  className?: string;
  isAllowedToEdit: boolean;
  isPreview?: boolean;
  onCancel: () => void;
  onDelete: (section: SectionFunctionProps) => void;
  onEdit: (
    type: string,
    title: string | null,
    task: TaskProps,
    id: string,
  ) => void;
  sectionId?: string;
  step: ChecklistSectionStepDTO;
  taskUpdated: boolean;
}

interface TaskAndProcedureBodyStyleProps {
  $isExpandable: boolean;
}

interface TaskAndProcedureIconStyleProps {
  $isGame?: boolean;
  $isQuiz?: boolean;
}

interface TaskAndProcedureIconsStyleProps {
  $isQuiz: boolean;
}

const TaskContainer: React.FC<TaskContainerProps> = ({
  className,
  isAllowedToEdit,
  isPreview,
  onCancel,
  onDelete,
  onEdit,
  sectionId,
  step,
  taskUpdated,
}) => {
  const { t } = useTranslation();
  const hasNotes = !isEmpty(step?.note);
  const hasUrls = !isEmpty(step?.urls && step?.urls[0]?.urlSet);
  const isTask = step?.type === Constants.STEP_TYPES.TASK;
  const isDocument = step?.type === Constants.STEP_TYPES.DOCUMENT;
  const isGame = step?.reference?.type === Constants.DOCUMENT_TYPES.GAME;
  const isQuiz = step?.type === Constants.STEP_TYPES.QUIZ;
  const isBrightCoveVideo =
    step?.type === Constants.STEP_TYPES.DOCUMENT &&
    step?.reference?.type?.toLowerCase() ===
      Constants.EXPANDED_FILE_FORMAT_TYPES.BRIGHTCOVE;
  const taskIsExpandable = !isDocument && (hasNotes || hasUrls);
  const name =
    isDocument && step?.available
      ? getNameFromLanguage(step?.reference?.name as LanguageObject)
      : getNameFromLanguage(step?.name as LanguageObject) ||
        getNameFromLanguage(step?.reference?.name as LanguageObject);
  const [task, setTask] = useState({
    id: step?.id,
    name: name,
    note:
      getNameFromLanguage(step?.note as LanguageObject) ||
      getNameFromLanguage(step?.note as LanguageObject),
    url: getNameFromLanguage(step?.urls?.[0]?.urlSet as LanguageObject),
  });
  const [showEditTask, setShowEditTask] = useState(false);
  const [showExpandedItem, setShowExpandedItem] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (taskUpdated) {
      setError('');
    }
  }, [taskUpdated]);

  const handleCancel = () => {
    setError('');
    onCancel();
    setShowEditTask(false);
    setTask({
      id: '',
      name:
        getNameFromLanguage(step?.name as LanguageObject) ||
        getNameFromLanguage(step?.reference?.name as LanguageObject),
      note:
        getNameFromLanguage(step?.note as LanguageObject) ||
        getNameFromLanguage(step?.note as LanguageObject),
      url: getNameFromLanguage(step?.urls?.[0]?.urlSet as LanguageObject),
    });
  };

  const handleOnEdit = ({
    type,
    updatedSectionTitle,
    editableTask,
    id,
  }: {
    type: string;
    updatedSectionTitle: null;
    editableTask: any;
    id: string;
  }) => {
    const editAndClearUrl = () => {
      onEdit(type, updatedSectionTitle, { ...editableTask, url: '' }, id);
      setTask({ ...task, url: '' });
    };

    const editAndTrimUrl = () => {
      onEdit(
        type,
        updatedSectionTitle,
        { ...editableTask, url: editableTask.url.trim() },
        id,
      );
      setTask({ ...task, url: task.url.trim() });
    };
    if (
      !editableTask.url ||
      isValidUrl(editableTask.url.trim()) ||
      editableTask?.url?.trim() === ''
    ) {
      editableTask.url === undefined || editableTask.url.trim() === ''
        ? editAndClearUrl()
        : editAndTrimUrl();
      setShowEditTask(false);
      setError('');
    } else {
      setError(t('Input.Errors.url'));
    }
  };

  const handleItemClick = () => {
    if (isTask && taskIsExpandable && !showEditTask) {
      setShowExpandedItem(prev => !prev);
    }
  };

  const taskTitle = isBrightCoveVideo
    ? t('Generic.video')
    : isDocument && isGame
      ? t('Generic.game')
      : isDocument
        ? t('Generic.resource')
        : isQuiz
          ? t('Generic.quiz')
          : t('Generic.task');

  return (
    <StyledTaskContainer className={className}>
      <TaskAndProcedure>
        {!isPreview && (
          <SixDotIcon position={showEditTask ? 'top' : 'center'} />
        )}
        <TaskOrProcedureBodyAndIconsWrapper>
          {showEditTask && (
            <InlineTextEditContainer>
              <EditTask
                error={error}
                onEdit={() => {
                  handleOnEdit({
                    type: Constants.TRAINING_MENU_OPTIONS.TASK,
                    updatedSectionTitle: null,
                    editableTask: task,
                    id: sectionId!,
                  });
                }}
                setTask={setTask}
                task={task}
              />
              <EditButtonsWrapper>
                <EditButtons
                  onCancel={handleCancel}
                  onEdit={() =>
                    handleOnEdit({
                      type: Constants.TRAINING_MENU_OPTIONS.TASK,
                      updatedSectionTitle: null,
                      editableTask: task,
                      id: sectionId!,
                    })
                  }
                  validated={task.name.trim().length > 0}
                />
              </EditButtonsWrapper>
            </InlineTextEditContainer>
          )}
          {!showEditTask && (
            <>
              <TaskAndProcedureBody
                $isExpandable={taskIsExpandable}
                onClick={handleItemClick}
              >
                <StyledTaskType data-testid="TaskTitle" variant="overline3">
                  {taskTitle}
                </StyledTaskType>
                <StyledTaskName data-testid="TaskName">
                  {isDocument && !step?.available ? (
                    <>
                      <StyledTaskNameText variant="body1">
                        {/* remove underscores from name */}
                        {name.replace(/_/g, ' ')}
                      </StyledTaskNameText>
                      <StyledWarningWrapper>
                        <IconAlertTriangleFilled size={16} />
                        <StyledWarningMessage variant="body1">
                          {t('TrainingPlans.noResourceBuild')}
                        </StyledWarningMessage>
                      </StyledWarningWrapper>
                    </>
                  ) : (
                    <StyledTaskNameText variant="body1">
                      {name}
                    </StyledTaskNameText>
                  )}
                </StyledTaskName>
              </TaskAndProcedureBody>
              <TaskAndProcedureIconsWrapper $isQuiz={isQuiz}>
                {isGame && (
                  <TaskAndProcedureIcon $isGame={isGame}>
                    <PlanItemTypeIcon>
                      <IconDeviceGamepad2 />
                    </PlanItemTypeIcon>
                  </TaskAndProcedureIcon>
                )}
                {isQuiz && (
                  <TaskAndProcedureIcon $isQuiz={isQuiz}>
                    <PlanItemTypeIcon>
                      <IconCertificate />
                    </PlanItemTypeIcon>
                  </TaskAndProcedureIcon>
                )}
                {hasNotes && (
                  <TaskAndProcedureIcon
                    data-testid="TaskNoteIcon"
                    onClick={() => setShowExpandedItem(prev => !prev)}
                  >
                    <PlanItemTypeIcon>
                      <IconNote />
                    </PlanItemTypeIcon>
                  </TaskAndProcedureIcon>
                )}
                {hasUrls && (
                  <TaskAndProcedureIcon
                    data-testid="TaskUrlIcon"
                    onClick={() => setShowExpandedItem(prev => !prev)}
                  >
                    <PlanItemTypeIcon>
                      <IconLink />
                    </PlanItemTypeIcon>
                  </TaskAndProcedureIcon>
                )}
                {!isPreview && isAllowedToEdit && isTask && (
                  <PopoverMenuButton dataTestId="ThreeDotMenu">
                    <PopoverMenuButtonItem
                      dataTestId="TaskEdit"
                      icon={<IconEdit />}
                      onClick={() => {
                        setShowExpandedItem(false);
                        setShowEditTask(true);
                      }}
                      text={t('TrainingPlans.manageThreeDotMenu.editTask')}
                    />
                    <PopoverMenuButtonItem
                      dataTestId="ThreeDotMenuDeletePlanPopup"
                      icon={<IconTrash data-testid="DeleteTask" />}
                      isDestructive={true}
                      onClick={() =>
                        onDelete({
                          id: step.id!,
                          name:
                            getNameFromLanguage(step?.name as LanguageObject) ||
                            getNameFromLanguage(
                              step?.reference?.name as LanguageObject,
                            ),
                          sectionId: sectionId!,
                          type: step.type!,
                        })
                      }
                      text={t('TrainingPlans.manageThreeDotMenu.deleteTask')}
                    />
                  </PopoverMenuButton>
                )}
                {!isTask && isAllowedToEdit && (
                  <IconButton>
                    <IconTrash
                      data-testid="ResourceTrashIcon"
                      onClick={() =>
                        onDelete({
                          id: step.id!,
                          name:
                            getNameFromLanguage(step?.name as LanguageObject) ||
                            getNameFromLanguage(
                              step?.reference?.name as LanguageObject,
                            ),
                          sectionId: sectionId!,
                          type: step.type!,
                        })
                      }
                    />
                  </IconButton>
                )}
              </TaskAndProcedureIconsWrapper>
            </>
          )}
        </TaskOrProcedureBodyAndIconsWrapper>
      </TaskAndProcedure>
      {showExpandedItem && isTask && (
        <TaskAndProcedureNotesLinkWrapper>
          {hasNotes && (
            <TaskAndProcedureNotes>
              <Typography variant="overline3">{t('Generic.note')}</Typography>
              <Typography data-testid="TaskNote" variant="body1">
                {getNameFromLanguage(step.note as LanguageObject)}
              </Typography>
            </TaskAndProcedureNotes>
          )}
          {hasUrls &&
            step.urls &&
            step.urls.map((link, idx) => (
              <TaskAndProcedureLink
                href={getClickableLink(
                  getNameFromLanguage(link.urlSet as LanguageObject),
                )}
                key={`${link.urlSet}-${idx}`}
                target="_blank"
              >
                <Typography variant="overline3">{t('Generic.link')}</Typography>
                <TaskAndProcedureLinkDescription
                  data-testid="TaskUrl"
                  fontWeight="extralight"
                  variant="body1"
                >
                  {getNameFromLanguage(link.urlSet as LanguageObject)}
                </TaskAndProcedureLinkDescription>
              </TaskAndProcedureLink>
            ))}
        </TaskAndProcedureNotesLinkWrapper>
      )}
    </StyledTaskContainer>
  );
};

const InlineTextEditContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

const PlanItemTypeIcon = styled.div`
  color: ${({ theme }) => theme.grayScale.gray2};
`;

const StyledTaskContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.primaryPalette.white};
`;

const TaskAndProcedure = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.primaryPalette.white};
`;

const StyledTaskName = styled.div`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

const StyledWarningWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.semanticColors.error};
`;

const StyledWarningMessage = styled(Typography)`
  color: ${({ theme }) => theme.semanticColors.error};
  margin-left: 0.5em;
`;

const StyledTaskNameText = styled(Typography)`
  display: flex;
  align-items: center;
`;

const TaskAndProcedureIcon = styled.div<TaskAndProcedureIconStyleProps>`
  width: 25px;
  margin: 0 5px;
  cursor: ${({ $isGame, $isQuiz }) =>
    !$isGame && !$isQuiz ? 'pointer' : 'default'};
`;

const TaskAndProcedureBody = styled.div<TaskAndProcedureBodyStyleProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  cursor: ${({ $isExpandable }) => ($isExpandable ? 'pointer' : 'default')};
`;

const StyledTaskType = styled(Typography)`
  color: ${({ theme }) => theme.grayScale.gray6};
`;

const TaskOrProcedureBodyAndIconsWrapper = styled.div`
  display: contents;
`;

const TaskAndProcedureIconsWrapper = styled.div<TaskAndProcedureIconsStyleProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: ${({ $isQuiz }) => (!$isQuiz ? 'pointer' : 'default')};
`;

const TaskAndProcedureNotes = styled.div`
  padding-top: 10px;
`;
const TaskAndProcedureLink = styled.a`
  padding-top: 10px;
`;

const TaskAndProcedureLinkDescription = styled(Typography)`
  color: ${({ theme }) => theme.primaryPalette.navyBlue};
  text-decoration: underline;
  word-wrap: break-word;

  &:hover {
    color: ${({ theme }) => theme.primaryPalette.button.hover};
    font-weight: normal;
    text-decoration: underline;
    cursor: pointer;
  }
`;

const TaskAndProcedureNotesLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 36px;

  a:hover {
    font-weight: normal;
  }
`;

const EditButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export default TaskContainer;
