import { colorTag } from 'utils/formatDocumentTag';
import { IconLanguage, IconPrinter, IconShare2 } from '@tabler/icons-react';
import { Tag, Tooltip, Typography } from 'cfa-react-components';
import { FC, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import styled from 'styled-components';
import ToastMessageBlock from '@/components/Toasts/SuccessToast';
import { Breadcrumb } from '@/types/types';
import BreadcrumbLinks from '@/components/BreadcrumbLinks/BreadcrumbLinks';
import { useAppSelector } from '@/hooks';
import { selectHasTranslatedValue } from '@/store/document-persisted/selectors';
import {
  setIsPrinting,
  translationButtonPress,
} from '@/store/document-transient/slice';
import { selectChosenDocumentCountry } from '@/store/document-transient/selectors';
import {
  selectUserCountry,
  selectUserIsAdminOperatorOrStakeholder,
} from '@/store/user/selectors';
import { getCountryLabel } from '@/utils/document-utils/countries';

interface DocumentTitleProps {
  breadcrumbs?: Breadcrumb[];
  isMobile?: boolean;
  tags?: string[];
  title?: string;
}

const DocumentTitle: FC<DocumentTitleProps> = ({
  breadcrumbs = [],
  isMobile = false,
  tags = [],
  title = '',
}) => {
  const { chooseDocumentVersion } = useFlags();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const showTranslateIcon = useAppSelector(selectHasTranslatedValue);
  const chosenDocumentCountry = useAppSelector(selectChosenDocumentCountry);
  const userCountry = useAppSelector(selectUserCountry);
  const isAdminOperatorOrStakeholder = useAppSelector(
    selectUserIsAdminOperatorOrStakeholder,
  );

  const enableChooseDocumentVersion =
    chooseDocumentVersion && isAdminOperatorOrStakeholder;
  const documentCountry = chosenDocumentCountry || userCountry.id;

  const onCopyLink = () => {
    navigator.clipboard.writeText(window.location.href);
    toast.custom(toastObj => (
      <ToastMessageBlock id={toastObj.id}>
        {t('Generic.linkCopied')}
      </ToastMessageBlock>
    ));
  };

  useEffect(() => {
    const handleKeyDown = (event: {
      ctrlKey: any;
      metaKey: any;
      key: string;
      charCode: number;
      keyCode: number;
      stopPropagation: () => void;
      preventDefault: () => void;
    }) => {
      if (
        (event.ctrlKey || event.metaKey) &&
        (event.key === 'p' ||
          event.charCode === 16 ||
          event.charCode === 112 ||
          event.keyCode === 80)
      ) {
        event.stopPropagation();
        event.preventDefault(); // Prevent default print dialog
        dispatch(setIsPrinting(true));
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [dispatch]);

  const renderDocTags = () => {
    if (!tags?.length) {
      return null;
    }
    return tags?.map((tag, index) => (
      <StyledTag
        $isMobile={isMobile}
        color={colorTag(tag)}
        key={index}
        label={tag}
        variant="filled"
      />
    ));
  };

  const renderAllTags = () => {
    return (
      <>
        {renderDocTags()}
        {!!documentCountry && enableChooseDocumentVersion && (
          <StyledTag
            $isMobile={isMobile}
            color="secondaryBlue"
            key={documentCountry}
            label={getCountryLabel(documentCountry)}
            variant="outlined"
          />
        )}
      </>
    );
  };

  return (
    <TitleWrapper $isMobile={isMobile}>
      {breadcrumbs && (
        <BreadcrumbWrapper>
          <BreadcrumbLinks breadcrumbs={breadcrumbs} />
        </BreadcrumbWrapper>
      )}
      <TagWrapper>
        {/** Tags are on top of title on mobile */}
        {isMobile && renderAllTags()}
      </TagWrapper>
      <TypographyWrapper $isMobile={isMobile}>
        <Title variant="h2">
          <div style={{ display: 'inline', marginRight: '10px' }}>{title}</div>

          {/** Tags are to the right of title on desktop */}
          {!isMobile && renderAllTags()}
        </Title>
      </TypographyWrapper>
      {isMobile && (
        <>
          <ActionContainer>
            {showTranslateIcon ? (
              <TranslateIcon
                onClick={() => dispatch(translationButtonPress())}
              />
            ) : (
              <Tooltip
                content={t('Generic.noTranslationsAvailable')}
                placement="top"
                showOnElementEvents={['hover']}
              >
                <TranslateIcon
                  $hideIcon={true}
                  onClick={() => dispatch(translationButtonPress())}
                />
              </Tooltip>
            )}

            <IconPrinter
              onClick={() => dispatch(setIsPrinting(true))}
              style={{ marginRight: 12 }}
            />
            <IconShare2 onClick={onCopyLink} style={{ marginRight: 24 }} />
          </ActionContainer>
        </>
      )}
    </TitleWrapper>
  );
};

const TitleWrapper = styled.div<{ $isMobile: boolean }>`
  background: white;
  box-shadow: ${({ theme }) => theme.boxShadow.elevation16};
  width: 100%;
  padding-bottom: ${({ $isMobile }) => ($isMobile ? '1em' : '2.5em')};
`;

const BreadcrumbWrapper = styled.div`
  padding: 40px 40px 0;
`;

const TagWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 1em;
`;

const TypographyWrapper = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ $isMobile }) => $isMobile && 'center'};
  padding: ${({ $isMobile }) => ($isMobile ? '0 20px' : '0 40px')};
`;

const StyledTag = styled(Tag)<{ $isMobile: boolean }>`
  ${({ $isMobile }) =>
    !$isMobile &&
    `
      font-weight: 400;
      margin-right: 10px;
    `}
`;

const Title = styled(Typography)`
  color: ${({ theme }) => theme.primaryPalette.navyBlue};
  margin-top: 12px;
  margin-bottom: 8px;
`;

const ActionContainer = styled.div`
  color: ${({ theme }) => theme.primaryPalette.navyBlue};
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
`;

const TranslateIcon = styled(IconLanguage)<{ $hideIcon?: boolean }>`
  color: ${({ $hideIcon, theme }) => $hideIcon && theme.grayScale.gray3};
  cursor: ${({ $hideIcon }) => $hideIcon && 'default'};
  margin-right: 12px;
`;

export default DocumentTitle;
