import {
  Plugin,
  BrowserClient,
  BrowserConfig,
} from '@amplitude/analytics-types';

type ExecuteFunction = Plugin<BrowserClient, BrowserConfig>['execute'];

const devicePlatform = () => {
  if (window.accessToken) {
    return 'iOS';
  } else if (
    window?.ReactNativeWebView &&
    typeof window?.ReactNativeWebView.injectedObjectJson === 'function'
  ) {
    return 'Android';
  } else {
    return 'Web';
  }
};

export const addPlatformPlugin = (): Plugin<BrowserClient, BrowserConfig> => {
  const name = 'add-platform-plugin';
  const type = 'enrichment';

  /**
   * execute() is called on each event instrumented
   * example: client.track('New Event');
   */
  const execute: ExecuteFunction = async event => {
    event.platform = devicePlatform();
    return event;
  };

  return {
    name,
    type,
    execute,
  };
};
