import {
  AuthResponse,
  FeatureSet,
  CloudFrontCookieGroup,
  LocationDetails,
  PermittedLocation,
} from '@cfacorp-pathway/xp-api-typescript-client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getUserInfoFromAuth } from '@/utils/user';

export interface UserState {
  audience: string;
  email: string;
  features: FeatureSet;
  firstName: string;
  fullName: string;
  language: string;
  country: Country;
  locations: string[];
  permissions: Record<string, string[]>;
  userId: string;
  userType: string;
  isInternationalUser: boolean;
  isLicensee: boolean;
  franchiseeLocations: LocationDetails[];
  licenseeLocations: LocationDetails[];
  supportCenterLocations: LocationDetails[];
  cdn: CloudFrontCookieGroup[];
  locationsWithPermissions: PermittedLocation[];
  selectedLocation: string | null;
}

export interface Country {
  id?: string;
}

const initialState: UserState = {
  audience: '',
  cdn: [],
  email: '',
  features: {
    rum: {
      enabled: false,
    },
    plans: {
      enabled: false,
      create: {
        enabled: false,
      },
      manage: {
        enabled: false,
      },
    },
    quizzes: {
      enabled: false,
      create: {
        enabled: false,
      },
    },
    reporting: {
      enabled: false,
      plans: {
        enabled: false,
      },
      teamMember: {
        enabled: false,
      },
      compliance: {
        enabled: false,
      },
      licensee: {
        enabled: false,
      },
    },
    settings: {
      enabled: false,
      country: {
        enabled: false,
      },
      language: {
        enabled: false,
      },
      assistance: {
        enabled: false,
      },
      location: {
        enabled: false,
      },
      compliance: {
        enabled: false,
      },
    },
    admin: {
      enabled: false,
    },
    analytics: {
      enabled: false,
    },
    monitoring: {
      enabled: false,
    },
  },
  firstName: '',
  fullName: '',
  // default to true until it can be set from auth or token
  isInternationalUser: true,
  isLicensee: false,
  language: '',
  country: {},
  locations: [],
  locationsWithPermissions: [],
  permissions: {},
  selectedLocation: null,
  userId: '',
  userType: '',
  // below locations are only applicable to operators, these are locations you operate
  franchiseeLocations: [],
  licenseeLocations: [],
  supportCenterLocations: [],
};

export const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    initializeUser: (state, action: PayloadAction<Partial<UserState>>) => ({
      ...state,
      ...action.payload,
    }),
    initializeUserFromAuth: (
      state,
      action: PayloadAction<Partial<UserState>>,
    ) => ({
      ...state,
      ...action.payload,
    }),
    setDataFromAuth: (state, action: PayloadAction<AuthResponse>) => {
      const user = getUserInfoFromAuth(action.payload);
      return {
        ...state,
        ...user,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { initializeUser, initializeUserFromAuth, setDataFromAuth } =
  slice.actions;

export default slice.reducer;
