import styled from 'styled-components';
import { Button } from 'cfa-react-components';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { showProgressModal } from 'store/trainingMode/slice';
import { useFlags } from 'launchdarkly-react-client-sdk';

const TrainingModeExitButton = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { plansRedesign: plansRedesignFeatureFlag } = useFlags();

  return (
    <TrainingModeWrapper>
      <Button
        color="secondary"
        data-testid="ExitTrainingModeButton"
        onClick={() => dispatch(showProgressModal())}
      >
        {plansRedesignFeatureFlag
          ? t('Button.exitTrainTogether')
          : t('Button.exitTrainingMode')}
      </Button>
    </TrainingModeWrapper>
  );
};

const TrainingModeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  gap: 1rem;
`;

export default TrainingModeExitButton;
