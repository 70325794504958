import { getNameFromLanguage } from 'utils/language';
import { getClickableLink } from 'utils/url';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { IconCircleCheckDisabled, IconCircleDisabled } from 'icons';
import {
  IconLink,
  IconNote,
  IconCircle,
  IconCircleCheck,
} from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'cfa-react-components';
import {
  ChecklistSectionStepDTO,
  IdentityUser,
  UserChecklistDTO,
  UserChecklistStatus,
} from '@cfacorp-pathway/xp-api-typescript-client';
import { LanguageObject } from '@/types/types';

interface PlanItemTaskProps {
  disabled?: boolean;
  isActive: boolean;
  isComplete: boolean;
  language?: string;
  onClick: () => void;
  onCompleteToggle: () => void;
  statuses: UserChecklistDTO[];
  step: ChecklistSectionStepDTO;
  user?: IdentityUser | UserChecklistStatus;
}

interface TaskOrProcedureBodyStyleProps {
  $isActive: boolean;
}

interface IconWrapperStyleProps {
  $isDisabled: boolean;
}

interface TypeAndNameWrapperStyleProps {
  $canExpand: boolean;
  $isDisabled: boolean;
}

interface TaskIconsWrapperStyleProps {
  $canExpand: boolean;
  $isDisabled: boolean;
}

const PlanItemTask: React.FC<PlanItemTaskProps> = ({
  disabled,
  isActive,
  isComplete,
  language,
  onClick,
  onCompleteToggle,
  statuses,
  step,
  user,
}) => {
  const [isCompleted, setIsCompleted] = useState(isComplete);
  const { t } = useTranslation();
  const hasNotes = !isEmpty(step?.note);
  const hasUrls = !isEmpty(step?.urls?.[0]?.urlSet);
  const canExpand = hasNotes || hasUrls;
  // @ts-ignore
  const userId = user?.adId ?? user?.userId ?? undefined;
  const userStatus = statuses
    ?.find(status => status.userId === userId)
    ?.steps?.find(statusStep => statusStep.stepId === step.id);
  const stepDuration = userStatus?.stepDuration
    ? Math.round(userStatus?.stepDuration / 60)
    : 0;

  useEffect(() => {
    setIsCompleted(isComplete);
  }, [isComplete]);

  return (
    <>
      <TaskOrProcedureBody $isActive={isActive}>
        {isCompleted ? (
          // @ts-ignore overload error from CFA DS
          <IconWrapper
            $isDisabled={disabled}
            data-testid="StepStatusIcon"
            onClick={
              !disabled
                ? () => {
                    setIsCompleted(prevState => !prevState);
                    onCompleteToggle();
                  }
                : null
            }
          >
            {!disabled ? <StyledIconCircleCheck /> : <DisabledCompleteIcon />}
          </IconWrapper>
        ) : (
          // @ts-ignore overload error from CFA DS
          <IconWrapper
            $isDisabled={disabled}
            data-testid="StepStatusIcon"
            onClick={
              !disabled
                ? () => {
                    setIsCompleted(prevState => !prevState);
                    onCompleteToggle();
                  }
                : null
            }
          >
            {!disabled ? <StyledIconCircle /> : <DisabledOvalIcon />}
          </IconWrapper>
        )}
        <TypeAndNameWrapper
          $canExpand={canExpand}
          $isDisabled={disabled as boolean}
          data-testid={`PlanStep${step.id}`}
          onClick={onClick}
        >
          <Typography data-testid="TaskTitle" variant="overline3">
            {t('Generic.task')}
            {isCompleted &&
              user &&
              ` - ${t('Generic.completedIn')} ${stepDuration} ${t(
                'Generic.mins',
              )}`}
          </Typography>
          <Typography data-testid="TaskOrProcedureName" variant="body1">
            {getNameFromLanguage(step?.name as LanguageObject, language)}
          </Typography>
        </TypeAndNameWrapper>
        <TaskIconsWrapper
          $canExpand={canExpand}
          $isDisabled={disabled as boolean}
          onClick={onClick}
        >
          {getNameFromLanguage(step?.note as LanguageObject, language) && (
            <IconNote />
          )}
          {hasUrls && <IconLink />}
        </TaskIconsWrapper>
      </TaskOrProcedureBody>
      {isActive && (
        <>
          {hasNotes || hasUrls ? (
            <NotesAndLinkWrapper>
              {getNameFromLanguage(step?.note as LanguageObject, language) && (
                <TaskAndProcedureNotes>
                  <Typography variant="overline3">
                    {t('Generic.notes')}
                  </Typography>
                  <Typography variant="body1">
                    {getNameFromLanguage(
                      step?.note as LanguageObject,
                      language,
                    )}
                  </Typography>
                </TaskAndProcedureNotes>
              )}
              {hasUrls &&
                step?.urls!.map((link, index) => (
                  <TaskAndProcedureLink
                    href={getClickableLink(
                      getNameFromLanguage(
                        link.urlSet as LanguageObject,
                        language,
                      ),
                    )}
                    // @ts-ignore
                    key={`${link.id}-${index}`}
                    target="_blank"
                  >
                    <Typography variant="body1">{t('Generic.link')}</Typography>
                    <TaskAndProcedureLinkDescription variant="body1">
                      {getNameFromLanguage(
                        link.urlSet as LanguageObject,
                        language,
                      )}
                    </TaskAndProcedureLinkDescription>
                  </TaskAndProcedureLink>
                ))}
            </NotesAndLinkWrapper>
          ) : null}
        </>
      )}
    </>
  );
};

const TaskOrProcedureBody = styled.div<TaskOrProcedureBodyStyleProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 8px;
  padding-bottom: ${({ $isActive }) => $isActive && '10px'};
`;

const IconWrapper = styled.div<IconWrapperStyleProps>`
  margin-right: 0.5em;
  cursor: ${({ $isDisabled }) => (!$isDisabled ? 'pointer' : 'default')};
`;

const StyledIconCircle = styled(IconCircle)`
  color: ${({ theme }) => theme.grayScale.gray2};
`;

const StyledIconCircleCheck = styled(IconCircleCheck)`
  color: ${({ theme }) => theme.semanticColors.success};
`;

const DisabledOvalIcon = styled(IconCircleDisabled)`
  color: ${({ theme }) => theme.grayScale.gray2};
`;

const DisabledCompleteIcon = styled(IconCircleCheckDisabled)`
  color: ${({ theme }) => theme.grayScale.gray2};
`;

const TypeAndNameWrapper = styled.div<TypeAndNameWrapperStyleProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: ${({ $canExpand, $isDisabled }) =>
    $canExpand && !$isDisabled && 'pointer'};
`;

const TaskIconsWrapper = styled.div<TaskIconsWrapperStyleProps>`
  display: flex;
  color: ${({ theme }) => theme.grayScale.gray2};
  cursor: ${({ $canExpand, $isDisabled }) =>
    $canExpand && !$isDisabled && 'pointer'};
`;

const NotesAndLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 41px;
`;

const TaskAndProcedureNotes = styled.div`
  padding-bottom: 10px;
`;

const TaskAndProcedureLink = styled.a`
  padding-bottom: 10px;
`;

const TaskAndProcedureLinkDescription = styled(Typography)`
  color: ${({ theme }) => theme.primaryPalette.navyBlue};
  text-decoration: underline;
  word-wrap: break-word;

  &:hover {
    color: ${({ theme }) => theme.primaryPalette.button.hover};
    font-weight: normal;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export default PlanItemTask;
