import { device } from 'utils/device';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from 'cfa-react-components';

// NOTE: important to minimize dependencies on this screen to reduce the chance
// of it blowing up after another screen has also blown up. Ideally no Redux
// connection. Some duplication is okay to keep this component simple.
const GenericError = () => {
  const { t } = useTranslation();
  // This is non-reactive, but this component cannot get to redux because
  // it is outside of the redux provider.

  const handleReload = () => window.location.reload();

  const ErrorPageContents = () => {
    return (
      <>
        <Typography color={'secondary'} variant={'h1'}>
          {t('GenericError.oops')}
        </Typography>
        <StyledReload>{t('GenericError.reloadThisPage')}</StyledReload>
        <Button color="secondary" onClick={handleReload}>
          {t('GenericError.reloadPage')}
        </Button>
      </>
    );
  };

  return (
    <>
      <StyledMain>
        <FlexWrapper>
          <ErrorPageContents />
        </FlexWrapper>
      </StyledMain>
    </>
  );
};

const StyledMain = styled.main`
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const FlexWrapper = styled.div`
  flex: 1 0 auto;
  padding: 50px 24px 40px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @media ${device.desktop} {
    max-width: 684px;
    margin: 0 auto;
    padding-top: 112px;
  }
`;

const StyledReload = styled(Typography)`
  margin: 48px 0 0 0;
`;

export default GenericError;
