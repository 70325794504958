import Constants from 'constants/index';
import {
  AuthState,
  OktaAuth,
  OktaAuthOptions,
  toRelativeUrl,
} from '@okta/okta-auth-js';
import {
  AccessTokenClaims,
  IdTokenClaims,
  parseIdUserClaims,
  parseAccessTokenClaims,
} from './okta-utils';
import history from '@/utils/history';

export const restoreOriginalUri = async (_: OktaAuth, originalUri: string) => {
  if (originalUri) {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  } else {
    history.push('/');
  }
};

const transformAuthState: OktaAuthOptions['transformAuthState'] = async (
  _,
  authState,
) => {
  if (Constants.IS_IN_CYPRESS_TEST) {
    authState.isAuthenticated = true;

    (authState as any).idToken = 'dev';

    (authState as any).accessToken = 'dev';
    return authState;
  }
  // below from: https://github.com/okta/okta-auth-js
  // extra requirement: user must have valid Okta SSO session
  // const user = await oktaAuth.token.getUserInfo();
  // authState.isAuthenticated = true; // convert to boolean
  // authState.users = user; // also store user object on authState
  return authState;
};

const oktaConfig: OktaAuthOptions = {
  issuer: Constants.OKTA_ISSUER || 'http://bogus.jest',
  clientId: Constants.OKTA_CLIENT_ID || 'jest',
  redirectUri: window.location.origin + '/login/callback',
  pkce: true,
  scopes: ['openid', 'email', 'profile', 'location_info', 'job_info'],
  transformAuthState,
};
const oktaAuth = new OktaAuth(oktaConfig);

export default oktaAuth;

export interface CfaAuthState {
  authState: AuthState;
  accessTokenClaims?: AccessTokenClaims;
  idTokenClaims?: IdTokenClaims;
}

const authSubscribers = new Set<(authState: AuthState) => void>();

export const clearAuthSubscribers = () => {
  authSubscribers.forEach(fn => {
    oktaAuth.authStateManager.unsubscribe(fn);
  });
  authSubscribers.clear();
};

/** Subscribe to changes in the Okta auth state */
export const subscribeToAuthStateChange = (
  fn: (state: CfaAuthState) => void | Promise<void>,
): {
  unsubscribe: () => void;
} => {
  const subscriptionHandler = (authState: AuthState) => {
    const accessTokenClaims = authState.accessToken?.claims
      ? parseAccessTokenClaims(authState.accessToken?.claims)
      : undefined;
    const idTokenClaims = authState.idToken?.claims
      ? parseIdUserClaims(authState.idToken?.claims)
      : undefined;

    fn({ authState, accessTokenClaims, idTokenClaims });
  };

  oktaAuth.authStateManager.subscribe(subscriptionHandler);
  authSubscribers.add(subscriptionHandler);

  return {
    unsubscribe: () => {
      oktaAuth.authStateManager.unsubscribe(subscriptionHandler);
      authSubscribers.delete(subscriptionHandler);
    },
  };
};
