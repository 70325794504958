import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Label } from '@/components/Cards/StickyFilterCard/CheckboxFilterSection';
import {
  ChecklistDTO,
  UserChecklistResultDTO,
} from '@cfacorp-pathway/xp-api-typescript-client';
import { DropdownOptions } from '@/types';
import { selectPagination } from '@/store/teamMembersFilter/selector';
import Filter from './Filter';
import Sort from './Sort';
import TeamMemberCards from './TeamMemberCards';
import Search from './Search';

interface TeamMembersProps {
  assignedTeamMembersList: UserChecklistResultDTO;
  currentFilters: string[];
  filterLocations: string[];
  isCompliance: boolean;
  isFoodSafety: boolean;
  isSmAndDown: boolean;
  onUnassign: (id: string, name: string) => void;
  onUpdateDueDate: (id: string, name: string) => void;
  plan: ChecklistDTO;
  planId: string;
  refetchAssignedChecklist: () => void;
  refetchAssignedTeamMembers: () => void;
  sort: string;
  sortOptions: DropdownOptions[];
  statusLabels: Label;
  stepCount: number;
  teamMembers: any[];
}

const TeamMembers: React.FC<TeamMembersProps> = ({
  assignedTeamMembersList,
  currentFilters,
  filterLocations,
  isCompliance,
  isFoodSafety,
  isSmAndDown,
  onUnassign,
  onUpdateDueDate,
  plan,
  planId,
  refetchAssignedChecklist,
  refetchAssignedTeamMembers,
  sort,
  sortOptions,
  statusLabels,
  stepCount,
  teamMembers,
}) => {
  const { showing, total } = useSelector(selectPagination);
  return (
    <TeamMemberList>
      <Filter
        currentFilters={currentFilters}
        filterLocations={filterLocations}
        isSmAndDown={isSmAndDown}
        statusLabels={statusLabels}
      />
      <TeamMembersContainer>
        <Search />
        <Sort
          currentFilters={currentFilters}
          sort={sort}
          sortOptions={sortOptions}
          statusLabels={statusLabels}
          total={total}
        />
        <TeamMemberCards
          assignedTeamMembersList={assignedTeamMembersList}
          currentFilters={currentFilters}
          isCompliance={isCompliance}
          isFoodSafety={isFoodSafety}
          onUnassign={onUnassign}
          onUpdateDueDate={onUpdateDueDate}
          plan={plan}
          planId={planId}
          refetchAssignedChecklist={refetchAssignedChecklist}
          refetchAssignedTeamMembers={refetchAssignedTeamMembers}
          showing={showing}
          stepCount={stepCount}
          teamMembers={teamMembers}
          total={total}
        />
      </TeamMembersContainer>
    </TeamMemberList>
  );
};

const TeamMembersContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow: hidden;
`;
const TeamMemberList = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  position: relative;
  flex-grow: 1;
  max-width: 100%;
`;

export default TeamMembers;
