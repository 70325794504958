import { useEffect } from 'react';

export const useClickOutside = (ref: any, callBack: () => void) => {
  useEffect(() => {
    const handleClick = ({ target }: { target: any }) => {
      const dataCancelOutsideClickAttr = target?.dataset?.cancelOutsideClick;
      if (
        callBack &&
        typeof callBack === 'function' &&
        ref?.current &&
        !ref.current.contains(target) &&
        !dataCancelOutsideClickAttr
      ) {
        callBack();
      }
    };
    document.addEventListener('click', handleClick, true);
    document.addEventListener('touchstart', handleClick, true);
    return () => {
      document.removeEventListener('click', handleClick, true);
      document.removeEventListener('touchstart', handleClick, true);
    };
  }, [callBack, ref]);
};
