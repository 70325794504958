import Constants from 'constants/index';
import {
  IconCornerDownRight,
  IconEdit,
  IconEye,
  IconEyeOff,
  IconGripVertical,
  IconTrash,
} from '@tabler/icons-react';
import {
  IconButton,
  Tag,
  TextField,
  TextFieldType,
  Typography,
} from 'cfa-react-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { getNameFromLanguage } from '@/utils/language';
import { setTemporaryCategory } from '@/store/admin/slice';
import { selectTemporaryCategory } from '@/store/admin/selectors';
import PopoverMenuButtonItem from '@/components/PopoverMenuButton/PopoverMenuButtonItem';
import PopoverMenuButton from '@/components/PopoverMenuButton/PopoverMenuButton';
import { LanguageObject } from '@/types';

interface HeaderProps {
  countryName: string;
  index: string;
  isEditingSubcategory: boolean;
  selectedSubcategoryId: string;
  setIsEditingSubcategory: (isEditing: true) => void;
  setShowDeleteSubcategory: (show: boolean) => void;
  setShowToggleSubcategoryVisibility: (show: boolean) => void;
  setSubcategory: (isSubcategory?: boolean) => void;
  showAdminFunctionality: boolean;
  subcategoryIsVisible: boolean;
  subcategoryName: LanguageObject;
  triggerEnableCancelButton: () => void;
  userIsAdmin: boolean;
}

const Header: React.FC<HeaderProps> = ({
  countryName,
  index,
  isEditingSubcategory,
  selectedSubcategoryId,
  setIsEditingSubcategory,
  setShowDeleteSubcategory,
  setShowToggleSubcategoryVisibility,
  setSubcategory,
  showAdminFunctionality,
  subcategoryIsVisible,
  subcategoryName,
  triggerEnableCancelButton,
  userIsAdmin,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedTemporaryCategory = useSelector(selectTemporaryCategory) as any;

  const onNameChange = (e: React.ChangeEvent<TextFieldType>) => {
    const name = e.target.value;
    if (name.length) {
      dispatch(
        setTemporaryCategory({
          ...selectedTemporaryCategory,
          subcategories: Object.values({
            ...selectedTemporaryCategory.subcategories,
            [index]: {
              ...(selectedTemporaryCategory.subcategories[index] ?? {}),
              name: {
                en: name,
                es: selectedTemporaryCategory?.subcategories[index]?.name.es,
              },
            },
          }),
        }),
      );
    } else {
      dispatch(
        setTemporaryCategory({
          ...selectedTemporaryCategory,
          subcategories: Object.values({
            ...selectedTemporaryCategory.subcategories,
            [index]: {
              ...(selectedTemporaryCategory.subcategories[index] ?? {}),
              name: {
                en: '',
                es: selectedTemporaryCategory?.subcategories[index]?.name.es,
              },
            },
          }),
        }),
      );
    }
  };

  const onTranslationChange = (e: React.ChangeEvent<TextFieldType>) => {
    const translation = e.target.value;
    if (translation.length) {
      dispatch(
        setTemporaryCategory({
          ...selectedTemporaryCategory,
          subcategories: Object.values({
            ...selectedTemporaryCategory.subcategories,
            [index]: {
              ...(selectedTemporaryCategory.subcategories[index] ?? {}),
              name: {
                en: selectedTemporaryCategory?.subcategories[index]?.name.en,
                es: translation,
              },
            },
          }),
        }),
      );
    } else {
      dispatch(
        setTemporaryCategory({
          ...selectedTemporaryCategory,
          subcategories: Object.values({
            ...selectedTemporaryCategory.subcategories,
            [index]: {
              ...(selectedTemporaryCategory.subcategories[index] ?? {}),
              name: {
                en: selectedTemporaryCategory?.subcategories[index]?.name.en,
                es: '',
              },
            },
          }),
        }),
      );
    }
  };

  const onSubcategoryEdit = () => {
    setSubcategory(true);
    setIsEditingSubcategory(true);
    triggerEnableCancelButton();
  };

  return (
    <AccordionHeader>
      {!!userIsAdmin && !!showAdminFunctionality && (
        <StyledSixDotIcon className={'subcategory-drag-handle'} />
      )}
      {isEditingSubcategory && selectedSubcategoryId === index ? (
        <>
          <SubcategoryTitleRow onClick={e => e.stopPropagation()}>
            <StyledFormControlWrapper>
              <TextField
                data-testid={'EditSubcategoryNameInput'}
                fullWidth
                label={t('Admin.subcategory')}
                onChange={onNameChange}
                placeholder={'Enter Name'}
                required={true}
                value={
                  // @ts-ignore
                  selectedTemporaryCategory.subcategories[index].name.en
                }
              />
            </StyledFormControlWrapper>
            <StyledTranslationRow>
              <IconCornerDownRight size={35} />
              <TextField
                data-testid={'EditSubcategoryTranslationInput'}
                fullWidth
                label={t('Admin.spanishTranslation')}
                onChange={onTranslationChange}
                placeholder={'Enter Translation'}
                required={
                  countryName !==
                  Constants.ADMIN_MANAGING_COUNTRIES.CA.LABEL_KEY
                }
                value={
                  // @ts-ignore
                  selectedTemporaryCategory.subcategories[index].name.es
                }
              />
            </StyledTranslationRow>
          </SubcategoryTitleRow>
          {!!userIsAdmin && !!showAdminFunctionality && (
            <EditAdminFeaturesWrapper>
              {subcategoryIsVisible ? (
                <StyledShowTag
                  label={t('Admin.categoryIsVisible')}
                  leadingElement={<IconEye width={10} />}
                  onClick={() => setShowToggleSubcategoryVisibility(true)}
                  variant="filled"
                />
              ) : !subcategoryIsVisible ? (
                <StyledHideTagWrapper
                  onClick={() => setShowToggleSubcategoryVisibility(true)}
                >
                  <StyledHideTag
                    disabled
                    label={t('Admin.categoryHidden')}
                    leadingElement={<IconEyeOff width={10} />}
                    variant="filled"
                  />
                </StyledHideTagWrapper>
              ) : null}
              <StyledDeleteIconButton
                aria-label="Delete Category"
                color="default"
                data-testid="EditCategoryTrashIcon"
                href=""
                onClick={() => setShowDeleteSubcategory(true)}
                size="md"
              >
                <IconTrash />
              </StyledDeleteIconButton>
            </EditAdminFeaturesWrapper>
          )}
        </>
      ) : (
        <AccordionHeaderRow>
          <AccordionTitle variant="body1">
            {getNameFromLanguage(subcategoryName)}
          </AccordionTitle>
          {!!userIsAdmin && !!showAdminFunctionality && (
            <AdminFeaturesWrapper>
              {subcategoryIsVisible ? (
                <StyledShowTag
                  data-testid="EditSubcategoryToggleShow"
                  label={t('Admin.categoryIsVisible')}
                  leadingElement={<IconEye width={10} />}
                  onClick={(e: React.FormEvent<HTMLFormElement>) => {
                    // We do this to prevent the accordion from opening/closing
                    e.stopPropagation();
                    setShowToggleSubcategoryVisibility(true);
                  }}
                  variant="filled"
                />
              ) : !subcategoryIsVisible ? (
                <StyledHideTagWrapper
                  onClick={e => {
                    e.stopPropagation();
                    setShowToggleSubcategoryVisibility(true);
                  }}
                >
                  <StyledHideTag
                    data-testid="EditSubcategoryToggleHide"
                    disabled
                    label={t('Admin.categoryHidden')}
                    leadingElement={<IconEyeOff width={10} />}
                    variant="filled"
                  />
                </StyledHideTagWrapper>
              ) : null}
              <PopoverMenuButton>
                <PopoverMenuButtonItem
                  icon={<IconEdit />}
                  onClick={onSubcategoryEdit}
                  text={t('Generic.rename')}
                />
                <PopoverMenuButtonItem
                  icon={<IconTrash />}
                  isDestructive={true}
                  onClick={() => setShowDeleteSubcategory(true)}
                  text={t('Button.delete')}
                />
              </PopoverMenuButton>
            </AdminFeaturesWrapper>
          )}
        </AccordionHeaderRow>
      )}
    </AccordionHeader>
  );
};

const AccordionHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const AccordionHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const AccordionTitle = styled(Typography)`
  margin-left: 16px;
`;

const StyledTranslationRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const StyledDeleteIconButton = styled(IconButton)`
  margin-left: auto;
`;

const SubcategoryTitleRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  width: 100%;
  padding: 0 16px;
`;

const StyledFormControlWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 24px;
`;

const StyledSixDotIcon = styled(IconGripVertical)`
  width: 25px;
  height: 25px;
  cursor: move;
  z-index: 2;
  color: ${({ theme }) => theme.grayScale.gray3};
`;

const AdminFeaturesWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const EditAdminFeaturesWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const StyledShowTag = styled(Tag)`
  flex-direction: row-reverse;
  align-items: center;
  margin-left: 16px;
  height: fit-content;

  .tag-leading-element {
    margin: 0 0 0 6px;
    width: unset;
    height: unset;
  }
`;

const StyledHideTagWrapper = styled('span')`
  margin-left: 16px;
  cursor: pointer;
`;

const StyledHideTag = styled(Tag)`
  flex-direction: row-reverse;
  align-items: center;
  height: fit-content;

  .tag-leading-element {
    margin: 0 0 0 6px;
    width: unset;
    height: unset;
  }
`;

export default Header;
