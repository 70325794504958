import Constants from 'constants/index';
import Bugsnag from '@bugsnag/browser';
import { useAppSelector } from '.';
import { formatBugsnagErrorMessage } from '@/utils/bugsnag';
import { selectBugsnagInitialized } from '@/store/app/selectors';
import { useFeaturePermission } from '@/utils/permissions';

const useBugsnagNotify = () => {
  const bugsnagInitialized = useAppSelector(selectBugsnagInitialized);
  const monitoringEnabled = useFeaturePermission('monitoring');

  const notifyBugsnag = (err: unknown): void => {
    const error = typeof err === 'string' ? new Error(err) : err;

    if (monitoringEnabled && bugsnagInitialized && Constants.BUGSNAG_ENABLED) {
      Bugsnag.notify(formatBugsnagErrorMessage(error));
    } else {
      console.error(error);
    }
  };

  return { notifyBugsnag };
};

export default useBugsnagNotify;
