import Constants from 'constants/index';
import InputDialog from '@/components/Dialogs/InputDialog';
import { useUpdateTrainingPlanMutation } from '@/services/pathwayApi';
import toast from 'react-hot-toast';
import {
  selectUserLanguage,
  selectUserPermissions,
} from '@/store/user/selectors';
import { LanguageObject } from '@/types';
import { getNameFromLanguage } from '@/utils/language';
import { ChecklistDTO } from '@cfacorp-pathway/xp-api-typescript-client';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useBugsnagNotify from '@/hooks/useBugsnagNotify';
import ToastMessageBlock from '@/components/Toasts/SuccessToast';
import ConfirmationModal from '@/components/popups/ConfirmationModal';

interface RenamePlanProps {
  plan: PlanWithOperator;
  refetchPlans: () => void;
  setShowRenamePlan: (show: boolean) => void;
  showRenamePlan: boolean;
}

interface PlanWithOperator extends ChecklistDTO {
  operatorId: string;
}

const RenamePlan: React.FC<RenamePlanProps> = ({
  plan,
  refetchPlans,
  setShowRenamePlan,
  showRenamePlan,
}) => {
  const { t } = useTranslation();
  const userPermissions = useSelector(selectUserPermissions);
  const userLanguage = useSelector(selectUserLanguage);
  const { notifyBugsnag } = useBugsnagNotify();
  const [updateTrainingPlan] = useUpdateTrainingPlanMutation();
  const userIsStakeholder = userPermissions.hasOwnProperty(
    Constants.USER_PERMISSIONS.STAKEHOLDER,
  );
  const [showInvalidNameError, setShowInvalidNameError] = useState(false);

  const isValidPlanName = (planName: string, assignedOperator: string) => {
    const noAssignedOperatorAndUserIsNotStakeholder =
      !assignedOperator && !userIsStakeholder;

    if (!planName || noAssignedOperatorAndUserIsNotStakeholder) {
      setShowInvalidNameError(true);
      console.error('invalid name or missing operator');
      notifyBugsnag(
        new Error(
          `Build plans error - no plan name or no operator: ${JSON.stringify({
            planName,
            assignedOperator,
          })}`,
        ),
      );
      return false;
    }
    return true;
  };

  const onRenamePlan = (newName: string, plan: PlanWithOperator) => {
    const trimmedName = newName?.trim();
    if (!isValidPlanName(trimmedName, plan.ownerId!)) {
      return;
    }
    const payload = {
      operatorId: plan.operatorId,
      checklist: {
        ...plan,
        name: {
          ...plan.name,
          [userLanguage]: trimmedName,
        },
      },
    };
    updateTrainingPlan(payload)
      .unwrap()
      .then(() => {
        refetchPlans();
        toast.custom(toastObj => (
          <ToastMessageBlock id={toastObj.id}>
            {`${trimmedName} ${t('TrainingPlans.toastMessage.renamed')}`}
          </ToastMessageBlock>
        ));
      })
      .catch(err => {
        notifyBugsnag(err);
      });
  };

  return (
    <>
      <InputDialog
        headerText={t('Generic.giveNewName', { object: t('Generic.plan') })}
        isOpen={showRenamePlan}
        onClose={() => setShowRenamePlan(false)}
        primaryButtonHandler={inputValue => {
          onRenamePlan(inputValue, plan);
          setShowRenamePlan(false);
        }}
        primaryButtonText={t('Button.update')}
        primaryButtonVariant="filled"
        secondaryButtonHandler={() => setShowRenamePlan(false)}
        secondaryButtonText={t('Button.cancel')}
        textInputDefaultValue={getNameFromLanguage(
          plan?.name as LanguageObject,
        )}
        textInputPlaceholder={t('TrainingPlans.planNamePlaceholder')}
      />
      <ConfirmationModal
        bodyText={t('InvalidPlanName.paragraphText')}
        headerText={t('InvalidPlanName.errorHeader')}
        isError={true}
        isOpen={!!showInvalidNameError}
        onClose={() => setShowInvalidNameError(false)}
        primaryButtonHandler={() => setShowInvalidNameError(false)}
        primaryButtonText={t('Button.close')}
      />
    </>
  );
};
export default RenamePlan;
