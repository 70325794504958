import { getRefreshTokenWithRedirect, isUnAuthorizedError } from 'utils/auth';
import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react';
import {
  ApiError,
  AuthResponse,
  OpenAPI,
  OpenAPIConfig,
  OperatorDTO,
  ComplianceConfigurationDTO,
  CourseEntity,
  BrowseResponse,
  PathwayXPClient,
  SearchResponse,
  UserPreferencesEntity,
  UserChecklistResponse,
  UserChecklistStatusResponse,
  CourseReportDTO,
  Course,
  AssignableCourseResult,
  AssignableResponse,
  PathwayUser,
  UserChecklistResultDTO,
  Resource,
} from '@cfacorp-pathway/xp-api-typescript-client';
import store from 'store';
import Constants from '../constants/index';
import {
  AllCoursesParams,
  AssignableChecklistsParams,
  AssignedByChecklistParams,
  BrowseParams,
  EnrollmentsByCourseParams,
  generateCourseReportParams,
  ListPlanStatusParams,
  OperatorCourseSettingParams,
  OperatorCourseSettingsParams,
  ResourceParams,
  SaveUserPreferencesParams,
  SearchClickFeedbackParams,
  SearchParams,
  UsersForLocationsParams,
  UserTrainingPlansParams,
} from './api-types';

export const getApiToken = () => {
  const localStorageToken = localStorage.getItem('okta-token-storage');
  const webToken = localStorageToken
    ? JSON.parse(localStorageToken)?.accessToken?.accessToken
    : null;
  const iOSToken = (window as any)?.accessToken;
  const reactNativeWebView = (window as any)?.ReactNativeWebView;
  let androidToken;
  if (
    reactNativeWebView &&
    typeof reactNativeWebView.injectedObjectJson === 'function'
  ) {
    androidToken = JSON.parse(
      (reactNativeWebView as any)?.injectedObjectJson(),
    )?.accessToken;
  }
  return webToken || iOSToken || androidToken;
};

export const xpApiConfig: OpenAPIConfig = {
  ...OpenAPI,
  BASE: Constants.XP_API_BASE_URL ?? '',
  HEADERS: {
    'Content-Type': 'application/json',
  },
  TOKEN: () => getApiToken(),
  CREDENTIALS: 'include',
  WITH_CREDENTIALS: true,
};

const pathwayXPApiBaseQuery =
  (): BaseQueryFn<
    {
      sdkFunction: (data: any) => Promise<any>;
      data?: object;
    },
    unknown,
    unknown
  > =>
  async ({ sdkFunction, data }) => {
    try {
      const result = await sdkFunction(data);
      return { data: result };
    } catch (error) {
      const err = error as ApiError;
      // if unauthorized, get refresh token and redirect
      if (isUnAuthorizedError(err)) {
        // invalidate Browse api response cache when user refreshes token
        store.dispatch(xpApi.util.invalidateTags(['Browse']));
        await getRefreshTokenWithRedirect();
      }
      return {
        error: {
          status: err?.status,
          data: err?.body || err.message,
        },
      };
    }
  };

const client = new PathwayXPClient(xpApiConfig);

export const xpApi = createApi({
  reducerPath: 'xpApi',
  baseQuery: pathwayXPApiBaseQuery(),
  tagTypes: ['Browse'],
  endpoints: builder => ({
    getAuth: builder.query<AuthResponse, void>({
      query: () => ({
        sdkFunction: async () => await client.authorization.getAuth(),
      }),
    }),
    setAuthCookie: builder.query<any, void>({
      query: () => ({
        sdkFunction: async () => await client.documents.setAuthCookie(),
      }),
    }),
    getBrowseStructure: builder.query<BrowseResponse, BrowseParams>({
      query: ({ country, language }) => ({
        sdkFunction: async (args: BrowseParams) =>
          await client.browse.browseStructure(args),
        data: { country, language },
      }),
      providesTags: ['Browse'],
      keepUnusedDataFor: Number.MAX_SAFE_INTEGER,
    }),
    getAllCourses: builder.query<Array<CourseEntity>, AllCoursesParams>({
      query: ({ isAdmin, type }) => ({
        sdkFunction: async (args: AllCoursesParams) =>
          await client.courses.getAllCourses(args),
        data: { isAdmin, type },
      }),
    }),
    setOperatorCourseSetting: builder.mutation<
      any,
      OperatorCourseSettingParams
    >({
      query: ({ courseId, enable, operatorId }) => ({
        sdkFunction: async (args: OperatorCourseSettingParams) =>
          await client.courses.setOperatorCourseSetting(args),
        data: { courseId, enable, operatorId },
      }),
    }),
    addSearchResultFeedback: builder.mutation<any, SearchClickFeedbackParams>({
      query: ({ resultId, queryId }) => ({
        sdkFunction: async (args: SearchClickFeedbackParams) =>
          await client.search.searchClickFeedback(args),
        data: { resultId, queryId },
      }),
    }),
    getSearchResults: builder.query<SearchResponse, SearchParams>({
      query: ({ query, country, language, pageNumber }) => ({
        sdkFunction: async (args: SearchParams) =>
          await client.search.search(args),
        data: { query, country, language, pageNumber },
      }),
    }),
    saveUserPreferences: builder.mutation<
      UserPreferencesEntity,
      SaveUserPreferencesParams['requestBody']
    >({
      query: ({ country, language, location, userId }) => ({
        sdkFunction: async (args: SaveUserPreferencesParams) =>
          await client.users.saveUserPreferences(args),
        data: { requestBody: { country, language, location, userId } },
      }),
    }),
    getReportsUserTrainingPlans: builder.query<
      UserChecklistResponse,
      { userId: string }
    >({
      query: ({ userId }) => ({
        sdkFunction: async (args: UserTrainingPlansParams) =>
          await client.reports.getUserTrainingPlans(args),
        data: { adId: userId },
      }),
    }),
    getReportsTrainingPlanStatuses: builder.query<
      UserChecklistStatusResponse,
      { locations: string[] }
    >({
      query: ({ locations }) => ({
        sdkFunction: async (args: ListPlanStatusParams) =>
          await client.reports.listPlanStatus(args),
        data: { locationIds: locations },
      }),
    }),
    getCourseReport: builder.query<
      CourseReportDTO,
      { courseId: string; locations: string[] }
    >({
      query: ({ courseId, locations }) => ({
        sdkFunction: async (args: generateCourseReportParams) =>
          await client.courses.generateCourseReport(args),
        data: { courseId, location: locations },
      }),
    }),
    getOperatorCourseSettings: builder.query<
      ComplianceConfigurationDTO,
      OperatorCourseSettingsParams
    >({
      query: ({ operatorId }) => ({
        sdkFunction: async (args: OperatorCourseSettingsParams) =>
          await client.courses.getOperatorCourseSettings(args),
        data: { operatorId },
      }),
    }),
    getCompliancePlanUrl: builder.query<
      { url: string },
      { courseId: string; lang?: string }
    >({
      query: query => ({
        sdkFunction: async () => await client.courses.getSqssl(query),
      }),
    }),
    getComplianceCourses: builder.query<Course[], void>({
      query: () => ({
        sdkFunction: async () => await client.courses.getComplianceCourses(),
      }),
    }),
    getProcedureIcons: builder.query<string[], void>({
      query: () => ({
        sdkFunction: async () => await client.icons.getProcedureIcons(),
      }),
    }),
    getOperators: builder.query<Array<OperatorDTO>, void>({
      query: () => ({
        sdkFunction: async () => await client.users.getOperators(),
      }),
    }),
    getEnrollmentsByCourse: builder.query<
      AssignableCourseResult,
      { courseId: string; locations: string[] }
    >({
      query: ({ courseId, locations }) => ({
        sdkFunction: async (args: EnrollmentsByCourseParams) =>
          await client.courses.listEnrollmentsByCourse(args),
        data: { courseId, listOfLocationsToFilter: locations },
      }),
    }),
    getUsersForLocations: builder.query<PathwayUser[], { locations: string[] }>(
      {
        query: ({ locations }) => ({
          sdkFunction: async (args: UsersForLocationsParams) =>
            await client.users.getUsersForLocations(args),
          data: { locationNumbers: locations },
        }),
      },
    ),
    getAssignableChecklistsAndCourses: builder.query<
      AssignableResponse,
      { locations?: string[] }
    >({
      query: ({ locations }) => ({
        sdkFunction: async (args: AssignableChecklistsParams) =>
          await client.checklistV2.listAssignableChecklists(args),
        data: { locationIds: locations },
      }),
    }),
    getStatusesAssignedToChecklist: builder.query<
      UserChecklistResultDTO,
      { checklistId: string; locations: string[] }
    >({
      query: ({ checklistId, locations }) => ({
        sdkFunction: async (args: AssignedByChecklistParams) =>
          await client.checklistV2.listAssignedByChecklist(args),
        data: { checklistId, locationIds: locations },
      }),
    }),
    getResource: builder.query<Resource, ResourceParams>({
      query: ({ country, id, language }) => ({
        sdkFunction: async (args: ResourceParams) =>
          await client.resources.getResource(args),
        data: { country, id, language },
      }),
    }),
  }),
});

export const {
  useGetAuthQuery,
  useSetAuthCookieQuery,
  useAddSearchResultFeedbackMutation,
  useGetBrowseStructureQuery,
  useGetOperatorCourseSettingsQuery,
  useGetSearchResultsQuery,
  useGetAllCoursesQuery,
  useSetOperatorCourseSettingMutation,
  useSaveUserPreferencesMutation,
  useGetReportsUserTrainingPlansQuery,
  useGetReportsTrainingPlanStatusesQuery,
  useGetCourseReportQuery,
  useGetCompliancePlanUrlQuery,
  useGetComplianceCoursesQuery,
  useGetEnrollmentsByCourseQuery,
  useGetProcedureIconsQuery,
  useGetUsersForLocationsQuery,
  useGetAssignableChecklistsAndCoursesQuery,
  useGetOperatorsQuery,
  useGetStatusesAssignedToChecklistQuery,
  useGetResourceQuery,
} = xpApi;
