import styled from 'styled-components';
import CreatePlanFab from './CreatePlanFab';
import LoadMorePaginator from '@/components/LoadMorePaginator/LoadMorePaginator';
import ManagePlansCompliancePlanCard from '@/components/Cards/PlanCard/PlanCards/ManagePlansComplianceCard';
import NoMessage from '@/components/NoMessage/NoMessage';
import { Trans, useTranslation } from 'react-i18next';
import ClearFiltersHeader from '@/components/ClearFiltersHeader/ClearFiltersHeader';
import SortFilterHeader from '@/components/SortFilterHeader/SortFilterHeader';
import { useDispatch, useSelector } from 'react-redux';
import StickyFilterCard from '@/components/Cards/StickyFilterCard/StickyFilterCard';
import CheckboxFilterSection, {
  Label,
} from '@/components/Cards/StickyFilterCard/CheckboxFilterSection';
import {
  addManagePlansCategoryFilter,
  clearManagePlansCheckboxFilters,
  loadMorePlans,
  removeManagePlansFilter,
  setManagePlansSort,
} from '@/store/managePlansFilter/slice';
import { selectPagination } from '@/store/managePlansFilter/selector';
import { Dispatch, SetStateAction, useState } from 'react';
import { DropdownOptions } from '@/types';
import ManagedPlansCard from '@/components/Cards/PlanCard/PlanCards/ManagedPlans/ManagedPlansCard';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ManagePlansPlanCard from '@/components/Cards/PlanCard/PlanCards/ManagePlansCard';
import { useGetOperatorsQuery } from '@/services/pathwayApi';
import { useLocation } from 'react-router-dom';
import AddTeamMembers from './Modals/AddTeamMembers';
import TrainTogether from './Modals/TrainTogether';
import RenamePlan from './Modals/RenamePlan';
import EditTranslations from './Modals/EditTranslations';
import DuplicatePlan from './Modals/DuplicatePlan';
import DeletePlan from './Modals/DeletePlan';
import Print from './Modals/Print';

interface PlanCardsProps {
  categoryLabels: Label;
  currentFilters: string[];
  filteredAndSortedPlans: any[];
  isFetchingPlans: boolean;
  isSmAndDown: boolean;
  onPrintReport: (id: string, plan: any) => void;
  onShowPopUp: (id: string, name: string) => void;
  refetchPlans: () => void;
  searchFilter: string;
  sort: string;
  sortOptions: DropdownOptions[];
  setShowCustomPlan: Dispatch<SetStateAction<boolean>>;
  setShowTemplateModal: Dispatch<SetStateAction<boolean>>;
}

const PlanCards: React.FC<PlanCardsProps> = ({
  categoryLabels,
  currentFilters,
  filteredAndSortedPlans,
  isFetchingPlans,
  isSmAndDown,
  onPrintReport,
  onShowPopUp,
  refetchPlans,
  searchFilter,
  sort,
  sortOptions,
  setShowCustomPlan,
  setShowTemplateModal,
}) => {
  const { t } = useTranslation();
  const { plansRedesign: plansRedesignFeatureFlag } = useFlags();
  const dispatch = useDispatch();
  const { showing, total } = useSelector(selectPagination);
  const { search } = useLocation<{ refetch: () => void }>();
  const isCompliance = search.includes('compliancePlan');
  const [showAddPlanPopUp, setShowAddPlanPopUp] = useState(false);
  const [showAddTeamMembersPopup, setShowAddTeamMembersPopup] = useState(false);
  const [showRenamePlan, setShowRenamePlan] = useState(false);
  const [showDueDatePopup, setShowDueDatePopup] = useState(false);
  const [editTranslations, setEditTranslations] = useState(false);
  const [showDuplicatePlan, setShowDuplicatePlan] = useState(false);
  const [showDeletePlan, setShowDeletePlan] = useState(false);
  const [printReport, setPrintReport] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [plan, setPlan] = useState<any>({});

  const { data: operators } = useGetOperatorsQuery();

  const operatorLocations = operators?.find(
    operator => operator.id === plan?.ownerId,
  )?.locations;

  return (
    <>
      <PlanCardList>
        {!isSmAndDown && !isFetchingPlans && (
          <StickyFilterCard>
            <CheckboxFilterSection
              labels={categoryLabels}
              onChange={value => {
                if (!!currentFilters.includes(value)) {
                  dispatch(removeManagePlansFilter({ filter: value }));
                } else {
                  dispatch(addManagePlansCategoryFilter({ filter: value }));
                }
              }}
              title={t('Browse.categories')}
            />
          </StickyFilterCard>
        )}
        <PlanCardsContainer>
          <SortFilterHeader
            label={t('TrainingPlans.filtering.sortBy')}
            onChange={option => {
              dispatch(setManagePlansSort({ sort: option?.value }));
            }}
            onClear={() => {
              dispatch(clearManagePlansCheckboxFilters());
            }}
            options={sortOptions}
            showClear={false}
            showCompletedPlansOption={false}
            showMyCompletedPlansOnlySwitchValue={false}
            text={`${total ?? 0} ${t('TrainingPlans.filtering.plans')}`}
            value={sortOptions?.find(option => option.value === sort)}
          />

          <ClearFiltersHeader
            aliases={categoryLabels}
            clearAllFilters={() => {
              dispatch(clearManagePlansCheckboxFilters());
            }}
            clearFilter={value => {
              dispatch(removeManagePlansFilter({ filter: value }));
            }}
            filters={currentFilters}
          />
          <PlanCardsList>
            {!filteredAndSortedPlans?.length &&
              !isFetchingPlans &&
              !currentFilters?.length &&
              !searchFilter && (
                <NoMessage
                  message={
                    <Trans
                      i18nKey={'TrainingPlans.createPlanOnBuildPlanPage'}
                    />
                  }
                />
              )}
            {(!!currentFilters?.length || !!searchFilter) && !total && (
              <NoMessage message={t('TrainingPlans.noPlansResults')} />
            )}
            {!!filteredAndSortedPlans?.length && !isFetchingPlans && (
              <ManagePlansCardList>
                {filteredAndSortedPlans.map(plan =>
                  plansRedesignFeatureFlag && !!plan?.checklist ? (
                    <ManagedPlansCard
                      assignedUsers={plan?.assignedUsers}
                      assignPlan={plan => {
                        setPlan(plan);
                        setShowAddTeamMembersPopup(true);
                      }}
                      completedUsers={plan?.completedUsers}
                      deletePlan={plan => {
                        setPlan(plan);
                        setShowDeletePlan(true);
                      }}
                      duplicatePlan={plan => {
                        setPlan(plan);
                        setShowDuplicatePlan(true);
                      }}
                      editTranslations={plan => {
                        setPlan(plan);
                        setEditTranslations(true);
                      }}
                      key={plan.checklist.id}
                      plan={plan.checklist}
                      printReport={plan => {
                        setPlan(plan);
                        setPrintReport(true);
                      }}
                      renamePlan={plan => {
                        setPlan(plan);
                        setShowRenamePlan(true);
                      }}
                      sendPlanToParent={plan => setPlan(plan)}
                    />
                  ) : !plansRedesignFeatureFlag && !!plan?.checklist ? (
                    <ManagePlansPlanCard
                      assignedUsers={plan?.assignedUsers}
                      completedUsers={plan?.completedUsers}
                      key={plan.checklist.id}
                      plan={plan.checklist}
                    />
                  ) : (
                    <ManagePlansCompliancePlanCard
                      course={{
                        courseID: plan?.courseID,
                        courseName: plan?.courseName,
                        createdDate: plan?.createdDate,
                        enabled: plan?.enabled,
                        enrollments: plan?.enrollments,
                        id: plan?.id,
                      }}
                      key={plan?.courseID}
                      onPrintReport={id => {
                        onPrintReport(id, plan);
                      }}
                      onShowEnableCompliancePlan={onShowPopUp}
                    />
                  ),
                )}
                <LoadMorePaginator
                  onClick={() => dispatch(loadMorePlans())}
                  showing={showing}
                  showingText={t('TrainingPlans.showingXOfYPlans', {
                    showing,
                    total,
                  })}
                  total={total}
                />
              </ManagePlansCardList>
            )}
          </PlanCardsList>
        </PlanCardsContainer>
        <CreatePlanFab
          setShowAddPlanPopUp={setShowAddPlanPopUp}
          setShowCustomPlan={setShowCustomPlan}
          setShowTemplateModal={setShowTemplateModal}
          showAddPlanPopUp={showAddPlanPopUp}
        />
      </PlanCardList>
      <AddTeamMembers
        operatorLocations={operatorLocations!}
        plan={plan}
        selectedId={selectedId}
        showAddTeamMembersPopup={showAddTeamMembersPopup}
        showDueDatePopup={showDueDatePopup}
        setSelectedId={setSelectedId}
        setShowDueDatePopup={setShowDueDatePopup}
        setShowAddTeamMembersPopup={setShowAddTeamMembersPopup}
      />
      <TrainTogether
        isCompliance={isCompliance}
        operatorLocations={operatorLocations!}
        plan={plan}
        setSelectedId={setSelectedId}
        setShowAddTeamMembersPopup={setShowAddTeamMembersPopup}
        setShowDueDatePopup={setShowDueDatePopup}
      />
      <RenamePlan
        plan={plan}
        refetchPlans={refetchPlans}
        setShowRenamePlan={setShowRenamePlan}
        showRenamePlan={showRenamePlan}
      />
      <EditTranslations
        editTranslations={editTranslations}
        plan={plan}
        refetchPlans={refetchPlans}
        setEditTranslations={setEditTranslations}
      />
      <DuplicatePlan
        plan={plan}
        refetchPlans={refetchPlans}
        setShowDuplicatePlan={setShowDuplicatePlan}
        showDuplicatePlan={showDuplicatePlan}
      />
      <DeletePlan
        plan={plan}
        refetchPlans={refetchPlans}
        setShowDeletePlan={setShowDeletePlan}
        showDeletePlan={showDeletePlan}
      />
      <Print
        plan={plan}
        printReport={printReport}
        resetReport={() => setPrintReport(false)}
      />
    </>
  );
};

const ManagePlansCardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const PlanCardList = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  position: relative;
  flex-grow: 1;
  max-width: 100%;
`;

const PlanCardsContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow: hidden;
`;

const PlanCardsList = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  max-width: 100%;
`;

export default PlanCards;
