import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'trainingMode',
  initialState: {
    isOpen: false,
    isTrainingMode: false,
    showProgressModal: false,
    assignedChecklistStatus: false,
    trainingModeData: {
      estimatedMinutes: 0,
      languageSelected: '',
      planId: '',
      planLabel: '',
      planName: '',
      teamMembers: [],
      selectedTeamMembers: [],
      assignedTeamMembersStatus: [],
    },
  },
  reducers: {
    enterTrainingMode: state => {
      state.isTrainingMode = true;
    },
    exitTrainingMode: state => {
      state.isTrainingMode = false;
    },
    showProgressModal: state => {
      state.showProgressModal = true;
    },
    hideProgressModal: state => {
      state.showProgressModal = false;
    },
    showTrainingMode: state => {
      state.isOpen = true;
    },
    hideTrainingMode: state => {
      state.isOpen = false;
    },
    setAssignedChecklistStatus: (state, action) => {
      state.assignedChecklistStatus = action.payload;
    },
    setTrainingModeData: (state, action) => {
      state.trainingModeData = action.payload;
    },
    resetTrainingModeData: state => {
      state.trainingModeData = {
        estimatedMinutes: 0,
        languageSelected: '',
        planId: '',
        planLabel: '',
        planName: '',
        teamMembers: [],
        selectedTeamMembers: [],
        assignedTeamMembersStatus: [],
      };
    },
  },
});

export const {
  enterTrainingMode,
  exitTrainingMode,
  showProgressModal,
  hideProgressModal,
  showTrainingMode,
  hideTrainingMode,
  setAssignedChecklistStatus,
  setTrainingModeData,
  resetTrainingModeData,
} = slice.actions;

export default slice.reducer;
