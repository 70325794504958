import {
  ContentReference,
  Document,
} from '@cfacorp-pathway/xp-api-typescript-client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/** How we got to the document. */
export type RoutedFrom = 'search' | 'browse' | 'link';

interface DocumentState {
  /** The document we are currently viewing. */
  document: Document | null;
  /** How we got to the document. */
  routedFrom: RoutedFrom;
  /** Document icon. */
  icon: string;
  /** Whether the document is a Tridion document. Derived state from the document's source system. */
  isTridion: boolean;
  /** Tridion document has a translated url. */
  hasTranslatedValue: boolean;
  /** Tridion category name. */
  categoryName: string;
  /** Tridion subcategory name. */
  subcategoryName: string;
  /** Document route from url, mainly for browse category url */
  backUrl?: string;
}

export type SetDocumentActionPayload = {
  document: Document | null;
  routedFrom: RoutedFrom;
  icon?: string;
  backUrl?: string;
  country?: ContentReference.country;
};

const initialState: DocumentState = {
  document: null,
  routedFrom: 'link',
  icon: 'placeholder',
  isTridion: false,
  hasTranslatedValue: false,
  categoryName: '',
  subcategoryName: '',
  backUrl: '',
};

export const slice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    setDocument: (state, action: PayloadAction<SetDocumentActionPayload>) => {
      const { icon, routedFrom, document, backUrl } = action.payload;
      const isFromLink = routedFrom === 'link';

      if (isFromLink && !document) {
        return;
      }

      state.routedFrom = routedFrom;
      state.icon = icon ?? 'placeholder';
      state.backUrl = backUrl;
      state.isTridion =
        document?.sourceSystem === Document.sourceSystem.TRIDION;
      state.hasTranslatedValue =
        !!document?.otherContentUrl &&
        document?.sourceSystem === Document.sourceSystem.TRIDION;

      if (!isFromLink) {
        state.document = document;
      }
    },
    setIcon: (state, action: PayloadAction<string | undefined>) => {
      state.icon = action.payload ?? 'placeholder';
    },
    setRoutedFrom: (state, action: PayloadAction<RoutedFrom>) => {
      state.routedFrom = action.payload;
    },
    setCategory: (
      state,
      action: PayloadAction<{
        categoryName?: string;
        subcategoryName?: string;
      }>,
    ) => {
      state.categoryName = action.payload.categoryName ?? '';
      state.subcategoryName = action.payload.subcategoryName ?? '';
    },
    clearDocument: state => {
      state.document = null;
      state.routedFrom = 'link';
      state.icon = 'placeholder';
      state.isTridion = false;
      state.hasTranslatedValue = false;
      state.categoryName = '';
      state.subcategoryName = '';
      state.backUrl = '';
    },
  },
});

export const {
  setDocument,
  setIcon,
  clearDocument,
  setCategory,
  setRoutedFrom,
} = slice.actions;

export default slice.reducer;
