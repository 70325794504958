import Constants from 'constants/index';
import { createSlice } from '@reduxjs/toolkit';

interface TeamMember {
  courseId: string;
  status: string;
  locations: string[];
  name: string;
  sort: string;
  filteredTeamMembers: TeamMember[];
  searchFilters: string;
  sortedTeamMembers: TeamMember[];
  paginatedTeamMembers: TeamMember[];
  page: number;
  pageSize: number;
  showing: number;
}

const applyFilters = ({
  teamMembers,
  locationFilters,
  statusFilters,
  searchFilters,
}: {
  teamMembers: TeamMember[];
  locationFilters: string[];
  statusFilters: string[];
  searchFilters: string;
}) => {
  let filteredTeamMembers = teamMembers;
  if (locationFilters?.length) {
    filteredTeamMembers = filteredTeamMembers?.filter(teamMember => {
      const { locations } = teamMember;
      const hasLocation = locations?.some((location: string) =>
        locationFilters.includes(location),
      );
      return hasLocation;
    });
  }
  if (statusFilters?.length) {
    filteredTeamMembers = filteredTeamMembers?.filter(teamMember => {
      let adjustedFilters = statusFilters;
      const { status } = teamMember;
      if (
        !!teamMember.courseId &&
        statusFilters.includes(Constants.LEARN_UPON_TRAINING_PLANS.COMPLETED)
      ) {
        adjustedFilters = [
          ...adjustedFilters,
          Constants.LEARN_UPON_TRAINING_PLANS.PASSED,
        ];
      }
      // If not complete is chosen, we return all non-completed statuses
      if (
        (!!teamMember.courseId || teamMember.courseId === '') &&
        statusFilters.includes(
          Constants.LEARN_UPON_TRAINING_PLANS.NOT_COMPLETED,
        )
      ) {
        adjustedFilters = [
          ...adjustedFilters,
          Constants.LEARN_UPON_TRAINING_PLANS.CANCELLED,
          Constants.LEARN_UPON_TRAINING_PLANS.FAILED,
          Constants.LEARN_UPON_TRAINING_PLANS.IN_PROGRESS,
          Constants.LEARN_UPON_TRAINING_PLANS.NO_SHOW,
          Constants.LEARN_UPON_TRAINING_PLANS.NOT_STARTED,
          Constants.LEARN_UPON_TRAINING_PLANS.PENDING_REVIEW,
          '',
        ];
      }
      // Non-compliance plans
      if (
        !teamMember.courseId &&
        statusFilters.includes(Constants.TRAINING_PLANS.NOT_COMPLETED)
      ) {
        adjustedFilters = [
          ...adjustedFilters,
          Constants.TRAINING_PLANS.IN_PROGRESS,
          Constants.TRAINING_PLANS.NOT_STARTED,
          '',
        ];
      }

      const hasStatus = adjustedFilters.includes(status);
      return hasStatus;
    });
  }

  if (searchFilters) {
    filteredTeamMembers = filteredTeamMembers?.filter(teamMember => {
      const { name } = teamMember;
      return name.toLowerCase().includes(searchFilters.toLowerCase());
    });
  }
  return filteredTeamMembers;
};

const sortTeamMembersByDate = (teamMembers: never[], dateProp: string) => {
  return teamMembers?.toSorted((a: any, b: any) => {
    // We do this to ensure an undefined or null date sorts to the back of the list
    const dateA = +new Date(a[dateProp]) || Number.MAX_SAFE_INTEGER;
    const dateB = +new Date(b[dateProp]) || Number.MAX_SAFE_INTEGER;
    return dateA - dateB;
  });
};

const applySort = (teamMembers: never[], sort: string) => {
  let sortedTeamMembers = [...teamMembers];
  switch (sort) {
    case Constants.PLANS_SORT_OPTIONS.A2Z:
      sortedTeamMembers = sortedTeamMembers?.toSorted((a: any, b: any) =>
        a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase()),
      );
      break;
    case Constants.PLANS_SORT_OPTIONS.Z2A:
      sortedTeamMembers = sortedTeamMembers?.toSorted((a: any, b: any) =>
        b?.name?.toLowerCase().localeCompare(a?.name?.toLowerCase()),
      );
      break;
    case Constants.PLANS_SORT_OPTIONS.START_DATE:
      sortedTeamMembers = sortTeamMembersByDate(sortedTeamMembers, 'startDate');
      break;
    case Constants.PLANS_SORT_OPTIONS.DUE_DATE:
      sortedTeamMembers = sortTeamMembersByDate(sortedTeamMembers, 'dueDate');
      break;
    default:
      console.log(`Error unknown sort option: ${sort}`);
      break;
  }
  return sortedTeamMembers;
};

const applyPagination = (
  teamMembers: any[],
  page: number,
  pageSize: number,
) => {
  const total = teamMembers.length;
  const showing = page * pageSize < total ? page * pageSize : total;
  const loadedTeamMembers = teamMembers.slice(0, showing);
  return { loadedTeamMembers, showing, total };
};

export const slice = createSlice({
  name: 'teamMembersFilter',
  initialState: {
    teamMembers: [],
    statusFilters: [],
    locationFilters: [],
    sort: Constants.PLANS_SORT_OPTIONS.A2Z,
    filteredTeamMembers: [],
    searchFilters: '',
    sortedTeamMembers: [],
    paginatedTeamMembers: [],
    page: 1,
    pageSize: 12,
    showing: 0,
    total: 0,
  },
  reducers: {
    addTeamMembersStatusFilter: (state, action) => {
      (state.statusFilters as string[]) = [
        ...state.statusFilters,
        action.payload.filter,
      ];
      (state.filteredTeamMembers as TeamMember[]) = applyFilters({
        teamMembers: state.sortedTeamMembers,
        locationFilters: state.locationFilters,
        statusFilters: state.statusFilters,
        searchFilters: state.searchFilters,
      });
      ({
        loadedTeamMembers: state.paginatedTeamMembers as TeamMember[],
        showing: state.showing,
        total: state.total,
      } = applyPagination(
        state.filteredTeamMembers,
        state.page,
        state.pageSize,
      ));
    },
    addTeamMembersLocationFilter: (state, action) => {
      (state.locationFilters as TeamMember[]) = [
        ...state.locationFilters,
        action.payload.filter,
      ];
      (state.filteredTeamMembers as TeamMember[]) = applyFilters({
        teamMembers: state.sortedTeamMembers,
        locationFilters: state.locationFilters,
        statusFilters: state.statusFilters,
        searchFilters: state.searchFilters,
      });
      ({
        loadedTeamMembers: state.paginatedTeamMembers as TeamMember[],
        showing: state.showing,
        total: state.total,
      } = applyPagination(
        state.filteredTeamMembers,
        state.page,
        state.pageSize,
      ));
    },
    clearTeamMembersCheckboxFilters: state => {
      state.locationFilters = [];
      state.statusFilters = [];
      (state.filteredTeamMembers as TeamMember[]) = applyFilters({
        teamMembers: state.sortedTeamMembers,
        locationFilters: state.locationFilters,
        statusFilters: state.statusFilters,
        searchFilters: state.searchFilters,
      });
      ({
        loadedTeamMembers: state.paginatedTeamMembers as TeamMember[],
        showing: state.showing,
        total: state.total,
      } = applyPagination(
        state.filteredTeamMembers,
        state.page,
        state.pageSize,
      ));
    },
    clearTeamMembersSearchFilter: state => {
      state.page = 1;
      state.showing = state.pageSize;
      state.searchFilters = '';
      (state.filteredTeamMembers as TeamMember[]) = applyFilters({
        teamMembers: state.sortedTeamMembers,
        locationFilters: state.locationFilters,
        statusFilters: state.statusFilters,
        searchFilters: state.searchFilters,
      });
      ({
        loadedTeamMembers: state.paginatedTeamMembers as TeamMember[],
        showing: state.showing,
        total: state.total,
      } = applyPagination(
        state.filteredTeamMembers,
        state.page,
        state.pageSize,
      ));
    },
    loadMoreTeamMembers: state => {
      state.page += 1;
      ({
        loadedTeamMembers: state.paginatedTeamMembers as TeamMember[],
        showing: state.showing,
        total: state.total,
      } = applyPagination(
        state.filteredTeamMembers,
        state.page,
        state.pageSize,
      ));
    },
    removeTeamMembersFilter: (state, action) => {
      const { filter } = action.payload;
      if ((state.locationFilters as string[]).includes(filter)) {
        state.locationFilters = state.locationFilters.filter(
          locationFilter => locationFilter !== filter,
        );
      }
      if ((state.statusFilters as string[]).includes(filter)) {
        state.statusFilters = state.statusFilters.filter(
          statusFilter => statusFilter !== filter,
        );
      }

      (state.filteredTeamMembers as TeamMember[]) = applyFilters({
        teamMembers: state.sortedTeamMembers,
        locationFilters: state.locationFilters,
        statusFilters: state.statusFilters,
        searchFilters: state.searchFilters,
      });
      ({
        loadedTeamMembers: state.paginatedTeamMembers as TeamMember[],
        showing: state.showing,
        total: state.total,
      } = applyPagination(
        state.filteredTeamMembers,
        state.page,
        state.pageSize,
      ));
    },
    setTeamMembers: (state, action) => {
      state.teamMembers = action.payload.teamMembers ?? [];
      state.sortedTeamMembers = applySort(state.teamMembers, state.sort);
      (state.filteredTeamMembers as TeamMember[]) = applyFilters({
        teamMembers: state.sortedTeamMembers,
        locationFilters: state.locationFilters,
        statusFilters: state.statusFilters,
        searchFilters: state.searchFilters,
      });
      ({
        loadedTeamMembers: state.paginatedTeamMembers as TeamMember[],
        showing: state.showing,
        total: state.total,
      } = applyPagination(
        state.filteredTeamMembers,
        state.page,
        state.pageSize,
      ));
    },
    setTeamMembersSort: (state, action) => {
      state.sort = action.payload.sort;
      state.sortedTeamMembers = applySort(state.teamMembers, state.sort);
      (state.filteredTeamMembers as TeamMember[]) = applyFilters({
        teamMembers: state.sortedTeamMembers,
        locationFilters: state.locationFilters,
        statusFilters: state.statusFilters,
        searchFilters: state.searchFilters,
      });
      ({
        loadedTeamMembers: state.paginatedTeamMembers as TeamMember[],
        showing: state.showing,
        total: state.total,
      } = applyPagination(
        state.filteredTeamMembers,
        state.page,
        state.pageSize,
      ));
    },
    setTeamMembersSearchFilter: (state, action) => {
      state.page = 1;
      state.showing = state.pageSize;
      state.searchFilters = action.payload.searchFilter;
      (state.filteredTeamMembers as TeamMember[]) = applyFilters({
        teamMembers: state.sortedTeamMembers,
        locationFilters: state.locationFilters,
        statusFilters: state.statusFilters,
        searchFilters: state.searchFilters,
      });
      ({
        loadedTeamMembers: state.paginatedTeamMembers as TeamMember[],
        showing: state.showing,
        total: state.total,
      } = applyPagination(
        state.filteredTeamMembers,
        state.page,
        state.pageSize,
      ));
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addTeamMembersLocationFilter,
  addTeamMembersStatusFilter,
  clearTeamMembersCheckboxFilters,
  clearTeamMembersSearchFilter,
  loadMoreTeamMembers,
  removeTeamMembersFilter,
  setTeamMembers,
  setTeamMembersSort,
  setTeamMembersSearchFilter,
} = slice.actions;

export default slice.reducer;
