import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CourseReportDTO } from '@cfacorp-pathway/xp-api-typescript-client';
import Constants from '@/constants';
import { useGetCourseReportQuery as useGetCourseReportQueryOld } from '@/services/pathwayApi';
import { useGetCourseReportQuery } from '@/services/xpApi';
import { getCompletionDate } from '@/utils/reports';
import { convertDateForReports } from '@/utils/date';

/**
 * For ReportsCompliancePlansTab, ReportsPlansTab PrintReport, ReportsPlansView PrintReport,
 *     and ManagePlanView (only content api used in ManagePlanView)
 * A compliance plan report status and data to print
 */
const useGetCourseReportData = (
  planId: string,
  locations: string[],
  additionalConditionToCall: boolean,
  useContentApi?: boolean, // keep using content api for ManagePlanView
) => {
  const { t } = useTranslation();
  const { xpApi: rawXpApiFeatureFlag } = useFlags();

  const xpApiFeatureFlag = rawXpApiFeatureFlag && !useContentApi;

  const [statusReport, setStatusReport] = useState<CourseReportDTO>({
    courseId: '',
    courseName: '',
    foodSafetyLetters: [], // foodSafetyImage: string in ManagePlanView
    locations: [''],
    timeStamp: '',
    userData: [],
  });

  // content or xp api: get selected print plan/course report, to prepare print data
  const {
    data: oldStatusReportData,
    isFetching: oldStatusReportIsFetching,
    isSuccess: oldIsStatusReportSuccess,
    refetch: oldRefetchStatusReport,
  } = useGetCourseReportQueryOld(
    {
      courseId: planId,
      location: locations,
    },
    {
      skip: !planId || !additionalConditionToCall || !!xpApiFeatureFlag,
    },
  );

  const {
    data: xpApiStatusReportData,
    isFetching: xpApiStatusReportIsFetching,
    isSuccess: xpApiIsStatusReportSuccess,
    refetch: xpApiRefetchStatusReport,
  } = useGetCourseReportQuery(
    {
      courseId: planId,
      locations,
    },
    {
      skip: !planId || !additionalConditionToCall || !xpApiFeatureFlag,
    },
  );

  const statusReportData = xpApiFeatureFlag
    ? xpApiStatusReportData
    : oldStatusReportData;
  const isStatusReportFetching = xpApiFeatureFlag
    ? xpApiStatusReportIsFetching
    : oldStatusReportIsFetching;
  const isStatusReportSuccess = xpApiFeatureFlag
    ? xpApiIsStatusReportSuccess
    : oldIsStatusReportSuccess;
  const refetchCourseReportData = xpApiFeatureFlag
    ? xpApiRefetchStatusReport
    : oldRefetchStatusReport;

  useEffect(() => {
    if (isStatusReportSuccess && !isStatusReportFetching) {
      setStatusReport(statusReportData!);
    }
  }, [isStatusReportSuccess, statusReportData, isStatusReportFetching]);

  const resetStatusReportData = useCallback(() => {
    setStatusReport({
      courseId: '',
      courseName: '',
      foodSafetyLetters: [],
      locations: [''],
      timeStamp: '',
      userData: [],
    });
  }, []);

  const statusReportToPrint = useMemo(() => {
    return [...(statusReport?.userData ?? [])]
      .sort((teamMemberA, teamMemberB) => {
        return teamMemberA?.firstName!.localeCompare(teamMemberB?.firstName!);
      })
      .map(report => ({
        [Constants.REPORT_TABLE.NAME]: `${report.firstName} ${report.lastName}`,
        [Constants.REPORT_TABLE.DATE_OF_BIRTH]: report.birthDate
          ? report.birthDate
          : t('Generic.na'),
        [Constants.REPORT_TABLE.COMPLETION_DATE]: getCompletionDate(report),
        [Constants.REPORT_TABLE.EXPIRATION_DATE]:
          report?.certificate?.expiration &&
          report?.courseStatus !==
            Constants.LEARN_UPON_TRAINING_PLAN_STATUSES.failed
            ? convertDateForReports(report.certificate.expiration)
            : t('Generic.na'),
      }));
  }, [statusReport, t]);

  return {
    statusReportData: statusReport,
    statusReportToPrint,
    isStatusReportFetching,
    isStatusReportSuccess,
    resetStatusReportData,
    refetchCourseReportData,
  };
};

export default useGetCourseReportData;
