import i18next from 'i18next';
import { ProgressBarText } from '@/types/types';

export const getProgressBarText = ({
  isReports = false,
  completedStepCount = null,
  isComplete,
  timeSpentOnPlan,
  isCompliance = false,
  percentComplete,
  stepCount = null,
}: ProgressBarText) => {
  if (isReports && isComplete) {
    return i18next.t('Generic.plansCompleted');
  } else if (isReports) {
    return `${completedStepCount}/${stepCount} ${i18next.t(
      'Generic.plansCompleted',
    )}`;
  } else if (isComplete) {
    return `${i18next.t('Generic.planCompletedIn')} ${timeSpentOnPlan}`;
  } else if (!isComplete && !isCompliance) {
    return `${completedStepCount}/${stepCount} ${i18next.t(
      'Generic.itemsCompletedIn',
    )} ${timeSpentOnPlan}`;
  } else if (!!isCompliance && !isComplete && !!timeSpentOnPlan) {
    return `${percentComplete}% ${i18next.t(
      'Generic.completedIn',
    )} ${timeSpentOnPlan}`;
  } else if (!!isCompliance && !isComplete) {
    return `${percentComplete}% ${i18next.t('Generic.completed')}. ${i18next.t(
      'Generic.timeSpentNotAvailable',
    )}.`;
  } else {
    return `${completedStepCount}/${stepCount} ${i18next.t(
      'Generic.itemsCompleted',
    )}`;
  }
};
