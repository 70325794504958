import Constants from 'constants/index';
import { getNameFromLanguage } from 'utils/language';
import { isTrainingAssignedPath } from 'utils/url';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IconCircleCheckDisabled, IconCircleDisabled } from 'icons';
import {
  IconCircle,
  IconCircleCheck,
  IconAlertTriangleFilled,
  IconDeviceGamepad2,
} from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'cfa-react-components';
import { useLocation } from 'react-router-dom';
import {
  ChecklistSectionStepDTO,
  IdentityUser,
  UserChecklistDTO,
  UserChecklistStatus,
} from '@cfacorp-pathway/xp-api-typescript-client';
import { LanguageObject } from '@/types/types';

interface PlanItemProcedureProps {
  disabled?: boolean;
  isActive: boolean;
  isComplete: boolean;
  isMissing: boolean;
  language?: string;
  onClick: () => void;
  onCompleteToggle: () => void;
  statuses: UserChecklistDTO[];
  step: ChecklistSectionStepDTO;
  user?: IdentityUser | UserChecklistStatus;
}

interface TaskOrProcedureBodyStyleProps {
  $isActive: boolean;
  $isDisabled: boolean;
}

interface IconWrapperStyleProps {
  $isDisabled: boolean;
}

interface TypeAndNameWrapperStyleProps {
  $isDisabled: boolean;
}

const PlanItemProcedure: React.FC<PlanItemProcedureProps> = ({
  disabled,
  isActive,
  isComplete,
  isMissing,
  language,
  onClick,
  onCompleteToggle,
  statuses,
  step,
  user,
}) => {
  const [isCompleted, setIsCompleted] = useState(isComplete);
  const { t } = useTranslation();
  // @ts-ignore
  const userId = user?.adId ?? user?.userId ?? undefined;
  const userStatus = statuses
    ?.find(status => status.userId === userId)
    ?.steps?.find((statusStep: any) => statusStep.stepId === step.id);
  const stepDuration = userStatus?.stepDuration
    ? Math.round(userStatus?.stepDuration / 60)
    : 0;
  const isGame = step?.reference?.type === Constants.DOCUMENT_TYPES.GAME;
  const isDisabled = isMissing || disabled;
  const locationUrl = useLocation();
  const isMyPlansTab = isTrainingAssignedPath(locationUrl);

  useEffect(() => {
    setIsCompleted(isComplete);
  }, [isComplete]);

  return (
    <>
      <TaskOrProcedureBody
        $isActive={isActive}
        $isDisabled={isDisabled as boolean}
      >
        {isCompleted ? (
          <IconWrapper
            $isDisabled={isDisabled as boolean}
            data-testid="StepStatusIcon"
            // @ts-ignore overload error from CFA DS
            onClick={
              !isDisabled
                ? () => {
                    setIsCompleted(prevState => !prevState);
                    onCompleteToggle();
                  }
                : null
            }
          >
            {!isDisabled ? (
              <StyledIconCircleCheck />
            ) : (
              <IconCircleCheckDisabled />
            )}
          </IconWrapper>
        ) : (
          <IconWrapper
            $isDisabled={isDisabled as boolean}
            data-testid="StepStatusIcon"
            // @ts-ignore overload error from CFA DS
            onClick={
              !isDisabled
                ? () => {
                    setIsCompleted(prevState => !prevState);
                    onCompleteToggle();
                  }
                : null
            }
          >
            {!isDisabled ? <StyledIconCircle /> : <DisabledOvalIcon />}
          </IconWrapper>
        )}
        <TypeAndNameWrapper
          $isDisabled={
            // This allows for a completed plan's resource to be clickable as long as it's not missing
            ((isDisabled as boolean) && !isMyPlansTab) ||
            ((isDisabled as boolean) && isMissing)
          }
          data-testid={`PlanStep${step.id}`}
          onClick={() => {
            step?.type === Constants.STEP_TYPES.DOCUMENT && onClick();
          }}
        >
          <Typography data-testid="TaskTitle" variant="overline3">
            {isGame ? t('Generic.game') : t('Generic.resource')}
            {isCompleted &&
              user &&
              ` - ${t('Generic.completedIn')} ${stepDuration} ${t(
                'Generic.mins',
              )}`}
          </Typography>
          <>
            {isMissing ? (
              <>
                <Typography data-testid="TaskOrProcedureName" variant="body1">
                  {/* remove underscores from name */}
                  {getNameFromLanguage(
                    step?.name as LanguageObject,
                    language,
                  ).replace(/_/g, ' ')}
                </Typography>
                <StyledWarningWrapper>
                  <IconAlertTriangleFilled size={16} />
                  <StyledWarningMessage
                    data-testid="TaskOrProcedureName"
                    variant="body1"
                  >
                    {isMyPlansTab
                      ? t('TrainingPlans.noResource')
                      : t('TrainingPlans.noResourceBuild')}
                  </StyledWarningMessage>
                </StyledWarningWrapper>
              </>
            ) : step?.type === Constants.STEP_TYPES.DOCUMENT ? (
              <Typography data-testid="TaskOrProcedureName" variant="body1">
                {getNameFromLanguage(
                  step?.reference?.name as LanguageObject,
                  language,
                )}
              </Typography>
            ) : (
              <Typography data-testid="TaskOrProcedureName" variant="body1">
                {getNameFromLanguage(step?.name as LanguageObject, language)}
              </Typography>
            )}
          </>
        </TypeAndNameWrapper>
        {isGame && (
          <TaskAndProcedureIcon>
            <IconDeviceGamepad2 />
          </TaskAndProcedureIcon>
        )}
      </TaskOrProcedureBody>
    </>
  );
};

const TaskOrProcedureBody = styled.div<TaskOrProcedureBodyStyleProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: 100%;
  cursor: ${props => (!props.$isDisabled ? 'pointer' : 'default')};
  padding-bottom: ${props => props.$isActive && '10px'};
`;

const IconWrapper = styled.div<IconWrapperStyleProps>`
  margin-right: 0.5em;
  cursor: ${props => (!props.$isDisabled ? 'pointer' : 'default')};
`;

const StyledIconCircle = styled(IconCircle)`
  color: ${({ theme }) => theme.grayScale.gray2};
`;

const StyledIconCircleCheck = styled(IconCircleCheck)`
  color: ${({ theme }) => theme.semanticColors.success};
`;

const DisabledOvalIcon = styled(IconCircleDisabled)`
  color: ${({ theme }) => theme.grayScale.gray2};
`;

const TypeAndNameWrapper = styled.div<TypeAndNameWrapperStyleProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: ${({ $isDisabled }) => ($isDisabled ? 'default' : 'pointer')};
  pointer-events: ${({ $isDisabled }) => $isDisabled && 'none'};
`;

const StyledWarningWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.semanticColors.error};
`;

const StyledWarningMessage = styled(Typography)`
  margin-left: 0.5em;
  color: ${({ theme }) => theme.semanticColors.error};
`;

const TaskAndProcedureIcon = styled.div`
  color: ${({ theme }) => theme.grayScale.gray2};
  width: 25px;
  margin: 0 5px;
  cursor: pointer;
`;

export default PlanItemProcedure;
