import Constants from 'constants/index';
import { Button, TextField, TextFieldType } from 'cfa-react-components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { LanguageObject } from '@/types';
import { SubtitleProps } from './SubCategoryAccordion';
import { useEffect, useState } from 'react';
import { IconCornerDownRight } from '@tabler/icons-react';
import SubtitleDocuments from '../SubtitleDocuments';
import { DocumentDTO } from '@cfacorp-pathway/xp-api-typescript-client';
import { useDispatch } from 'react-redux';
import {
  setDocumentForDeletion,
  setDocumentForVisibilityToggle,
  setDocumentVisibilityForConfirmationPopup,
  setSelectedCategoryId,
  setSelectedSubcategoryIndex,
  setSelectedSubtitleIndex,
  setShowAddAdminAddProcedureToSubtitle,
} from '@/store/admin/slice';
import AddResourceToSubtitleButton from '../AddResourceToSubtitleButton';

interface EditSectionProps {
  addSectionFormIsValid: boolean;
  adminCategoryId: string;
  countryName: string;
  editSection: boolean;
  handleAddSectionCancel: () => void;
  handleEditSection: ({
    index,
    language,
    name,
    subtitleIndex,
  }: {
    index: any;
    language: string;
    name: LanguageObject | string;
    subtitleIndex: any;
  }) => void;
  index: string;
  onSaveSubtitle: (
    pendingSectionMutation: { name: LanguageObject },
    index: string,
  ) => void;
  openInNewTab: boolean;
  pendingSectionMutation: {
    name: { en: string; es: string };
  };
  RenderDocumentEndContent: () => void;
  setEditSection: (edit: boolean) => void;
  setMutatingSubcategorySection: (mutate: boolean) => void;
  setPendingSectionMutation: (section: {
    name: { en: string; es: string };
  }) => void;
  setShowDeleteResource: (show: boolean) => void;
  setShowToggleResource: (show: boolean) => void;
  setSubtitle: any;
  subtitle: SubtitleProps;
}

const EditSection: React.FC<EditSectionProps> = ({
  addSectionFormIsValid,
  adminCategoryId,
  countryName,
  editSection,
  handleAddSectionCancel,
  handleEditSection,
  index,
  onSaveSubtitle,
  openInNewTab,
  pendingSectionMutation,
  RenderDocumentEndContent,
  setEditSection,
  setMutatingSubcategorySection,
  setPendingSectionMutation,
  setShowDeleteResource,
  setShowToggleResource,
  setSubtitle,
  subtitle,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showDocuments, setShowDocuments] = useState(false);

  // We need this to avoid an edge case bug (see P20-2425)
  useEffect(() => {
    if (subtitle?.documents?.length! > 0) {
      setShowDocuments(true);
    } else {
      setShowDocuments(false);
    }
  }, [subtitle?.documents]);

  const onPendingSectionNameChange = (
    event: React.ChangeEvent<TextFieldType>,
  ) => {
    const name = event.target.value;
    if (name.length) {
      setPendingSectionMutation({
        ...pendingSectionMutation,
        name: { en: name, es: pendingSectionMutation.name?.es },
      });
    } else {
      setPendingSectionMutation({
        ...pendingSectionMutation,
        name: { en: '', es: pendingSectionMutation.name?.es },
      });
    }
  };

  const onPendingSectionTranslationChange = (
    event: React.ChangeEvent<TextFieldType>,
  ) => {
    const translation = event.target.value;
    if (translation.length) {
      setPendingSectionMutation({
        ...pendingSectionMutation,
        name: { en: pendingSectionMutation.name?.en, es: translation },
      });
    } else {
      setPendingSectionMutation({
        ...pendingSectionMutation,
        name: { en: pendingSectionMutation.name?.en, es: '' },
      });
    }
  };

  const deleteDocument = (
    subcategoryIndex: string,
    subtitleIndex: number,
    document: DocumentDTO,
    documentIsMarkedAsVisible: boolean,
    documentIndex: string,
  ) => {
    dispatch(setSelectedCategoryId(adminCategoryId));
    dispatch(setSelectedSubcategoryIndex(subcategoryIndex));
    dispatch(setSelectedSubtitleIndex(subtitleIndex));
    dispatch(setDocumentForDeletion({ ...document, index: documentIndex }));
    dispatch(
      setDocumentVisibilityForConfirmationPopup(documentIsMarkedAsVisible),
    );
    setShowDeleteResource(true);
  };

  const handleToggleDocument = (
    subcategoryIndex: any,
    subtitleIndex: any,
    document: DocumentDTO,
    documentIsMarkedAsVisible: boolean,
  ) => {
    dispatch(setSelectedSubcategoryIndex(subcategoryIndex));
    dispatch(setSelectedSubtitleIndex(subtitleIndex));
    dispatch(setDocumentForVisibilityToggle(document));
    dispatch(
      setDocumentVisibilityForConfirmationPopup(documentIsMarkedAsVisible),
    );
    // remember to use setShowToggleResourceVisibility before changing the name
    setShowToggleResource(true);
  };

  const handleAddResourceToExistingSubtitle = (
    subcategoryIndex: any,
    subtitleIndex: any,
  ) => {
    dispatch(setSelectedSubcategoryIndex(subcategoryIndex));
    dispatch(setSelectedSubtitleIndex(subtitleIndex));
    dispatch(setShowAddAdminAddProcedureToSubtitle(true));
    setEditSection(false);
  };

  return (
    <>
      <SectionRow>
        <TextField
          data-testid={'EditSectionNameInput'}
          fullWidth
          label={t('Admin.sectionName')}
          onChange={
            editSection
              ? event => {
                  handleEditSection({
                    index,
                    language: Constants.LANGUAGE_OPTIONS.ENGLISH,
                    name: event.target.value,
                    subtitleIndex: subtitle?.index,
                  });
                  setSubtitle((prev: any) => {
                    return {
                      ...prev,
                      name: {
                        en: event.target.value,
                        es: prev?.name?.es,
                      },
                    } as SubtitleProps;
                  });
                }
              : onPendingSectionNameChange
          }
          placeholder={t('Admin.addSectionNamePlaceholder')}
          required={true}
          value={
            editSection ? subtitle?.name?.en : pendingSectionMutation.name?.en
          }
        />
      </SectionRow>
      <SectionRow>
        <IconCornerDownRight size={35} />
        <TextField
          data-testid={'EditSectionNameTranslationInput'}
          fullWidth
          label={t('Admin.spanishTranslation')}
          onChange={
            editSection
              ? event => {
                  handleEditSection({
                    index,
                    language: Constants.LANGUAGE_OPTIONS.SPANISH,
                    name: event.target.value,
                    subtitleIndex: subtitle?.index,
                  });
                  setSubtitle((prev: any) => {
                    return {
                      ...prev,
                      name: {
                        en: prev?.name?.en,
                        es: event.target.value,
                      },
                    } as SubtitleProps;
                  });
                }
              : onPendingSectionTranslationChange
          }
          placeholder={t('Admin.addSectionTranslationPlaceholder')}
          required={
            countryName !== Constants.ADMIN_MANAGING_COUNTRIES.CA.LABEL_KEY
          }
          value={
            editSection ? subtitle?.name?.es : pendingSectionMutation.name?.es
          }
        />
      </SectionRow>
      <>
        {showDocuments && (
          <SubtitleDocuments
            contentToRenderAtDocumentEnd={RenderDocumentEndContent}
            disableSixDotMenuHandles={true}
            handleDocumentDelete={(
              document,
              documentIsMarkedAsVisible,
              documentIndex,
            ) =>
              deleteDocument(
                index,
                subtitle?.index,
                document,
                documentIsMarkedAsVisible,
                documentIndex,
              )
            }
            handleToggleDocument={(document, documentIsMarkedAsVisible) =>
              handleToggleDocument(
                index,
                subtitle?.index,
                document,
                documentIsMarkedAsVisible,
              )
            }
            openDocumentInNewTab={openInNewTab!}
            subtitle={subtitle as SubtitleProps}
          />
        )}
        {subtitle?.index !== undefined && (
          <AddResourceToSubtitleButton
            onAdminAddResourceToSubtitle={() =>
              handleAddResourceToExistingSubtitle(index, subtitle?.index)
            }
          />
        )}
      </>
      {!editSection && (
        <SectionRow>
          <ButtonWrapper>
            <Button
              color="secondary"
              onClick={handleAddSectionCancel}
              variant="outlined"
            >
              {t('Button.cancel')}
            </Button>
            <Button
              color="secondary"
              data-testid="AddSectionSaveButton"
              disabled={!addSectionFormIsValid}
              onClick={() => {
                setMutatingSubcategorySection(false);
                onSaveSubtitle(pendingSectionMutation, index);
              }}
              variant="filled"
            >
              {t('Button.add')}
            </Button>
          </ButtonWrapper>
        </SectionRow>
      )}
    </>
  );
};

const SectionRow = styled.div`
  padding-bottom: 8px;
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  margin-top: 1em;
  display: flex;
  justify-content: center;
  gap: 1em;
  margin-bottom: 2em;
`;

export default EditSection;
