import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { FC, PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';
import { selectIsTridion } from '@/store/document-persisted/selectors';
import { isDocumentPath } from '@/utils/url';

const MainContent: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const isDocument = isDocumentPath(location);
  const isTridion = useSelector(selectIsTridion);
  const isTridionDoc = isDocument && isTridion;

  return (
    <MainContentContainer
      $isDocument={isDocument}
      $isTridionDocument={isTridionDoc}
    >
      {children}
    </MainContentContainer>
  );
};

const MainContentContainer = styled.div<{
  $isDocument: boolean;
  $isTridionDocument: boolean;
}>`
  width: 100%;
  height: auto;
  max-width: ${({ $isDocument }) => ($isDocument ? '100%' : '1152px')};
  flex-grow: 1;
  margin: 0 auto;
  padding: ${({ $isDocument }) => ($isDocument ? '0' : '15px')};
  display: flex;
  flex-direction: column;
  background: ${({ $isTridionDocument, theme }) =>
    $isTridionDocument && theme.primaryPalette.documentBackground};
`;

export default MainContent;
