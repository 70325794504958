import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  Typography,
  useBreakpoints,
  useMediaQuery,
} from 'cfa-react-components/dist/cjs';

import { AssignableCourseResult } from '@cfacorp-pathway/xp-api-typescript-client';
import Constants from '@/constants';
import PrintReportModal from '@/components/popups/PrintReportModal';
import CheckboxList from '@/components/Checkbox/CheckboxList';
import { withRoles } from '@/components/ConfirmationModal/withRoles';
import LoadingOverlay from '@/components/LoadingOverlay/LoadingOverlay';
import ReportsCompliancePlanCard from '@/components/Cards/PlanCard/PlanCards/ReportsCompliancePlanCard';
import {
  selectAllLocationsWithAtLeastLeaderPermissions,
  selectAllLocationsWithAtLeastTrainer,
  selectLocationsWithOperatorPermission,
  selectLocationsWithTrainerPermission,
} from '@/store/user/selectors';
import { setHeader } from '@/store/header/slice';
import { ComplianceCourse, LanguageObject } from '@/types/types';
import { arrayToCommaString } from '@/utils/arrayToCommaString';
import { getNameFromLanguage } from '@/utils/language';
import { mapCategoryToAliasTranslation } from '@/utils/categoryUtils';
import useGetCompliancePlans from '@/utils/reportPrint/useGetCompliancePlans';
import useGetCourseReportData from '@/utils/reportPrint/useGetCourseReportData';
import { generateReportAndMessageReactNative } from '@/utils/generateReport';

const ReportsCompliancePlansTab = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const breakpoints = useBreakpoints();
  const isSmAndDown = useMediaQuery(breakpoints.down('sm'));

  const locationsWithAtLeastLeaderPermission = useSelector(
    selectAllLocationsWithAtLeastLeaderPermissions,
  );
  const locationsWithAtLeastTrainer = useSelector(
    selectAllLocationsWithAtLeastTrainer,
  );
  const locationsWithAtLeastTrainerPermission = useSelector(
    selectLocationsWithTrainerPermission,
  );
  const locationsWithAtLeastOperatorPermission = useSelector(
    selectLocationsWithOperatorPermission,
  );

  const isTrainerOrLeaderOrOperator =
    !!locationsWithAtLeastTrainerPermission.length ||
    !!locationsWithAtLeastLeaderPermission.length ||
    !!locationsWithAtLeastOperatorPermission.length;

  const [planId, setPlanId] = useState('');
  const [showDoBChecked, setShowDoBChecked] = useState<boolean>(false);
  const [noLocationsSelected, setNoLocationsSelected] =
    useState<boolean>(false);
  const [isFoodSafety, setIsFoodSafety] = useState<boolean>(false);
  const [
    isTriggerComplianceReportHandler,
    setIsTriggerComplianceReportHandler,
  ] = useState<boolean>(false);
  const [showChooseReportLocationsPopup, setShowChooseReportLocationsPopup] =
    useState<boolean>(false);
  const [selectedReportLocations, setSelectedReportLocations] = useState(
    locationsWithAtLeastTrainer,
  );

  const locationsToReport = useMemo(
    () =>
      selectedReportLocations?.length
        ? selectedReportLocations
        : locationsWithAtLeastTrainer,
    [selectedReportLocations, locationsWithAtLeastTrainer],
  );

  const { compliancePlans, isFetchingPlans } = useGetCompliancePlans(
    locationsWithAtLeastLeaderPermission,
  );

  const {
    statusReportData,
    statusReportToPrint,
    isStatusReportFetching,
    isStatusReportSuccess,
  } = useGetCourseReportData(
    planId,
    locationsToReport,
    isTrainerOrLeaderOrOperator,
  );

  useEffect(() => {
    dispatch(setHeader(t('Generic.reports')));
  }, [dispatch, t]);

  const tableHead = useMemo(
    () => [
      [
        Constants.REPORT_TABLE.NAME,
        ...(showDoBChecked ? [Constants.REPORT_TABLE.DATE_OF_BIRTH] : []),
        Constants.REPORT_TABLE.COMPLETION_DATE,
        Constants.REPORT_TABLE.EXPIRATION_DATE,
      ],
    ],
    [showDoBChecked],
  );

  const reportToGenerate = useMemo(() => {
    return {
      category: t(
        mapCategoryToAliasTranslation(Constants.PLAN_CATEGORIES.COMPLIANCE),
      ),
      foodSafetyImage:
        statusReportData?.foodSafetyLetters?.[0]?.foodSafetyLetter?.content,
      location: `${t('Generic.at')} ${
        !selectedReportLocations.length && statusReportData?.locations
          ? statusReportData?.locations.toString()
          : arrayToCommaString(selectedReportLocations, t('Generic.and'))
      }`,
      name: statusReportData?.courseName!,
      reportToPrint: statusReportToPrint.map(teamMember =>
        tableHead?.[0]?.map(headerItem => teamMember?.[headerItem]),
      ),
      tableHead,
    };
  }, [
    t,
    tableHead,
    statusReportData,
    statusReportToPrint,
    selectedReportLocations,
  ]);

  const handleCurrentFilteredLocations = useCallback(() => {
    setSelectedReportLocations(locationsWithAtLeastTrainer);
    if (!!noLocationsSelected) {
      setNoLocationsSelected(false);
    }
    setShowDoBChecked(false);
  }, [locationsWithAtLeastTrainer, noLocationsSelected]);

  /**
   * Actual Print handler.
   * Trigger by print button in choose locations popup,
   * or by plan item print icon if not food safety and don't have more than 1 location with at least trainer.
   */
  const handlePrintReport = useCallback(() => {
    if (
      !selectedReportLocations.length &&
      locationsWithAtLeastTrainer?.length > 1
    ) {
      setNoLocationsSelected(true);
    } else {
      setShowChooseReportLocationsPopup(false);
      generateReportAndMessageReactNative(
        reportToGenerate,
        statusReportToPrint,
        tableHead,
      );
      setPlanId('');
      handleCurrentFilteredLocations();
    }
  }, [
    tableHead,
    reportToGenerate,
    statusReportToPrint,
    handleCurrentFilteredLocations,
    locationsWithAtLeastTrainer?.length,
    selectedReportLocations.length,
  ]);

  useEffect(() => {
    if (
      !!statusReportData?.courseId &&
      !!isTriggerComplianceReportHandler &&
      !!isStatusReportSuccess &&
      !isStatusReportFetching
    ) {
      handlePrintReport();
      setIsTriggerComplianceReportHandler(false);
    }
  }, [
    handlePrintReport,
    isStatusReportSuccess,
    isStatusReportFetching,
    isTriggerComplianceReportHandler,
    statusReportData?.courseId,
  ]);

  /**
   * Compliance Plan Print button Click handler:
   * show choose locations pop up or Jump to real print handler
   * @param id: selected plan id to start print
   * @param plan: selected plan data to start print
   */
  const handleOnPrintReport = (
    id: string | undefined,
    plan: AssignableCourseResult,
  ) => {
    const courseName = getNameFromLanguage(plan?.courseName as LanguageObject);
    if (!courseName) {
      throw new Error('Course name is missing.');
    }
    const isFoodSafetyToPrint = courseName.includes(
      Constants.FOOD_SAFETY_COURSE_NAME,
    );
    setIsFoodSafety(isFoodSafetyToPrint);
    if (id) {
      setPlanId(id);
    }
    locationsWithAtLeastTrainer?.length > 1 || isFoodSafetyToPrint
      ? setShowChooseReportLocationsPopup(true)
      : setIsTriggerComplianceReportHandler(true);
  };

  const handleToggleDoBCheckbox = () => {
    setShowDoBChecked(!showDoBChecked);
  };

  const onPrintReportCancel = () => {
    setShowChooseReportLocationsPopup(false);
    handleCurrentFilteredLocations();
  };

  return (
    <>
      <SearchHeaderContainer $isSmAndDown={isSmAndDown}>
        <Typography variant="h3">{t('Reports.tabCompliancePlans')}</Typography>
      </SearchHeaderContainer>
      <PlanCardsList $isSmAndDown={isSmAndDown}>
        <Typography fontWeight="bold" variant="body1">{`${
          compliancePlans?.length ?? 0
        } ${t('TrainingPlans.filtering.plans')}`}</Typography>
        <LoadingOverlay isOpen={isFetchingPlans} />
        {!!compliancePlans?.length && !isFetchingPlans && (
          <ReportsCardContainer>
            {compliancePlans.map(plan => (
              <ReportsCompliancePlanCard
                course={
                  {
                    courseID: plan?.courseID,
                    courseName: plan?.courseName,
                    createdDate: plan?.createdDate,
                    enabled: plan?.enabled,
                    id: plan?.id,
                  } as ComplianceCourse
                }
                enrollments={plan?.enrollments as any}
                key={plan.courseID}
                locations={locationsWithAtLeastLeaderPermission}
                onPrintReport={({ id }: { id?: string }) =>
                  handleOnPrintReport(id, plan)
                }
              />
            ))}
          </ReportsCardContainer>
        )}
      </PlanCardsList>
      <PrintReportModal
        bodyText={t('TrainingPlans.chooseWhatToInclude')}
        handleToggleDoBCheckbox={handleToggleDoBCheckbox}
        headerText={t('Generic.printReport')}
        isFoodSafety={isFoodSafety}
        isOpen={showChooseReportLocationsPopup}
        isPrintDisabled={isStatusReportFetching}
        noLocationsSelected={noLocationsSelected}
        onClose={onPrintReportCancel}
        primaryButtonHandler={handlePrintReport}
        primaryButtonText={t('Button.print')}
        secondaryButtonHandler={onPrintReportCancel}
        secondaryButtonText={t('Button.cancel')}
        selectedReportLocations={selectedReportLocations}
        showDoBChecked={showDoBChecked}
      >
        {locationsWithAtLeastTrainer.length > 1
          ? locationsWithAtLeastTrainer.map((id: string, idx: number) => (
              <StyledCheckboxList
                id={id}
                idx={idx}
                key={idx}
                selectedLocations={selectedReportLocations}
                setSelectedLocations={setSelectedReportLocations}
              />
            ))
          : null}
      </PrintReportModal>
    </>
  );
};

const PlanCardsList = styled.div<{ $isSmAndDown: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  max-width: 100%;
  margin: ${({ $isSmAndDown }) => !$isSmAndDown && '0 6em'};
`;

const SearchHeaderContainer = styled.div<{ $isSmAndDown: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: ${({ $isSmAndDown }) =>
    $isSmAndDown ? 'column-reverse' : 'row'};
  justify-content: space-between;
  align-items: ${({ $isSmAndDown }) =>
    $isSmAndDown ? 'flex-start' : 'center'};
  margin: 16px 0;
`;

const ReportsCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledCheckboxList = styled(CheckboxList)`
  padding: 6px 0;
`;

const ReportsCompliancePlansTabWithRoles = withRoles(
  ReportsCompliancePlansTab,
  'reporting.compliance',
);

export default ReportsCompliancePlansTabWithRoles;
