import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface AssignedTeamMembersCountProps {
  inNavbar: boolean;
}

interface StyledAssignedTeamMembersCountProps {
  $inNavbar: boolean;
}

export const AssignedTeamMembersCount: React.FC<
  AssignedTeamMembersCountProps
> = ({ inNavbar }) => {
  const { t } = useTranslation();
  return (
    <StyledAssignedTeamMembers $inNavbar={inNavbar}>
      {inNavbar ? t('Generic.chooseTeamMembers') : t('TrainingPlans.assigned')}
    </StyledAssignedTeamMembers>
  );
};

const StyledAssignedTeamMembers = styled.div<StyledAssignedTeamMembersCountProps>`
  color: ${({ theme }) => theme.grayScale.gray7};
  font-weight: 500;
  margin-bottom: ${({ $inNavbar }) => ($inNavbar ? '0' : '10px')};
`;
