import { useBreakpoints, useMediaQuery, Divider } from 'cfa-react-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import FilterAndSortButton from '@/components/FilterAndSortButton/FilterAndSortButton';
import {
  addManagePlansCategoryFilter,
  addManagePlansLocationFilter,
  removeManagePlansFilter,
  setManagePlansSort,
} from '@/store/managePlansFilter/slice';
import { selectPlansToggleLicensee } from '@/store/licensee/selector';
import {
  isOperatorConsultant,
  selectUserLicenseeLocations,
} from '@/store/user/selectors';
import CheckboxFilterSection from '@/components/Cards/StickyFilterCard/CheckboxFilterSection';
import { useSortOptions } from '@/hooks/useSortOptions';
import {
  selectFilters,
  selectSort,
  selectSortedAndFilteredAndPaginatedPlans,
} from '@/store/managePlansFilter/selector';
import { useCategoryLabels } from '@/hooks/useCategoryLabels';

interface FilterAndSortMobileProps {
  isFetching: boolean;
}
const FilterAndSortMobile: React.FC<FilterAndSortMobileProps> = ({
  isFetching,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const breakpoints = useBreakpoints();
  const { licensee: licenseeFeatureFlag } = useFlags();
  const isSmAndDown = useMediaQuery(breakpoints.down('sm'));
  const sortOptions = useSortOptions();
  const isLicenseePlans = useSelector(selectPlansToggleLicensee);
  const isOperatorConsultantUser = useSelector(isOperatorConsultant);
  const userLicenseeLocations = useSelector(selectUserLicenseeLocations);
  const currentFilters: string[] = useSelector(selectFilters);
  const sort = useSelector(selectSort);
  const filteredAndSortedPlans = useSelector(
    selectSortedAndFilteredAndPaginatedPlans,
  );
  const categoryLabels = useCategoryLabels(currentFilters);
  return (
    !!isSmAndDown &&
    !isFetching && (
      <FilterAndSortButton
        onSortChange={option => {
          dispatch(setManagePlansSort({ sort: option.value }));
        }}
        sortOptions={sortOptions}
        sortValue={sortOptions?.find(option => option.value === sort)}
        text={`${t('TrainingPlans.filtering.show')} ${
          filteredAndSortedPlans?.length ?? 0
        } ${t('TrainingPlans.filtering.results')}`}
      >
        {isOperatorConsultantUser && isLicenseePlans && licenseeFeatureFlag && (
          <>
            <CheckboxFilterSection
              labels={userLicenseeLocations.reduce(
                (acc, location) => {
                  acc = {
                    ...acc,
                    [location?.number!]: {
                      translationString: location?.name!,
                      value: !!currentFilters.includes(location?.number!),
                    },
                  };
                  return acc;
                },
                {} as Record<
                  string,
                  { translationString: string; value: boolean }
                >,
              )}
              onChange={(value: string) => {
                if (!!currentFilters.includes(value)) {
                  dispatch(removeManagePlansFilter({ filter: value }));
                } else {
                  dispatch(addManagePlansLocationFilter({ filter: value }));
                }
              }}
              title={t('Licensee.licenseeLocations')}
            />
            <Divider style={{ margin: '16px 0' }} variant="fullLength" />
          </>
        )}
        <CheckboxFilterSection
          labels={categoryLabels}
          onChange={(value: string) => {
            if (!!currentFilters.includes(value)) {
              dispatch(removeManagePlansFilter({ filter: value }));
            } else {
              dispatch(addManagePlansCategoryFilter({ filter: value }));
            }
          }}
          title={t('Browse.categories')}
        />
      </FilterAndSortButton>
    )
  );
};

export default FilterAndSortMobile;
