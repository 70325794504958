import Constants from 'constants/index';
import toast from 'react-hot-toast';
import AddTeamMembersPopUp from '@/components/popups/AddTeamMemberPopUp/AddTeamMembersPopUp';
import DueDatePopUp from '@/components/popups/DueDatePopUp';
import useBugsnagNotify from '@/hooks/useBugsnagNotify';
import { useAssignUserToChecklistMutation } from '@/services/pathwayApi';
import { selectActiveLicenseeLocation } from '@/store/licenseeLocationPicker/selector';
import {
  isUserLicensee,
  selectAllLocationsWithAtLeastTrainer,
} from '@/store/user/selectors';
import { arrayIntersect } from '@/utils/keepDuplicatesFromTwoArrays';
import { ChecklistDTO } from '@cfacorp-pathway/xp-api-typescript-client';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ToastMessageBlock from '@/components/Toasts/SuccessToast';
import { useTranslation } from 'react-i18next';

interface AddTeamMembersProps {
  operatorLocations: string[];
  plan: ChecklistDTO;
  selectedId: string;
  setSelectedId: (id: string) => void;
  setShowAddTeamMembersPopup: (show: boolean) => void;
  setShowDueDatePopup: (show: boolean) => void;
  showAddTeamMembersPopup: boolean;
  showDueDatePopup: boolean;
}
const AddTeamMembers: React.FC<AddTeamMembersProps> = ({
  operatorLocations,
  plan,
  selectedId,
  showAddTeamMembersPopup,
  showDueDatePopup,
  setSelectedId,
  setShowDueDatePopup,
  setShowAddTeamMembersPopup,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { notifyBugsnag } = useBugsnagNotify();
  const [assignUserToChecklist] = useAssignUserToChecklistMutation();
  const isLicenseeUser = useSelector(isUserLicensee);
  const activeLicenseeLocation = useSelector(selectActiveLicenseeLocation);
  const locationsWithAtLeastTrainer = useSelector(
    selectAllLocationsWithAtLeastTrainer,
  );

  const onAssignTeamMembers = ({
    dueDate,
    members,
    planId,
  }: {
    dueDate: string;
    members: string | string[];
    planId: string;
  }) => {
    const payload = Array.isArray(members)
      ? { userIds: members, dueDate }
      : { userIds: [members], dueDate };
    const numberOfTeamMembersToAssign = payload.userIds.length;

    assignUserToChecklist({ body: payload, id: planId })
      .unwrap()
      .then(() => {
        history.push({
          pathname: `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${planId}/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}`,
          state: { refetch: true },
        });
        toast.custom(toastObj => (
          <ToastMessageBlock id={toastObj.id}>
            {numberOfTeamMembersToAssign === 1
              ? t('TrainingPlans.toastMessage.assignedTeamMember')
              : t('TrainingPlans.toastMessage.assignedTeamMembers', {
                  count: numberOfTeamMembersToAssign,
                })}
          </ToastMessageBlock>
        ));
      })
      .catch(err => {
        notifyBugsnag(err);
      });
  };

  return (
    <>
      <AddTeamMembersPopUp
        handleNextClick={({ id }: { id: string }) => {
          setShowAddTeamMembersPopup(false);
          setShowDueDatePopup(true);
          setSelectedId(id);
        }}
        isOpen={showAddTeamMembersPopup}
        locations={
          isLicenseeUser
            ? [activeLicenseeLocation.number]
            : arrayIntersect(
                locationsWithAtLeastTrainer,
                operatorLocations ?? [],
              )
        }
        onClose={() => setShowAddTeamMembersPopup(false)}
        planDetails={plan ?? {}}
        teamMemberQueryOptions={{
          refetchOnMountOrArgChange: true,
        }}
      />
      <DueDatePopUp
        isOpen={showDueDatePopup}
        onClose={() => setShowDueDatePopup(false)}
        onSave={(dueDate: string | Date) => {
          setShowDueDatePopup(false);
          onAssignTeamMembers({
            dueDate: dueDate as string,
            members: selectedId.includes(',')
              ? selectedId.split(',')
              : selectedId,
            planId: plan.id!,
          });
        }}
      />
    </>
  );
};

export default AddTeamMembers;
