import Constants from 'constants/index';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CheckboxList from '@/components/Checkbox/CheckboxList';
import PrintReportModal from '@/components/popups/PrintReportModal';
import { selectAllLocationsWithAtLeastLeaderPermissions } from '@/store/user/selectors';
import { PlanDetails, ReportToPrint } from '@/types/types';
import { generateReportAndMessageReactNative } from '@/utils/generateReport';
import { arrayToCommaString } from '@/utils/arrayToCommaString';
import { mapCategoryToAliasTranslation } from '@/utils/categoryUtils';
import useGetCourseReportData from '@/utils/reportPrint/useGetCourseReportData';
import useGetPlanReportData from '@/utils/reportPrint/useGetPlanReportData';

interface PrintReportProps {
  reportToPrint: ReportToPrint;
  turnOffPrintTrigger: () => void;
  planLocations: string[];
}

const PrintReport: React.FC<PrintReportProps> = ({
  reportToPrint,
  turnOffPrintTrigger,
  planLocations,
}) => {
  const { t } = useTranslation();

  const locationsWithAtLeastLeaderPermission = useSelector(
    selectAllLocationsWithAtLeastLeaderPermissions,
  );

  const [showDoBChecked, setShowDoBChecked] = useState<boolean>(false);
  const [showChooseReportLocationsPopup, setShowChooseReportLocationsPopup] =
    useState<boolean>(false);
  const [noLocationsSelected, setNoLocationsSelected] =
    useState<boolean>(false);
  const [selectedReportLocations, setSelectedReportLocations] = useState(
    locationsWithAtLeastLeaderPermission,
  );
  const [
    isTriggerTeamMembersReportRefetch,
    setIsTriggerTeamMembersReportRefetch,
  ] = useState<boolean>(false);
  const [
    isTriggerComplianceReportHandler,
    setIsTriggerComplianceReportHandler,
  ] = useState<boolean>(false);
  const [planDetails, setPlanDetails] = useState<PlanDetails>({
    category: '',
    id: '',
    isCompliance: false,
    locations: [] as string[],
    name: '',
  });

  const isLeaderOrOperator = !!locationsWithAtLeastLeaderPermission.length;

  // Compliance plan status report
  const {
    statusReportData,
    statusReportToPrint,
    isStatusReportFetching,
    isStatusReportSuccess,
    resetStatusReportData,
  } = useGetCourseReportData(
    planDetails.id,
    selectedReportLocations,
    Boolean(
      planDetails.isCompliance &&
        isLeaderOrOperator &&
        selectedReportLocations?.length,
    ),
  );

  // Regular plan status report
  const {
    reportDataToPrint: planReportDataToPrint,
    // refetchReportsTeamMembers,
    isReportsTeamMembersFetching,
    isReportsTeamMembersDataSuccess,
    isAssignedTeamMembersListFetching,
    isAssignedTeamMembersListSuccess,
  } = useGetPlanReportData(planDetails, planLocations, selectedReportLocations);

  const reportDataToPrint = planDetails?.isCompliance
    ? statusReportToPrint
    : planReportDataToPrint;

  const onPrintReport = ({
    category,
    id,
    isCompliancePlan,
    locations,
    planName,
  }: ReportToPrint) => {
    turnOffPrintTrigger();
    setPlanDetails({
      category,
      id,
      isCompliance: isCompliancePlan,
      locations,
      name: planName,
    });
    if (isCompliancePlan) {
      if (
        planName.includes(Constants.FOOD_SAFETY_COURSE_NAME) ||
        locations?.length > 1
      ) {
        setShowChooseReportLocationsPopup(true);
      } else {
        // need to wait for setPlanDetails to populate the state :(
        setIsTriggerComplianceReportHandler(true);
      }
    } else if (locations?.length > 1) {
      setShowChooseReportLocationsPopup(true);
    } else {
      // We don't have the data to print yet so we need to refetch first
      setIsTriggerTeamMembersReportRefetch(true);
    }
  };

  // Q: I don't see refetchReportsTeamMembers is necessary. Do we need to refetch?
  // useEffect(() => {
  //   if (isTriggerTeamMembersReportRefetch) {
  //     refetchReportsTeamMembers();
  //   }
  // }, [refetchReportsTeamMembers, isTriggerTeamMembersReportRefetch]);

  useEffect(() => {
    if (reportToPrint?.id) {
      onPrintReport({
        category: reportToPrint.category,
        id: reportToPrint.id,
        isCompliancePlan: reportToPrint.isCompliancePlan,
        locations: reportToPrint.locations,
        planName: reportToPrint.planName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportToPrint]);

  const tableHead = useMemo(
    () => [
      [
        Constants.REPORT_TABLE.NAME,
        ...(showDoBChecked ? [Constants.REPORT_TABLE.DATE_OF_BIRTH] : []),
        ...(planDetails.isCompliance
          ? [
              Constants.REPORT_TABLE.COMPLETION_DATE,
              Constants.REPORT_TABLE.EXPIRATION_DATE,
            ]
          : [
              Constants.REPORT_TABLE.ITEMS_COMPLETED,
              Constants.REPORT_TABLE.TOTAL_TIME_SPENT_ON_PLAN,
              Constants.REPORT_TABLE.STATUS,
              Constants.REPORT_TABLE.COMPLETION_DATE,
            ]),
      ],
    ],
    [showDoBChecked, planDetails.isCompliance],
  );

  const resetPlanState = useCallback(() => {
    setPlanDetails({
      category: '',
      id: '',
      isCompliance: false,
      locations: [],
      name: '',
    });
    resetStatusReportData();
  }, [resetStatusReportData]);

  const processReportGenerateAndMessageSend = useCallback(() => {
    const reportToGenerate = {
      category: t(mapCategoryToAliasTranslation(planDetails?.category ?? '')),
      foodSafetyImage:
        statusReportData?.foodSafetyLetters?.[0]?.foodSafetyLetter?.content,
      id: planDetails?.id,
      location: `${t('Generic.at')} ${
        !selectedReportLocations.length || planDetails?.locations.length < 2
          ? planDetails?.locations.toString()
          : arrayToCommaString(selectedReportLocations, t('Generic.and'))
      }`,
      name: planDetails?.name,
      reportToPrint: reportDataToPrint.map((teamMember: any) =>
        tableHead?.[0]?.map(headerItem => teamMember?.[headerItem]),
      ),
      tableHead,
    };

    generateReportAndMessageReactNative(
      reportToGenerate,
      reportDataToPrint,
      tableHead,
    );

    resetPlanState();
  }, [
    t,
    tableHead,
    planDetails,
    reportDataToPrint,
    selectedReportLocations,
    statusReportData?.foodSafetyLetters,
    resetPlanState,
  ]);

  /**
   * If a report does not have a location screen, we need to wait until the data is
   * returned from the query.  Once we have the data from the backend, we can then
   * show the printable report.
   */
  useEffect(() => {
    if (
      isTriggerTeamMembersReportRefetch &&
      isReportsTeamMembersDataSuccess &&
      isAssignedTeamMembersListSuccess &&
      !isAssignedTeamMembersListFetching &&
      !isReportsTeamMembersFetching
    ) {
      processReportGenerateAndMessageSend();
      setIsTriggerTeamMembersReportRefetch(false);
    }
  }, [
    processReportGenerateAndMessageSend,
    isAssignedTeamMembersListFetching,
    isAssignedTeamMembersListSuccess,
    isReportsTeamMembersFetching,
    isReportsTeamMembersDataSuccess,
    isTriggerTeamMembersReportRefetch,
  ]);

  const handleCurrentFilteredLocations = useCallback(() => {
    setSelectedReportLocations(locationsWithAtLeastLeaderPermission);
    if (!!noLocationsSelected) {
      setNoLocationsSelected(false);
    }
    setShowDoBChecked(false);
  }, [locationsWithAtLeastLeaderPermission, noLocationsSelected]);

  const handlePrintReport = useCallback(() => {
    if (
      !selectedReportLocations.length &&
      locationsWithAtLeastLeaderPermission?.length > 1
    ) {
      setNoLocationsSelected(true);
    } else {
      processReportGenerateAndMessageSend();
      setShowChooseReportLocationsPopup(false);
      handleCurrentFilteredLocations();
    }
  }, [
    processReportGenerateAndMessageSend,
    handleCurrentFilteredLocations,
    locationsWithAtLeastLeaderPermission?.length,
    selectedReportLocations.length,
  ]);

  useEffect(() => {
    if (
      !!planDetails.id &&
      !!statusReportData.courseId &&
      !!isTriggerComplianceReportHandler &&
      !!isStatusReportSuccess &&
      !isStatusReportFetching
    ) {
      handlePrintReport();
      setIsTriggerComplianceReportHandler(false);
    }
  }, [
    handlePrintReport,
    isStatusReportSuccess,
    planDetails.id,
    statusReportData.courseId,
    isStatusReportFetching,
    isTriggerComplianceReportHandler,
  ]);

  const handleToggleDoBCheckbox = () => {
    setShowDoBChecked(!showDoBChecked);
  };

  const onPrintReportCancel = () => {
    setShowChooseReportLocationsPopup(false);
    handleCurrentFilteredLocations();
    resetPlanState();
  };

  return (
    <PrintReportModal
      bodyText={t('TrainingPlans.chooseWhatToInclude')}
      children={
        !planDetails?.isCompliance
          ? planDetails?.locations?.map((location, idx) => (
              <CheckboxList
                id={location}
                idx={idx}
                key={idx}
                selectedLocations={selectedReportLocations}
                setSelectedLocations={setSelectedReportLocations}
              />
            ))
          : // Compliance plans
            locationsWithAtLeastLeaderPermission.length > 1
            ? locationsWithAtLeastLeaderPermission.map(
                (id: string, idx: number) => (
                  <CheckboxList
                    id={id}
                    idx={idx}
                    key={idx}
                    selectedLocations={selectedReportLocations}
                    setSelectedLocations={setSelectedReportLocations}
                  />
                ),
              )
            : null
      }
      handleToggleDoBCheckbox={handleToggleDoBCheckbox}
      headerText={t('Generic.printReport')}
      isFoodSafety={planDetails?.name?.includes(
        Constants.FOOD_SAFETY_COURSE_NAME,
      )}
      isOpen={showChooseReportLocationsPopup}
      isPrintDisabled={
        isStatusReportFetching ||
        isAssignedTeamMembersListFetching ||
        isReportsTeamMembersFetching
      }
      noLocationsSelected={noLocationsSelected}
      onClose={onPrintReportCancel}
      primaryButtonHandler={handlePrintReport}
      primaryButtonText={t('Button.print')}
      secondaryButtonHandler={onPrintReportCancel}
      secondaryButtonText={t('Button.cancel')}
      selectedReportLocations={selectedReportLocations}
      showDoBChecked={showDoBChecked}
    />
  );
};

export default PrintReport;
