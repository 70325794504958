import { Container } from 'cfa-react-components';
import styled from 'styled-components';

interface DocumentContainerProps {
  $isPrinting: boolean;
  $isTridion: boolean;
}

export const DocumentContainer = styled(Container)<DocumentContainerProps>`
  width: 100%;
  max-width: ${({ $isPrinting }) => ($isPrinting ? 'unset' : '840px')};
  padding: 0;
  background-color: ${({ $isPrinting }) => ($isPrinting ? 'white' : 'initial')};
`;
