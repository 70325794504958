import Constants from 'constants/index';
import { useState } from 'react';
import styled from 'styled-components';
import { Container, Draggable, DropResult } from 'react-smooth-dnd';
import { Card, Divider } from 'cfa-react-components';
import { ChecklistSectionDTO } from '@cfacorp-pathway/xp-api-typescript-client';
import { AddTask } from './AddTask';
import Title from './Title';
import SectionButtons from './SectionButtons';
import TaskContainer, { SectionFunctionProps } from './TaskContainer';

interface SectionProps {
  plan: any;
  refetch?: () => void;
  section: ChecklistSectionDTO;
  taskUpdated?: boolean;
  isAllowedToEdit?: boolean;
  editSectionTitle?: (section: ChecklistSectionDTO) => boolean;
  renameSectionPopUp?: () => void;
  deleteSectionPopUp?: (sectionId: string, sectionName: string) => void;
  onClickThreeDotMenuDeleteTask?: ({
    id,
    name,
    sectionId,
    type,
  }: SectionFunctionProps) => void;
  addProcedure?: (procedure: string) => void;
  addQuiz?: () => void;
  onCancel?: () => void;
  // TODO update type
  onEdit?: any;
  onDrop?: (
    e: {
      addedIndex: number;
      payload: { id: string; sectionId: string };
      removedIndex: number;
    },
    id: string,
  ) => void;
  setActiveSectionId?: (id: string) => void;
  setIndexToAdd?: (index: DropResult) => void;
  isPreview?: boolean;
  index: number;
}

const Section: React.FC<SectionProps> = ({
  isPreview,
  plan,
  refetch,
  section,
  isAllowedToEdit,
  editSectionTitle,
  renameSectionPopUp,
  deleteSectionPopUp,
  onClickThreeDotMenuDeleteTask,
  addProcedure,
  addQuiz,
  onCancel,
  onEdit,
  onDrop,
  setActiveSectionId,
  setIndexToAdd,
  taskUpdated,
  index,
}) => {
  const [showAddTask, setShowAddTask] = useState(false);

  return (
    <SectionCard data-testid={`SectionCard-${index}`} elevation={1}>
      <Title
        deleteSectionPopUp={
          deleteSectionPopUp as (sectionId: string, sectionName: string) => void
        }
        editSectionTitle={
          editSectionTitle as (title: ChecklistSectionDTO) => boolean
        }
        index={index}
        isAllowedToEdit={isAllowedToEdit!}
        isPreview={isPreview}
        onCancel={onCancel as () => void}
        onEdit={updatedSectionTitle =>
          onEdit(
            Constants.TRAINING_MENU_OPTIONS.SECTION,
            updatedSectionTitle,
            null,
          )
        }
        renameSectionPopUp={renameSectionPopUp as (name: string) => void}
        section={section}
      />
      <SectionDivider variant="fullLength" />
      <ContainerWrapper>
        {/** @ts-ignore it's yelling about children but we need children for the drag and drop */}
        <Container
          dragHandleSelector=".step-in-section-drag-handle"
          dropPlaceholder={{
            className: 'shadow-on-drop',
          }}
          getChildPayload={idx => {
            return {
              ...plan.sections.find(
                (sect: { id: string }) => sect.id === section.id,
              ).steps[idx],
              sectionId: section.id,
            };
          }}
          groupName="steps"
          nonDragAreaSelector=".cfa-textfield"
          onDragEnter={() =>
            (setActiveSectionId as (id: string) => void)(section?.id!)
          }
          onDrop={e =>
            (onDrop as (dropResult: DropResult, id: string) => void)(
              e,
              section.id!,
            )
          }
          onDropReady={addedIndex =>
            (setIndexToAdd as (dropResult: DropResult) => void)(addedIndex)
          }
          style={{ display: 'flex', flexDirection: 'column', gap: '16px' }} // this component is not comptaible with styled components
        >
          {section?.steps &&
            section.steps.map(step => (
              // @ts-ignore it's yelling about children but we need children for the drag and drop
              <Draggable key={step.id}>
                <TaskContainer
                  className={
                    isPreview ? 'no-drag' : 'step-in-section-drag-handle'
                  }
                  isAllowedToEdit={isAllowedToEdit!}
                  isPreview={isPreview}
                  onCancel={onCancel as () => void}
                  onDelete={onClickThreeDotMenuDeleteTask as () => void}
                  onEdit={onEdit}
                  sectionId={section.id}
                  step={step}
                  taskUpdated={taskUpdated!}
                />
              </Draggable>
            ))}
        </Container>
      </ContainerWrapper>

      {showAddTask && (
        <AddTask
          isInSection
          plan={plan}
          refetch={refetch as () => void}
          sectionId={section.id!}
          setShowAddTask={setShowAddTask}
        />
      )}
      {!isPreview && (
        <SectionButtons
          addProcedure={addProcedure as () => void}
          addQuiz={addQuiz as () => void}
          setShowAddTask={setShowAddTask}
          showAddTask={showAddTask}
        />
      )}
    </SectionCard>
  );
};

export default Section;

const SectionCard = styled(Card)`
  padding: 16px;
  position: relative;
  margin: 24px 0;
`;
const SectionDivider = styled(Divider)`
  margin: 16px 0;
`;

const ContainerWrapper = styled.div`
  width: 100%;
  .shadow-on-drop {
    background-color: ${({ theme }) => theme.grayScale.gray2};
    padding: 10px;
    border-radius: 5px;
  }
`;
