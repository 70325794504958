// Because we don't boot react for unsupported browser this is duplicated in public/index.html
function uaIncludes(find: string) {
  return window.navigator.userAgent.indexOf(find) > -1;
}

export default () => {
  // FormData.set and prepend are not polyfilled by coreJS, but that's ok because
  // we don't support browsers without it anyway so it's a fairly easy test case
  const hasFormDataSet = typeof FormData?.prototype?.set === 'function';
  const hasPrepend = typeof Element?.prototype?.prepend === 'function';
  if (!hasFormDataSet || !hasPrepend) {
    return false;
  }
  const isOldIE = uaIncludes(' MSIE ');
  const isIE11 = uaIncludes(' Trident/');
  const isLegacyEdge =
    uaIncludes(' Edge/18.') ||
    uaIncludes(' Edge/17.') ||
    uaIncludes(' Edge/16.') ||
    uaIncludes(' Edge/15.') ||
    uaIncludes(' Edge/14.') ||
    uaIncludes(' Edge/13.') ||
    uaIncludes(' Edge/12.');

  if (isOldIE || isIE11 || isLegacyEdge) {
    return false;
  } else if (uaIncludes(' Safari/')) {
    // Legacy Safari
    if (
      uaIncludes(' Version/8.') ||
      uaIncludes(' Version/7.') ||
      uaIncludes(' Version/6.') ||
      uaIncludes(' Version/5.') ||
      uaIncludes(' Version/4.') ||
      uaIncludes(' Version/3.')
    ) {
      return false;
    }
  }
  return true;
};
