import {
  Drawer,
  IconButton,
  NavLink,
  TopNavigation,
  TopNavigationContent,
  TopNavigationContentShift,
  Typography,
} from 'cfa-react-components';
import { IconX, IconMenu2, IconChevronDown } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Constants from '@/constants';
import { PathwayLogo } from '@/icons';
import {
  isUserLicensee,
  selectUserLicenseeLocations,
} from '@/store/user/selectors';
import { selectActiveLicenseeLocation } from '@/store/licenseeLocationPicker/selector';
import { setShowLicenseeLocationPicker } from '@/store/licenseeLocationPicker/slice';
import { resetManagePlans } from '@/store/managePlansFilter/slice';
import { selectIsPrinting } from '@/store/document-transient/selectors';

interface NavbarMobileProps {
  tabs: {
    icon: React.ReactNode;
    isActive: boolean;
    label: string;
    to: string;
  }[];
  children: React.ReactNode;
  showMenu: boolean;
}

const NavbarMobile: React.FC<NavbarMobileProps> = ({
  tabs,
  children,
  showMenu,
}) => {
  const { t } = useTranslation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const dispatch = useDispatch();
  const userLicenseeLocations = useSelector(selectUserLicenseeLocations);
  const isLicenseeUser = useSelector(isUserLicensee);
  const activeLicenseeLocation = useSelector(selectActiveLicenseeLocation);
  const isPrinting = useSelector(selectIsPrinting);
  const showLicenseeLocation =
    isLicenseeUser &&
    !!activeLicenseeLocation?.number &&
    userLicenseeLocations.length > 1;

  const handleNavClick = () => {
    dispatch(resetManagePlans());
    setDrawerOpen(false);
  };

  const handleLicenseeLocationClick = () => {
    dispatch(setShowLicenseeLocationPicker());
  };

  return !showMenu ? (
    children
  ) : (
    <TopNavigationContainer>
      {!isPrinting && (
        <TopNavigation position="sticky">
          <TopNavigationContent section="start">
            <Link
              data-testid="PathwayLogo"
              title={t('Generic.appName')}
              to={`/`}
            >
              <NavLogo />
            </Link>
            {showLicenseeLocation && (
              <LicenseeLocationWrapper onClick={handleLicenseeLocationClick}>
                <LicenseeLocationName color="secondary" variant="overline3">
                  {activeLicenseeLocation.name}
                </LicenseeLocationName>
                <StyledIconChevronDown />
              </LicenseeLocationWrapper>
            )}
          </TopNavigationContent>

          {/* not rendering middle section */}
          <div style={{ flex: 1 }} />

          <TopNavigationContent section="end">
            <IconButton
              aria-label={`${drawerOpen ? 'close' : 'open'} menu`}
              className="collapse-menu"
              color="default"
              onClick={() => setDrawerOpen(prevState => !prevState)}
              size="sm"
            >
              {drawerOpen ? <IconX /> : <IconMenu2 />}
            </IconButton>
          </TopNavigationContent>
        </TopNavigation>
      )}
      <Drawer
        content={
          <>
            <TopNavigationContentShift />
            <div
              style={{
                marginTop: '16px',
                display: 'flex',
                flexDirection: 'column',
                padding: 4,
              }}
            >
              {tabs.map((tab, index) => (
                <NavLink
                  aria-label={`Navigate to ${tab.label}`}
                  as={Link}
                  data-testid={`Nav${tab.label}`}
                  icon={tab.icon}
                  isActive={tab.isActive}
                  key={index}
                  onClick={
                    tab.label === Constants.NAVBAR_NAMES.PLANS_NAVBAR_NAME ||
                    tab.label === Constants.NAVBAR_NAMES.REPORTS_NAVBAR_NAME
                      ? handleNavClick
                      : () => setDrawerOpen(false)
                  }
                  to={tab.to}
                  variant="drawer-link"
                >
                  {tab.label}
                </NavLink>
              ))}
            </div>
          </>
        }
        onClose={() => setDrawerOpen(false)}
        open={drawerOpen}
      />
      {children}
    </TopNavigationContainer>
  );
};

const TopNavigationContainer = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

const NavLogo = styled(PathwayLogo)`
  height: 32px;
  width: 28px;
`;

const LicenseeLocationWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LicenseeLocationName = styled(Typography)`
  padding-left: 0.5rem;
`;

const StyledIconChevronDown = styled(IconChevronDown)`
  color: ${props => props.theme.primaryPalette.navyBlue};
`;

export default NavbarMobile;
