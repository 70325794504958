import { getNameFromLanguage } from 'utils/language';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEditCategoryMutation } from 'services/pathwayApi';
import isEqual from 'lodash/isEqual';
import { selectUserCountry } from 'store/user/selectors';
import {
  selectDocumentForDeletion,
  selectDocumentForVisibilityToggle,
  selectDocumentVisibilityFlagForConfirmationPopup,
  selectSelectedAdminSubcategoryIndex,
  selectSelectedAdminSubtitleIndex,
  selectTemporaryCategory,
} from 'store/admin/selectors';
import { TextFieldType } from 'cfa-react-components';
import {
  setTemporaryCategory,
  setSelectedSubcategoryIndex,
  setSelectedSubtitleIndex,
  setDocumentForVisibilityToggle,
  setDocumentVisibilityForConfirmationPopup,
  setDocumentForDeletion,
} from 'store/admin/slice';
import cloneDeep from 'lodash/cloneDeep';
import ConfirmationModal from 'components/popups/ConfirmationModal';
import { DocumentDTO } from '@cfacorp-pathway/xp-api-typescript-client';
import { setSubcategoryId } from '../../../store/subcategory/slice';
import { SubtitleProps } from './SubCategoryAccordion';
import {
  IntlCategoryDTO,
  IntlSubcategoryDTO,
} from '@/services/content-api-types';
import { LanguageObject } from '@/types/types';
import { getUpdatedToggleVisibleDocuments } from '@/utils/adminEdit';

interface ConfirmationModalsProps {
  category: IntlCategoryDTO;
  countryName: string;
  editSection: boolean;
  handleSubcategoryVisibility: (subcategory: string) => void;
  index: any;
  onShowToast: (name: string) => void;
  refetchCategories: () => void;
  setShowDeleteResource: (show: boolean) => void;
  setShowDeleteSection: any;
  setShowDeleteSubcategory: (show: boolean) => void;
  setShowToggleResourceVisibility: (show: boolean) => void;
  setShowToggleSubcategoryVisibility: any;
  showDeleteResource: boolean;
  showDeleteSection: boolean;
  showDeleteSubcategory: boolean;
  showToggleResourceVisibility: boolean;
  showToggleSubcategoryVisibility: boolean;
  subcategory: { name: LanguageObject; subtitles: IntlSubcategoryDTO[] };
  subcategoryIsVisible: boolean;
  subtitle: SubtitleProps;
  setSubtitle: (subtitle: SubtitleProps) => void;
}

const ConfirmationModals: React.FC<ConfirmationModalsProps> = ({
  category,
  countryName,
  editSection,
  handleSubcategoryVisibility,
  index,
  onShowToast,
  refetchCategories,
  setShowDeleteResource,
  setShowDeleteSection,
  setShowDeleteSubcategory,
  setShowToggleResourceVisibility,
  setShowToggleSubcategoryVisibility,
  showDeleteResource,
  showDeleteSection,
  showDeleteSubcategory,
  showToggleResourceVisibility,
  showToggleSubcategoryVisibility,
  subcategory,
  subcategoryIsVisible = true,
  subtitle,
  setSubtitle,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedTemporaryCategory = useSelector(selectTemporaryCategory);
  const selectedCountry: any = useSelector(selectUserCountry);
  const selectedDocumentForVisibilityToggle = useSelector(
    selectDocumentForVisibilityToggle,
  );
  const selectedSubcategoryIndex = useSelector(
    selectSelectedAdminSubcategoryIndex,
  );
  const selectedSubtitleIndex = useSelector(selectSelectedAdminSubtitleIndex);
  const documentBeingMutatedIsVisible = useSelector(
    selectDocumentVisibilityFlagForConfirmationPopup,
  );
  const selectedDocumentForDeletion = useSelector(selectDocumentForDeletion);
  const [editCategory] = useEditCategoryMutation();

  const resetSubtitleMutationState = () => {
    dispatch(setSelectedSubcategoryIndex(null));
    dispatch(setSelectedSubtitleIndex(null));
    dispatch(setDocumentVisibilityForConfirmationPopup(null));
  };

  const onCancelToggleSubtitleDocumentVisibility = () => {
    resetSubtitleMutationState();
    dispatch(setDocumentForVisibilityToggle(null));
    setShowToggleResourceVisibility(false);
  };

  const onCancelToggleSubtitleDocumentDeletion = () => {
    resetSubtitleMutationState();
    dispatch(setDocumentForDeletion(null));
    setShowDeleteResource(false);
  };

  const deleteDocumentFromSubtitle = (isInEditSectionMode: boolean) => {
    const { id: documentId, name: documentName } =
      selectedDocumentForDeletion as any;
    if (isInEditSectionMode) {
      setSubtitle({
        ...subtitle,
        visibleDocuments: documentBeingMutatedIsVisible
          ? subtitle?.visibleDocuments?.filter((id: any) => id !== documentId)
          : subtitle?.visibleDocuments,
        documents: subtitle?.documents?.filter(
          ({ id }: any) => id !== documentId,
        ),
      } as SubtitleProps);
    }

    const mapSubcategoriesFunc = (
      subcat: IntlSubcategoryDTO,
      mappedSubcatIndex: any,
    ) => {
      return mappedSubcatIndex === selectedSubcategoryIndex
        ? {
            ...subcat,
            subtitles: [
              ...subcat?.subtitles!.map(
                (mappedSubtitle, mappedSubtitleIndex) => {
                  return mappedSubtitleIndex === selectedSubtitleIndex
                    ? {
                        ...mappedSubtitle,
                        documents: mappedSubtitle.documents.filter(
                          doc =>
                            doc.id !== (selectedDocumentForDeletion as any)?.id,
                        ),
                        visibleDocuments:
                          mappedSubtitle?.visibleDocuments?.filter(
                            docId =>
                              docId !==
                              (selectedDocumentForDeletion as any)?.id,
                          ),
                      }
                    : mappedSubtitle;
                },
              ),
            ],
          }
        : subcat;
    };

    const updatedTemporaryCategory = {
      ...category,
      subcategories:
        selectedTemporaryCategory.subcategories.map(mapSubcategoriesFunc),
    };

    if (
      (selectedDocumentForDeletion as any)?.id ===
      category?.subcategories?.[selectedSubcategoryIndex as any]?.subtitles?.[
        selectedSubtitleIndex as any
      ]!.documents?.[(selectedDocumentForDeletion as any)?.index]?.id
    ) {
      const updatedCategory = {
        ...category,

        subcategories: category?.subcategories!.map(mapSubcategoriesFunc),
      };

      editCategory(updatedCategory).then(() => {
        dispatch(setTemporaryCategory(updatedTemporaryCategory));
        onShowToast(getNameFromLanguage(documentName));
        dispatch(setDocumentForDeletion(null));
        setShowDeleteResource(false);
      });
    } else {
      dispatch(setTemporaryCategory(updatedTemporaryCategory));
      setShowDeleteResource(false);
      dispatch(setDocumentForDeletion(null));
    }
  };

  const onToggleSubtitleDocumentVisibility = (isInEditSectionMode: boolean) => {
    // @ts-ignore
    const { id: documentId } = selectedDocumentForVisibilityToggle;
    const mutableCategory = cloneDeep(selectedTemporaryCategory);

    const mutableCategorySubtitle =
      // @ts-ignore
      mutableCategory.subcategories[selectedSubcategoryIndex].subtitles[
        selectedSubtitleIndex
      ];

    const documentIsCurrentlyVisible =
      // @ts-ignore
      mutableCategorySubtitle.visibleDocuments.indexOf(documentId) > -1;

    if (isInEditSectionMode) {
      setSubtitle({
        ...subtitle,
        visibleDocuments: getUpdatedToggleVisibleDocuments(
          documentId,
          documentIsCurrentlyVisible,
          subtitle?.visibleDocuments as string[],
          subtitle?.documents,
        ),
      } as SubtitleProps);
    }

    const updatedVisibleDocuments = getUpdatedToggleVisibleDocuments(
      documentId,
      documentIsCurrentlyVisible,
      mutableCategorySubtitle?.visibleDocuments as string[],
      mutableCategorySubtitle?.documents as DocumentDTO[],
    );

    // @ts-ignore
    mutableCategory.subcategories[selectedSubcategoryIndex].subtitles[
      selectedSubtitleIndex
    ].visibleDocuments = updatedVisibleDocuments;

    resetSubtitleMutationState();
    dispatch(setTemporaryCategory(mutableCategory));
    dispatch(setDocumentForVisibilityToggle(null));
    setShowToggleResourceVisibility(false);
  };

  const onToggleSubcategoryVisibility = () => {
    handleSubcategoryVisibility(getNameFromLanguage(subcategory.name));
    setShowToggleSubcategoryVisibility(false);
  };

  const onDeleteSubcategory = () => {
    setShowDeleteSubcategory(false);
    const futureSubcategories = [...selectedTemporaryCategory.subcategories];
    const removedSubcategory = futureSubcategories.splice(index, 1);

    dispatch(
      setTemporaryCategory({
        ...selectedTemporaryCategory,
        subcategories: futureSubcategories,
      }),
    );

    // checking to make sure we are not in editing mode
    if (isEqual(selectedTemporaryCategory, category)) {
      editCategory({
        ...category,
        subcategories: futureSubcategories,
      }).then(() => {
        refetchCategories();
        // @ts-ignore
        onShowToast(getNameFromLanguage(removedSubcategory?.[0]?.name));
        dispatch(
          setSubcategoryId({
            id: -1,
          }),
        );
      });
    }
  };
  const onDeleteSection = () => {
    setShowDeleteSection(false);
    const futureSubcategories = [...selectedTemporaryCategory.subcategories];
    const futureSubtitles = [
      ...[...(selectedTemporaryCategory as any)?.subcategories][index]
        ?.subtitles,
    ];
    // @ts-ignore
    const removedSubtitle = futureSubtitles.splice(subtitle.index, 1);
    dispatch(
      setTemporaryCategory({
        ...selectedTemporaryCategory,
        subcategories: futureSubcategories.map((it, ind) =>
          ind === index
            ? {
                ...(it as any),
                subtitles: (it as any).subtitles.filter(
                  (_: any, idx: string) => idx !== subtitle.index,
                ),
              }
            : it,
        ),
      }),
    );

    // checking to make sure we are not in editing mode
    if (isEqual(selectedTemporaryCategory, category)) {
      editCategory({
        ...category,
        subcategories: futureSubcategories.map((it, ind) =>
          ind === index
            ? {
                ...(it as any),
                subtitles: (it as any).subtitles.filter(
                  (_: any, idx: string) => idx !== subtitle.index,
                ),
              }
            : it,
        ),
      }).then(refetchCategories);
    }

    // We guard against adding a subtitle and deleting it before it's saved
    getNameFromLanguage(removedSubtitle?.[0]?.name) &&
      onShowToast(getNameFromLanguage(removedSubtitle?.[0]?.name));
  };

  const handleDocumentConfirmationsOnClose = () => {
    setShowDeleteResource(false);
    setShowToggleResourceVisibility(false);
    resetSubtitleMutationState();
    dispatch(setDocumentForDeletion(null));
    dispatch(
      setDocumentVisibilityForConfirmationPopup(documentBeingMutatedIsVisible),
    );
  };

  return (
    <>
      <ConfirmationModal
        bodyText={
          subcategoryIsVisible
            ? t('Admin.makeHiddenSubcategoryBody', {
                categoryName: getNameFromLanguage(subcategory?.name),
                countryName,
              })
            : t('Admin.makeVisibleSubcategoryBody', {
                categoryName: getNameFromLanguage(subcategory?.name),
                countryName,
              })
        }
        headerText={
          subcategoryIsVisible
            ? t('Admin.makeHiddenHeader')
            : t('Admin.makeVisibleHeader')
        }
        isOpen={showToggleSubcategoryVisibility}
        onClose={(e: React.ChangeEvent<TextFieldType>) => {
          e.stopPropagation();
          setShowToggleSubcategoryVisibility(false);
        }}
        primaryButtonColor={subcategoryIsVisible ? 'primary' : 'secondary'}
        primaryButtonHandler={(e: React.ChangeEvent<TextFieldType>) => {
          e.stopPropagation();
          onToggleSubcategoryVisibility();
        }}
        primaryButtonText={
          subcategoryIsVisible
            ? t('Button.makeHidden')
            : t('Button.makeVisible')
        }
        secondaryButtonHandler={e => {
          e.stopPropagation();
          setShowToggleSubcategoryVisibility((prev: boolean) => !prev);
        }}
        secondaryButtonText={t('Button.cancel')}
      />
      <ConfirmationModal
        bodyText={t('Generic.deleteObject', {
          name: getNameFromLanguage(subcategory?.name),
          object: t('Admin.subcategory').toLowerCase(),
        })}
        headerText={t('Generic.deleteHeader', {
          type: t('Admin.subcategory'),
        })}
        isOpen={showDeleteSubcategory}
        onClose={() => setShowDeleteSubcategory(false)}
        primaryButtonHandler={onDeleteSubcategory}
        primaryButtonText={t('Button.delete')}
        primaryButtonVariant="destructive"
        secondaryButtonHandler={prev => setShowDeleteSubcategory(!prev)}
        secondaryButtonText={t('Button.cancel')}
      />
      <ConfirmationModal
        bodyText={t('Generic.deleteObject', {
          name: getNameFromLanguage(subtitle?.name as LanguageObject),
          object: t('Generic.section').toLowerCase(),
        })}
        headerText={t('Generic.deleteHeader', {
          type: t('Generic.section'),
        })}
        isOpen={showDeleteSection}
        onClose={e => {
          e.stopPropagation();
          setShowDeleteSection(false);
        }}
        primaryButtonHandler={e => {
          e.stopPropagation();
          onDeleteSection();
        }}
        primaryButtonText={t('Button.delete')}
        primaryButtonVariant="destructive"
        secondaryButtonHandler={e => {
          e.stopPropagation();
          setShowDeleteSection((prev: boolean) => !prev);
        }}
        secondaryButtonText={t('Button.cancel')}
      />
      <ConfirmationModal
        bodyText={
          documentBeingMutatedIsVisible
            ? t('Admin.makeHiddenDocumentBody', {
                countryName: selectedCountry.LABEL_KEY ?? '',
                documentName:
                  getNameFromLanguage(
                    // @ts-ignore
                    selectedDocumentForVisibilityToggle?.name,
                  ) ?? '',
              })
            : t('Admin.makeVisibleDocumentBody', {
                countryName: selectedCountry.LABEL_KEY ?? '',
                documentName:
                  getNameFromLanguage(
                    // @ts-ignore
                    selectedDocumentForVisibilityToggle?.name,
                  ) ?? '',
              })
        }
        headerText={
          documentBeingMutatedIsVisible
            ? t('Admin.makeHiddenHeader')
            : t('Admin.makeVisibleHeader')
        }
        isOpen={showToggleResourceVisibility}
        onClose={e => {
          e.stopPropagation();
          handleDocumentConfirmationsOnClose();
        }}
        primaryButtonColor={
          documentBeingMutatedIsVisible ? 'primary' : 'secondary'
        }
        primaryButtonHandler={e => {
          e.stopPropagation();
          onToggleSubtitleDocumentVisibility(editSection);
        }}
        primaryButtonText={
          documentBeingMutatedIsVisible
            ? t('Button.makeHidden')
            : t('Button.makeVisible')
        }
        secondaryButtonHandler={e => {
          e.stopPropagation();
          onCancelToggleSubtitleDocumentVisibility();
        }}
        secondaryButtonText={t('Button.cancel')}
      />
      <ConfirmationModal
        bodyText={t('Generic.removeObject', {
          // @ts-ignore
          name: getNameFromLanguage(selectedDocumentForDeletion?.name) ?? '',
          object: t('Generic.resource').toLowerCase(),
        })}
        headerText={t('Generic.removeHeader', {
          type: t('Generic.resource'),
        })}
        isOpen={showDeleteResource}
        onClose={e => {
          e.stopPropagation();
          handleDocumentConfirmationsOnClose();
        }}
        primaryButtonHandler={e => {
          e.stopPropagation();
          deleteDocumentFromSubtitle(editSection);
        }}
        primaryButtonText={t('Button.remove')}
        primaryButtonVariant="destructive"
        secondaryButtonHandler={e => {
          e.stopPropagation();
          onCancelToggleSubtitleDocumentDeletion();
        }}
        secondaryButtonText={t('Button.cancel')}
      />
    </>
  );
};

export default ConfirmationModals;
