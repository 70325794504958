import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useBreakpoints, useMediaQuery } from 'cfa-react-components';
import styled from 'styled-components';
import Constants from '@/constants';
import GenericError from '@/components/Error/GenericError';
import { withRoles } from '@/components/ConfirmationModal/withRoles';
import { setHeader } from '@/store/header/slice';
import useBugsnagNotify from '@/hooks/useBugsnagNotify';
import { useCategoryLabels } from '@/hooks/useCategoryLabels';
import { useSortOptions } from '@/hooks/useSortOptions';
import {
  selectAllLocationsWithAtLeastTrainer,
  isUserLicensee,
} from '@/store/user/selectors';
import {
  selectFilters,
  selectSearchFilter,
  selectSort,
  selectSortedAndFilteredAndPaginatedPlans,
} from '@/store/managePlansFilter/selector';
import { useGetOperatorsQuery } from '@/services/pathwayApi';
import { LanguageObject, PaginatedPlan } from '@/types/types';
import { isApiError } from '@/utils/request';
import { getNameFromLanguage } from '@/utils/language';
import { removeUnderscoreAndCapitalizeString } from '@/utils/removeUnderscoreAndCapitalizeString';
import { arrayToCommaString } from '@/utils/arrayToCommaString';
import { mapCategoryToAliasTranslation } from '@/utils/categoryUtils';
import { generateReportAndMessageReactNative } from '@/utils/generateReport';
import useGetManagePlansData from '@/utils/reportPrint/useGetManagePlansData';
import Modals from './Modals';
import { OperatorDTO } from '@cfacorp-pathway/xp-api-typescript-client';
import PlanCards from './PlanCards';
import FilterAndSearch from './FilterAndSearch';
import LoadingOverlay from '@/components/LoadingOverlay/LoadingOverlay';

const ManagePlansTab = () => {
  const { notifyBugsnag } = useBugsnagNotify();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const breakpoints = useBreakpoints();
  const isSmAndDown = useMediaQuery(breakpoints.down('sm'));
  const filteredAndSortedPlans = useSelector(
    selectSortedAndFilteredAndPaginatedPlans,
  );
  const currentFilters = useSelector(selectFilters);
  const sort = useSelector(selectSort);
  const isLicenseeUser = useSelector(isUserLicensee);
  const locationsWithAtLeastTrainer = useSelector(
    selectAllLocationsWithAtLeastTrainer,
  );
  const searchFilter = useSelector(selectSearchFilter);
  const [showChooseReportLocationsPopup, setShowChooseReportLocationsPopup] =
    useState(false);
  const [showDoBChecked, setShowDoBChecked] = useState(false);
  const [noLocationsSelected, setNoLocationsSelected] = useState(false);
  const [showEnablePlanPopUp, setShowEnablePlanPopUp] = useState(false);
  const [courseId, setCourseId] = useState('');
  const [courseName, setCourseName] = useState('');
  const [planId, setPlanId] = useState('');
  const [triggerComplianceReportHandler, setTriggerComplianceReportHandler] =
    useState(false);
  const [selectedReportLocations, setSelectedReportLocations] = useState(
    locationsWithAtLeastTrainer,
  );
  const [showCustomPlan, setShowCustomPlan] = useState(false);
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [showCopyTemplatePopup, setShowCopyTemplatePopup] = useState(false);
  const [copiedTemplateId, setCopiedTemplatePlanId] = useState('');
  const [previewTemplate, setPreviewTemplate] = useState({});
  const [showPreview, setShowPreview] = useState(false);
  const [showInvalidNameError, setShowInvalidNameError] = useState(false);
  const sortOptions = useSortOptions();
  const categoryLabels = useCategoryLabels(currentFilters, isLicenseeUser);
  const { data: operators, isFetching: isFetchingOperators } =
    useGetOperatorsQuery();

  const {
    resetStatusReport,
    refetchIsCourseEnabled,
    refetchPlans,
    isFetchingPlans,
    isStatusReportFetching,
    plansDataError,
    statusReport,
    statusReportToPrint,
  } = useGetManagePlansData(planId, selectedReportLocations);

  useEffect(() => {
    dispatch(setHeader(t('TrainingPlans.tabPlans')));
  }, [dispatch, t]);

  const resetPlanState = useCallback(() => {
    resetStatusReport();
    setPlanId('');
  }, [resetStatusReport]);

  const tableHead = useMemo(
    () => [
      [
        Constants.REPORT_TABLE.NAME,
        ...(showDoBChecked ? [Constants.REPORT_TABLE.DATE_OF_BIRTH] : []),
        Constants.REPORT_TABLE.COMPLETION_DATE,
        Constants.REPORT_TABLE.EXPIRATION_DATE,
      ],
    ],
    [showDoBChecked],
  );

  const reportToGenerate = useMemo(() => {
    return {
      category: t(
        mapCategoryToAliasTranslation(Constants.PLAN_CATEGORIES.COMPLIANCE),
      ),
      foodSafetyImage: (statusReport as any)?.foodSafetyLetters?.[0]?.content,
      location: `${t('Generic.at')} ${
        !statusReport?.locations.length
          ? statusReport?.locations.toString()
          : arrayToCommaString(statusReport?.locations, t('Generic.and'))
      }`,
      name: removeUnderscoreAndCapitalizeString(statusReport?.courseName ?? ''),
      reportToPrint: statusReportToPrint.map(teamMember =>
        tableHead?.[0]?.map(headerItem => teamMember?.[headerItem]),
      ),
      tableHead,
    };
  }, [t, statusReport, statusReportToPrint, tableHead]);

  const handleCurrentFilteredLocations = useCallback(() => {
    setSelectedReportLocations(locationsWithAtLeastTrainer);
    if (!!noLocationsSelected) {
      setNoLocationsSelected(false);
    }
    setShowDoBChecked(false);
  }, [locationsWithAtLeastTrainer, noLocationsSelected]);

  const handlePrintReport = useCallback(() => {
    if (
      !selectedReportLocations.length &&
      locationsWithAtLeastTrainer?.length > 1
    ) {
      setNoLocationsSelected(true);
    } else {
      setShowChooseReportLocationsPopup(false);
      generateReportAndMessageReactNative(
        reportToGenerate,
        statusReportToPrint,
        tableHead,
      );
      resetPlanState();
      handleCurrentFilteredLocations();
    }
  }, [
    tableHead,
    resetPlanState,
    handleCurrentFilteredLocations,
    locationsWithAtLeastTrainer?.length,
    reportToGenerate,
    statusReportToPrint,
    selectedReportLocations.length,
  ]);

  useEffect(() => {
    if (!!statusReport.courseId && !!triggerComplianceReportHandler) {
      handlePrintReport();
      setTriggerComplianceReportHandler(false);
    }
  }, [
    handlePrintReport,
    statusReport.courseId,
    triggerComplianceReportHandler,
  ]);

  if (isApiError(plansDataError)) {
    notifyBugsnag(plansDataError);
    return <GenericError />;
  }

  const onShowPopUp = (id: string, name: string) => {
    setCourseId(id);
    setCourseName(name);
    setShowEnablePlanPopUp(true);
  };

  const onPrintReport = (id: string, plan: PaginatedPlan) => {
    setPlanId(id);
    if (
      getNameFromLanguage(plan?.courseName as LanguageObject).includes(
        Constants.FOOD_SAFETY_COURSE_NAME,
      ) ||
      locationsWithAtLeastTrainer?.length > 1
    ) {
      setShowChooseReportLocationsPopup(true);
    } else {
      // need to wait for setPlanDetails to populate the state :(
      setTriggerComplianceReportHandler(true);
    }
  };

  const handlePreviewPlan = (template: any) => {
    setPreviewTemplate(template);
    setShowTemplateModal(false);
    setShowPreview(true);
  };

  const handleTemplateBackButton = () => {
    setPreviewTemplate({});
    setShowTemplateModal(true);
    setShowPreview(false);
  };

  const onShowCopyPlan = (id: string) => {
    setCopiedTemplatePlanId(id);
    setShowPreview(false);
    setShowTemplateModal(false);
    setShowCopyTemplatePopup(true);
  };

  return (
    <>
      <StyledContent>
        <LoadingOverlay isOpen={isFetchingPlans} />
        <FilterAndSearch
          categoryLabels={categoryLabels}
          currentFilters={currentFilters}
          filteredAndSortedPlans={filteredAndSortedPlans}
          isFetchingPlans={isFetchingPlans}
          isSmAndDown={isSmAndDown}
          searchFilter={searchFilter}
          sort={sort}
          sortOptions={sortOptions}
        />
        <PlanCards
          categoryLabels={categoryLabels}
          currentFilters={currentFilters}
          filteredAndSortedPlans={filteredAndSortedPlans}
          isFetchingPlans={isFetchingPlans}
          isSmAndDown={isSmAndDown}
          onPrintReport={onPrintReport}
          onShowPopUp={onShowPopUp}
          refetchPlans={refetchPlans}
          searchFilter={searchFilter}
          sort={sort}
          sortOptions={sortOptions}
          setShowCustomPlan={setShowCustomPlan}
          setShowTemplateModal={setShowTemplateModal}
        />
        <Modals
          copiedTemplateId={copiedTemplateId}
          courseId={courseId}
          courseName={courseName}
          handlePreviewPlan={handlePreviewPlan}
          handlePrintReport={handlePrintReport}
          handleTemplateBackButton={handleTemplateBackButton}
          isFetchingOperators={isFetchingOperators}
          isStatusReportFetching={isStatusReportFetching}
          locationsWithAtLeastTrainer={locationsWithAtLeastTrainer}
          onShowCopyPlan={onShowCopyPlan}
          operators={operators as OperatorDTO[]}
          previewTemplate={previewTemplate}
          refetchIsCourseEnabled={refetchIsCourseEnabled}
          refetchPlans={refetchPlans}
          resetPlanState={resetPlanState}
          resetStatusReport={resetStatusReport}
          selectedReportLocations={selectedReportLocations}
          setPlanId={setPlanId}
          setSelectedReportLocations={setSelectedReportLocations}
          setShowChooseReportLocationsPopup={setShowChooseReportLocationsPopup}
          setShowCopyTemplatePopup={setShowCopyTemplatePopup}
          setShowCustomPlan={setShowCustomPlan}
          setShowDoBChecked={setShowDoBChecked}
          setShowEnablePlanPopUp={setShowEnablePlanPopUp}
          setShowInvalidNameError={setShowInvalidNameError}
          setShowPreview={setShowPreview}
          setShowTemplateModal={setShowTemplateModal}
          showChooseReportLocationsPopup={showChooseReportLocationsPopup}
          showCopyTemplatePopup={showCopyTemplatePopup}
          showCustomPlan={showCustomPlan}
          showDoBChecked={showDoBChecked}
          showEnablePlanPopUp={showEnablePlanPopUp}
          showInvalidNameError={showInvalidNameError}
          showPreview={showPreview}
          showTemplateModal={showTemplateModal}
          statusReport={statusReport}
          statusReportToPrint={statusReportToPrint}
        />
      </StyledContent>
    </>
  );
};

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  max-width: 100%;
`;

const ManagePlansTabWithRoles = withRoles(ManagePlansTab, 'plans.manage');

export default ManagePlansTabWithRoles;
