import { Trans, useTranslation } from 'react-i18next';
import { useGetOperatorsQuery as useGetOperatorsQueryOld } from 'services/pathwayApi';
import { useGetOperatorsQuery } from 'services/xpApi';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { OperatorDTO } from '@cfacorp-pathway/xp-api-typescript-client';
import ReportsPlanCard from '@/components/Cards/PlanCard/PlanCards/ReportsPlanCard';
import SortFilterHeader from '@/components/SortFilterHeader/SortFilterHeader';
import NoMessage from '@/components/NoMessage/NoMessage';
import { useSortOptions } from '@/hooks/useSortOptions';
import LoadingOverlay from '@/components/LoadingOverlay/LoadingOverlay';
import {
  selectFilters,
  selectPagination,
  selectSearchFilter,
  selectSort,
  selectSortedAndFilteredAndPaginatedPlans,
} from '@/store/managePlansFilter/selector';
import {
  clearManagePlansCheckboxFilters,
  loadMorePlans,
  setManagePlansSort,
} from '@/store/managePlansFilter/slice';
import { arrayIntersect } from '@/utils/keepDuplicatesFromTwoArrays';
import {
  selectAllLocationsWithAtLeastLeaderPermissions,
  selectUserIsLicenseeStaff,
} from '@/store/user/selectors';
import { getNameFromLanguage } from '@/utils/language';
import ReportsCompliancePlanCard from '@/components/Cards/PlanCard/PlanCards/ReportsCompliancePlanCard';
import { ComplianceCourse } from '@/types/types';
import LoadMorePaginator from '@/components/LoadMorePaginator/LoadMorePaginator';
import { selectActiveLicenseeLocation } from '@/store/licenseeLocationPicker/selector';

interface PlansListProps {
  isFetching: boolean;
  onPrintReport: (report: {
    category: string;
    id: string;
    isCompliancePlan: boolean;
    locations: string[];
    planName: string;
  }) => void;
}

const PlansList: React.FC<PlansListProps> = ({ isFetching, onPrintReport }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { xpApi: xpApiFeatureFlag } = useFlags();
  const sort = useSelector(selectSort);
  const { showing, total } = useSelector(selectPagination) as any;
  const filteredAndSortedPlans = useSelector(
    selectSortedAndFilteredAndPaginatedPlans,
  );
  const locationsWithAtLeastLeaderPermission = useSelector(
    selectAllLocationsWithAtLeastLeaderPermissions,
  );
  const activeLicenseeLocation: { name: string; number: string } = useSelector(
    selectActiveLicenseeLocation,
  );
  const userIsLicenseeStaff = useSelector(selectUserIsLicenseeStaff);
  const currentFilters: string[] = useSelector(selectFilters);
  const searchFilter = useSelector(selectSearchFilter) ?? '';
  const sortOptions = useSortOptions();
  const { data: oldOperators } = useGetOperatorsQueryOld(undefined, {
    skip: !!xpApiFeatureFlag,
  });

  const { data: xpOperators } = useGetOperatorsQuery(undefined, {
    skip: !xpApiFeatureFlag,
  });

  const operators: OperatorDTO[] = xpApiFeatureFlag
    ? xpOperators
    : (oldOperators as any);

  const operatorsWhereUserIsLeader = operators?.filter(operator =>
    operator?.locations?.some(operatorLocation =>
      locationsWithAtLeastLeaderPermission.includes(operatorLocation),
    ),
  );
  return (
    <PlansContainer>
      <LoadingOverlay isOpen={isFetching} />
      <PlanCardsList>
        {!isFetching && (
          <>
            <SortFilterHeader
              label={t('TrainingPlans.filtering.sortBy')}
              onChange={option => {
                dispatch(setManagePlansSort({ sort: option?.value }));
              }}
              onClear={() => {
                dispatch(clearManagePlansCheckboxFilters());
              }}
              options={sortOptions}
              showClear={false}
              showCompletedPlansOption={false}
              showMyCompletedPlansOnlySwitchValue={false}
              text={`${total ?? 0} ${t('Reports.tabPlans')}`}
              value={sortOptions?.find(option => option.value === sort)}
            />
            <ReportsCardContainer>
              {filteredAndSortedPlans?.map(plan =>
                plan?.checklist ? (
                  <ReportsPlanCard
                    assignedUsers={plan?.assignedUsers}
                    completedUsers={plan?.completedUsers}
                    key={plan?.checklist?.id}
                    locations={
                      // intersects locations where user is a leader with the plans locations
                      arrayIntersect(
                        operatorsWhereUserIsLeader?.find(
                          operator => operator.id === plan?.checklist?.ownerId,
                        )?.locations ?? [],
                        locationsWithAtLeastLeaderPermission,
                      )
                    }
                    onPrintReport={({
                      category,
                      id,
                      locations,
                    }: {
                      category: string;
                      id: string;
                      locations: string[];
                    }) =>
                      onPrintReport({
                        category,
                        id,
                        isCompliancePlan: false,
                        locations,
                        planName: getNameFromLanguage(plan?.checklist?.name),
                      })
                    }
                    plan={plan?.checklist}
                  />
                ) : (
                  <ReportsCompliancePlanCard
                    course={
                      {
                        courseID: plan?.courseID,
                        courseName: plan?.courseName,
                        createdDate: plan?.createdDate,
                        enabled: plan?.enabled,
                        id: plan?.id,
                      } as ComplianceCourse
                    }
                    enrollments={plan?.enrollments}
                    key={plan.courseID}
                    locations={locationsWithAtLeastLeaderPermission}
                    onPrintReport={({
                      category,
                      id,
                      locations,
                    }: {
                      category: string;
                      id: string;
                      locations: string[];
                    }) =>
                      onPrintReport({
                        category,
                        id,
                        isCompliancePlan: true,
                        locations,
                        planName: getNameFromLanguage(plan?.name!),
                      })
                    }
                  />
                ),
              )}
            </ReportsCardContainer>
            <LoadMorePaginator
              onClick={() => dispatch(loadMorePlans())}
              showing={showing}
              showingText={t('TrainingPlans.showingXOfYPlans', {
                showing,
                total: total ?? 0,
              })}
              total={total ?? 0}
            />
          </>
        )}
        {!filteredAndSortedPlans?.length &&
          !isFetching &&
          userIsLicenseeStaff &&
          !activeLicenseeLocation?.number && (
            <NoMessage
              message={<Trans i18nKey={'Licensee.chooseALicenseeLocation'} />}
            />
          )}
        {!filteredAndSortedPlans?.length &&
          !isFetching &&
          !currentFilters?.length &&
          !searchFilter &&
          !!activeLicenseeLocation?.number && (
            <NoMessage message={<Trans i18nKey={'Reports.noPlans'} />} />
          )}
        {(!!currentFilters?.length || !!searchFilter) &&
          !total &&
          !!activeLicenseeLocation?.number && (
            <NoMessage message={t('TrainingPlans.noPlansResults')} />
          )}
      </PlanCardsList>
    </PlansContainer>
  );
};

const PlanCardsList = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  max-width: 100%;
`;

const PlansContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow: hidden;
`;

const ReportsCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export default PlansList;
