import Constants from 'constants/index';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { htmlFromPrintJson } from './PrintJSConversion';
import { messageReactNative } from './messageReactNative';

interface ReportProps {
  category: string;
  complianceReport?: any;
  complianceTableHead?: string[][];
  foodSafetyImage?: string;
  isTeamMember?: boolean;
  location?: string;
  name: string;
  reportToPrint: any;
  tableHead: string[][];
}

export const generateReport = ({
  category,
  complianceReport,
  complianceTableHead,
  foodSafetyImage,
  isTeamMember,
  location,
  name,
  reportToPrint,
  tableHead,
}: ReportProps) => {
  const head = tableHead;
  const body = reportToPrint;
  const styles = {
    halign: 'center',
    valign: 'middle',
    lineWidth: 1,
  };
  const hasFoodSafetyImage =
    name?.includes(Constants.FOOD_SAFETY_COURSE_NAME) && foodSafetyImage;
  const header = (report: { pageNumber: number }) => {
    const xOffset = doc.internal.pageSize.width / 2;
    if (report.pageNumber === 1) {
      /** Unfortunately, jsPDF does not support css styling
       * so we have to set everything manually */
      // @ts-ignore
      doc.setFont(undefined, 'bold');
      doc.setFontSize(12);
      doc.text(category.toUpperCase(), xOffset, 10, {
        align: 'center',
      });
      doc.setFontSize(18);
      doc.text(name, xOffset, 20, {
        align: 'center',
      });
      if (location) {
        doc.setFontSize(12);
        // @ts-ignore
        doc.setFont(undefined, 'normal');
        doc.text(location, xOffset, 29, { align: 'center' });
      }
    }
  };
  const doc = new jsPDF();

  /**
   * We print two separate tables when viewing a Team Member
   * in Reports (compliance and store) */
  if (isTeamMember) {
    if (complianceReport?.length) {
      (doc as any).autoTable({
        body: complianceReport,
        // This sets the header
        didDrawPage: header,
        head: complianceTableHead,
        startY: 25,
        styles,
      });
    }

    if (reportToPrint?.length) {
      (doc as any).autoTable({
        body,
        didDrawPage: !complianceReport?.length && header,
        head,
        startY: !complianceReport?.length && 25,
        styles,
      });
    }
  } else if (hasFoodSafetyImage) {
    const foodSafetyLetter = `data:image/jpeg;base64,${foodSafetyImage}`;
    (doc as any).autoTable({
      body,
      didDrawPage: header,
      head,
      startY: location ? 34 : 25,
      styles,
    });
    // Add blank page to render image
    doc.addPage();
    doc.addImage(foodSafetyLetter, 'JPG', 0, 0, 200, 275);
  } else {
    (doc as any).autoTable({
      body,
      didDrawPage: header,
      head,
      startY: location ? 34 : 25,
      styles,
    });
  }

  doc.autoPrint();
  doc.output('dataurlnewwindow');
};

export const printToNative = ({
  category,
  location,
  name,
  reportToPrint,
  tableHead,
}: ReportProps) => {
  return htmlFromPrintJson({
    gridHeaderStyle: 'border: 1px solid #5B6770; padding: 0.5em;',
    gridStyle: 'border: 1px solid #5B6770; text-align: center; padding: 0.5em;',
    header: `
        <div style="display: flex; flex-direction: column; align-items: center; font-weight: 900;">
        <span style="font: 700 1em "Apercu", sans-serif; text-transform: uppercase">${category.toUpperCase()}</span>
        <span style="font: 700 1.5em "Apercu", sans-serif; margin-top: 0.5em; margin-bottom: 0.5em;">${name}</span>
        ${
          location
            ? `<span style="margin-bottom: 1em; font-weight: 400;">${location}</span>`
            : ''
        }
        </div>
        `,
    printable: reportToPrint,
    properties: tableHead[0],
    type: 'json',
  }).innerHTML;
};

export const generateReportAndMessageReactNative = (
  reportToGenerate: ReportProps,
  statusReportToPrint: Array<{ [x: string]: string }>,
  tableHead: string[][],
  isTeamMember?: boolean,
) => {
  const messageToSend = {
    category: reportToGenerate.category,
    ...(!isTeamMember && { location: reportToGenerate.location }),
    name: reportToGenerate.name,
    reportToPrint: statusReportToPrint,
    tableHead,
  };
  generateReport(reportToGenerate);
  messageReactNative(
    Constants.RN_MESSAGE_TYPES.PRINT,
    printToNative(messageToSend),
  );
};
