import { ContentReference } from '@cfacorp-pathway/xp-api-typescript-client';
import { Modal, ModalBody } from 'cfa-react-components';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import styled from 'styled-components';
import groupBy from 'lodash/groupBy';
import {
  ChooseDocumentCard,
  ChooseDocumentCountryTabs,
} from '@/components/document';
import {
  selectUserCountry,
  selectUserIsAdminOperatorOrStakeholder,
} from '@/store/user/selectors';
import { selectChosenCountryTab } from '@/store/document-transient/selectors';
import { COUNTRIES, getCountryLabel } from '@/utils/document-utils/countries';
import { formatDocumentTag } from '@/utils/formatDocumentTag';

interface ChooseDocumentProps {
  icon?: string;
  isOpen: boolean;
  onClick: (document: ContentReference) => void;
  onClose?: () => void;
  references: ContentReference[];
}

const ChooseDocumentModal: FC<ChooseDocumentProps> = ({
  icon = '',
  isOpen,
  onClick,
  onClose,
  references = [],
}) => {
  const { chooseDocumentVersion } = useFlags();
  const { t } = useTranslation();
  const userCountry = useSelector(selectUserCountry);
  const chosenCountryTab = useSelector(selectChosenCountryTab);
  const isAdminOperatorOrStakeholder = useSelector(
    selectUserIsAdminOperatorOrStakeholder,
  );

  const enableChooseDocumentVersion =
    chooseDocumentVersion && isAdminOperatorOrStakeholder;

  const referencesGroup = useMemo(
    () => groupBy(references, 'country'),
    [references],
  );
  const activeCountryTab = useMemo(() => {
    if (chosenCountryTab) {
      return chosenCountryTab;
    }
    const userCountryId = userCountry.id;
    if (referencesGroup[userCountryId!]) {
      return userCountry.id;
    }
    return COUNTRIES.find(({ id }) => referencesGroup[id]?.length)?.id;
  }, [chosenCountryTab, userCountry, referencesGroup]);

  const countryHasResource = useMemo(() => {
    return COUNTRIES.filter(({ id }) => referencesGroup[id]?.length).map(
      ({ id }) => id,
    );
  }, [referencesGroup]);

  const activeReferences = referencesGroup[activeCountryTab!];

  return (
    <Modal
      onClose={() => {
        onClose?.();
      }}
      scrollMode="modal-body"
      show={isOpen}
      size="lg"
    >
      <ModalBody>
        <StyledTitle>{t('Generic.chooseVersion')}</StyledTitle>
        <ChooseDocumentCountryTabs
          activeCountryTab={activeCountryTab}
          countryHasResource={countryHasResource}
        />
        <StyledDocumentsWrapper>
          {!!activeReferences?.length &&
            [...activeReferences].map((document, index) => (
              <ChooseDocumentCard
                icon={icon}
                key={`${document.country}_${index}`}
                name={document.name ?? ''}
                onClick={() => onClick(document)}
                tags={document.tags?.map(tag => formatDocumentTag(tag))}
                country={
                  enableChooseDocumentVersion
                    ? getCountryLabel(document.country as string)
                    : ''
                }
              />
            ))}
        </StyledDocumentsWrapper>
      </ModalBody>
    </Modal>
  );
};

const StyledTitle = styled.div`
  color: ${({ theme }) => theme.grayScale.gray7};
  font-size: 2em;
  font-weight: 700;
  padding: 2em 0 1em;
`;

const StyledDocumentsWrapper = styled.div`
  display: 'flex';
  justify-content: 'center';
  align-items: 'center';
  width: 100%;
`;

export default ChooseDocumentModal;
