import { LocationDetails } from '@cfacorp-pathway/xp-api-typescript-client';
import { UserState } from '@/store/user/slice';

export const mockedAuthData: UserState = {
  audience: 'OPERATOR_AUDIENCE',
  cdn: [
    {
      domain: 'https://cdn.pathway-dev.cfadevelop.com',
      path: '/binary/',
      expiresIn: 10799,
      cookies: {
        'CloudFront-Policy':
          'eyJTdGF0ZW1lbnQiOiBbeyJSZXNvdXJjZSI6Imh0dHBzOi8vY2RuLnBhdGh3YXktZGV2LmNmYWRldmVsb3AuY29tL2JpbmFyeS8qIiwiQ29uZGl0aW9uIjp7IkRhdGVMZXNzVGhhbiI6eyJBV1M6RXBvY2hUaW1lIjoxNzM4ODAxNzc4fSwiSXBBZGRyZXNzIjp7IkFXUzpTb3VyY2VJcCI6IjAuMC4wLjAvMCJ9LCJEYXRlR3JlYXRlclRoYW4iOnsiQVdTOkVwb2NoVGltZSI6MTczODc5MDY3OH19fV19',
        'CloudFront-Signature':
          'nJCDDfPKvNOB17jF9P7p~nCnnnVOe2Gp~aTZalbhiYaB8~0wwulleaLp7gbacfSwswjskWo7ZumPY5pivXsjL~h1hHgHU8b-c2NEShz8D4UMoaTB-Y686tITzgFOjAdaUvrlRMY1t-V7uyc9QS4ixRSVcp0ndqkZHUt1Z8QXHEI0vuA2nt1hLoATA4vKTe6sjmST3xT2EVrnrGp-z6BSo9yN6LNxpgFlDRNLBJZDLB9Ll8znltAeebuqNVpqRQs4HpLF0wnrpnXVxvrlkaKAQojvi070KqcHK5rzy32a~trZCLEMpA6pwh6lMhZrARnydjczHHwCCX13dmivpju5HQ__',
        'CloudFront-Key-Pair-Id': 'K2WQ7WVYINOJN9',
      },
      queryString:
        '?Policy=eyJTdGF0ZW1lbnQiOiBbeyJSZXNvdXJjZSI6Imh0dHBzOi8vY2RuLnBhdGh3YXktZGV2LmNmYWRldmVsb3AuY29tL2JpbmFyeS8qIiwiQ29uZGl0aW9uIjp7IkRhdGVMZXNzVGhhbiI6eyJBV1M6RXBvY2hUaW1lIjoxNzM4ODAxNzc4fSwiSXBBZGRyZXNzIjp7IkFXUzpTb3VyY2VJcCI6IjAuMC4wLjAvMCJ9LCJEYXRlR3JlYXRlclRoYW4iOnsiQVdTOkVwb2NoVGltZSI6MTczODc5MDY3OH19fV19&Signature=nJCDDfPKvNOB17jF9P7p~nCnnnVOe2Gp~aTZalbhiYaB8~0wwulleaLp7gbacfSwswjskWo7ZumPY5pivXsjL~h1hHgHU8b-c2NEShz8D4UMoaTB-Y686tITzgFOjAdaUvrlRMY1t-V7uyc9QS4ixRSVcp0ndqkZHUt1Z8QXHEI0vuA2nt1hLoATA4vKTe6sjmST3xT2EVrnrGp-z6BSo9yN6LNxpgFlDRNLBJZDLB9Ll8znltAeebuqNVpqRQs4HpLF0wnrpnXVxvrlkaKAQojvi070KqcHK5rzy32a~trZCLEMpA6pwh6lMhZrARnydjczHHwCCX13dmivpju5HQ__&Key-Pair-Id=K2WQ7WVYINOJN9',
    },
    {
      domain: 'https://cdn.pathway-dev.cfadevelop.com',
      path: '/games-and-videos/',
      expiresIn: 10799,
      cookies: {
        'CloudFront-Policy':
          'eyJTdGF0ZW1lbnQiOiBbeyJSZXNvdXJjZSI6Imh0dHBzOi8vY2RuLnBhdGh3YXktZGV2LmNmYWRldmVsb3AuY29tL2dhbWVzLWFuZC12aWRlb3MvKiIsIkNvbmRpdGlvbiI6eyJEYXRlTGVzc1RoYW4iOnsiQVdTOkVwb2NoVGltZSI6MTczODgwMTc3OH0sIklwQWRkcmVzcyI6eyJBV1M6U291cmNlSXAiOiIwLjAuMC4wLzAifSwiRGF0ZUdyZWF0ZXJUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE3Mzg3OTA2Nzh9fX1dfQ__',
        'CloudFront-Signature':
          'ZV4bakbTK3Lb1e0ZQrWH62VqL9ujGg3zr4-eyOHOTmJfrfc361xf08FQBQqrlxxVFSwxMg--Bs3oBJ1~V8LbH39irmLOb1TTz5lq0lNB--OspcbWIlGQhWtrFE0Z0e0fGgnCG8xfvYU-Xty2M3mX9L~Vbsh3LLZqJCNsshLGXUHd-DeWmMfppqlfNYy-ZAeFSYMgI3UW~O~Caj911WIH2IqQq8VgrxF7QC8ge5QYq3swpcV01w1VK6Nan5l3O5lsXmmXf0~iyWCxS2oWtuXQNUHSf4~vve5VexIcH3nNkaYMVLIh2I2ibWNXPOQetGwOZxucxmUJ4MjfG-msEIwYUw__',
        'CloudFront-Key-Pair-Id': 'K2WQ7WVYINOJN9',
      },
      queryString:
        '?Policy=eyJTdGF0ZW1lbnQiOiBbeyJSZXNvdXJjZSI6Imh0dHBzOi8vY2RuLnBhdGh3YXktZGV2LmNmYWRldmVsb3AuY29tL2dhbWVzLWFuZC12aWRlb3MvKiIsIkNvbmRpdGlvbiI6eyJEYXRlTGVzc1RoYW4iOnsiQVdTOkVwb2NoVGltZSI6MTczODgwMTc3OH0sIklwQWRkcmVzcyI6eyJBV1M6U291cmNlSXAiOiIwLjAuMC4wLzAifSwiRGF0ZUdyZWF0ZXJUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE3Mzg3OTA2Nzh9fX1dfQ__&Signature=ZV4bakbTK3Lb1e0ZQrWH62VqL9ujGg3zr4-eyOHOTmJfrfc361xf08FQBQqrlxxVFSwxMg--Bs3oBJ1~V8LbH39irmLOb1TTz5lq0lNB--OspcbWIlGQhWtrFE0Z0e0fGgnCG8xfvYU-Xty2M3mX9L~Vbsh3LLZqJCNsshLGXUHd-DeWmMfppqlfNYy-ZAeFSYMgI3UW~O~Caj911WIH2IqQq8VgrxF7QC8ge5QYq3swpcV01w1VK6Nan5l3O5lsXmmXf0~iyWCxS2oWtuXQNUHSf4~vve5VexIcH3nNkaYMVLIh2I2ibWNXPOQetGwOZxucxmUJ4MjfG-msEIwYUw__&Key-Pair-Id=K2WQ7WVYINOJN9',
    },
    {
      domain: 'https://cdn.pathway-dev.cfadevelop.com',
      path: '/tridion/cfa/',
      expiresIn: 10799,
      cookies: {
        'CloudFront-Policy':
          'eyJTdGF0ZW1lbnQiOiBbeyJSZXNvdXJjZSI6Imh0dHBzOi8vY2RuLnBhdGh3YXktZGV2LmNmYWRldmVsb3AuY29tL3RyaWRpb24vY2ZhLyoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE3Mzg4MDE3Nzh9LCJJcEFkZHJlc3MiOnsiQVdTOlNvdXJjZUlwIjoiMC4wLjAuMC8wIn0sIkRhdGVHcmVhdGVyVGhhbiI6eyJBV1M6RXBvY2hUaW1lIjoxNzM4NzkwNjc4fX19XX0_',
        'CloudFront-Signature':
          'RVL7xlAb7Zkq7WOmO4L76inSogL1Qz8MLqKaHLu2enrWjz0oe8m7nEfl5NyLdPYwH1ubdRU3ek0zZBw5sqfjBVzAxpt43uuFv6UsxliRzc-KXH4av~sMIAmYqTC01jK1r6D-ni80TxlSO5occDgdvIOsfEw7R3lodSfD-HmpO0tJ-h-797oAnaxLjWrgSU-xtGeKvI34t53YlafnFgIee2vwMRV88ZJVJ00gaZb2wsA1dJy-4laqesq67-ot7BvqU0GieK7VEBKk8Xzt9nSVSHWgF~kFwIyHBJd1IJV0FyM9dPvcPrcZeWX3QYVfcpsgNrPNVJq9XvLLkeL3I6~amg__',
        'CloudFront-Key-Pair-Id': 'K2WQ7WVYINOJN9',
      },
      queryString:
        '?Policy=eyJTdGF0ZW1lbnQiOiBbeyJSZXNvdXJjZSI6Imh0dHBzOi8vY2RuLnBhdGh3YXktZGV2LmNmYWRldmVsb3AuY29tL3RyaWRpb24vY2ZhLyoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE3Mzg4MDE3Nzh9LCJJcEFkZHJlc3MiOnsiQVdTOlNvdXJjZUlwIjoiMC4wLjAuMC8wIn0sIkRhdGVHcmVhdGVyVGhhbiI6eyJBV1M6RXBvY2hUaW1lIjoxNzM4NzkwNjc4fX19XX0_&Signature=RVL7xlAb7Zkq7WOmO4L76inSogL1Qz8MLqKaHLu2enrWjz0oe8m7nEfl5NyLdPYwH1ubdRU3ek0zZBw5sqfjBVzAxpt43uuFv6UsxliRzc-KXH4av~sMIAmYqTC01jK1r6D-ni80TxlSO5occDgdvIOsfEw7R3lodSfD-HmpO0tJ-h-797oAnaxLjWrgSU-xtGeKvI34t53YlafnFgIee2vwMRV88ZJVJ00gaZb2wsA1dJy-4laqesq67-ot7BvqU0GieK7VEBKk8Xzt9nSVSHWgF~kFwIyHBJd1IJV0FyM9dPvcPrcZeWX3QYVfcpsgNrPNVJq9XvLLkeL3I6~amg__&Key-Pair-Id=K2WQ7WVYINOJN9',
    },
    {
      domain: 'https://cdn.pathway-dev.cfadevelop.com',
      path: '/xyleme-content/',
      expiresIn: 10799,
      cookies: {
        'CloudFront-Policy':
          'eyJTdGF0ZW1lbnQiOiBbeyJSZXNvdXJjZSI6Imh0dHBzOi8vY2RuLnBhdGh3YXktZGV2LmNmYWRldmVsb3AuY29tL3h5bGVtZS1jb250ZW50LyoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE3Mzg4MDE3Nzh9LCJJcEFkZHJlc3MiOnsiQVdTOlNvdXJjZUlwIjoiMC4wLjAuMC8wIn0sIkRhdGVHcmVhdGVyVGhhbiI6eyJBV1M6RXBvY2hUaW1lIjoxNzM4NzkwNjc4fX19XX0_',
        'CloudFront-Signature':
          'E0khzZL1V9MITOp4YDUOKO9MhmiVTYgIkTw5HB0Nhm8gpJ6JblTLbCBgXBO1~gQVDy6guKjYwQUmmFhK8CkFPECqDNVL4bTRRGE89jVZLKh0fmPwyOhZPfr3sS5qKLTJRbrcy4b5Hz2EtnZDiELSnsnaCEUY~bgLgjGAm4dimdHa2L2CmYKEhgund2RGqLhcBCXSwo9Q0Hx3wPu~rbtOXpVFVtIYC32ImqO53hyCetlrPa1y0ekdJc-8vT1g3tUd2QDbyhGdet-I8Ptp41~vZy7ZAJMRILfCDnaHLdDkyrTsJzbWT1DT~oZS7Qad5nUfEa~zm5VbXD~TdlCW1mKi9Q__',
        'CloudFront-Key-Pair-Id': 'K2WQ7WVYINOJN9',
      },
      queryString:
        '?Policy=eyJTdGF0ZW1lbnQiOiBbeyJSZXNvdXJjZSI6Imh0dHBzOi8vY2RuLnBhdGh3YXktZGV2LmNmYWRldmVsb3AuY29tL3h5bGVtZS1jb250ZW50LyoiLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE3Mzg4MDE3Nzh9LCJJcEFkZHJlc3MiOnsiQVdTOlNvdXJjZUlwIjoiMC4wLjAuMC8wIn0sIkRhdGVHcmVhdGVyVGhhbiI6eyJBV1M6RXBvY2hUaW1lIjoxNzM4NzkwNjc4fX19XX0_&Signature=E0khzZL1V9MITOp4YDUOKO9MhmiVTYgIkTw5HB0Nhm8gpJ6JblTLbCBgXBO1~gQVDy6guKjYwQUmmFhK8CkFPECqDNVL4bTRRGE89jVZLKh0fmPwyOhZPfr3sS5qKLTJRbrcy4b5Hz2EtnZDiELSnsnaCEUY~bgLgjGAm4dimdHa2L2CmYKEhgund2RGqLhcBCXSwo9Q0Hx3wPu~rbtOXpVFVtIYC32ImqO53hyCetlrPa1y0ekdJc-8vT1g3tUd2QDbyhGdet-I8Ptp41~vZy7ZAJMRILfCDnaHLdDkyrTsJzbWT1DT~oZS7Qad5nUfEa~zm5VbXD~TdlCW1mKi9Q__&Key-Pair-Id=K2WQ7WVYINOJN9',
    },
    {
      domain: 'https://cdn.pathway-dev.cfadevelop.com',
      path: '/xyleme/cfa/',
      expiresIn: 10799,
      cookies: {
        'CloudFront-Policy':
          'eyJTdGF0ZW1lbnQiOiBbeyJSZXNvdXJjZSI6Imh0dHBzOi8vY2RuLnBhdGh3YXktZGV2LmNmYWRldmVsb3AuY29tL3h5bGVtZS9jZmEvKiIsIkNvbmRpdGlvbiI6eyJEYXRlTGVzc1RoYW4iOnsiQVdTOkVwb2NoVGltZSI6MTczODgwMTc3OH0sIklwQWRkcmVzcyI6eyJBV1M6U291cmNlSXAiOiIwLjAuMC4wLzAifSwiRGF0ZUdyZWF0ZXJUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE3Mzg3OTA2Nzh9fX1dfQ__',
        'CloudFront-Signature':
          'PaRCLIBEJhcBuveKvBFKd9hqAhHdiWdSmU5emjDAivVIa~FnJREmWPPUtn5uOLkXmul7dlBqwgufkSL97NzcHYHAgodI20YSo-oculdfTwfxTWBtEkHhf8CrEZKKzY9Jmu6B3iJuZR4FWbb8O3MbaOMbrkbQWf0~el4ek080kzDW1yVFJRhtEXL-w4980J0b0EdnaKzHE7LFaqmics8R04dptriepGsHUeT9tq7x61d4ZlSIjBSHcCyQGk6ktFsF4A0GVftgmEjvH~ePJSswHU8gUtr0uZbeXJLTAM6WnPeJs-gfmu5aC6Kp3gLpU3q3mSLKstImpVqZ3Ey7w70hGg__',
        'CloudFront-Key-Pair-Id': 'K2WQ7WVYINOJN9',
      },
      queryString:
        '?Policy=eyJTdGF0ZW1lbnQiOiBbeyJSZXNvdXJjZSI6Imh0dHBzOi8vY2RuLnBhdGh3YXktZGV2LmNmYWRldmVsb3AuY29tL3h5bGVtZS9jZmEvKiIsIkNvbmRpdGlvbiI6eyJEYXRlTGVzc1RoYW4iOnsiQVdTOkVwb2NoVGltZSI6MTczODgwMTc3OH0sIklwQWRkcmVzcyI6eyJBV1M6U291cmNlSXAiOiIwLjAuMC4wLzAifSwiRGF0ZUdyZWF0ZXJUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE3Mzg3OTA2Nzh9fX1dfQ__&Signature=PaRCLIBEJhcBuveKvBFKd9hqAhHdiWdSmU5emjDAivVIa~FnJREmWPPUtn5uOLkXmul7dlBqwgufkSL97NzcHYHAgodI20YSo-oculdfTwfxTWBtEkHhf8CrEZKKzY9Jmu6B3iJuZR4FWbb8O3MbaOMbrkbQWf0~el4ek080kzDW1yVFJRhtEXL-w4980J0b0EdnaKzHE7LFaqmics8R04dptriepGsHUeT9tq7x61d4ZlSIjBSHcCyQGk6ktFsF4A0GVftgmEjvH~ePJSswHU8gUtr0uZbeXJLTAM6WnPeJs-gfmu5aC6Kp3gLpU3q3mSLKstImpVqZ3Ey7w70hGg__&Key-Pair-Id=K2WQ7WVYINOJN9',
    },
  ],
  email: 'pvlukzo@cfafranchisee.com',
  features: {
    plans: {
      enabled: true,
      create: {
        enabled: true,
      },
      manage: {
        enabled: true,
      },
    },
    quizzes: {
      enabled: true,
      create: {
        enabled: true,
      },
    },
    reporting: {
      enabled: true,
      plans: {
        enabled: true,
      },
      teamMember: {
        enabled: true,
      },
      compliance: {
        enabled: true,
      },
      licensee: {
        enabled: false,
      },
    },
    settings: {
      enabled: true,
      country: {
        enabled: true,
      },
      language: {
        enabled: true,
      },
      assistance: {
        enabled: true,
      },
      location: {
        enabled: true,
      },
      compliance: {
        enabled: true,
      },
    },
    admin: {
      enabled: true,
    },
    analytics: {
      enabled: true,
    },
    monitoring: {
      enabled: true,
    },
    rum: {
      enabled: true,
    },
  },
  firstName: 'A.J.',
  fullName: 'A.J. Clemans',
  isInternationalUser: false,
  isLicensee: false,
  language: 'en',
  country: {
    id: 'US',
  },
  locations: ['00838', '02007', '02931', '00000'],
  locationsWithPermissions: [
    {
      location: {
        number: '00838',
        name: 'Peachtree City Midtown FSU',
        businessModel: LocationDetails.businessModel.FRANCHISEE,
        entity: LocationDetails.entity.CFA,
        type: 'FSU',
        country: 'US',
        state: 'GA',
        operator: {
          id: 'f2bdf66661da34428eda691dd10e38f4',
        },
      },
      permissions: [
        'RESETPLANPROGRESS',
        'SEARCH_ALT_PROCEDURE',
        'ASSIGNANDTRACKPLANS',
        'LEADER',
        'TRAININGMODE',
        'MARKTMCOMPLETE',
        'OPERATOR',
        'CRUDPLANSANDQUIZZES',
        'REPORTING',
        'LOGIN',
        'TRAINER',
      ],
    },
    {
      location: {
        number: '02007',
        name: 'Kedron Village FSU',
        businessModel: LocationDetails.businessModel.FRANCHISEE,
        entity: LocationDetails.entity.CFA,
        type: 'FSU',
        country: 'US',
        state: 'GA',
        operator: {
          id: 'f2bdf66661da34428eda691dd10e38f4',
        },
      },
      permissions: [
        'RESETPLANPROGRESS',
        'SEARCH_ALT_PROCEDURE',
        'ASSIGNANDTRACKPLANS',
        'LEADER',
        'TRAININGMODE',
        'MARKTMCOMPLETE',
        'OPERATOR',
        'CRUDPLANSANDQUIZZES',
        'REPORTING',
        'LOGIN',
        'TRAINER',
      ],
    },
    {
      location: {
        number: '02931',
        name: 'Seven Corners In-Line',
        businessModel: LocationDetails.businessModel.FRANCHISEE,
        entity: LocationDetails.entity.CFA,
        type: 'MALL',
        country: 'US',
        state: 'VA',
        operator: {
          id: '183c62a995aa4ddab56cff31db8f923e',
        },
      },
      permissions: [
        'RESETPLANPROGRESS',
        'SEARCH_ALT_PROCEDURE',
        'ASSIGNANDTRACKPLANS',
        'LEADER',
        'TRAININGMODE',
        'MARKTMCOMPLETE',
        'OPERATOR',
        'CRUDPLANSANDQUIZZES',
        'REPORTING',
        'LOGIN',
        'TRAINER',
      ],
    },
    {
      location: {
        number: '00000',
        name: 'Chick-fil-A, Inc.',
        businessModel: undefined,
        entity: undefined,
        type: 'CORP',
        country: 'US',
        state: 'GA',
        operator: undefined,
      },
      permissions: ['OPERATOR'],
    },
  ],
  permissions: {
    RESETPLANPROGRESS: ['00838', '02007', '02931'],
    SEARCH_ALT_PROCEDURE: ['00838', '02007', '02931'],
    ASSIGNANDTRACKPLANS: ['00838', '02007', '02931'],
    LEADER: ['00838', '02007', '02931'],
    TRAININGMODE: ['00838', '02007', '02931'],
    MARKTMCOMPLETE: ['00838', '02007', '02931'],
    OPERATOR: ['00838', '02007', '02931', '00000'],
    CRUDPLANSANDQUIZZES: ['00838', '02007', '02931'],
    REPORTING: ['00838', '02007', '02931'],
    LOGIN: ['00838', '02007', '02931'],
    TRAINER: ['00838', '02007', '02931'],
  },
  selectedLocation: null,
  userId: '183c62a995aa4ddab56cff31db8f923e',
  userType: 'Operator',
  franchiseeLocations: [
    {
      number: '00838',
      name: 'Peachtree City Midtown FSU',
      businessModel: LocationDetails.businessModel.FRANCHISEE,
      entity: LocationDetails.entity.CFA,
      type: 'FSU',
      country: 'US',
      state: 'GA',
      operator: {
        id: 'f2bdf66661da34428eda691dd10e38f4',
      },
    },
    {
      number: '02007',
      name: 'Kedron Village FSU',
      businessModel: LocationDetails.businessModel.FRANCHISEE,
      entity: LocationDetails.entity.CFA,
      type: 'FSU',
      country: 'US',
      state: 'GA',
      operator: {
        id: 'f2bdf66661da34428eda691dd10e38f4',
      },
    },
    {
      number: '02931',
      name: 'Seven Corners In-Line',
      businessModel: LocationDetails.businessModel.FRANCHISEE,
      entity: LocationDetails.entity.CFA,
      type: 'MALL',
      country: 'US',
      state: 'VA',
      operator: {
        id: '183c62a995aa4ddab56cff31db8f923e',
      },
    },
  ],
  licenseeLocations: [],
  supportCenterLocations: [],
};
