import styled from 'styled-components';
import Constants from '@/constants';
import { useDeviceInfo } from '@/utils/device';

interface StickyAreaProps {
  children: React.ReactNode;
}

interface StickyAreaDivProps {
  $isDesktop: boolean;
  $topOffset: string;
}

const StickyArea: React.FC<StickyAreaProps> = ({ children }) => {
  const { isDesktop } = useDeviceInfo();
  const topOffset = '103px';
  return (
    <StickyAreaDiv $isDesktop={isDesktop} $topOffset={topOffset}>
      {children}
    </StickyAreaDiv>
  );
};

const StickyAreaDiv = styled.div<StickyAreaDivProps>`
  position: sticky;
  top: ${({ $isDesktop, $topOffset }) =>
    $isDesktop
      ? $topOffset
      : `calc(${$topOffset} + ${Constants.HEIGHT.MOBILE_TOP_NAV})`};
  // this calc is to prevent the card from overflowing and also going over the topnav
  max-height: ${({ $isDesktop, $topOffset }) =>
    $isDesktop
      ? `calc(100vh - ${$topOffset} - ${Constants.HEIGHT.DESKTOP_TOP_NAV} - ${Constants.HEIGHT.BUILD_PLANS_HEADER} - ${Constants.HEIGHT.STICKY_MENU_MARGIN})`
      : `calc(100vh - ${$topOffset} - ${Constants.HEIGHT.MOBILE_TOP_NAV} - ${Constants.HEIGHT.DESKTOP_TOP_NAV} - ${Constants.HEIGHT.BUILD_PLANS_HEADER} - ${Constants.HEIGHT.STICKY_MENU_MARGIN})`};
  z-index: 4;
  width: 270px;
  min-width: 270px;
  flex-shrink: 1;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 16px 0;
`;

export default StickyArea;
