import { LDClient, useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { useAppSelector } from '@/hooks';
import { selectIsInitialized } from '@/store/app/selectors';
import { isUserInternational, selectUser } from '@/store/user/selectors';
import { sendNativeMessage } from '@/hooks/useNativeListener';
import { getLaunchdDarklyUser } from '@/utils/user';
import { UserState } from '@/store/user/slice';

let clientSetup = false;

const LaunchDarklySetup: React.FC = () => {
  const user = useAppSelector(selectUser);
  const isInternational = useAppSelector(isUserInternational);
  const isInitialized = useAppSelector(selectIsInitialized);
  const ldClient = useLDClient();

  useEffect(() => {
    if (!isInitialized || !ldClient || isInternational || clientSetup) {
      return;
    }
    clientSetup = true;
    initializeLaunchDarklyUser(ldClient, user);
  }, [isInitialized, isInternational, ldClient, user]);

  return <></>;
};

const initializeLaunchDarklyUser = (ldClient: LDClient, user: UserState) => {
  sendNativeMessage({
    type: 'updateUser',
    payload: {
      user,
    },
  });

  const ldUser = getLaunchdDarklyUser(user);
  // if browser setting > cookies > "Do not track" then user info wont show in Users list page in LD
  // although the user info should still have been posted to LD for use in flag targeting: apparently
  // "Do not track" browser setting impacts the LD analytics api (events.launchdarkly.com)
  // but for user flag targeting the feature flag API (app.launchdarkly.com) is used
  // https://docs.launchdarkly.com/guides/best-practices/user-data
  if (ldUser && ldUser.email) {
    ldClient.identify(ldUser, undefined, function () {});
  }
};

export default LaunchDarklySetup;
