import {
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from 'cfa-react-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectPlansToggleLicensee } from '@/store/licensee/selector';
import {
  setTogglePlansLicensee,
  setTogglePlansMyRestaurants,
} from '@/store/licensee/slice';
import { clearTeamMembersCheckboxFilters } from '@/store/teamMembersFilter/slice';

const ToggleButtons = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isLicenseePlans = useSelector(selectPlansToggleLicensee);

  const toggleMyRestaurants = () => {
    dispatch(setTogglePlansMyRestaurants());
    dispatch(clearTeamMembersCheckboxFilters());
  };

  const toggleLicensee = () => {
    dispatch(setTogglePlansLicensee());
    dispatch(clearTeamMembersCheckboxFilters());
  };

  return (
    <ToggleButtonGroup
      fullWidth
      orientation="horizontal"
      size="sm"
      value={isLicenseePlans}
    >
      <ToggleButton
        aria-label="my-restaurants"
        onClick={toggleMyRestaurants}
        value={false}
      >
        <Typography
          fontWeight={isLicenseePlans ? 'regular' : 'bold'}
          variant="body1"
        >
          {t('Licensee.myRestaurants')}
        </Typography>
      </ToggleButton>
      <ToggleButton
        aria-label="licensees"
        onClick={toggleLicensee}
        value={true}
      >
        <Typography
          fontWeight={isLicenseePlans ? 'bold' : 'regular'}
          variant="body1"
        >
          {t('Licensee.licensees')}
        </Typography>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default ToggleButtons;
