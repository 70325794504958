import Constants from 'constants/index';
import styled from 'styled-components';
import { useBreakpoints, useMediaQuery } from 'cfa-react-components';
import { useLocation } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import isNil from 'lodash/isNil';
import DocumentCompleteButton from './DocumentCompleteButton';
import ManageTeamMembersButtons from './ManageTeamMembersButtons';
import TrainingModeExitButton from './TrainingModeExitButton';
import PrintReportButton from './PrintReportButton';
import {
  isReportsPlansViewPath,
  isReportsTeamMembersViewPath,
  isTrainingModePath,
  isTrainingTeamMembersPath,
  isTrainingToCompletePath,
} from '@/utils/url';

interface RightHeaderContentProps {
  onWidth: (width: number) => void;
  otherWidth: number;
}

interface LocationProps {
  state: {
    step: {
      reference: string;
    };
  };
}

interface RightHeaderStyleProps {
  $otherWidth: number;
  $width: number;
}

const RightHeaderContent: React.FC<RightHeaderContentProps> = ({
  onWidth,
  otherWidth,
}) => {
  const [width, setWidth] = useState(0);
  const rightHeaderRef = useRef<HTMLDivElement>(null);

  const breakpoints = useBreakpoints();
  const isLgAndUp = useMediaQuery(breakpoints.up('lg'));

  const location = useLocation();
  const isCompliance = location?.search?.includes(
    Constants.PLAN_CATEGORIES.COMPLIANCE,
  );

  useEffect(() => {
    if (rightHeaderRef.current) {
      if (
        rightHeaderRef.current.clientWidth !== width &&
        rightHeaderRef.current.clientWidth !== otherWidth
      ) {
        setWidth(rightHeaderRef.current.clientWidth);
      }
    }
    if (rightHeaderRef.current && rightHeaderRef.current.clientWidth === 0) {
      setWidth(0);
    }
  }, [otherWidth, rightHeaderRef, width, location]);

  useEffect(() => {
    if (isLgAndUp) {
      onWidth(width);
    } else {
      onWidth(0);
    }
  }, [onWidth, width, isLgAndUp]);

  const showManagePlansButtons =
    !!isTrainingTeamMembersPath(location) && !isCompliance;
  const showPrintReportButton =
    (!!isTrainingTeamMembersPath(location) && !!isCompliance) ||
    !!isReportsTeamMembersViewPath(location) ||
    !!isReportsPlansViewPath(location);
  const showExitTrainingModeButton = !!isTrainingModePath(location);
  const showCompleteButton =
    !!isTrainingToCompletePath(location) &&
    !isNil((location as LocationProps)?.state?.step?.reference);

  return (
    <RightHeader $otherWidth={otherWidth} $width={width} ref={rightHeaderRef}>
      {showManagePlansButtons && <ManageTeamMembersButtons />}
      {showExitTrainingModeButton && <TrainingModeExitButton />}
      {showCompleteButton && <DocumentCompleteButton />}
      {showPrintReportButton && <PrintReportButton />}
    </RightHeader>
  );
};

const RightHeader = styled.div<RightHeaderStyleProps>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: ${({ $width, $otherWidth }) =>
    $width >= $otherWidth ? 'auto' : `${$otherWidth}px`};
`;

export default RightHeaderContent;
