import { DocumentDTO } from '@cfacorp-pathway/xp-api-typescript-client';

/**
 * @param documentId: the document Id to toggle its visibility
 * @param documents: documents with correct order
 * @param visibleDocuments: current visible documents IDs (with correct order) before visibility toggle edit
 * @param isCurrentlyVisible: currently the document's visibility before toggle edit
 * @returns: updated ordered visible documents matching order in documents
 **/
export const getUpdatedToggleVisibleDocuments = (
  documentId: string,
  isCurrentlyVisible: boolean,
  visibleDocuments?: string[],
  documents?: DocumentDTO[],
): string[] => {
  if (isCurrentlyVisible) {
    return visibleDocuments?.length
      ? visibleDocuments?.filter(id => id !== documentId)
      : [];
  }
  if (!visibleDocuments?.length) {
    return [documentId];
  }
  const allVisibleDocuments = [...visibleDocuments, documentId];
  const orderedVisibleDocuments: string[] = [];
  documents?.forEach(({ id }) => {
    if (id && allVisibleDocuments.includes(id)) {
      orderedVisibleDocuments.push(id);
    }
  });
  return orderedVisibleDocuments.length === allVisibleDocuments.length
    ? orderedVisibleDocuments
    : allVisibleDocuments;
};

/**
 * @param documents: documents with correct order after drop
 * @param visibleDocuments: current visible documents IDs (with correct order) before drag and drop edit
 * @returns: updated ordered visible documents matching order in updated documents
 **/
export const getUpdatedDropVisibleDocuments = (
  visibleDocuments?: string[],
  updatedDocuments?: DocumentDTO[],
): DocumentDTO[] => {
  if (!visibleDocuments?.length || !updatedDocuments?.length) {
    return [];
  }

  const orderedVisibleDocuments: string[] = [];
  updatedDocuments?.forEach(({ id }) => {
    if (id && visibleDocuments.includes(id)) {
      orderedVisibleDocuments.push(id);
    }
  });

  return orderedVisibleDocuments as DocumentDTO[];
};
