import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Location } from 'history';

type ChangeFunction = (location?: Location, prevLocation?: Location) => void;

export const useLocationChange = (fn: ChangeFunction) => {
  const location = useLocation();
  const prevLocation = useRef<Location>();

  useEffect(() => {
    fn(location, prevLocation.current);
    prevLocation.current = location;
  }, [fn, location]);
};
