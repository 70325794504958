interface PrintJSParams {
  gridHeaderStyle: string;
  gridStyle: string;
  header: string;
  headerStyle?: string;
  footer?: string;
  footerStyle?: string;
  printable: Record<string, string>[];
  properties?: (string | Record<string, string>)[];
  type: string;
  repeatTableHeader?: boolean;
}

export function capitalizePrint(str: string) {
  return str?.charAt(0).toUpperCase() + str?.slice(1);
}

export function isRawHTML(raw: string) {
  const regexHtml = new RegExp('<([A-Za-z][A-Za-z0-9]*)\\b[^>]*>(.*?)</\\1>');
  return regexHtml.test(raw);
}

export function addHeader(printElement: HTMLDivElement, params: PrintJSParams) {
  // Create the header container div
  const headerContainer = document.createElement('div');

  // Check if the header is text or raw html
  if (isRawHTML(params.header)) {
    headerContainer.innerHTML = params.header;
  } else {
    // Create header element
    const headerElement = document.createElement('h1');

    // Create header text node
    const headerNode = document.createTextNode(params.header);

    // Build and style
    headerElement.appendChild(headerNode);
    headerElement.setAttribute('style', params.headerStyle ?? '');
    headerContainer.appendChild(headerElement);
  }

  printElement.insertBefore(headerContainer, printElement.childNodes[0]!);
}

export function addFooter(printElement: HTMLDivElement, params: PrintJSParams) {
  // Create the footer container div
  const footerContainer = document.createElement('div');

  // Check if the footer is text or raw html
  if (params.footer && isRawHTML(params.footer)) {
    footerContainer.innerHTML = params.footer;
  } else {
    // Create footer element
    const footerElement = document.createElement('h1');

    // Create footer text node
    const footerNode = document.createTextNode(params.footer ?? '');

    // Build and style
    footerElement.appendChild(footerNode);
    footerElement.setAttribute('style', params.footerStyle ?? '');
    footerContainer.appendChild(footerElement);
  }

  printElement.insertBefore(
    footerContainer,
    printElement.childNodes.item(printElement.childNodes.length - 1)!,
  );
}

export function printJsonToHTML(params: PrintJSParams) {
  const properties =
    params.properties?.map(property => {
      return {
        field: typeof property === 'object' ? property.field : property,
        displayName:
          typeof property === 'object' ? property.displayName : property,
        columnSize:
          typeof property === 'object' && property.columnSize
            ? property.columnSize + ';'
            : 100 / (params.properties?.length ?? 0) + '%;',
      };
    }) ?? [];
  // Get the row and column data
  const data = params.printable;

  // Create a html table
  let htmlData = '<table style="border-collapse: collapse; width: 100%;">';

  // Check if the header should be repeated
  if (params.repeatTableHeader) {
    htmlData += '<thead>';
  }

  // Add the table header row
  htmlData += '<tr>';

  // Add the table header columns
  for (let a = 0; a < properties.length; a++) {
    htmlData +=
      '<th style="width:' +
      properties[a]!.columnSize +
      ';' +
      params.gridHeaderStyle +
      '">' +
      capitalizePrint(properties[a]!.displayName!) +
      '</th>';
  }

  // Add the closing tag for the table header row
  htmlData += '</tr>';

  // If the table header is marked as repeated, add the closing tag
  if (params.repeatTableHeader) {
    htmlData += '</thead>';
  }

  // Create the table body
  htmlData += '<tbody>';

  // Add the table data rows
  for (let i = 0; i < data.length; i++) {
    // Add the row starting tag
    htmlData += '<tr>';

    // Print selected properties only
    for (let n = 0; n < properties.length; n++) {
      let stringData: any = data[i];

      // Support nested objects
      const property =
        (properties[n]?.field && properties[n]?.field?.split('.')) || [];
      if (property?.length > 1) {
        for (let p = 0; p < property.length; p++) {
          stringData = stringData![property[p]!];
        }
      } else {
        stringData = stringData[properties[n]!.field!];
      }

      // Add the row contents and styles
      htmlData +=
        '<td style="width:' +
        properties[n]!.columnSize +
        params.gridStyle +
        '">' +
        stringData +
        '</td>';
    }

    // Add the row closing tag
    htmlData += '</tr>';
  }

  // Add the table and body closing tags
  htmlData += '</tbody></table>';
  return htmlData;
}

export const htmlFromPrintJson = (params: PrintJSParams) => {
  // Create a print container element
  const printableElement = document.createElement('div');

  // Check if we are adding a print header
  if (params.header) {
    addHeader(printableElement, params);
  }

  // Build the printable html data
  printableElement.innerHTML += printJsonToHTML(params);

  // Check if we are adding a print footer
  if (params.footer) {
    addFooter(printableElement, params);
  }
  return printableElement;
};
