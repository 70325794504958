import Constants from 'constants/index';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import uniqBy from 'lodash/uniqBy';
import { useGetReportsTrainingPlanStatusesQuery } from 'services/xpApi';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { UserChecklistStatusResponse } from '@cfacorp-pathway/xp-api-typescript-client';
import {
  useGetReportsAssignedProgressByPlanQuery,
  useGetReportsTeamMembersQuery,
} from '@/services/pathwayApi';
import { PlanDetails } from '@/types/types';
import { convertDateForReports } from '@/utils/date';
import { generateTotalTimeFromSteps } from '@/utils/time';

// For ReportPlansTab PrintReport, a regular plan report status and data to print
const useGetPlanReportData = (
  planDetails: PlanDetails,
  planLocations: string[],
  selectedReportLocations: string[],
) => {
  const { t } = useTranslation();
  const { xpApi: xpApiFeatureFlag } = useFlags();
  const planId = planDetails?.id;
  const isCompliance = planDetails?.isCompliance;
  const planDetailLocationLength = planDetails?.locations?.length;

  // Team Members
  const {
    data: oldReportsTeamMembersData,
    isFetching: oldReportsTeamMembersIsFetching,
    isSuccess: oldIsReportsTeamMembersDataSuccess,
    refetch: oldRefetchReportsTeamMembers,
  } = useGetReportsTeamMembersQuery(
    {
      locations: planLocations,
    },
    {
      skip: !!xpApiFeatureFlag,
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    data: xpReportsTeamMemberStatuses,
    isFetching: xpReportsTeamMembersStatusesIsFetching,
    isSuccess: xpReportsTeamMembersStatusesIsSuccess,
    refetch: refetchReportsTeamMembersStatuses,
  } = useGetReportsTrainingPlanStatusesQuery(
    {
      locations: planLocations,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !xpApiFeatureFlag,
    },
  );

  const reportsTeamMembersData: UserChecklistStatusResponse = xpApiFeatureFlag
    ? xpReportsTeamMemberStatuses
    : (oldReportsTeamMembersData as any);
  const isReportsTeamMembersFetching = xpApiFeatureFlag
    ? xpReportsTeamMembersStatusesIsFetching
    : oldReportsTeamMembersIsFetching;
  const isReportsTeamMembersDataSuccess = xpApiFeatureFlag
    ? xpReportsTeamMembersStatusesIsSuccess
    : oldIsReportsTeamMembersDataSuccess;
  const refetchReportsTeamMembers = xpApiFeatureFlag
    ? refetchReportsTeamMembersStatuses
    : oldRefetchReportsTeamMembers;

  // this content call will need to get converted to the xpApi proxy call as part of P-887
  const {
    data: assignedTeamMembersList,
    isFetching: isAssignedTeamMembersListFetching,
    isSuccess: isAssignedTeamMembersListSuccess,
  } = useGetReportsAssignedProgressByPlanQuery(
    {
      planId: planId,
    },
    {
      skip: !planId || isCompliance,
    },
  );

  const reportData = useMemo(
    () =>
      uniqBy(
        reportsTeamMembersData?.users
          ?.map(teamMember => {
            return {
              ...teamMember.user,
              plansAssigned: teamMember.plansAssigned,
              plansCompleted: teamMember.plansCompleted,
            };
          })
          ?.filter(teamMember1 => {
            return assignedTeamMembersList
              ?.status!.filter(
                teamMember =>
                  teamMember.status !== Constants.TRAINING_PLANS.UNASSIGNED,
              )
              .some(teamMember2 => teamMember1.adId === teamMember2.userId);
          })
          .map(user => {
            return {
              ...user,
              completionDate: assignedTeamMembersList?.status?.find(
                teamMember => teamMember?.userId === user?.adId,
              )?.completionDate,
              status: assignedTeamMembersList?.status?.find(
                teamMember => teamMember.userId === user.adId,
              )?.status,
              steps: assignedTeamMembersList?.status?.find(
                teamMember => teamMember.userId === user.adId,
              )?.steps,
            };
          }),
        'adId',
      ),
    [reportsTeamMembersData?.users, assignedTeamMembersList],
  );

  const reportDataToPrint = useMemo(() => {
    return uniqBy(
      reportData.filter((teamMember: any) =>
        planDetailLocationLength > 1 && teamMember?.locations?.length
          ? teamMember?.locations.some((location: string) =>
              selectedReportLocations.includes(location),
            )
          : teamMember,
      ),
      'adId',
    )
      .sort((teamMemberA: any, teamMemberB: any) => {
        return (teamMemberA.name ?? '').localeCompare(teamMemberB.name ?? '');
      })
      .map((teamMember: any) => {
        return {
          [Constants.REPORT_TABLE.NAME]: teamMember.name,
          [Constants.REPORT_TABLE.ITEMS_COMPLETED]: `${
            assignedTeamMembersList?.status?.find(
              userStatus => userStatus.userId === teamMember.adId,
            )?.stepsComplete || 0
          }/${assignedTeamMembersList?.checklist?.stepsTotal}`,
          [Constants.REPORT_TABLE.TOTAL_TIME_SPENT_ON_PLAN]:
            generateTotalTimeFromSteps(
              teamMember.steps!,
              t('Generic.hour'),
              t('Generic.mins'),
            ),
          [Constants.REPORT_TABLE.STATUS]: t(
            `TrainingPlans.statusOptions.${
              Constants.TRAINING_PLANS_STATUSES[
                teamMember.status as keyof typeof Constants.TRAINING_PLANS_STATUSES
              ]
            }`,
          ),
          [Constants.REPORT_TABLE.COMPLETION_DATE]: teamMember.completionDate
            ? convertDateForReports(teamMember.completionDate)
            : t('Generic.na'),
          id: assignedTeamMembersList?.checklist?.id,
        };
      });
  }, [
    assignedTeamMembersList?.checklist?.id,
    assignedTeamMembersList?.checklist?.stepsTotal,
    assignedTeamMembersList?.status,
    planDetailLocationLength,
    reportData,
    selectedReportLocations,
    t,
  ]);

  return {
    reportData,
    reportDataToPrint,
    refetchReportsTeamMembers,
    isReportsTeamMembersFetching,
    isReportsTeamMembersDataSuccess,
    isAssignedTeamMembersListFetching,
    isAssignedTeamMembersListSuccess,
  };
};

export default useGetPlanReportData;
