import Constants from 'constants/index';
import { useTranslation } from 'react-i18next';
import { Ribbon } from 'icons';
import { IconAlertTriangleFilled } from '@tabler/icons-react';
import theme from 'styles/theme';
import styled from 'styled-components';
import PlanCardIcon from '../PlanCardComponents/PlanCardIcon';
import PlanCardTag from '../PlanCardComponents/PlanCardTag';
import PlanCardContent from '../PlanCardContent/PlanCardContent';
import PlanCard from '../PlanCard';
import PlanCardTitle from '../PlanCardComponents/PlanCardTitle';
import PlanCardLabel from '../PlanCardComponents/PlanCardLabel';
import PlanCardSubHeader from '../PlanCardComponents/PlanCardSubHeader';
import { getDueDateColor, getDueDateString } from '@/utils/dueDate';
import { Certificate, LanguageObject } from '@/types/types';
import { getTimeSpentOnPlan } from '@/utils/time';
import { getNameFromLanguage } from '@/utils/language';
import { generateProgressText } from '@/utils/generateProgressText';
import { getProgressBarText } from '@/utils/generateProgressBarText';
import { mapCategoryToAliasTranslation } from '@/utils/categoryUtils';

interface Course {
  certificate: Certificate;
  courseName: LanguageObject;
  createdDate: string;
  enabled: boolean;
  id: string;
  completedDate: string;
  dueDate: number | string;
  duration: number | string;
  enrolledDate: string;
  enrollmentId: string;
  finalScore: number | string;
  hidden: boolean;
  mostRecentCompletedDate: string;
  pathwayCourseId: string;
  percentComplete: number | string;
  startedDate: string;
  status: string;
}
interface MyPlansCompliancePlanCardProps {
  course: Course;
}

const MyPlansCompliancePlanCard: React.FC<MyPlansCompliancePlanCardProps> = ({
  course,
}) => {
  const { t } = useTranslation();
  const timeSpentOnPlan = getTimeSpentOnPlan(course as any, true);
  const isComplete =
    course?.status === Constants.LEARN_UPON_TRAINING_PLANS.COMPLETED ||
    course?.status === Constants.LEARN_UPON_TRAINING_PLANS.PASSED;
  const isFailed =
    course?.status === Constants.LEARN_UPON_TRAINING_PLANS.FAILED;
  const finalScore = course?.finalScore;
  const isCompliance = true;
  const isFoodSafety = getNameFromLanguage(course?.courseName)?.includes(
    Constants.FOOD_SAFETY_COURSE_NAME,
  );
  const percentComplete = course?.percentComplete;

  return (
    <>
      {!!course && (
        <>
          <PlanCard
            category={Constants.PLAN_CATEGORIES.COMPLIANCE}
            dataTestId={'MyCompliancePlan'}
            link={`/${Constants.ROUTE_PATH_NAMES.COMPLIANCE_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.DOCUMENT_PATH_NAME}/${course?.pathwayCourseId}/true`}
            progress={
              !!isComplete || !!isFailed ? null : (percentComplete as number)
            }
          >
            <PlanCardContent>
              <PlanCardLabel>
                {t(
                  mapCategoryToAliasTranslation(
                    Constants.PLAN_CATEGORIES.COMPLIANCE,
                  ),
                )}
              </PlanCardLabel>
              <PlanCardTitle>
                {getNameFromLanguage(course?.courseName)}
              </PlanCardTitle>
              {!!isComplete && (
                <>
                  <PlanCardSubHeader
                    className="success"
                    dataTestId="PlanTaskCount"
                  >
                    {generateProgressText({
                      finalScore: finalScore as string,
                      isCompliance,
                      isComplete,
                      isFailed,
                      isFoodSafety,
                      timeSpentOnPlan: timeSpentOnPlan!,
                    })}
                  </PlanCardSubHeader>
                  <PlanCardSubHeader className="bold">
                    {course?.certificate?.expiration ? (
                      t('TrainingPlans.certificationExpires', {
                        expirationDate: new Date(
                          course?.certificate?.expiration,
                        ).toLocaleDateString(),
                      })
                    ) : (
                      <>
                        <FailedIconAlertTriangleFilled />
                        <span style={{ marginLeft: 4 }}>
                          {t('TrainingPlans.noExpirationDate')}
                        </span>
                      </>
                    )}
                  </PlanCardSubHeader>
                </>
              )}
              {!!isFailed && (
                <PlanCardSubHeader className="bold" dataTestId="PlanTaskCount">
                  {generateProgressText({
                    finalScore: finalScore as string,
                    isCompliance,
                    isComplete,
                    isFailed,
                    isFoodSafety,
                    timeSpentOnPlan: timeSpentOnPlan!,
                  })}
                </PlanCardSubHeader>
              )}
              {!isComplete && !isFailed && (
                <PlanCardSubHeader dataTestId="PlanTaskCount">
                  {getProgressBarText({
                    isCompliance,
                    isComplete,
                    percentComplete: percentComplete!,
                    timeSpentOnPlan: timeSpentOnPlan!,
                  })}
                </PlanCardSubHeader>
              )}
            </PlanCardContent>
            {!isComplete && !!course?.dueDate && (
              <PlanCardTag
                color={getDueDateColor(course?.dueDate)}
                text={getDueDateString(course?.dueDate)}
                tooltip={t('TrainingPlans.accountability.due.dueDate', {
                  dueDate: new Date(course?.dueDate).toLocaleDateString(),
                })}
              />
            )}
            {!!isComplete && (
              <PlanCardIcon
                icon={<CompleteRibbon />}
                tooltip={
                  <>
                    <strong>
                      {t('TrainingPlans.statusOptions.completed')}:
                    </strong>
                    {new Date(course?.completedDate).toLocaleDateString()}
                  </>
                }
              />
            )}
          </PlanCard>
        </>
      )}
    </>
  );
};

const CompleteRibbon = styled(Ribbon)`
  height: 28px;
  width: 28px;
`;
export const FailedIconAlertTriangleFilled = styled(IconAlertTriangleFilled)`
  height: 16px;
  width: 16px;
  color: ${() => theme.semanticColors.warning};
`;

export default MyPlansCompliancePlanCard;
