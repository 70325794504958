import { useMemo, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Constants from '@/constants';
import {
  selectAllLocationsWithAtLeastLeaderPermissions,
  selectLocationsWithOperatorPermission,
  selectLocationsWithTrainerPermission,
  isUserLicensee,
} from '@/store/user/selectors';
import { selectActiveLicenseeLocation } from '@/store/licenseeLocationPicker/selector';
import { setManagePlans } from '@/store/managePlansFilter/slice';
import {
  useGetAssignableChecklistsQuery,
  useGetIsCourseEnabledQuery,
  useGetOperatorsQuery,
  useGetCourseReportQuery,
} from '@/services/pathwayApi';
import { LanguageObject } from '@/types/types';
import { getNameFromLanguage } from '@/utils/language';
import { arrayIntersect } from '@/utils/keepDuplicatesFromTwoArrays';
import { convertDateForReports } from '@/utils/date';
import { getCompletionDate } from '@/utils/reports';

// For ManagePlanTab setManagePlans to redux and return report data to print
const useGetManagePlansData = (
  planId: string,
  selectedReportLocations: string[],
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isLicenseeUser = useSelector(isUserLicensee);
  const selectedLicenseeLocation = useSelector(selectActiveLicenseeLocation);
  const locationsWithAtLeastLeaderPermission = useSelector(
    selectAllLocationsWithAtLeastLeaderPermissions,
  );
  const locationsWithAtLeastTrainerPermission = useSelector(
    selectLocationsWithTrainerPermission,
  );
  const locationsWithAtLeastOperatorPermission = useSelector(
    selectLocationsWithOperatorPermission,
  );

  const [statusReport, setStatusReport] = useState({
    courseId: '',
    courseName: '',
    foodSafetyImage: '',
    locations: [''],
    timeStamp: '',
    userData: [],
  });

  const { data: operators } = useGetOperatorsQuery();

  const OperatorsOfLocationsWithAtLeastLeader = operators?.filter(
    operator =>
      arrayIntersect(operator?.locations!, locationsWithAtLeastLeaderPermission)
        .length > 0,
  );

  const { data: complianceStatusData, refetch: refetchIsCourseEnabled } =
    useGetIsCourseEnabledQuery(
      // @ts-ignore
      OperatorsOfLocationsWithAtLeastLeader?.[0]?.id ?? operators?.[0]?.adId,
      {
        skip: !operators?.length,
      },
    );

  const {
    data: unOrderedPlans,
    isFetching: isFetchingPlans,
    isSuccess: isSuccessPlans,
    error: plansDataError,
    refetch: refetchPlans,
  } = useGetAssignableChecklistsQuery();

  const isTrainerOrLeaderOrOperator =
    !!locationsWithAtLeastTrainerPermission.length ||
    !!locationsWithAtLeastLeaderPermission.length ||
    !!locationsWithAtLeastOperatorPermission.length;

  const planIsLearnUponDocumentUuid =
    planId?.match(Constants.UUID_REGEX_MATCH)?.length! > 0;

  const {
    data: statusReportData,
    isSuccess: isStatusReportSuccess,
    isFetching: isStatusReportFetching,
  } = useGetCourseReportQuery(
    {
      courseId: planId,
      location: selectedReportLocations,
    },
    {
      skip:
        !planId ||
        !isTrainerOrLeaderOrOperator ||
        !planIsLearnUponDocumentUuid ||
        !selectedReportLocations.length,
      refetchOnMountOrArgChange: true,
    },
  );

  // Set Plans
  useEffect(() => {
    if (!isSuccessPlans) {
      return;
    }
    const storePlansWithName = unOrderedPlans?.checklists?.map(plan => {
      return {
        ...plan,
        planName: getNameFromLanguage(
          plan?.checklist?.name as LanguageObject,
        ).toLowerCase(),
      };
    });
    if (isLicenseeUser) {
      const licenseeLocationPlans = storePlansWithName?.filter(plan => {
        return plan?.checklist?.ownerId === selectedLicenseeLocation.number;
      });
      dispatch(setManagePlans({ plans: licenseeLocationPlans ?? [] }));
    } else {
      dispatch(
        setManagePlans({
          plans:
            storePlansWithName?.concat(unOrderedPlans?.courses as any) ?? [],
        }),
      );
    }
  }, [
    dispatch,
    isSuccessPlans,
    isLicenseeUser,
    selectedLicenseeLocation.number,
    unOrderedPlans,
  ]);

  // we want to call api on each page load, no caching
  useEffect(() => {
    refetchPlans();
  }, [refetchPlans]);

  // Check if compliance plan is enabled
  useEffect(() => {
    if (complianceStatusData && unOrderedPlans) {
      const compliancePlansWithStatus = unOrderedPlans?.courses
        ?.filter(plan => plan?.courseID)
        .map(course => ({
          ...course,
          enabled: complianceStatusData?.[course?.id!]?.enabled ?? false,
        }));
      const plansWithoutCompliance = unOrderedPlans?.checklists?.filter(
        plan => !(plan as any)?.courseID,
      );

      if (isLicenseeUser) {
        const licenseeLocationPlans = plansWithoutCompliance?.filter(plan => {
          return plan?.checklist?.ownerId === selectedLicenseeLocation.number;
        });
        dispatch(setManagePlans({ plans: licenseeLocationPlans ?? [] }));
      } else {
        dispatch(
          setManagePlans({
            plans: plansWithoutCompliance?.concat(
              compliancePlansWithStatus as any,
            ),
          }),
        );
      }
    }
  }, [
    complianceStatusData,
    dispatch,
    isLicenseeUser,
    selectedLicenseeLocation.number,
    unOrderedPlans,
  ]);

  // Status Report
  useEffect(() => {
    if (isStatusReportSuccess) {
      // @ts-ignore
      setStatusReport(statusReportData);
    }
  }, [isStatusReportSuccess, statusReportData]);

  const statusReportToPrint = useMemo(() => {
    return [...(statusReport?.userData ?? [])]
      .sort((teamMemberA: any, teamMemberB: any) => {
        return teamMemberA?.firstName?.localeCompare(teamMemberB?.firstName);
      })
      .map((report: any) => {
        return {
          [Constants.REPORT_TABLE.NAME]:
            `${report.firstName} ${report.lastName}`,
          [Constants.REPORT_TABLE.DATE_OF_BIRTH]: report.birthDate
            ? report.birthDate
            : t('Generic.na'),
          [Constants.REPORT_TABLE.COMPLETION_DATE]: getCompletionDate(report),
          [Constants.REPORT_TABLE.EXPIRATION_DATE]:
            report?.certificate?.expiration &&
            report?.courseStatus !==
              Constants.LEARN_UPON_TRAINING_PLAN_STATUSES.failed
              ? convertDateForReports(report.certificate.expiration)
              : t('Generic.na'),
        };
      });
  }, [statusReport, t]);

  const resetStatusReport = useCallback(() => {
    setStatusReport({
      courseId: '',
      courseName: '',
      foodSafetyImage: '',
      locations: [''],
      timeStamp: '',
      userData: [],
    });
  }, []);

  return {
    resetStatusReport,
    refetchIsCourseEnabled,
    refetchPlans,
    isFetchingPlans,
    isStatusReportFetching,
    plansDataError,
    statusReport,
    statusReportToPrint,
  };
};

export default useGetManagePlansData;
