import Constants from 'constants/index';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import {
  selectHeader,
  selectHeaderLabel,
  selectHeaderSubtext,
} from 'store/header/selectors';
import {
  Surface,
  Typography,
  useBreakpoints,
  useMediaQuery,
} from 'cfa-react-components';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useIsNative } from 'hooks/useIsNative';
import RightHeaderContent from './RightHeaderContent/RightHeaderContent';
import LeftHeaderContent from './LeftHeaderContent/LeftHeaderContent';
import {
  isLeadershipPath,
  isReportsPlansViewPath,
  isTrainingAssignedPath,
  isTrainingEditQuizzesPath,
  isTrainingPlanEditPath,
  isTrainingTeamMembersPath,
  isDocumentPath,
  isLeadershipDocumentPath,
  isReportsTeamMembersViewPath,
} from '@/utils/url';
import { useDeviceInfo } from '@/utils/device';

const Header = () => {
  const [leftWidth, setLeftWidth] = useState(0);
  const [rightWidth, setRightWidth] = useState(0);
  const isNative = useIsNative();
  const headerLabel = useSelector(selectHeaderLabel);
  const headerName = useSelector(selectHeader);
  const headerSubtext = useSelector(selectHeaderSubtext);
  const { isDesktop } = useDeviceInfo();
  const location = useLocation();
  const breakpoints = useBreakpoints();
  const isSmAndDown = useMediaQuery(breakpoints.down('sm'));
  const { ascendNav: featureflagAscendNav } = useFlags();

  const onLeftReportWidth = (width: number) => {
    if (width !== leftWidth) {
      setLeftWidth(width);
    }
  };

  const onRightReportWidth = (width: number) => {
    if (width !== rightWidth) {
      setRightWidth(width);
    }
  };

  const pathsToShowHeaderInNative =
    isTrainingPlanEditPath(location) ||
    isTrainingTeamMembersPath(location) ||
    isTrainingAssignedPath(location) ||
    isTrainingEditQuizzesPath(location) ||
    isReportsPlansViewPath(location) ||
    isReportsTeamMembersViewPath(location);

  const hideHeader =
    (isLeadershipPath(location) &&
      featureflagAscendNav &&
      !isLeadershipDocumentPath(location)) ||
    (isNative && !pathsToShowHeaderInNative) ||
    (isDocumentPath(location) && !isLeadershipDocumentPath(location)) ||
    (isLeadershipDocumentPath(location) && isSmAndDown);

  return (
    <>
      {!hideHeader && (
        <HeaderSurface $isDesktop={isDesktop} $isSmAndDown={isSmAndDown}>
          <LeftHeaderContent
            onWidth={onLeftReportWidth}
            otherWidth={rightWidth}
          />
          <HeaderWrapper>
            <Typography variant="overline1">{headerLabel}</Typography>
            <Typography data-testid="PageHeader" variant="h2">
              {headerName}
            </Typography>
            <Typography variant="overline2">{headerSubtext}</Typography>
          </HeaderWrapper>
          <RightHeaderContent
            onWidth={onRightReportWidth}
            otherWidth={leftWidth}
          />
        </HeaderSurface>
      )}
    </>
  );
};

const HeaderSurface = styled(Surface)`
  top: ${({ $isDesktop }) =>
    $isDesktop ? '0' : Constants.HEIGHT.MOBILE_TOP_NAV};
  z-index: 3;
  display: flex;
  flex-direction: ${({ $isSmAndDown }) => ($isSmAndDown ? 'column' : 'row')};
  justify-content: space-between;
  align-items: center;
  width: 100%;
  overflow: visible;
  padding: 0 2rem;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-gap: 8px;
  padding: 32px;
`;

export default Header;
