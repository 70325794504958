import {
  ChecklistDTO,
  Document,
} from '@cfacorp-pathway/xp-api-typescript-client';
import { IconArrowLeft } from '@tabler/icons-react';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import StepWizard from 'react-step-wizard';
import {
  Button,
  IconButton,
  Modal,
  ModalBody,
  TextFieldType,
  Typography,
} from 'cfa-react-components';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import StepOneCategories from './StepOneCategories';
import StepTwoSubcategories from './StepTwoSubcategories';
import StepThreeSearchResults from './StepThreeSearchResults';
import { PopupDocument, StepWizardInstance } from './stepTypes';
import DocumentPreview from '@/components/DocumentPreview/DocumentPreview';
import Searchbar from '@/components/Searchbar/Searchbar';
import ScrollModalHeader from '@/components/ScrollModal/ScrollModalHeader';
import ScrollModalFooter from '@/components/ScrollModal/ScrollModalFooter';
import useBugsnagNotify from '@/hooks/useBugsnagNotify';
import {
  useGetAdminCategoriesQuery,
  useGetCategoriesQuery,
  useUpdateTrainingPlanMutation,
} from '@/services/pathwayApi';
import { resetSearchState } from '@/store/search/slice';
import GenericError from '@/components/Error/GenericError';
import {
  BaseReferenceDTO,
  IntlCategoryEntity,
} from '@/services/content-api-types';
import { CustomError } from '@/types/types';
import { isApiError } from '@/utils/request';

interface AddProcedurePopUpProps {
  isOpen: boolean;
  onClose: () => void;
  plan?: ChecklistDTO;
  refetch: () => void;
  isInSection?: boolean;
  sectionId?: string;
  onAdminAddProcedure?: (selectedDocuments: any[]) => void;
  adminAddingProcedure?: boolean;
  selectedCountryCode?: string; // country code value will only exist if imported from Admin component and is Admin user
}

const AddProcedurePopUp: React.FC<AddProcedurePopUpProps> = ({
  isOpen,
  onClose,
  plan = {},
  refetch,
  isInSection = false,
  sectionId = '',
  onAdminAddProcedure = () => {},
  adminAddingProcedure = false,
  selectedCountryCode,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { notifyBugsnag } = useBugsnagNotify();

  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const modalBodyRef = useRef<any>();
  const [categorySelected, setCategorySelected] = useState<string | null>(null);
  const [activeIndexStep, setActiveIndexStep] = useState(1);
  const [stepWizard, setStepWizard] = useState<StepWizardInstance>();
  const [selectedDocuments, setSelectedDocuments] = useState<PopupDocument[]>(
    [],
  );
  const [searchTermDisplayed, setSearchTermDisplayed] = useState('');
  const [searchTermQuery, setSearchTermQuery] = useState('');
  const [previewDocument, setPreviewDocument] = useState<
    Document | BaseReferenceDTO
  >({});

  useEffect(() => {
    dispatch(resetSearchState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchTermQuery) {
      stepWizard?.goToStep(3);
    }
  }, [searchTermQuery, stepWizard]);

  const [updateTrainingPlan] = useUpdateTrainingPlanMutation();
  const { data: categoriesData, error: categoriesError } =
    useGetCategoriesQuery(undefined, { skip: !!selectedCountryCode });
  const { data: adminCategoriesData, error: adminCategoriesError } =
    useGetAdminCategoriesQuery(
      { countryCode: selectedCountryCode! },
      { skip: !selectedCountryCode }, // selectedCountryCode will only exist if imported from Admin component and is Admin user
    );

  const data = categoriesData || adminCategoriesData;
  const error = categoriesError || adminCategoriesError;

  const categories: IntlCategoryEntity[] = data?.categories ?? [];
  const featured: IntlCategoryEntity[] = data?.featured ?? [];

  const handleAddAdminProcedure = () => {
    onAdminAddProcedure(selectedDocuments);
    setSelectedDocuments([]);
  };

  const onAddProcedure = () => {
    const mappedUuidToSelectedDocuments = selectedDocuments.map(
      selectedDocument => {
        return { ...selectedDocument, id: uuidv4() };
      },
    );
    const newSteps = [...plan?.steps!, ...mappedUuidToSelectedDocuments];
    const payload = {
      locations: plan.locations,
      checklist: isInSection
        ? {
            ...plan,
            sections: [
              ...plan.sections!.map(section =>
                section.id === sectionId
                  ? {
                      ...section,
                      steps: [
                        ...section.steps!,
                        ...mappedUuidToSelectedDocuments,
                      ],
                    }
                  : section,
              ),
            ],
          }
        : { ...plan, steps: newSteps },
    };

    updateTrainingPlan(payload)
      .unwrap()
      .then(() => {
        refetch();
        onClose();
        setSelectedDocuments([]);
      });
  };

  const onClearSearchClick = () => {
    setSearchTermDisplayed('');
    searchInputRef.current?.focus();
  };

  const onSearchInputChange = (e: React.ChangeEvent<TextFieldType>) => {
    const searchTerm = e.target.value;
    searchTerm.length
      ? setSearchTermDisplayed(searchTerm)
      : setSearchTermDisplayed('');
  };

  const onSearchTermSelect = (
    suggestion: string | null,
    spellingSuggestion?: string,
  ) => {
    setActiveIndexStep(2);
    if (spellingSuggestion) {
      setSearchTermDisplayed(spellingSuggestion);
      setSearchTermQuery(spellingSuggestion);
      return;
    }
    if (suggestion) {
      setSearchTermDisplayed(suggestion);
      setSearchTermQuery(suggestion);
      return;
    }
    const searchTerm = searchTermDisplayed?.trim();
    if (searchTerm?.length) {
      setSearchTermDisplayed(searchTerm);
      setSearchTermQuery(searchTerm);
    }
  };

  const currentCount = selectedDocuments.length;

  if (isApiError(error as CustomError)) {
    notifyBugsnag(error);
    return <GenericError />;
  }

  const onBackButtonClick = () => {
    if (searchTermQuery && activeIndexStep === 3) {
      setSearchTermQuery('');
      setSearchTermDisplayed('');
      stepWizard?.goToStep(1);
      setActiveIndexStep(1);
      return;
    }
    if (searchTermQuery && activeIndexStep === 4) {
      stepWizard?.goToStep(3);
      setActiveIndexStep(3);
      return;
    }
    if (!searchTermQuery && activeIndexStep === 4) {
      stepWizard?.goToStep(2);
      setActiveIndexStep(2);
      return;
    }
    activeIndexStep > 1 ? stepWizard?.previousStep() : onClose();
    const step = stepWizard?.state?.activeStep + 1;
    setActiveIndexStep(step ?? 0);
  };

  const handleOnClose = () => {
    onClose();
    dispatch(resetSearchState());
    setSearchTermQuery('');
    setSearchTermDisplayed('');
    setSelectedDocuments([]);
  };

  const handleDocumentClick = (doc: Document | BaseReferenceDTO) => {
    setPreviewDocument(doc);
    stepWizard?.goToStep(4);
  };

  const isPreviewResource = activeIndexStep === 4;

  return (
    <Modal
      onClose={handleOnClose}
      scrollMode="modal-body"
      show={isOpen}
      size="lg"
    >
      <ScrollModalHeader
        scrollRef={modalBodyRef}
        showCloseButton={!isPreviewResource}
      >
        <ModalHeaderRow>
          {activeIndexStep > 1 ? (
            <IconButton data-testid="BackButton" onClick={onBackButtonClick}>
              <IconArrowLeft />
            </IconButton>
          ) : (
            <HeaderSpacer />
          )}
          {isPreviewResource ? (
            <PreviewHeader>
              <Typography variant="overline2">
                {t('TrainingPlans.preview')}
              </Typography>
              <>{previewDocument?.name}</>
            </PreviewHeader>
          ) : (
            t('Generic.chooseResources')
          )}
          <HeaderSpacer />
        </ModalHeaderRow>
        {!isPreviewResource && (
          <AddProcedureSearchbar
            elevation={1}
            fullWidth
            onChange={onSearchInputChange}
            onClear={onClearSearchClick}
            onSubmit={onSearchTermSelect}
            placeholder={t('TrainingPlans.addResource.placeholder')}
            ref={searchInputRef}
            searchValue={searchTermDisplayed}
            showRecentSearchDropdown
          />
        )}
      </ScrollModalHeader>
      <ModalBody ref={modalBodyRef}>
        <StyledProceduresWrapper $currentStep={activeIndexStep}>
          <StepWizard
            // type is broken in the package
            instance={(instance: any) => setStepWizard(instance)}
            isLazyMount
            onStepChange={({ activeStep }) => setActiveIndexStep(activeStep)}
            transitions={{
              enterRight: '',
              enterLeft: '',
              exitRight: '',
              exitLeft: '',
            }}
          >
            {/* Step 1 */}
            <StepOneCategories
              categories={categories}
              featured={featured}
              setCategorySelected={setCategorySelected}
              setSearchTermDisplayed={setSearchTermDisplayed}
              stepWizard={stepWizard!}
            />
            {/* Step 2 */}
            <StepTwoSubcategories
              categorySelected={categorySelected}
              onDocumentClick={handleDocumentClick}
              selectedCountryCode={selectedCountryCode}
              selectedDocuments={selectedDocuments}
              setSelectedDocuments={setSelectedDocuments}
            />
            {/* Step 3 */}
            <StepThreeSearchResults
              onDocumentClick={handleDocumentClick}
              onSearchTermSelect={onSearchTermSelect}
              searchTermQuery={searchTermQuery}
              selectedCountryCode={selectedCountryCode}
              selectedDocuments={selectedDocuments}
              setSelectedDocuments={setSelectedDocuments}
            />
            {/* Step 4 */}
            <DocumentPreview previewDocument={previewDocument} />
          </StepWizard>
        </StyledProceduresWrapper>
      </ModalBody>
      {!isPreviewResource && (
        <ScrollModalFooter scrollRef={modalBodyRef}>
          <Button
            color="secondary"
            data-testid="addProcedureButton"
            disabled={currentCount === 0}
            onClick={
              adminAddingProcedure ? handleAddAdminProcedure : onAddProcedure
            }
          >
            {t('Button.addXObjects', {
              count: currentCount,
              object:
                currentCount === 1
                  ? t('Generic.resource')
                  : t('Generic.resources'),
            })}
          </Button>
        </ScrollModalFooter>
      )}
    </Modal>
  );
};

const PreviewHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const ModalHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const HeaderSpacer = styled.div`
  width: 24px;
`;

const AddProcedureSearchbar = styled(Searchbar)`
  margin-top: 24px;
  margin-bottom: 8px;
  z-index: 5;
`;

const StyledProceduresWrapper = styled.div<any>`
  margin: 16px 0;
  ${({ $currentStep }) =>
    $currentStep === 4 &&
    `height: calc(100% - 32px);
  & div {
    height: 100%;
  }`}
`;

export default AddProcedurePopUp;
