import { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { UserChecklistResultDTO } from '@cfacorp-pathway/xp-api-typescript-client';
import { generateTotalTimeFromSteps } from '../time';
import { convertDateForReports } from '../date';
import Constants from '@/constants';
import { selectAllTeamMembers } from '@/store/teamMembersFilter/selector';
import { TeamMembers } from '@/types';

/**
 * For ManagePlanView and ReportsPlanView PrintReport,
 * get store reportDataToPrint from filtered team members (if not compliance)
 */
const useGetStoreReportToPrint = (
  stepCount: number,
  selectedReportLocations?: string[],
  assignedTeamMembersList?: UserChecklistResultDTO,
  itemsCompleted?: boolean,
) => {
  const { t } = useTranslation();

  const allTeamMembers = useSelector(selectAllTeamMembers);

  const [filteredReportTeamMembers, setFilteredReportTeamMembers] =
    useState<TeamMembers[]>();

  // filter team members for reports
  useEffect(() => {
    if (selectedReportLocations?.length) {
      const filteredTeamMembers = allTeamMembers?.filter(teamMember => {
        const { locations } = teamMember;
        const hasLocation = (locations as [])?.some(loc =>
          selectedReportLocations.includes(loc),
        );
        return hasLocation;
      });
      setFilteredReportTeamMembers(filteredTeamMembers);
    } else {
      setFilteredReportTeamMembers(allTeamMembers);
    }
  }, [allTeamMembers, selectedReportLocations]);

  const storeReportToPrint = useMemo(() => {
    return [...(filteredReportTeamMembers ?? [])]
      .sort((teamMemberA, teamMemberB) => {
        return teamMemberA?.name?.localeCompare(teamMemberB?.name);
      })
      .map(teamMember => {
        return {
          [Constants.REPORT_TABLE.NAME]: teamMember.name,
          [itemsCompleted
            ? Constants.REPORT_TABLE.ITEMS_COMPLETED
            : Constants.REPORT_TABLE.COMPLETED]: `${
            assignedTeamMembersList?.status?.find(
              userStatus => userStatus.userId === teamMember.userId,
            )?.stepsComplete || 0
          }/${stepCount}`,
          [Constants.REPORT_TABLE.TOTAL_TIME_SPENT_ON_PLAN]:
            generateTotalTimeFromSteps(
              teamMember.steps,
              t('Generic.hour'),
              t('Generic.mins'),
            ),
          [Constants.REPORT_TABLE.STATUS]: t(
            `TrainingPlans.statusOptions.${
              // @ts-ignore
              Constants.TRAINING_PLANS_STATUSES[teamMember.status]
            }`,
          ),
          [Constants.REPORT_TABLE.COMPLETION_DATE]: teamMember.completionDate
            ? convertDateForReports(teamMember.completionDate)
            : t('Generic.na'),
        };
      });
  }, [
    filteredReportTeamMembers,
    assignedTeamMembersList,
    stepCount,
    itemsCompleted,
    t,
  ]);

  return storeReportToPrint;
};

export default useGetStoreReportToPrint;
