import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useBreakpoints, useMediaQuery } from 'cfa-react-components';
import { ChecklistDTO } from '@cfacorp-pathway/xp-api-typescript-client';
import Constants from '@/constants';
import LoadingOverlay from '@/components/LoadingOverlay/LoadingOverlay';
import GenericError from '@/components/Error/GenericError';
import { withRoles } from '@/components/ConfirmationModal/withRoles';
import { useSortOptions } from '@/hooks/useSortOptions';
import useBugsnagNotify from '@/hooks/useBugsnagNotify';
import useGetStatusLabels from '@/hooks/useGetStatusLabels';
import {
  setHeader,
  setHeaderLabel,
  setHeaderSubtext,
} from '@/store/header/slice';
import {
  hideTrainingMode,
  setAssignedChecklistStatus,
} from '@/store/trainingMode/slice';
import {
  setTeamMembers,
  clearTeamMembersCheckboxFilters,
  clearTeamMembersSearchFilter,
} from '@/store/teamMembersFilter/slice';
import {
  selectAllTeamMembers,
  selectFilters,
  selectSort,
  selectSortedAndFilteredAndPaginatedTeamMembers,
} from '@/store/teamMembersFilter/selector';
import { printReport } from '@/store/printReport/selectors';
import {
  isUserLicensee,
  selectAllLocationsWithAtLeastTrainer,
} from '@/store/user/selectors';
import { selectActiveLicenseeLocation } from '@/store/licenseeLocationPicker/selector';
import { selectTrainingModeModalIsOpen } from '@/store/trainingMode/selectors';
import {
  useGetAssignedChecklistByIdQuery,
  useGetAssignedCoursesQuery,
  useGetAssignedStatusQuery,
  useGetOperatorsQuery,
  useGetTeamMembersQuery,
  useGetTrainingPlanQuery,
} from '@/services/pathwayApi';
import { LanguageObject } from '@/types/types';
import { isApiError } from '@/utils/request';
import { getNameFromLanguage } from '@/utils/language';
import { arrayIntersect } from '@/utils/keepDuplicatesFromTwoArrays';
import { mapCategoryToAliasTranslation } from '@/utils/categoryUtils';
import { generateTotalTime } from '@/utils/time';
import useGetCourseReportData from '@/utils/reportPrint/useGetCourseReportData';
import Modals from './Modals';
import AddTeamMemberFab from './AddTeamMemberFab';
import PrintReport from './PrintReport';
import TeamMembers from './TeamMembers/TeamMembers';
import FilterAndSort from './FilterAndSort';
import { useFlags } from 'launchdarkly-react-client-sdk';

const ManagePlanView = () => {
  const { notifyBugsnag } = useBugsnagNotify();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { search, state } = useLocation<{ refetch: () => void }>();
  const { plansRedesign: plansRedesignFeatureFlag } = useFlags();
  const { id: planId } = useParams<{ id: string }>();
  const breakpoints = useBreakpoints();
  const isSmAndDown = useMediaQuery(breakpoints.down('sm'));

  const isCompliance = search.includes('compliancePlan');
  const sortOptions = useSortOptions({ managePlanView: true });
  const isFoodSafety =
    decodeURI(search).includes(Constants.FOOD_SAFETY_COURSE_NAME) &&
    search.includes('compliancePlan');

  const isLicenseeUser = useSelector(isUserLicensee);
  const activeLicenseeLocation = useSelector(selectActiveLicenseeLocation);
  const currentFilters = useSelector(selectFilters) as string[];
  const sort = useSelector(selectSort);
  const locationsWithAtLeastTrainer = useSelector(
    selectAllLocationsWithAtLeastTrainer,
  );
  const trainingModeModalIsOpen = useSelector(selectTrainingModeModalIsOpen);
  const allTeamMembers = useSelector(selectAllTeamMembers) as any;
  const teamMembers = useSelector(
    selectSortedAndFilteredAndPaginatedTeamMembers,
  );
  const isPrintReportOpen = useSelector(printReport);

  const [showAddTeamMembersMenu, setShowAddTeamMembersMenu] = useState(false);
  const [showAddTeamMembersPopup, setShowAddTeamMembersPopup] = useState(false);
  const [showDueDatePopup, setShowDueDatePopup] = useState(false);
  const [showSelectLanguagePopup, setShowSelectLanguagePopup] = useState(false);
  const [showChooseReportLocationsPopup, setShowChooseReportLocationsPopup] =
    useState(false);
  const [selectedReportLocations, setSelectedReportLocations] = useState<
    string[]
  >([]);
  const [noLocationsSelected, setNoLocationsSelected] = useState(false);
  const [showTrainingModePopup, setShowTrainingModePopup] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [showUnassignTeamMemberPopup, setShowUnassignTeamMemberPopup] =
    useState(false);
  const [activeTeamMember, setActiveTeamMember] = useState({
    id: '',
    name: '',
  });
  const [stepCount, setStepCount] = useState(0);
  const [isDueDateUpdate, setIsDueDateUpdate] = useState(false);

  const statusLabels = useGetStatusLabels();

  const { data, isFetching, error } = useGetTeamMembersQuery(
    {
      locations: locationsWithAtLeastTrainer,
    },
    { refetchOnMountOrArgChange: true },
  );

  const {
    data: assignedTeamMembersList,
    isLoading: isFetchingAssignedTeamMembers,
    error: assignedTeamMemberError,
    isSuccess,
    refetch: refetchAssignedTeamMembers,
  } = useGetAssignedStatusQuery(
    {
      checklist: planId,
      location: locationsWithAtLeastTrainer,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !planId || isCompliance,
    },
  );

  const {
    data: plan,
    isLoading: isFetchingPlan,
    error: errorGettingPlan,
  } = useGetTrainingPlanQuery(
    {
      id: planId,
    },
    {
      skip: !planId || isCompliance,
    },
  );

  const {
    data: complianceStatus,
    isSuccess: isAssignedCoursesSuccess,
    refetch: refetchAssignedComplianceTeamMembers,
  } = useGetAssignedCoursesQuery(
    {
      courseId: planId,
      location: locationsWithAtLeastTrainer,
    },
    { skip: !planId || !isCompliance },
  );

  const { data: operators } = useGetOperatorsQuery();
  const operatorLocations = operators?.find(
    operator => operator.id === (plan as any)?.ownerId,
  )?.locations;

  const filterLocations = useMemo(() => {
    if (isLicenseeUser) {
      return [activeLicenseeLocation.number];
    } else if (isCompliance) {
      return locationsWithAtLeastTrainer;
    } else {
      return (
        arrayIntersect(locationsWithAtLeastTrainer, operatorLocations ?? []) ??
        []
      );
    }
  }, [
    activeLicenseeLocation.number,
    isCompliance,
    isLicenseeUser,
    locationsWithAtLeastTrainer,
    operatorLocations,
  ]);

  const {
    statusReportData,
    isStatusReportFetching,
    refetchCourseReportData,
    statusReportToPrint: complianceReportToPrint,
  } = useGetCourseReportData(
    planId,
    selectedReportLocations,
    Boolean(isCompliance && selectedReportLocations.length),
    true, // use Content Api
  );

  const { data: assignedChecklist, refetch: refetchAssignedChecklist } =
    useGetAssignedChecklistByIdQuery(planId, {
      refetchOnMountOrArgChange: true,
      skip: !planId || isCompliance,
    });

  // Clear Team Members Filters
  useEffect(() => {
    dispatch(clearTeamMembersCheckboxFilters());
    dispatch(clearTeamMembersSearchFilter());
  }, [dispatch]);

  useEffect(() => {
    if (assignedChecklist) {
      const assignedChecklistStatus = !assignedChecklist?.status
        ?.filter(it => it.status !== Constants.TRAINING_PLANS.UNASSIGNED)
        .every(user => user.status === Constants.TRAINING_PLANS.COMPLETED);
      dispatch(setAssignedChecklistStatus(assignedChecklistStatus));
    }
  }, [assignedChecklist, dispatch]);

  useEffect(() => {
    if ((plan as ChecklistDTO)?.name) {
      dispatch(
        setHeader(
          getNameFromLanguage((plan as ChecklistDTO)?.name as LanguageObject),
        ),
      );
    }
    if ((plan as ChecklistDTO)?.category) {
      dispatch(
        setHeaderLabel(
          t(mapCategoryToAliasTranslation((plan as ChecklistDTO)?.category)),
        ),
      );
    }
    if (isCompliance) {
      dispatch(
        setHeader(
          getNameFromLanguage(complianceStatus?.courseName as LanguageObject),
        ),
      );
      dispatch(
        setHeaderLabel(
          t(
            mapCategoryToAliasTranslation(Constants.PLAN_CATEGORIES.COMPLIANCE),
          ),
        ),
      );
    }
    if ((plan as ChecklistDTO)?.estimatedMinutes) {
      dispatch(
        setHeaderSubtext(
          plansRedesignFeatureFlag
            ? t('TrainingPlans.trainTogether.estimatedCompletionTime', {
                estimatedTime: generateTotalTime(
                  (plan as ChecklistDTO)?.estimatedMinutes!,
                  t('Generic.hour'),
                  t('Generic.mins'),
                ),
              })
            : t('TrainingPlans.trainingMode.estimatedCompletionTime', {
                estimatedTime: generateTotalTime(
                  (plan as ChecklistDTO)?.estimatedMinutes!,
                  t('Generic.hour'),
                  t('Generic.mins'),
                ),
              }),
        ),
      );
    }
    return () => {
      dispatch(setHeaderLabel(''));
      dispatch(setHeader(''));
      dispatch(setHeaderSubtext(''));
    };
  }, [
    complianceStatus,
    dispatch,
    isCompliance,
    plan,
    plansRedesignFeatureFlag,
    t,
  ]);

  useEffect(() => {
    if (isSuccess && !!assignedTeamMembersList && !!data) {
      setStepCount(assignedTeamMembersList?.checklist?.stepsTotal!);
      dispatch(
        setTeamMembers({
          teamMembers: assignedTeamMembersList?.status
            ?.map(teamMember => {
              return {
                ...teamMember,
                completionDate: teamMember.completionDate ?? null,
                name: data?.find(({ adId }) => teamMember.userId === adId)
                  ?.name,
                locations: data?.find(({ adId }) => teamMember.userId === adId)
                  ?.locations,
              };
            })
            .filter(
              teamMember =>
                teamMember.status !== Constants.TRAINING_PLANS.UNASSIGNED,
            ),
        }),
      );
    }
  }, [assignedTeamMembersList, data, dispatch, isSuccess]);

  // Compliance plans
  useEffect(() => {
    if (
      isAssignedCoursesSuccess &&
      complianceStatus?.enrollments !== undefined
    ) {
      dispatch(
        setTeamMembers({
          teamMembers: data
            ?.map(teamMember => {
              return {
                userId:
                  (complianceStatus as any)?.enrollments?.[teamMember?.adId!]
                    ?.userId ?? teamMember.adId,
                completedDate:
                  complianceStatus?.enrollments?.[teamMember?.adId!]
                    ?.completedDate ?? null,
                courseId:
                  complianceStatus?.enrollments?.[teamMember?.adId!]
                    ?.courseId ?? '',
                dueDate:
                  complianceStatus?.enrollments?.[teamMember?.adId!]?.dueDate ??
                  null,
                duration:
                  complianceStatus?.enrollments?.[teamMember?.adId!]
                    ?.duration ?? null,
                enrolledDate:
                  complianceStatus?.enrollments?.[teamMember?.adId!]
                    ?.enrolledDate ?? '',
                enrollmentId:
                  complianceStatus?.enrollments?.[teamMember?.adId!]
                    ?.enrollmentId ?? '',
                expirationDate:
                  complianceStatus?.enrollments?.[teamMember?.adId!]
                    ?.certificate?.expiration ?? '',
                finalScore:
                  complianceStatus?.enrollments?.[teamMember?.adId!]
                    ?.finalScore ?? 0,
                hidden:
                  complianceStatus?.enrollments?.[teamMember?.adId!]?.hidden ??
                  false,
                locations:
                  (complianceStatus as any)?.enrollments?.[teamMember?.adId!]
                    ?.locations ?? teamMember.locations,
                mostRecentCompletedDate:
                  complianceStatus?.enrollments?.[teamMember?.adId!]
                    ?.mostRecentCompletedDate ?? null,
                name: teamMember.name,
                pathwayCourseId:
                  complianceStatus?.enrollments?.[teamMember?.adId!]
                    ?.pathwayCourseId ?? '',
                percentComplete:
                  complianceStatus?.enrollments?.[teamMember?.adId!]
                    ?.percentComplete ?? 0,
                startedDate:
                  complianceStatus?.enrollments?.[teamMember?.adId!]
                    ?.startedDate ?? '',
                status:
                  complianceStatus?.enrollments?.[teamMember?.adId!]?.status ??
                  '',
              };
            })
            .filter(
              (value, index, self) =>
                index ===
                self.findIndex(
                  user => user.userId === value.userId && !user.hidden,
                ),
            ),
        }),
      );
    }
  }, [complianceStatus, data, dispatch, isAssignedCoursesSuccess]);

  useEffect(() => {
    // We can't call `refetchAssignedTeamMembers` in compliance plans because we don't call `useGetAssignedStatusQuery` on component mount
    if (isCompliance) {
      return;
    }
    refetchAssignedTeamMembers();
    // After we refetch the team members, we set the state back to false
    history.replace({
      state: { refetch: false },
      search: history.location.search,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.refetch, isCompliance]);

  // GET Team Members By Location Fetch error
  if (isApiError(error)) {
    notifyBugsnag(error);
    return <GenericError />;
  }

  if (isApiError(errorGettingPlan)) {
    notifyBugsnag(errorGettingPlan);
    return <GenericError />;
  }

  // GET Assigned Team Members Fetch error
  if (isApiError(assignedTeamMemberError)) {
    notifyBugsnag(assignedTeamMemberError);
    return <GenericError />;
  }

  const onAddTeamMember = () => {
    setShowAddTeamMembersMenu(false);
    setShowAddTeamMembersPopup(true);
  };

  const onUnassign = (id: string, name: string) => {
    setActiveTeamMember({ id, name });
    setShowUnassignTeamMemberPopup(true);
  };

  const onClose = () => {
    setSelectedId('');
    setShowAddTeamMembersMenu(false);
    setShowAddTeamMembersPopup(false);
    setShowDueDatePopup(false);
    setShowTrainingModePopup(false);
    setIsDueDateUpdate(false);
  };

  const onUpdateDueDate = (id: string, name: string) => {
    setActiveTeamMember({ id, name });
    setIsDueDateUpdate(true);
    setShowDueDatePopup(true);
  };

  const onEnterTrainingMode = () => {
    setShowTrainingModePopup(true);
    dispatch(hideTrainingMode());
  };

  return (
    <>
      <StyledContent>
        <LoadingOverlay
          isOpen={isFetching || isFetchingPlan || isFetchingAssignedTeamMembers}
        />
        <FilterAndSort
          currentFilters={currentFilters}
          isSmAndDown={isSmAndDown}
          filterLocations={filterLocations}
          sort={sort}
          sortOptions={sortOptions}
          statusLabels={statusLabels}
          teamMembers={teamMembers}
        />
        <TeamMembers
          assignedTeamMembersList={assignedTeamMembersList!}
          currentFilters={currentFilters}
          filterLocations={filterLocations}
          isCompliance={isCompliance}
          isFoodSafety={isFoodSafety}
          isSmAndDown={isSmAndDown}
          onUnassign={onUnassign}
          onUpdateDueDate={onUpdateDueDate}
          plan={plan as ChecklistDTO}
          planId={planId}
          refetchAssignedChecklist={refetchAssignedChecklist}
          refetchAssignedTeamMembers={refetchAssignedTeamMembers}
          sort={sort}
          sortOptions={sortOptions}
          statusLabels={statusLabels}
          stepCount={stepCount}
          teamMembers={teamMembers}
        />
        <AddTeamMemberFab
          onAddTeamMember={onAddTeamMember}
          setShowAddTeamMembersMenu={setShowAddTeamMembersMenu}
          showAddTeamMembersMenu={showAddTeamMembersMenu}
        />
        <Modals
          activeTeamMember={activeTeamMember}
          allTeamMembers={allTeamMembers}
          assignedTeamMembersList={assignedTeamMembersList!}
          filterLocations={filterLocations}
          isCompliance={isCompliance}
          isDueDateUpdate={isDueDateUpdate}
          onClose={onClose}
          onEnterTrainingMode={onEnterTrainingMode}
          plan={plan}
          planId={planId}
          refetchAssignedChecklist={refetchAssignedChecklist}
          refetchAssignedComplianceTeamMembers={
            refetchAssignedComplianceTeamMembers
          }
          refetchAssignedTeamMembers={refetchAssignedTeamMembers}
          refetchCourseReportData={refetchCourseReportData}
          selectedId={selectedId}
          setActiveTeamMember={setActiveTeamMember}
          setSelectedId={setSelectedId}
          setShowAddTeamMembersPopup={setShowAddTeamMembersPopup}
          setShowDueDatePopup={setShowDueDatePopup}
          setShowSelectLanguagePopup={setShowSelectLanguagePopup}
          setShowUnassignTeamMemberPopup={setShowUnassignTeamMemberPopup}
          showAddTeamMembersPopup={showAddTeamMembersPopup}
          showDueDatePopup={showDueDatePopup}
          showSelectLanguagePopup={showSelectLanguagePopup}
          showTrainingModePopup={showTrainingModePopup}
          showUnassignTeamMemberPopup={showUnassignTeamMemberPopup}
          trainingModeModalIsOpen={trainingModeModalIsOpen}
        />
      </StyledContent>
      <PrintReport
        assignedTeamMembersList={assignedTeamMembersList}
        complianceReportToPrint={complianceReportToPrint}
        currentFilters={currentFilters}
        filterLocations={filterLocations}
        isCompliance={isCompliance}
        isFetching={isFetching}
        isFetchingAssignedTeamMembers={isFetchingAssignedTeamMembers}
        isFetchingPlan={isFetchingPlan}
        isFoodSafety={isFoodSafety}
        isPrintReportOpen={isPrintReportOpen}
        isStatusReportFetching={isStatusReportFetching}
        noLocationsSelected={noLocationsSelected}
        plan={plan!}
        selectedReportLocations={selectedReportLocations}
        setNoLocationsSelected={setNoLocationsSelected}
        setSelectedReportLocations={setSelectedReportLocations}
        setShowChooseReportLocationsPopup={setShowChooseReportLocationsPopup}
        showChooseReportLocationsPopup={showChooseReportLocationsPopup}
        statusReportData={statusReportData}
        stepCount={stepCount}
      />
    </>
  );
};

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  max-width: 100%;
`;

const ManagePlansViewWithRoles = withRoles(ManagePlanView, 'plans.manage');

export default ManagePlansViewWithRoles;
