import { createBrowserHistory } from 'history';
import Constants from '@/constants/index';

const history = createBrowserHistory({
  basename: Constants.BASE_PATH,
});

history.listen(location => {
  if (Constants.ROUTE_LOGGING_ENABLED) {
    console.log('router path changed:', location.pathname);
  }

  /*  const homePath = !matchPath(location.pathname, {
    path: Constants.BASE_PATH,
  });*/
});

export default history;
