import Constants from 'constants/index';
import { getNameFromLanguage } from 'utils/language';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import {
  Modal,
  Button,
  ModalBody,
  ModalHeader,
  Typography,
  ModalFooter,
} from 'cfa-react-components';
import { IconArrowLeft } from '@tabler/icons-react';
import {
  useGetQuizQuery,
  useUpdateTrainingPlanMutation,
} from 'services/pathwayApi';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';
import useBugsnagNotify from 'hooks/useBugsnagNotify';
import {
  ChecklistDTO,
  ChecklistSectionDTO,
  CustomQuizDTO,
  UserChecklistResponse,
} from '@cfacorp-pathway/xp-api-typescript-client';
import React from 'react';
import QuizPreviewCard from '../Cards/QuizPreviewCard';
import { LanguageObject } from '@/types';

interface QuizPreviewPopUpProps {
  handleQuizBackButton: () => void;
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
  quizToPreview: CustomQuizDTO;
  trainingPlan: ChecklistDTO | UserChecklistResponse | ChecklistDTO[];
  sectionId: string;
}

const QuizPreviewPopUp: React.FC<QuizPreviewPopUpProps> = ({
  handleQuizBackButton,
  isOpen,
  onClose,
  quizToPreview,
  trainingPlan,
  sectionId,
  refetch,
}) => {
  const { notifyBugsnag } = useBugsnagNotify();
  const { t } = useTranslation();
  const { data: quiz, isFetching: isFetchingQuiz } = useGetQuizQuery(
    quizToPreview?.id!,
    {
      skip: !quizToPreview?.id,
    },
  );
  const [updateTrainingPlan] = useUpdateTrainingPlanMutation();

  const stepType = Constants.STEP_TYPES.QUIZ;

  const withinSection = !!sectionId?.length;

  const quizName = quizToPreview?.name
    ? getNameFromLanguage(quizToPreview?.name)
    : '';

  const getQuizStep = () => ({
    id: uuidv4(),
    name: {
      en: getNameFromLanguage(
        quizToPreview?.name as LanguageObject,
        Constants.LANGUAGE.ENGLISH_LANGUAGE_CODE,
      ),
      es: getNameFromLanguage(
        quizToPreview?.name as LanguageObject,
        Constants.LANGUAGE.SPANISH_LANGUAGE_CODE,
      ),
    },
    type: stepType,
    quizId: quizToPreview?.typeFormId,
    reference: null,
    note: null,
    urls: [],
    available: true,
  });

  const updatePlanBasedOnPreviewAndRefetchThenRunOnClose = (payload: any) => {
    updateTrainingPlan(payload)
      .unwrap()
      .then(() => {
        refetch();
        onClose();
      })
      .catch(err => {
        notifyBugsnag(err);
      });
  };

  const buildPayloadForPlanUpdateWithQuiz = ({
    quizStep,
    newSteps,
  }: {
    quizStep: any;
    newSteps: any;
  }) => ({
    locations: (trainingPlan as ChecklistDTO).locations,
    checklist: withinSection
      ? {
          ...trainingPlan,
          sections: [
            ...(trainingPlan as any).sections.map(
              (section: ChecklistSectionDTO) =>
                section.id === sectionId
                  ? {
                      ...section,
                      steps: [...section.steps!, quizStep],
                    }
                  : section,
            ),
          ],
        }
      : {
          ...trainingPlan,
          steps: newSteps,
        },
  });

  const onAddQuizFromPreview = () => {
    const quizStep = getQuizStep();
    const newSteps = [...(trainingPlan as any).steps, quizStep];
    const payload = buildPayloadForPlanUpdateWithQuiz({ quizStep, newSteps });
    updatePlanBasedOnPreviewAndRefetchThenRunOnClose(payload);
  };

  return (
    <>
      <LoadingOverlay isOpen={isFetchingQuiz} />
      <StyledModal
        onClose={onClose}
        scrollMode="modal-body"
        show={isOpen}
        size="lg"
      >
        <StyledModalHeader>
          <StyledHeaderContentWrapper>
            <BackButtonWrapper>
              <StyledBackButton
                color="secondary"
                onClick={handleQuizBackButton}
                size="sm"
                variant="text"
              >
                <StyledIconArrowLeft />
              </StyledBackButton>
            </BackButtonWrapper>
            <div>
              <StyledOverlineTypography variant="overline1">
                {t('TrainingPlans.preview')}
              </StyledOverlineTypography>
              {quizName}
            </div>
            <StyledEmptyDiv />
          </StyledHeaderContentWrapper>
        </StyledModalHeader>
        <ModalBody>
          <QuizPreviewCard quiz={quiz} />
        </ModalBody>
        <StyledModalFooter>
          <StyledAddButton
            color="secondary"
            data-testid="addQuizFromPreviewButton"
            onClick={onAddQuizFromPreview}
            variant="filled"
          >
            {t('Button.add')}
          </StyledAddButton>
        </StyledModalFooter>
      </StyledModal>
    </>
  );
};

const StyledModal = styled(Modal)`
  .cfa-modal-header {
    padding-bottom: 2rem;
  }
`;

const StyledModalHeader = styled(ModalHeader)`
  display: flex;
  justify-content: center;
`;

const StyledHeaderContentWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const StyledOverlineTypography = styled(Typography)`
  margin-bottom: 0.5rem;
`;

const StyledEmptyDiv = styled.div`
  flex: 1;
  min-width: 155px;
`;

const BackButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  white-space: nowrap;
`;

const StyledBackButton = styled(Button)`
  padding-left: 0;
  transform: translateX(-10px);
`;

const StyledIconArrowLeft = styled(IconArrowLeft)`
  padding-right: 4px;
`;

const StyledModalFooter = styled(ModalFooter)`
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const StyledAddButton = styled(Button)`
  min-width: unset;
`;

export default QuizPreviewPopUp;
