import { DocumentDTO } from '@cfacorp-pathway/xp-api-typescript-client';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import NoMessage from '@/components/NoMessage/NoMessage';
import { TrainingPlan } from '@/components/document';
import { LanguageObject } from '@/types/types';
import { itemByLanguage } from '@/utils/language';

interface Document extends Omit<DocumentDTO, 'references'> {
  references: Record<
    string,
    {
      versionId: string;
      fileId: string;
      reference?: string;
    }
  >;
}

interface DocumentProxyState {
  planId: string;
  step?: {
    id: string;
    name: LanguageObject;
    type: string;
    quizId: string | null;
    reference: Document;
    note: string | null;
    urls: string[];
    available: boolean;
  };
  isCompleted: boolean;
  userIds: string[];
  stepStatus: string;
}

const DocumentProxy = () => {
  const { state } = useLocation() as { state?: DocumentProxyState };
  const { t } = useTranslation();

  // a checklist has an id - we call it planId or checklistId
  // within a checklist there are steps, which have an id, stepId
  // steps contain fields like: notes, quizId, urls...
  // within a step there are references that have an id, referenceId
  // referenceId ultimately refers to a training document

  const reference = state?.step?.reference;
  const referenceId = reference?.id;
  const refVersionId = itemByLanguage(reference?.references)?.versionId;
  const refFileId = itemByLanguage(reference?.references)?.fileId;
  const refUrl = itemByLanguage(reference?.references)?.reference;
  const planId = state?.planId;
  const stepId = state?.step?.id;
  const stepStatus = state?.stepStatus;
  const userIds = state?.userIds;

  return (
    <>
      {referenceId ? (
        <StyledWrapper>
          <TrainingPlan
            planId={planId}
            refFileId={refFileId}
            refUrl={refUrl}
            refVersionId={refVersionId}
            stepId={stepId}
            stepStatus={stepStatus}
            userIds={userIds}
          />
        </StyledWrapper>
      ) : (
        <NoMessage message={t('TrainingPlans.noResourceReference')} />
      )}
    </>
  );
};

const StyledWrapper = styled.div`
  display: flex;
`;

export default DocumentProxy;
