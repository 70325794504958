import Constants from 'constants/index';
import { getNameFromLanguage } from 'utils/language';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectUsersHighestPermissionLevel } from 'store/user/selectors';
import { Card, Tag } from 'cfa-react-components';
import styled from 'styled-components';
import theme from 'styles/theme';
import {
  IconEdit,
  IconEye,
  IconEyeOff,
  IconGripVertical,
  IconTrash,
} from '@tabler/icons-react';
import PopoverMenuButton from 'components/PopoverMenuButton/PopoverMenuButton';
import PopoverMenuButtonItem from 'components/PopoverMenuButton/PopoverMenuButtonItem';
import { IntlCategoryEntity } from '@/services/content-api-types';
import { LanguageObject } from '@/types/types';

interface CardCategoryProps {
  category: IntlCategoryEntity;
  categoryIsVisible: boolean;
  className: string;
  handleCategoryAdminPopupMenu: () => void;
  handleCategoryVisibility: () => void;
  isAdminPage: boolean;
  onCategoryDelete: () => void;
  onCategoryEdit: () => void;
  onClickCategory: (category: IntlCategoryEntity) => void;
  onSixDotMenuIconClick: () => void;
  showAdminFunctionality: boolean;
}

const CardCategory: React.FC<CardCategoryProps> = ({
  category,
  categoryIsVisible = true,
  className,
  handleCategoryAdminPopupMenu,
  handleCategoryVisibility,
  isAdminPage,
  onCategoryDelete,
  onCategoryEdit,
  onClickCategory,
  onSixDotMenuIconClick,
  showAdminFunctionality,
}) => {
  const { name, icon, id } = category;
  const history = useHistory();
  const { state } = useLocation<{ fromTrainingMode: boolean }>();
  const { t } = useTranslation();
  const userPermissionLevel = useSelector(selectUsersHighestPermissionLevel);
  let userIsAdmin = false;
  if (showAdminFunctionality) {
    userIsAdmin = Constants.USER_PERMISSIONS.ADMIN === userPermissionLevel;
  }
  useEffect(() => {
    if (state && state.fromTrainingMode) {
      // We then set the state back to default
      history.replace({
        pathname: `/`,
        state: { fromTrainingMode: false },
      });
    }
  }, [history, state]);

  return (
    <StyledCard
      data-testid={`${id}-card`}
      elevation={1}
      onClick={() => onClickCategory && onClickCategory(category)}
    >
      <StyledCategory>
        {!!userIsAdmin && !!showAdminFunctionality && (
          <div className={className}>
            <StyledSixDotIcon onClick={onSixDotMenuIconClick} />
          </div>
        )}
        <StyledTextWrapper onClick={isAdminPage ? onCategoryEdit : undefined}>
          <StyledIcon
            alt=""
            src={Constants.PATHWAY_CDN_IMG.CATEGORIES + icon + '.svg'}
          />
          <CategoryCardText>
            {getNameFromLanguage(name as LanguageObject)}
          </CategoryCardText>
        </StyledTextWrapper>
        {!!userIsAdmin && !!showAdminFunctionality && (
          <EditAdminFeaturesWrapper>
            {!!categoryIsVisible ? (
              <StyledShowTag
                data-testid="CategoryCardShow"
                label={t('Admin.categoryIsVisible')}
                leadingElement={<IconEye width={10} />}
                onClick={handleCategoryVisibility}
                variant="filled"
              />
            ) : !categoryIsVisible ? (
              <StyledHideTagWrapper onClick={handleCategoryVisibility}>
                <StyledHideTag
                  data-testid="CategoryCardHide"
                  disabled
                  label={t('Admin.categoryHidden')}
                  leadingElement={<IconEyeOff width={10} />}
                  variant="filled"
                />
              </StyledHideTagWrapper>
            ) : null}
            <PopoverMenuButton
              dataTestId="CategoryCardMenu"
              handleClick={handleCategoryAdminPopupMenu}
            >
              <PopoverMenuButtonItem
                dataTestId="CategoryCardEdit"
                icon={<IconEdit />}
                onClick={onCategoryEdit}
                text={t('Button.edit')}
              />
              <PopoverMenuButtonItem
                dataTestId="CategoryCardDelete"
                icon={<IconTrash />}
                isDestructive={true}
                onClick={onCategoryDelete}
                text={t('Button.delete')}
              />
            </PopoverMenuButton>
          </EditAdminFeaturesWrapper>
        )}
      </StyledCategory>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  padding: 1em;
  margin: 0.75em 0;
`;

const StyledTextWrapper = styled.div`
  display: flex;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  width: 100%;
`;

const CategoryCardText = styled.div`
  flex-grow: 1;
  overflow: hidden;
  margin: auto;
  display: -webkit-box;
  color: ${() => theme.grayScale.gray6};
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

const EditAdminFeaturesWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 12px;

  .cfa-tag .tag-label {
    font-weight: 400;
  }
`;

const StyledShowTag = styled(Tag)`
  flex-direction: row-reverse;
  align-items: center;
  margin-left: 16px;
  height: fit-content;

  .tag-leading-element {
    margin: 0 0 0 6px;
    width: unset;
    height: unset;
  }
`;

const StyledHideTagWrapper = styled('span')`
  margin-left: 16px;
  cursor: pointer;
`;

const StyledHideTag = styled(Tag)`
  flex-direction: row-reverse;
  align-items: center;
  height: fit-content;

  .tag-leading-element {
    margin: 0 0 0 6px;
    width: unset;
    height: unset;
  }
`;

const StyledSixDotIcon = styled(IconGripVertical)`
  margin-right: 8px;
  cursor: move;
  color: ${() => theme.grayScale.gray3};
`;

const StyledIcon = styled.img`
  width: 40px !important;
  height: 40px !important;
  margin-right: 10px;
`;
const StyledCategory = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
  width: 100%;
`;

export default CardCategory;
