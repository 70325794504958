import Constants from 'constants/index';
import AddTeamMembersPopUp from '@/components/popups/AddTeamMemberPopUp/AddTeamMembersPopUp';
import ConfirmationModal from '@/components/popups/ConfirmationModal';
import { selectTrainingModeModalIsOpen } from '@/store/trainingMode/selectors';
import {
  enterTrainingMode,
  hideTrainingMode,
  setTrainingModeData,
} from '@/store/trainingMode/slice';
import { LanguageObject } from '@/types';
import { mapCategoryToAliasTranslation } from '@/utils/categoryUtils';
import { getNameFromLanguage } from '@/utils/language';
import { ChecklistDTO } from '@cfacorp-pathway/xp-api-typescript-client';
import { RadioButton, RadioGroup } from 'cfa-react-components';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  isUserLicensee,
  selectAllLocationsWithAtLeastTrainer,
} from '@/store/user/selectors';
import { selectActiveLicenseeLocation } from '@/store/licenseeLocationPicker/selector';
import { arrayIntersect } from '@/utils/keepDuplicatesFromTwoArrays';
import { useGetAssignedStatusQuery } from '@/services/pathwayApi';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface TrainTogetherProps {
  isCompliance: boolean;
  operatorLocations: string[];
  plan: ChecklistDTO;
  setSelectedId: (id: string) => void;
  setShowAddTeamMembersPopup: (show: boolean) => void;
  setShowDueDatePopup: (show: boolean) => void;
}

const TrainTogether: React.FC<TrainTogetherProps> = ({
  isCompliance,
  operatorLocations,
  plan,
  setSelectedId,
  setShowAddTeamMembersPopup,
  setShowDueDatePopup,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { plansRedesign: plansRedesignFeatureFlag } = useFlags();
  const dispatch = useDispatch();
  const trainingModeModalIsOpen = useSelector(selectTrainingModeModalIsOpen);
  const isLicenseeUser = useSelector(isUserLicensee);
  const activeLicenseeLocation = useSelector(selectActiveLicenseeLocation);
  const locationsWithAtLeastTrainer = useSelector(
    selectAllLocationsWithAtLeastTrainer,
  );
  const [temporaryTeamMembers, setTemporaryTeamMembers] = useState<any[]>([]);
  const [showSelectLanguagePopup, setShowSelectLanguagePopup] = useState(false);
  const [showTrainingModePopup, setShowTrainingModePopup] = useState(false);
  const [languageSelected, setLanguageSelected] = useState('English');
  const [trainingModeTeamMembers, setTrainingModeTeamMembers] = useState([]);
  const filterLocations = useMemo(() => {
    if (isLicenseeUser) {
      return [activeLicenseeLocation.number];
    } else if (isCompliance) {
      return locationsWithAtLeastTrainer;
    } else {
      return (
        arrayIntersect(locationsWithAtLeastTrainer, operatorLocations ?? []) ??
        []
      );
    }
  }, [
    activeLicenseeLocation.number,
    isCompliance,
    isLicenseeUser,
    locationsWithAtLeastTrainer,
    operatorLocations,
  ]);

  const { data: assignedTeamMembersList } = useGetAssignedStatusQuery(
    {
      checklist: plan.id!,
      location: locationsWithAtLeastTrainer,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !plan.id || isCompliance,
    },
  );

  const onEnterTrainingMode = () => {
    setShowTrainingModePopup(true);
    dispatch(hideTrainingMode());
  };

  const handleNextClick = (selectedTeamMembers: any[]) => {
    /** We temporarily set these so they do not render until we send
     * the user to Train Together below.
     */
    setTemporaryTeamMembers(selectedTeamMembers);
    dispatch(hideTrainingMode());
    setShowSelectLanguagePopup(true);
  };

  const onNextClick = () => {
    setShowSelectLanguagePopup(false);
    dispatch(enterTrainingMode());
    dispatch(
      setTrainingModeData({
        estimatedMinutes: (plan as ChecklistDTO)?.estimatedMinutes,
        languageSelected,
        planId: plan.id,
        planLabel: t(
          mapCategoryToAliasTranslation((plan as ChecklistDTO)?.category),
        ),
        planName: getNameFromLanguage(
          (plan as ChecklistDTO)?.name as LanguageObject,
        ),
        teamMembers: trainingModeTeamMembers,
        selectedTeamMembers: temporaryTeamMembers,
        assignedTeamMembersStatus: (
          assignedTeamMembersList as any
        )?.status?.filter(
          (teamMember: any) =>
            teamMember.status !== Constants.TRAINING_PLANS.COMPLETED,
        ),
      }),
    );
    history.push({
      pathname: `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.TRAINING_MODE_PATH_NAME}`,
    });
  };

  const onClose = () => {
    setSelectedId('');
    setShowAddTeamMembersPopup(false);
    setShowDueDatePopup(false);
    setShowTrainingModePopup(false);
  };

  const onBack = () => {
    setTrainingModeTeamMembers([]);
    setShowSelectLanguagePopup(false);
    setSelectedId('');
  };
  return (
    <>
      <ConfirmationModal
        bodyText={
          plansRedesignFeatureFlag
            ? t('TrainingPlans.trainTogether.enterModalBody')
            : t('TrainingPlans.trainingMode.enterTrainingModeModal')
        }
        headerText={
          plansRedesignFeatureFlag
            ? t('TrainingPlans.trainTogether.enterModalHeader')
            : t('Generic.trainingMode')
        }
        isOpen={trainingModeModalIsOpen}
        onClose={() => dispatch(hideTrainingMode())}
        primaryButtonColor={'secondary'}
        primaryButtonHandler={onEnterTrainingMode}
        primaryButtonText={t('Button.iUnderstand')}
        secondaryButtonHandler={() => dispatch(hideTrainingMode())}
        secondaryButtonText={t('Button.cancel')}
      />
      {/** Assign Team Members in Train Together*/}
      <AddTeamMembersPopUp
        handleNextClick={({ selectedTeamMembers }) =>
          handleNextClick(selectedTeamMembers)
        }
        isOpen={showTrainingModePopup}
        isTrainingMode={true}
        locations={filterLocations}
        onClose={onClose}
        planDetails={plan}
      />
      <ConfirmationModal
        bodyText={t('TrainingPlans.trainingMode.selectLanguage')}
        children={
          <StyledLanguageWrapper>
            <RadioGroup
              defaultValue={t('Language.english')}
              orientation="vertical"
            >
              <RadioButton
                label={t('Language.english')}
                onClick={() => setLanguageSelected(t('Language.english'))}
                value={t('Language.english')}
              />
              <RadioButton
                label={t('Language.spanish')}
                onClick={() => setLanguageSelected(t('Language.spanish'))}
                value={t('Language.spanish')}
              />
            </RadioGroup>
          </StyledLanguageWrapper>
        }
        headerText={t('TrainingPlans.trainingMode.chooseLanguage')}
        isOpen={showSelectLanguagePopup}
        onClose={() => setShowSelectLanguagePopup(false)}
        primaryButtonColor={'secondary'}
        primaryButtonHandler={onNextClick}
        primaryButtonText={t('Button.next')}
        secondaryButtonHandler={onBack}
        secondaryButtonText={t('Button.back')}
      />
    </>
  );
};

const StyledLanguageWrapper = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
`;
export default TrainTogether;
