import Constants from '@/constants';
import { useGetAssignedCoursesQuery } from '@/services/pathwayApi';
import { selectAllLocationsWithAtLeastTrainer } from '@/store/user/selectors';
import theme from '@/styles/theme';
import { mapCategoryToAliasTranslation } from '@/utils/categoryUtils';
import { getNameFromLanguage } from '@/utils/language';
import {
  AssignableCourseResult,
  ComplianceCourseEnrollmentDTO,
} from '@cfacorp-pathway/xp-api-typescript-client';
import { IconAlertTriangleFilled } from '@tabler/icons-react';
import { Tooltip } from 'cfa-react-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import PlanCard from '../../PlanCard';
import PlanCardLabel from '../../PlanCardComponents/PlanCardLabel';
import PlanCardSubHeader from '../../PlanCardComponents/PlanCardSubHeader';
import PlanCardTitle from '../../PlanCardComponents/PlanCardTitle';
import PlanCardContent from '../../PlanCardContent/PlanCardContent';
import Buttons from './Buttons';
import {
  enrollmentPassed,
  NonNullComplianceCourse,
  useTeamMemberCount,
} from './utils';

export interface ComplianceCourseWithUserId
  extends ComplianceCourseEnrollmentDTO {
  userId: string;
}

interface ComplianceCardProps {
  course: NonNullComplianceCourse;
  onPrintReport: (id: string) => void;
  onShowEnableCompliancePlan: (id: string, name: string) => void;
}

const ComplianceCard: React.FC<ComplianceCardProps> = ({
  course,
  onPrintReport,
  onShowEnableCompliancePlan,
}) => {
  const { t } = useTranslation();
  const locationsWithAtLeastTrainer = useSelector(
    selectAllLocationsWithAtLeastTrainer,
  );

  const completedUsers = Object.values(course?.enrollments)?.filter(
    enrollmentPassed,
  ).length;

  const { data: complianceStatus } = useGetAssignedCoursesQuery({
    courseId: course.id,
    location: locationsWithAtLeastTrainer,
  });

  const totalTeamMembers = useTeamMemberCount(
    locationsWithAtLeastTrainer,
    complianceStatus?.enrollments,
  );

  const allUsersCompleted =
    !!totalTeamMembers && completedUsers === totalTeamMembers;
  const percentComplete =
    totalTeamMembers && (completedUsers / totalTeamMembers) * 100;

  return (
    <PlanCard
      buttons={
        <Buttons
          course={course}
          onPrintReport={onPrintReport}
          onShowEnableCompliancePlan={onShowEnableCompliancePlan}
        />
      }
      category={Constants.PLAN_CATEGORIES.COMPLIANCE}
      dataTestId={'ManagePlansPlanCard'}
      disabled={!course?.enabled}
      link={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${course.id}/${
        Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME
      }?compliancePlan=${getNameFromLanguage(course.courseName)}`}
      progress={percentComplete}
    >
      <PlanCardContent>
        <PlanCardLabel>
          {t(
            mapCategoryToAliasTranslation(Constants.PLAN_CATEGORIES.COMPLIANCE),
          )}
        </PlanCardLabel>
        <PlanCardTitle>{getNameFromLanguage(course.courseName)}</PlanCardTitle>
        {allUsersCompleted ? (
          <PlanCardSubHeader className="success" dataTestId="PlanTaskCount">
            {t('Generic.completedByAll')}
          </PlanCardSubHeader>
        ) : (
          <PlanCardSubHeader dataTestId="PlanTaskCount">
            {totalTeamMembers ? (
              <>
                <Tooltip
                  content={t('TrainingPlans.accountability.outOfCompliance')}
                  placement="bottom"
                >
                  <WarningIconAlertTriangleFilled
                    style={{ marginRight: '4px' }}
                  />
                </Tooltip>
                {`${completedUsers}/${totalTeamMembers} ${t(
                  'Generic.teamMembersCompleted',
                )}`}
              </>
            ) : (
              ` ${t('Generic.loading')}`
            )}
          </PlanCardSubHeader>
        )}
      </PlanCardContent>
    </PlanCard>
  );
};

interface ComplianceCardWrapperProps {
  course: AssignableCourseResult;
  onPrintReport: (id: string) => void;
  onShowEnableCompliancePlan: (id: string, name: string) => void;
}

const ComplianceCardWrapper: React.FC<ComplianceCardWrapperProps> = ({
  course: c,
  ...props
}) => {
  if (
    c.courseID === undefined ||
    c.courseName === undefined ||
    c.createdDate === undefined ||
    c.enabled === undefined ||
    c.id === undefined
  ) {
    return null;
  }

  return <ComplianceCard {...props} course={c as NonNullComplianceCourse} />;
};

const WarningIconAlertTriangleFilled = styled(IconAlertTriangleFilled)`
  height: 16px;
  width: 16px;
  color: ${() => theme.semanticColors.warning};
`;

export default ComplianceCardWrapper;
