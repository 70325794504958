import Constants from 'constants/index';
import { isApiError } from 'utils/request';
import {
  ChecklistTemplateDTO,
  OperatorDTO,
} from '@cfacorp-pathway/xp-api-typescript-client';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import TemplateModal from '../../CopyTemplateModal/TemplateModal';
import PreviewTemplateModal from '../../CopyTemplateModal/PreviewTemplateModal';
import CopyTemplatePopup from '../../CopyTemplateModal/CopyTemplatePopup';
import { useAmplitudeTrack } from '@/amplitude/useAmplitude';
import AddPlanWithOperators from '@/components/popups/AddPlanWithOperators';
import {
  useAddTrainingPlanMutation,
  useDuplicateTemplateTrainingPlanMutation,
  useEnableCourseMutation,
  useGetTemplatesQuery,
} from '@/services/pathwayApi';
import useBugsnagNotify from '@/hooks/useBugsnagNotify';
import GenericError from '@/components/Error/GenericError';
import {
  isUserLicensee,
  selectAllLocationsWithAtLeastLeaderPermissions,
  selectLocationsWithAdminPermission,
  selectUserId,
  selectUserPermissions,
} from '@/store/user/selectors';
import { selectActiveLicenseeLocation } from '@/store/licenseeLocationPicker/selector';
import { getNameFromLanguage } from '@/utils/language';
import { LanguageObject } from '@/types/types';
import ConfirmationModal from '@/components/popups/ConfirmationModal';
import { useTranslation } from 'react-i18next';
import PrintReportModal from '@/components/popups/PrintReportModal';
import CheckboxList from '@/components/Checkbox/CheckboxList';
import styled from 'styled-components';
import { useCallback, useState } from 'react';
import ToastMessageBlock from '@/components/Toasts/SuccessToast';
import { toast } from 'react-hot-toast';

interface StatusReport {
  courseId: string;
  courseName: string;
  foodSafetyImage: string;
  locations: string[];
  timeStamp: string;
  userData: never[];
}
interface ModalsProps {
  copiedTemplateId: string;
  courseId: string;
  courseName: string;
  handlePreviewPlan: (template: ChecklistTemplateDTO) => void;
  handlePrintReport: () => void;
  handleTemplateBackButton: () => void;
  isFetchingOperators: boolean;
  isStatusReportFetching: boolean;
  locationsWithAtLeastTrainer: string[];
  onShowCopyPlan: (id: string) => void;
  operators: OperatorDTO[];
  previewTemplate: any;
  refetchIsCourseEnabled: () => void;
  refetchPlans: () => void;
  resetPlanState: () => void;
  resetStatusReport: () => void;
  setPlanId: (id: string) => void;
  selectedReportLocations: string[];
  setSelectedReportLocations: (locations: string[]) => void;
  setShowChooseReportLocationsPopup: (show: boolean) => void;
  setShowCopyTemplatePopup: (show: boolean) => void;
  setShowCustomPlan: (show: boolean) => void;
  setShowDoBChecked: (show: boolean) => void;
  setShowEnablePlanPopUp: (show: boolean) => void;
  setShowInvalidNameError: (show: boolean) => void;
  setShowPreview: (show: boolean) => void;
  setShowTemplateModal: (show: boolean) => void;
  showChooseReportLocationsPopup: boolean;
  showCopyTemplatePopup: boolean;
  showCustomPlan: boolean;
  showDoBChecked: boolean;
  showEnablePlanPopUp: boolean;
  showInvalidNameError: boolean;
  showPreview: boolean;
  showTemplateModal: boolean;
  statusReport: StatusReport;
  statusReportToPrint: { [x: string]: string }[];
}

const Modals: React.FC<ModalsProps> = ({
  copiedTemplateId,
  courseId,
  courseName,
  handlePreviewPlan,
  handlePrintReport,
  handleTemplateBackButton,
  isFetchingOperators,
  isStatusReportFetching,
  locationsWithAtLeastTrainer,
  onShowCopyPlan,
  operators,
  previewTemplate,
  refetchIsCourseEnabled,
  refetchPlans,
  resetPlanState,
  selectedReportLocations,
  setSelectedReportLocations,
  setShowCopyTemplatePopup,
  setShowCustomPlan,
  setShowChooseReportLocationsPopup,
  setShowDoBChecked,
  setShowEnablePlanPopUp,
  setShowInvalidNameError,
  setShowPreview,
  setShowTemplateModal,
  showChooseReportLocationsPopup,
  showCopyTemplatePopup,
  showEnablePlanPopUp,
  showInvalidNameError,
  showCustomPlan,
  showDoBChecked,
  showPreview,
  showTemplateModal,
  statusReport,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { notifyBugsnag } = useBugsnagNotify();
  const [addTrainingPlan] = useAddTrainingPlanMutation();
  const [duplicateTemplateTrainingPlan] =
    useDuplicateTemplateTrainingPlanMutation();
  const track = useAmplitudeTrack();
  const [noLocationsSelected, setNoLocationsSelected] = useState(false);
  // const [selectedReportLocations, setSelectedReportLocations] = useState(
  //   locationsWithAtLeastTrainer,
  // );
  const isLicenseeUser = useSelector(isUserLicensee);
  const locationsWithAtLeastLeader = useSelector(
    selectAllLocationsWithAtLeastLeaderPermissions,
  );
  const locationsWithAtLeastAdmin = useSelector(
    selectLocationsWithAdminPermission,
  );
  const selectedLicenseeLocation = useSelector(selectActiveLicenseeLocation);
  const userId = useSelector(selectUserId);
  const userPermissions = useSelector(selectUserPermissions);
  const userIsStakeholder = userPermissions.hasOwnProperty(
    Constants.USER_PERMISSIONS.STAKEHOLDER,
  );
  const { data: templates, error: templatesError } = useGetTemplatesQuery(
    undefined,
    {
      skip: locationsWithAtLeastLeader.length <= 0,
    },
  );
  const operatorsWhereUserIsLeader = operators?.filter(operator =>
    operator?.locations?.some(operatorLocation =>
      locationsWithAtLeastLeader.includes(operatorLocation),
    ),
  );
  const [enableCourse] = useEnableCourseMutation();

  const handleCurrentFilteredLocations = useCallback(() => {
    setSelectedReportLocations(locationsWithAtLeastTrainer);
    if (!!noLocationsSelected) {
      setNoLocationsSelected(false);
    }
    setShowDoBChecked(false);
    // eslint-disable-next-line
  }, [locationsWithAtLeastTrainer, noLocationsSelected]);

  if (isApiError(templatesError)) {
    notifyBugsnag(templatesError);
    return <GenericError />;
  }

  const isValidPlanName = (planName: string, assignedOperator: string) => {
    const noAssignedOperatorAndUserIsNotStakeholder =
      !assignedOperator && !userIsStakeholder;

    if (!planName || noAssignedOperatorAndUserIsNotStakeholder) {
      setShowInvalidNameError(true);
      console.error('invalid name or missing operator');
      notifyBugsnag(
        new Error(
          `Build plans error - no plan name or no operator: ${JSON.stringify({
            planName,
            assignedOperator,
          })}`,
        ),
      );
      return false;
    }
    return true;
  };

  const onAddPlan = (name: string, operator: OperatorDTO) => {
    let ownerId = isLicenseeUser
      ? selectedLicenseeLocation.number
      : operator?.id;
    const licenseeLocation = isLicenseeUser
      ? selectedLicenseeLocation.number
      : null;
    const trimmedName = name?.trim();

    if (!ownerId && locationsWithAtLeastAdmin.length > 0) {
      console.warn(
        'No operator id found for users locations, using current user Id',
      );
      ownerId = userId;
    }
    if (!isValidPlanName(trimmedName, ownerId!)) {
      return;
    }

    addTrainingPlan({
      // If a user's only permission is stakeholder, we use the user id otherwise we use the owner id
      location: licenseeLocation!,
      ownerId: userIsStakeholder ? (ownerId ?? userId) : ownerId!,
      name: {
        en: trimmedName,
        es: trimmedName,
      },
    })
      .unwrap()
      .then(({ id }) => {
        // fire amplitude event
        if (track !== null) {
          track('training_plan_created', {
            training_plan_id: id ?? 'empty',
            owner: ownerId ?? 'empty',
          });
        }
        history.push(
          `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PLAN_PATH_NAME}/${id}`,
        );
      })
      .catch(err => {
        notifyBugsnag(err);
      });
  };

  const onCopyPlan = ({
    name,
    assignedOperator,
  }: {
    name: string;
    assignedOperator: OperatorDTO;
  }) => {
    const licenseeLocation = isLicenseeUser
      ? selectedLicenseeLocation.number
      : null;
    const trimmedName = name?.trim();
    let ownerId = assignedOperator?.id;
    if (!ownerId && locationsWithAtLeastAdmin.length > 0) {
      console.warn(
        'No operator id found for users locations, using current user Id',
      );
      ownerId = userId;
    }
    if (!isValidPlanName(trimmedName, ownerId!)) {
      return;
    }
    duplicateTemplateTrainingPlan({
      location: licenseeLocation!,
      ownerId: ownerId!,
      checklistId: copiedTemplateId,
      name: {
        en: name,
        es: name,
      },
    })
      .unwrap()
      .then(({ id }: any) => {
        // fire amplitude event
        if (track !== null) {
          track('training_template_copied', {
            template_id: copiedTemplateId ?? 'empty',
            operator: assignedOperator ?? 'empty',
          });
        }
        history.push(
          `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PLAN_PATH_NAME}/${id}`,
        );
      })
      .catch(err => {
        notifyBugsnag(err);
      });
  };

  const onPrintReportCancel = () => {
    setShowChooseReportLocationsPopup(false);
    handleCurrentFilteredLocations();
    resetPlanState();
  };

  const onContinue = () => {
    enableCourse({
      operatorId: operators?.[0]?.id!,
      courseId,
    })
      .unwrap()
      .then(() => {
        toast.custom(toastObj => (
          <ToastMessageBlock id={toastObj.id}>
            {t('TrainingPlans.toastMessage.enabled', { courseName })}
          </ToastMessageBlock>
        ));
        refetchPlans();
        refetchIsCourseEnabled();
      })
      .catch(err => {
        notifyBugsnag(err);
      });
    setShowEnablePlanPopUp(false);
  };

  return (
    <>
      {!!operators && (
        <AddPlanWithOperators
          isLicenseeUser={isLicenseeUser}
          open={showCustomPlan}
          operators={operatorsWhereUserIsLeader as OperatorDTO[]}
          setOpen={setShowCustomPlan}
          submitHandler={({ assignedOperator, name }) => {
            onAddPlan(name, assignedOperator);
            setShowCustomPlan(false);
          }}
        />
      )}
      {!!templates?.length && (
        <>
          <TemplateModal
            isOpen={showTemplateModal}
            onClose={() => setShowTemplateModal(false)}
            onCopy={onShowCopyPlan}
            onPreviewTemplateClick={handlePreviewPlan}
            templates={templates}
          />
          <PreviewTemplateModal
            isOpen={showPreview}
            onClose={() => setShowPreview(false)}
            onCopy={onShowCopyPlan}
            onTemplateBackButtonClick={handleTemplateBackButton}
            template={previewTemplate}
          />
        </>
      )}
      {!isFetchingOperators && (
        <CopyTemplatePopup
          isOpen={showCopyTemplatePopup}
          operators={operatorsWhereUserIsLeader as OperatorDTO[]}
          planName={
            getNameFromLanguage(
              templates?.find(template => template.id === copiedTemplateId)
                ?.name as LanguageObject,
            ) ?? ''
          }
          reopenTemplates={() => setShowTemplateModal(true)}
          setOpen={setShowCopyTemplatePopup}
          submitHandler={onCopyPlan}
        />
      )}
      {/** Invalid Name */}
      <ConfirmationModal
        bodyText={t('InvalidPlanName.paragraphText')}
        headerText={t('InvalidPlanName.errorHeader')}
        isError={true}
        isOpen={!!showInvalidNameError}
        onClose={() => setShowInvalidNameError(false)}
        primaryButtonHandler={() => setShowInvalidNameError(false)}
        primaryButtonText={t('Button.close')}
      />
      {/** Enable Plan */}
      <ConfirmationModal
        bodyText={t('Settings.complianceEnable', {
          name: courseName,
        })}
        headerText={t('Generic.enablePlan')}
        isOpen={showEnablePlanPopUp}
        onClose={() => setShowEnablePlanPopUp(false)}
        primaryButtonHandler={onContinue}
        primaryButtonText={t('Button.continue')}
        secondaryButtonHandler={() => setShowEnablePlanPopUp(false)}
        secondaryButtonText={t('Button.cancel')}
      />
      <PrintReportModal
        bodyText={t('TrainingPlans.chooseWhatToInclude')}
        children={
          locationsWithAtLeastTrainer.length > 1
            ? locationsWithAtLeastTrainer.map((id: string, idx: number) => (
                <StyledCheckboxList
                  id={id}
                  idx={idx}
                  key={idx}
                  selectedLocations={selectedReportLocations}
                  setSelectedLocations={setSelectedReportLocations}
                />
              ))
            : null
        }
        handleToggleDoBCheckbox={() => setShowDoBChecked(!showDoBChecked)}
        headerText={t('Generic.printReport')}
        isFoodSafety={
          !!statusReport?.courseName?.includes(
            Constants.FOOD_SAFETY_COURSE_NAME,
          )
        }
        isOpen={showChooseReportLocationsPopup}
        isPrintDisabled={isStatusReportFetching}
        noLocationsSelected={noLocationsSelected}
        onClose={onPrintReportCancel}
        primaryButtonHandler={handlePrintReport}
        primaryButtonText={t('Button.print')}
        secondaryButtonHandler={onPrintReportCancel}
        secondaryButtonText={t('Button.cancel')}
        selectedReportLocations={selectedReportLocations}
        showDoBChecked={showDoBChecked}
      />
    </>
  );
};

const StyledCheckboxList = styled(CheckboxList)`
  padding: 6px 0;
`;

export default Modals;
