import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Button, IconButton, Tooltip, Typography } from 'cfa-react-components';
import { IconWorld, IconInfoCircle } from '@tabler/icons-react';
import React from 'react';

interface TranslationsHeaderProps {
  noValidTranslations: boolean;
  onTranslateAll: () => void;
}

const TranslationsHeader: React.FC<TranslationsHeaderProps> = ({
  onTranslateAll,
  noValidTranslations = true,
}) => {
  const { t } = useTranslation();
  return (
    <div data-testid="translations-header">
      <StyledFlexContainer>
        <StyledLeftSideSectionContainer>
          <Typography fontWeight="bold" variant="h4">
            {t('TrainingPlans.manageTranslations.translateTocurrentLanguage')}
          </Typography>
        </StyledLeftSideSectionContainer>
        <StyledRightSideSectionContainer>
          <Tooltip
            content={t('TrainingPlans.manageTranslations.translateAllTooltip')}
            placement="left"
            showOnElementEvents={['hover']}
          >
            <IconButton aria-label="info" size="md">
              <IconInfoCircle />
            </IconButton>
          </Tooltip>
          <Button
            color="secondary"
            data-testid="TranslateAllButton"
            disabled={noValidTranslations}
            onClick={onTranslateAll}
          >
            <IconWorld />
            <StyledButtonText>{t('Button.translateAll')}</StyledButtonText>
          </Button>
        </StyledRightSideSectionContainer>
      </StyledFlexContainer>
    </div>
  );
};

const StyledFlexContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: 24px;
`;

const StyledLeftSideSectionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledRightSideSectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const StyledButtonText = styled.span`
  margin-left: 12px;
`;

export default TranslationsHeader;
