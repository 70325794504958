import { Document } from '@cfacorp-pathway/xp-api-typescript-client';
import { FC } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { IframeView } from '../common';

interface XylemeProps {
  /** Xyleme document. */
  document: Document;
}

/** Xyleme document. */
const Xyleme: FC<XylemeProps> = ({ document }) => {
  const { xylemeContentFromCdn } = useFlags();

  return (
    <IframeView
      url={xylemeContentFromCdn ? document.contentUrl : document.contentApiUrl}
    />
  );
};

export default Xyleme;
