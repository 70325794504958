import { createSelector } from 'reselect';
import isEqual from 'lodash/isEqual';
import { RootState } from '..';

export const manageTranslationsState = (state: RootState) =>
  state.manageTranslations;

export const manageTranslations = createSelector(
  manageTranslationsState,
  state => state.mutable,
);

export const areTranslationsEdited = createSelector(
  manageTranslationsState,
  state => !isEqual(state.mutable, state.initial),
);
