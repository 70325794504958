import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  TextFieldType,
  useBreakpoints,
  useMediaQuery,
} from 'cfa-react-components';
import { selectSearchFilter } from 'store/managePlansFilter/selector';
import {
  clearManagePlansSearchFilter,
  setManagePlansSearchFilter,
} from 'store/managePlansFilter/slice';
import {
  isOperatorConsultant,
  selectUserIsLicenseeStaff,
} from 'store/user/selectors';
import { useFlags } from 'launchdarkly-react-client-sdk';
import FilterAndSortMobile from './FilterAndSortMobile';
import LicenseeOptions from './LicenseeOptions';
import LicenseeOptionsMobile from './LicenseeOptionsMobile';
import PlansList from './PlansList';
import PrintReport from './PrintReport';
import { withRoles } from '@/components/ConfirmationModal/withRoles';
import SearchFilterHeader from '@/components/SearchFilterHeader/SearchFilterHeader';
import { setHeader } from '@/store/header/slice';
import { ReportToPrint } from '@/types/types';
import useGetPlansWithLocations from '@/utils/reportPrint/useGetPlansWithLocations';

const ReportsPlansTab = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const breakpoints = useBreakpoints();
  const isSmAndDown = useMediaQuery(breakpoints.down('sm'));
  const { licensee: licenseeFeatureFlag } = useFlags();

  const userIsLicenseeStaff = useSelector(selectUserIsLicenseeStaff);
  const isOperatorConsultantUser = useSelector(isOperatorConsultant);
  const searchFilter = useSelector(selectSearchFilter) ?? '';

  const [reportToPrint, setReportToPrint] = useState<ReportToPrint>();

  const { isFetchingPlans, planLocations } = useGetPlansWithLocations();

  useEffect(() => {
    dispatch(setHeader(t('Generic.reports')));
  }, [dispatch, t]);

  const handlePrintReport = ({
    category,
    id,
    isCompliancePlan,
    locations,
    planName,
  }: {
    category: string;
    id: string;
    isCompliancePlan: boolean;
    locations: string[];
    planName: string;
  }) => {
    setReportToPrint({
      category,
      id,
      isCompliancePlan,
      locations,
      planName,
    });
  };

  return (
    <>
      <StyledContent>
        <LicenseeOptionsMobile />
        <SearchFilterHeader
          hideTitle={
            (isOperatorConsultantUser || userIsLicenseeStaff) &&
            licenseeFeatureFlag &&
            !!isSmAndDown
          }
          onChange={(e: React.ChangeEvent<TextFieldType>) =>
            dispatch(
              setManagePlansSearchFilter({ searchFilter: e.target.value }),
            )
          }
          onClear={() => dispatch(clearManagePlansSearchFilter())}
          searchPlaceholder={t('Reports.filtering.searchPlans')}
          searchValue={searchFilter}
          title={t('Reports.tabPlans')}
        />
        <FilterAndSortMobile isFetching={isFetchingPlans} />
        <PlansListWrapper>
          <LicenseeOptions isFetching={isFetchingPlans} />
          <PlansList
            isFetching={isFetchingPlans}
            onPrintReport={({
              category,
              id,
              isCompliancePlan,
              locations,
              planName,
            }) =>
              handlePrintReport({
                category,
                id,
                isCompliancePlan,
                locations,
                planName,
              })
            }
          />
        </PlansListWrapper>
      </StyledContent>
      <PrintReport
        planLocations={planLocations}
        reportToPrint={reportToPrint as ReportToPrint}
        turnOffPrintTrigger={() =>
          setReportToPrint({
            category: '',
            id: '',
            isCompliancePlan: false,
            locations: [],
            planName: '',
          })
        }
      />
    </>
  );
};

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  max-width: 100%;
`;

const PlansListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  position: relative;
  flex-grow: 1;
  max-width: 100%;
`;

const ReportsPlansTabWithRoles = withRoles(ReportsPlansTab, 'reporting.plans');

export default ReportsPlansTabWithRoles;
