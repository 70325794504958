import { Label } from '@/components/Cards/StickyFilterCard/CheckboxFilterSection';
import ClearFiltersHeader from '@/components/ClearFiltersHeader/ClearFiltersHeader';
import SortFilterHeader from '@/components/SortFilterHeader/SortFilterHeader';
import {
  clearTeamMembersCheckboxFilters,
  removeTeamMembersFilter,
  setTeamMembersSort,
} from '@/store/teamMembersFilter/slice';
import { DropdownOptions } from '@/types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

interface SortProps {
  currentFilters: string[];
  sort: string;
  sortOptions: DropdownOptions[];
  statusLabels: Label;
  total: number;
}

const Sort: React.FC<SortProps> = ({
  currentFilters,
  sort,
  sortOptions,
  statusLabels,
  total,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <>
      <SortFilterHeader
        label={t('TrainingPlans.filtering.sortBy')}
        onChange={option => {
          dispatch(setTeamMembersSort({ sort: option?.value }));
        }}
        onClear={() => {
          dispatch(clearTeamMembersCheckboxFilters());
        }}
        options={sortOptions}
        showClear={false}
        showCompletedPlansOption={false}
        showMyCompletedPlansOnlySwitchValue={false}
        text={`${total ?? 0} ${t('Generic.teamMembers')}`}
        value={sortOptions?.find(option => option.value === sort)}
      />

      <ClearFiltersHeader
        aliases={statusLabels}
        clearAllFilters={() => {
          dispatch(clearTeamMembersCheckboxFilters());
        }}
        clearFilter={value => {
          dispatch(removeTeamMembersFilter({ filter: value }));
        }}
        filters={currentFilters}
      />
    </>
  );
};
export default Sort;
