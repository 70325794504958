import Constants from 'constants/index';
import {
  IconUsersPlus,
  IconUsersGroup,
  IconSignature,
  IconTrash,
  IconLanguage,
  IconCopy,
  IconPrinter,
  IconComponents,
} from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { Divider } from 'cfa-react-components';
import PopoverMenuButtonItem from '@/components/PopoverMenuButton/PopoverMenuButtonItem';
import PopoverMenuButton from '@/components/PopoverMenuButton/PopoverMenuButton';
import { useDispatch, useSelector } from 'react-redux';
import { getHighestUserPermission } from '@/utils/user';
import { selectUserPermissions } from '@/store/user/selectors';
import { showTrainingMode } from '@/store/trainingMode/slice';

interface PlanCardMenuProps {
  assignPlan: () => void;
  deletePlan: () => void;
  duplicatePlan: () => void;
  editTranslations: () => void;
  navigateToEditPlan: () => void;
  printReport: () => void;
  renamePlan: () => void;
  sendPlanToParent: () => void;
}

const PlanCardMenu: React.FC<PlanCardMenuProps> = ({
  assignPlan,
  deletePlan,
  duplicatePlan,
  editTranslations,
  navigateToEditPlan,
  printReport,
  renamePlan,
  sendPlanToParent,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userPermissions = useSelector(selectUserPermissions);
  const highestUserPermission = getHighestUserPermission(userPermissions);

  const userIsLeader =
    Constants.USER_PERMISSION_NAMES.LEADER === highestUserPermission;
  const userIsOperator =
    Constants.USER_PERMISSION_NAMES.OPERATOR === highestUserPermission;
  const userIsStakeholder =
    Constants.USER_PERMISSION_NAMES.STAKEHOLDER === highestUserPermission;
  const userIsAdmin =
    Constants.USER_PERMISSION_NAMES.ADMIN === highestUserPermission;

  const shouldSeeAllOptions =
    userIsLeader || userIsOperator || userIsStakeholder || userIsAdmin;

  const limitedMenuItems = [
    {
      dataTestId: 'ThreeDotMenuAssign',
      icon: <IconUsersPlus />,
      onClick: () => assignPlan(),
      text: t('Generic.assign'),
    },
    {
      dataTestId: 'ThreeDotMenuTrainTogether',
      icon: <IconUsersGroup />,
      onClick: () => {
        dispatch(showTrainingMode());
        sendPlanToParent();
      },
      text: t('Generic.trainTogether'),
    },
    { type: 'divider' },
    {
      dataTestId: 'ThreeDotMenuPrintReport',
      icon: <IconPrinter />,
      onClick: () => printReport(),
      text: t('Button.printReport'),
      isDestructive: false,
    },
  ];

  const allMenuItems = [
    {
      dataTestId: 'ThreeDotMenuAssign',
      icon: <IconUsersPlus />,
      onClick: () => assignPlan(),
      text: t('Generic.assign'),
    },
    {
      dataTestId: 'ThreeDotMenuTrainTogether',
      icon: <IconUsersGroup />,
      onClick: () => {
        dispatch(showTrainingMode());
        sendPlanToParent();
      },
      text: t('Generic.trainTogether'),
    },
    { type: 'divider' },
    {
      dataTestId: 'ThreeDotMenuRenamePlan',
      icon: <IconSignature />,
      onClick: () => renamePlan(),
      text: t('TrainingPlans.manageThreeDotMenu.rename'),
    },
    {
      dataTestId: 'ThreeDotMenuEditPlan',
      icon: <IconComponents />,
      onClick: () => navigateToEditPlan(),
      text: t('Generic.editPlanContent'),
    },
    {
      dataTestId: 'ThreeDotMenuManageTranslations',
      icon: <IconLanguage />,
      onClick: () => editTranslations(),
      text: t('Generic.editTranslations'),
    },
    {
      dataTestId: 'ThreeDotMenuDuplicatePlan',
      icon: <IconCopy />,
      onClick: () => duplicatePlan(),
      text: t('TrainingPlans.manageThreeDotMenu.duplicate'),
    },
    {
      dataTestId: 'ThreeDotMenuDeletePlan',
      icon: <IconTrash data-testid="PlanDelete" />,
      onClick: () => deletePlan(),
      text: t('TrainingPlans.manageThreeDotMenu.delete'),
      isDestructive: true,
    },
    { type: 'divider' },
    {
      dataTestId: 'ThreeDotMenuPrintReport',
      icon: <IconPrinter />,
      onClick: () => printReport(),
      text: t('Button.printReport'),
    },
  ];

  const menuItems = () => {
    if (shouldSeeAllOptions) {
      return allMenuItems;
    }

    return limitedMenuItems;
  };

  return (
    <PopoverMenuButton dataTestId="PlanCardMenu">
      {menuItems().map((item, index) =>
        item.type === 'divider' ? (
          <Divider
            key={`divider-${index}`}
            style={{ margin: '0.5em 0' }}
            variant="middle"
          />
        ) : (
          <PopoverMenuButtonItem
            key={item.dataTestId}
            dataTestId={item.dataTestId}
            icon={item.icon}
            onClick={item.onClick!}
            text={item.text!}
            isDestructive={item.isDestructive}
          />
        ),
      )}
    </PopoverMenuButton>
  );
};

export default PlanCardMenu;
