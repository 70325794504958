import Fab from '@/components/Fab/Fab';
import FabPopup from '@/components/Fab/FabPopup';
import FabPopupItem from '@/components/Fab/FabPopupItem';
import { IconUserPlus } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

interface AddTeamMemberFabProps {
  onAddTeamMember: () => void;
  setShowAddTeamMembersMenu: (show: boolean) => void;
  showAddTeamMembersMenu: boolean;
}

const AddTeamMemberFab: React.FC<AddTeamMemberFabProps> = ({
  onAddTeamMember,
  setShowAddTeamMembersMenu,
  showAddTeamMembersMenu,
}) => {
  const { t } = useTranslation();
  return (
    <Fab
      hasMenu={true}
      isOpen={showAddTeamMembersMenu}
      onClick={() => setShowAddTeamMembersMenu(!showAddTeamMembersMenu)}
      onClose={() => setShowAddTeamMembersMenu(false)}
    >
      <FabPopup>
        <FabPopupItem
          data-testid="AddTeamMembersMenu"
          icon={<IconUserPlus />}
          onClick={onAddTeamMember}
          title={t('Generic.teamMembers')}
        />
      </FabPopup>
    </Fab>
  );
};

export default AddTeamMemberFab;
