import Constants from 'constants/index';
import CheckboxList from '@/components/Checkbox/CheckboxList';
import PrintReportModal from '@/components/popups/PrintReportModal';
import { hidePrintReport } from '@/store/printReport/slice';
import { generateReportAndMessageReactNative } from '@/utils/generateReport';
import useGetStoreReportToPrint from '@/utils/reportPrint/useGetStoreReportToPrint';
import {
  ChecklistDTO,
  UserChecklistResponse,
  UserChecklistResultDTO,
} from '@cfacorp-pathway/xp-api-typescript-client';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { mapCategoryToAliasTranslation } from '@/utils/categoryUtils';
import { arrayToCommaString } from '@/utils/arrayToCommaString';
import { removeUnderscoreAndCapitalizeString } from '@/utils/removeUnderscoreAndCapitalizeString';
import { getNameFromLanguage } from '@/utils/language';
import { LanguageObject } from '@/types';

interface PrintReportProps {
  assignedTeamMembersList: UserChecklistResultDTO | undefined;
  complianceReportToPrint: any;
  currentFilters: string[];
  isCompliance: boolean;
  isFetching: boolean;
  isFetchingPlan: boolean;
  isFetchingAssignedTeamMembers: boolean;
  isFoodSafety: boolean;
  isPrintReportOpen: boolean;
  isStatusReportFetching: boolean;
  filterLocations: string[];
  noLocationsSelected: boolean;
  plan: UserChecklistResponse | ChecklistDTO | ChecklistDTO[];
  selectedReportLocations: string[];
  setNoLocationsSelected: (set: boolean) => void;
  setSelectedReportLocations: (locations: string[]) => void;
  setShowChooseReportLocationsPopup: (show: boolean) => void;
  showChooseReportLocationsPopup: boolean;
  statusReportData: any;
  stepCount: number;
}

const PrintReport: React.FC<PrintReportProps> = ({
  assignedTeamMembersList,
  complianceReportToPrint,
  currentFilters,
  filterLocations,
  isCompliance,
  isFetching,
  isFetchingAssignedTeamMembers,
  isFetchingPlan,
  isFoodSafety,
  isPrintReportOpen,
  isStatusReportFetching,
  noLocationsSelected,
  plan,
  selectedReportLocations,
  setNoLocationsSelected,
  setSelectedReportLocations,
  setShowChooseReportLocationsPopup,
  showChooseReportLocationsPopup,
  statusReportData,
  stepCount,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showDoBChecked, setShowDoBChecked] = useState(false);

  const onPrintReport = () => {
    dispatch(hidePrintReport());
    filterLocations?.length > 1 || isFoodSafety
      ? setShowChooseReportLocationsPopup(true)
      : handlePrintReport();
  };

  // Print Report
  useEffect(() => {
    if (isPrintReportOpen) {
      onPrintReport();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPrintReportOpen]);

  useEffect(() => {
    handleCurrentFilteredLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilters, filterLocations]);

  const storeReportToPrint = useGetStoreReportToPrint(
    stepCount,
    selectedReportLocations,
    assignedTeamMembersList,
  );

  const tableHead = useMemo(
    () => [
      [
        Constants.REPORT_TABLE.NAME,
        ...(showDoBChecked ? [Constants.REPORT_TABLE.DATE_OF_BIRTH] : []),
        ...(isCompliance
          ? [
              Constants.REPORT_TABLE.COMPLETION_DATE,
              Constants.REPORT_TABLE.EXPIRATION_DATE,
            ]
          : [
              Constants.REPORT_TABLE.COMPLETED,
              Constants.REPORT_TABLE.TOTAL_TIME_SPENT_ON_PLAN,
              Constants.REPORT_TABLE.STATUS,
              Constants.REPORT_TABLE.COMPLETION_DATE,
            ]),
      ],
    ],
    [showDoBChecked, isCompliance],
  );

  const reportDataToPrint = isCompliance
    ? complianceReportToPrint
    : storeReportToPrint;

  const reportToGenerate = {
    category: isCompliance
      ? t(mapCategoryToAliasTranslation(Constants.PLAN_CATEGORIES.COMPLIANCE))
      : t(
          mapCategoryToAliasTranslation((plan as ChecklistDTO)?.category ?? ''),
        ),
    foodSafetyImage: (statusReportData as any)?.foodSafetyLetters?.[0]?.content,
    location: `${t('Generic.at')} ${
      selectedReportLocations?.length === 0
        ? filterLocations
        : !selectedReportLocations.length
          ? selectedReportLocations.toString()
          : arrayToCommaString(selectedReportLocations, t('Generic.and'))
    }`,
    name: isCompliance
      ? removeUnderscoreAndCapitalizeString(statusReportData?.courseName ?? '')
      : getNameFromLanguage((plan as ChecklistDTO)?.name as LanguageObject),
    reportToPrint: reportDataToPrint.map((teamMember: any) =>
      tableHead?.[0]?.map(headerItem => teamMember?.[headerItem]),
    ),
    tableHead,
  };

  const handleCurrentFilteredLocations = () => {
    if (!currentFilters.length) {
      setSelectedReportLocations(filterLocations);
    } else {
      const currentFilteredLocations = currentFilters.filter(filter => {
        const isNumber = /^\d+$/.test(filter);
        return isNumber;
      });
      if (!currentFilteredLocations.length) {
        setSelectedReportLocations(filterLocations);
      } else {
        setSelectedReportLocations(currentFilteredLocations);
      }
    }
    if (!!noLocationsSelected) {
      setNoLocationsSelected(false);
    }
    setShowDoBChecked(false);
  };

  const handlePrintReport = () => {
    if (!selectedReportLocations.length && !!filterLocations.length) {
      setNoLocationsSelected(true);
    } else {
      setShowChooseReportLocationsPopup(false);
      generateReportAndMessageReactNative(
        reportToGenerate,
        reportDataToPrint,
        tableHead,
      );
      handleCurrentFilteredLocations();
    }
  };

  const onPrintReportCancel = () => {
    setShowChooseReportLocationsPopup(false);
    handleCurrentFilteredLocations();
  };

  return (
    <PrintReportModal
      bodyText={t('TrainingPlans.chooseWhatToInclude')}
      children={
        filterLocations.length > 1
          ? filterLocations.map((id, idx) => (
              <StyledCheckboxList
                id={id}
                idx={idx}
                key={idx}
                selectedLocations={selectedReportLocations}
                setSelectedLocations={setSelectedReportLocations}
              />
            ))
          : null
      }
      handleToggleDoBCheckbox={() => setShowDoBChecked(!showDoBChecked)}
      headerText={t('Generic.printReport')}
      isFoodSafety={isFoodSafety}
      isOpen={showChooseReportLocationsPopup}
      isPrintDisabled={
        isFetching ||
        isFetchingPlan ||
        isFetchingAssignedTeamMembers ||
        isStatusReportFetching
      }
      noLocationsSelected={noLocationsSelected}
      onClose={onPrintReportCancel}
      primaryButtonHandler={handlePrintReport}
      primaryButtonText={t('Button.print')}
      secondaryButtonHandler={onPrintReportCancel}
      secondaryButtonText={t('Button.cancel')}
      selectedReportLocations={selectedReportLocations}
      showDoBChecked={showDoBChecked}
    />
  );
};

const StyledCheckboxList = styled(CheckboxList)`
  padding: 6px 0;
`;

export default PrintReport;
