import Constants from 'constants/index';
import { AuthResponse } from '@cfacorp-pathway/xp-api-typescript-client';
import type { Country, UserState } from '@/store/user/slice';
import type { OktaUserRaw } from '@/types';

export const getLaunchdDarklyUser = ({
  audience,
  email,
  fullName,
  isInternationalUser,
  locations,
  userType,
}: UserState) => {
  if (isInternationalUser) {
    return {
      anonymous: true,
      audience,
      kind: 'user',
      locations,
      userType,
    };
  }
  return {
    audience,
    email,
    key: email,
    kind: 'user',
    locations,
    name: fullName,
    userType,
  };
};

export const getUserInfo = (
  token: OktaUserRaw,
  language?: string,
  country?: Country,
) => {
  const { nickname, family_name, email, userType } = {
    ...token,
  };

  const fullName = `${nickname} ${family_name}`;
  const firstName = `${nickname}`;

  return {
    firstName,
    fullName,
    email,
    userType,
    language,
    country,
  };
};

export const getUserInfoFromAuth = (resp: AuthResponse) => {
  const uniqueLocations = Array.from(
    new Set(resp.user?.locations?.map(loc => loc.location?.number)),
  ).filter(Boolean) as string[];
  const reducedPermissions = resp.user?.locations?.reduce(
    (acc, { location, permissions }) => {
      const number = location?.number;
      if (!number) {
        return acc;
      }
      const updatedAcc = { ...acc };
      permissions?.forEach(permission => {
        updatedAcc[permission] = [...(updatedAcc[permission] || []), number];
      });
      return updatedAcc;
    },
    {} as Record<string, string[]>,
  );
  const isLicensee = resp.user?.audience === Constants.USER_AUDIENCE.LICENSEE;

  return {
    audience: resp.user?.audience!,
    cdn: resp.cdn ?? [],
    country: { id: resp.user?.country! },
    features: resp.features!,
    isLicensee,
    language: resp.user?.language!,
    locations: uniqueLocations,
    locationsWithPermissions: resp.user?.locations ?? [],
    permissions: reducedPermissions!,
    selectedLocation: resp.user?.selectedLocation ?? null,
    userId: resp.user?.id!,
  };
};

export const getHighestUserPermission = (
  permissions: Record<string, string[]> = {},
) => {
  if (Constants.USER_PERMISSIONS.ADMIN in permissions) {
    return Constants.USER_PERMISSION_NAMES.ADMIN;
  }
  if (Constants.USER_PERMISSIONS.STAKEHOLDER in permissions) {
    return Constants.USER_PERMISSION_NAMES.STAKEHOLDER;
  }
  if (Constants.USER_PERMISSIONS.OPERATOR in permissions) {
    return Constants.USER_PERMISSION_NAMES.OPERATOR;
  }
  if (Constants.USER_PERMISSIONS.LEADER in permissions) {
    return Constants.USER_PERMISSION_NAMES.LEADER;
  }
  if (Constants.USER_PERMISSIONS.TRAINER in permissions) {
    return Constants.USER_PERMISSION_NAMES.TRAINER;
  }
  return Constants.USER_PERMISSION_NAMES.LOGIN;
};
