import { get, uniq } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from '..';
import Constants from '@/constants';

export const selectReducerState = (state: RootState) => state.user;

export const selectMonitoringEnabled = (state: RootState) =>
  state.user.features.monitoring?.enabled ?? false;

export const selectAnalyticsEnabled = (state: RootState) =>
  state.user.features.analytics?.enabled ?? false;

export const selectUserFullName = createSelector(
  selectReducerState,
  reducerState => reducerState.fullName,
);

export const selectUserType = createSelector(
  selectReducerState,
  reducerState => reducerState.userType,
);

export const isUserLicensee = createSelector(
  selectReducerState,
  reducerState => reducerState.isLicensee,
);

export const isUserInternational = createSelector(
  selectReducerState,
  reducerState => reducerState.isInternationalUser,
);

export const isOperatorConsultant = createSelector(
  selectReducerState,
  reducerState =>
    (reducerState.permissions?.OPERATOR?.length ?? 0) > 0 &&
    reducerState?.licenseeLocations?.length > 0 &&
    reducerState?.audience === Constants.USER_AUDIENCE.OPERATOR,
);

export const selectUserLicenseeLocations = createSelector(
  selectReducerState,
  reducerState => reducerState.licenseeLocations,
);

export const selectUserIsLicenseeStaff = createSelector(
  selectReducerState,
  reducerState =>
    (reducerState.permissions?.[Constants.USER_PERMISSIONS.LICENSEESTAFF]
      ?.length ?? 0) > 0,
);

export const selectUserFranchiseeLocations = createSelector(
  selectReducerState,
  reducerState => reducerState.franchiseeLocations,
);

export const selectUserHasSupportCenterLocation = createSelector(
  selectReducerState,
  reducerState => reducerState?.supportCenterLocations?.length > 0,
);

export const selectUserId = createSelector(
  selectReducerState,
  reducerState => reducerState.userId,
);

export const selectUserPermissions = createSelector(
  selectReducerState,
  reducerState => reducerState.permissions,
);

export const selectUserRestrictions = createSelector(
  selectReducerState,
  reducerState =>
    reducerState.country.id === Constants.SUPPORTED_COUNTRIES.GB.id
      ? [Constants.USER_RESTRICTIONS.GREAT_BRITAIN_USER]
      : [],
);

export const selectUserFeatureFlags = createSelector(
  selectReducerState,
  reducerState => reducerState.features,
);

export const selectLocationPermissions = createSelector(
  selectReducerState,
  reducerState => reducerState.locationsWithPermissions,
);

export const selectUserIsStaff = createSelector(
  selectUserPermissions,
  userPermissions => {
    const locations = getLocationsWithPermissionLevel(
      userPermissions,
      Constants.USER_PERMISSIONS.LOGIN,
    );
    return locations?.includes('00000') ?? false;
  },
);

// This currently places admin last since its not a role in CFA stores
export const selectUsersHighestPermissionLevel = createSelector(
  selectReducerState,
  reducerState => {
    if ((reducerState.permissions?.ADMIN?.length ?? 0) > 0) {
      return Constants.USER_PERMISSIONS.ADMIN;
    } else if ((reducerState.permissions?.OPERATOR?.length ?? 0) > 0) {
      return Constants.USER_PERMISSIONS.OPERATOR;
    } else if ((reducerState.permissions?.LEADER?.length ?? 0) > 0) {
      return Constants.USER_PERMISSIONS.LEADER;
    } else if ((reducerState.permissions?.STAKEHOLDER?.length ?? 0) > 0) {
      return Constants.USER_PERMISSIONS.STAKEHOLDER;
    } else if ((reducerState.permissions?.TRAINER?.length ?? 0) > 0) {
      return Constants.USER_PERMISSIONS.TRAINER;
    } else if ((reducerState.permissions?.LOGIN?.length ?? 0) > 0) {
      return Constants.USER_PERMISSIONS.LOGIN;
    }
  },
);

export const selectUserIsAdminOperatorOrStakeholder = createSelector(
  selectUsersHighestPermissionLevel,
  highestPermissionLevel => {
    return (
      highestPermissionLevel === Constants.USER_PERMISSIONS.ADMIN ||
      highestPermissionLevel === Constants.USER_PERMISSIONS.OPERATOR ||
      highestPermissionLevel === Constants.USER_PERMISSIONS.STAKEHOLDER
    );
  },
);

export const selectUserLanguage = createSelector(
  selectReducerState,
  reducerState => reducerState.language,
);

export const selectUserCountry = createSelector(
  selectReducerState,
  reducerState => reducerState.country,
);

export const selectUser = createSelector(selectReducerState, user => ({
  ...user,
}));

const getLocationsWithPermissionLevel = (
  userPermissions: { [key: string]: string[] },
  permissionLevel: string,
): string[] => {
  const permission = Object.keys(userPermissions).find(
    perm => perm === permissionLevel,
  );
  const locations = get(userPermissions, permission ?? '');
  return Array.isArray(locations) && locations.length ? locations : [];
};

export const selectLocationsWithOperatorPermission = createSelector(
  selectUserPermissions,
  userPermissions =>
    getLocationsWithPermissionLevel(
      userPermissions,
      Constants.USER_PERMISSIONS.OPERATOR,
    ),
);

export const selectLocationsWithStakeholderPermission = createSelector(
  selectUserPermissions,
  userPermissions =>
    getLocationsWithPermissionLevel(
      userPermissions,
      Constants.USER_PERMISSIONS.STAKEHOLDER,
    ),
);

export const selectLocationsWithLeaderPermission = createSelector(
  selectUserPermissions,
  userPermissions =>
    getLocationsWithPermissionLevel(
      userPermissions,
      Constants.USER_PERMISSIONS.LEADER,
    ),
);

export const selectLocationsWithTrainerPermission = createSelector(
  selectUserPermissions,
  userPermissions =>
    getLocationsWithPermissionLevel(
      userPermissions,
      Constants.USER_PERMISSIONS.TRAINER,
    ),
);

export const selectLocationsWithLoginPermission = createSelector(
  selectUserPermissions,
  userPermissions =>
    getLocationsWithPermissionLevel(
      userPermissions,
      Constants.USER_PERMISSIONS.LOGIN,
    ),
);

export const selectLocationsWithAdminPermission = createSelector(
  selectUserPermissions,
  userPermissions =>
    getLocationsWithPermissionLevel(
      userPermissions,
      Constants.USER_PERMISSIONS.ADMIN,
    ),
);

// Okta perms are distinct for actual users so there should not be any cases where a user is an operator and leader
// for ex. However this selector is still used to most easily get a list of all locations a user has permissions
export const selectAllLocationsWithAtLeastLeaderPermissions = createSelector(
  selectLocationsWithLeaderPermission,
  selectLocationsWithStakeholderPermission,
  selectLocationsWithOperatorPermission,
  (leaderPerms, stakeholderPerms, operatorPerms) =>
    uniq([...leaderPerms, ...stakeholderPerms, ...operatorPerms]),
);

export const selectAllLocationsWithAtLeastTrainer = createSelector(
  selectLocationsWithLeaderPermission,
  selectLocationsWithStakeholderPermission,
  selectLocationsWithOperatorPermission,
  selectLocationsWithTrainerPermission,
  (leaderPerms, stakeholderPerms, operatorPerms, trainerPerms) =>
    uniq([
      ...leaderPerms,
      ...stakeholderPerms,
      ...operatorPerms,
      ...trainerPerms,
    ]),
);

export const selectAllLocationsWithAllPermissions = createSelector(
  selectLocationsWithLeaderPermission,
  selectLocationsWithStakeholderPermission,
  selectLocationsWithOperatorPermission,
  selectLocationsWithTrainerPermission,
  selectLocationsWithLoginPermission,
  (leaderPerms, stakeholderPerms, operatorPerms, trainerPerms, loginPerms) =>
    uniq([
      ...leaderPerms,
      ...stakeholderPerms,
      ...operatorPerms,
      ...trainerPerms,
      ...loginPerms,
    ]),
);
