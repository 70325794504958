import { createContext, useContext } from 'react';

export type Track = (
  eventName: string,
  properties: Record<string, any>,
) => void;

export const AmplitudeContext = createContext<Track | null>(null);

export const useAmplitudeTrack = () => {
  const context = useContext(AmplitudeContext);
  if (context === undefined) {
    throw new Error('useAmplitude must be used within a AmplitudeProvider');
  }
  return context;
};
