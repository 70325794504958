import Constants from 'constants/index';
import { useEffect, useMemo, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isReportsPath } from '../url';
import { getNameFromLanguage } from '../language';
import { arrayIntersect } from '../keepDuplicatesFromTwoArrays';
import { mapCategoryToAliasTranslation } from '../categoryUtils';
import { generateTotalTime } from '../time';
import {
  useGetAssignedCoursesQuery,
  useGetOperatorsQuery,
  useGetReportsAssignedProgressByPlanQuery,
  useGetReportsTeamMembersQuery,
} from '@/services/pathwayApi';
import { useGetReportsTrainingPlanStatusesQuery } from '@/services/xpApi';
import {
  setHeader,
  setHeaderLabel,
  setHeaderSubtext,
} from '@/store/header/slice';
import {
  isUserLicensee,
  selectAllLocationsWithAtLeastTrainer,
  selectUserLicenseeLocations,
} from '@/store/user/selectors';
import {
  clearTeamMembersCheckboxFilters,
  clearTeamMembersSearchFilter,
  setTeamMembers,
} from '@/store/teamMembersFilter/slice';
import { setAssignedChecklistStatus } from '@/store/trainingMode/slice';
import { selectActiveLicenseeLocation } from '@/store/licenseeLocationPicker/selector';
import { LanguageObject } from '@/types/types';

// For ReportsPlansView
const useGetTeamMembersData = () => {
  const { t } = useTranslation();
  const params = useParams();
  const { id: planId } = params as { id: string };
  const { plansRedesign: plansRedesignFeatureFlag, xpApi: xpApiFeatureFlag } =
    useFlags();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const isReportsView = isReportsPath(location);
  const { search, state } = location;
  const isCompliance = search.includes('compliancePlan');
  const userLicenseeLocations = useSelector(selectUserLicenseeLocations);
  const locationsWithAtLeastTrainer = useSelector(
    selectAllLocationsWithAtLeastTrainer,
  );
  const isLicenseeUser = useSelector(isUserLicensee);
  const activeLicenseeLocation = useSelector(selectActiveLicenseeLocation);

  const [stepCount, setStepCount] = useState(0);
  const [isFoodSafety, setIsFoodSafety] = useState(false);

  const skipPlanAssignedProgressWithoutPlanId =
    !isReportsView || !!isCompliance;

  const {
    data: assignedTeamMembersList,
    isFetching: isFetchingAssignedTeamMembers,
    refetch: refetchAssignedTeamMembers,
    error: assignedTeamMemberError,
    isSuccess,
  } = useGetReportsAssignedProgressByPlanQuery(
    {
      planId: planId,
    },
    {
      skip: !planId || skipPlanAssignedProgressWithoutPlanId,
    },
  );

  /**
   * if is not in report review or is compliance,
   *    need to call report team members without success from useGetReportsAssignedProgressByPlanQuery;
   * else need to wait above query success to call report team members.
   */
  const skipReportsTeamMember =
    !skipPlanAssignedProgressWithoutPlanId && !isSuccess;

  const plan = useMemo(
    () => assignedTeamMembersList?.checklist ?? {},
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [assignedTeamMembersList?.checklist?.id],
  );

  const planLocationId = plan?.ownerId;

  // check current plan is licensee plan or not, if is licensee plan use licensee location to pull team members
  const { locations: reportsPlanLocations, isLicenseePlan } = useMemo(() => {
    if (skipPlanAssignedProgressWithoutPlanId) {
      return { locations: locationsWithAtLeastTrainer };
    }
    if (!planLocationId) {
      return { locations: [] };
    }
    const licenseeLocationIds = userLicenseeLocations.map(
      ({ number }) => number,
    );
    return licenseeLocationIds.includes(planLocationId)
      ? { locations: [planLocationId], isLicenseePlan: true }
      : { locations: locationsWithAtLeastTrainer };
  }, [
    locationsWithAtLeastTrainer,
    userLicenseeLocations,
    planLocationId,
    skipPlanAssignedProgressWithoutPlanId,
  ]);

  // Team Members
  const {
    data: oldReportsTeamMembersData,
    isFetching: oldReportsTeamMembersIsFetching,
    error: oldReportsTeamMembersError,
  } = useGetReportsTeamMembersQuery(
    { locations: reportsPlanLocations },
    {
      skip: !!xpApiFeatureFlag || skipReportsTeamMember,
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    data: xpReportsTeamMemberStatuses,
    isFetching: xpReportsTeamMembersStatusesIsFetching,
    error: xpReportsTeamMembersError,
  } = useGetReportsTrainingPlanStatusesQuery(
    {
      locations: reportsPlanLocations,
    },
    {
      skip: !xpApiFeatureFlag || skipReportsTeamMember,
      refetchOnMountOrArgChange: true,
    },
  );

  const { data: complianceStatus, isSuccess: isAssignedCoursesSuccess } =
    useGetAssignedCoursesQuery(
      {
        courseId: planId,
        location: locationsWithAtLeastTrainer,
      },
      { skip: !planId || !isCompliance },
    );

  const { data: operators } = useGetOperatorsQuery();

  const teamMemberData = useMemo(() => {
    return (
      oldReportsTeamMembersData || xpReportsTeamMemberStatuses
    )?.users?.map(({ user }) => user);
  }, [oldReportsTeamMembersData, xpReportsTeamMemberStatuses]);

  useEffect(() => {
    if (plan?.name) {
      dispatch(setHeader(getNameFromLanguage(plan?.name)));
    }
    if (plan?.category) {
      dispatch(
        setHeaderLabel(t(mapCategoryToAliasTranslation(plan?.category))),
      );
    }
    if (isCompliance) {
      dispatch(
        setHeader(
          getNameFromLanguage(complianceStatus?.courseName as LanguageObject),
        ),
      );
      dispatch(
        setHeaderLabel(
          t(
            mapCategoryToAliasTranslation(Constants.PLAN_CATEGORIES.COMPLIANCE),
          ),
        ),
      );
    }
    if (plan?.estimatedMinutes) {
      dispatch(
        setHeaderSubtext(
          plansRedesignFeatureFlag
            ? t('TrainingPlans.trainTogether.estimatedCompletionTime', {
                estimatedTime: generateTotalTime(
                  plan?.estimatedMinutes,
                  t('Generic.hour'),
                  t('Generic.mins'),
                ),
              })
            : t('TrainingPlans.trainingMode.estimatedCompletionTime', {
                estimatedTime: generateTotalTime(
                  plan?.estimatedMinutes,
                  t('Generic.hour'),
                  t('Generic.mins'),
                ),
              }),
        ),
      );
    }
    return () => {
      dispatch(setHeaderLabel(''));
      dispatch(setHeader(''));
      dispatch(setHeaderSubtext(''));
    };
  }, [
    complianceStatus,
    dispatch,
    isCompliance,
    plan,
    plansRedesignFeatureFlag,
    t,
  ]);

  useEffect(() => {
    if (assignedTeamMembersList) {
      const assignedChecklistStatus = !assignedTeamMembersList?.status
        ?.filter(it => it.status !== Constants.TRAINING_PLANS.UNASSIGNED)
        .every(user => user.status === Constants.TRAINING_PLANS.COMPLETED);
      dispatch(setAssignedChecklistStatus(assignedChecklistStatus));
    }
  }, [assignedTeamMembersList, dispatch]);

  useEffect(() => {
    if (!!complianceStatus?.courseName) {
      setIsFoodSafety(
        getNameFromLanguage(complianceStatus.courseName).includes(
          Constants.FOOD_SAFETY_COURSE_NAME,
        ) && search.includes('compliancePlan'),
      );
    }
  }, [complianceStatus?.courseName, search]);

  useEffect(() => {
    if (isSuccess && !!assignedTeamMembersList && !!teamMemberData) {
      setStepCount(assignedTeamMembersList?.checklist?.stepsTotal!);
      dispatch(
        setTeamMembers({
          teamMembers: assignedTeamMembersList?.status
            ?.map(teamMember => {
              return {
                ...teamMember,
                completionDate: teamMember.completionDate ?? null,
                name: teamMemberData?.find(
                  ({ adId }: any) => teamMember.userId === adId,
                )?.name,
                locations: teamMemberData?.find(
                  ({ adId }: any) => teamMember.userId === adId,
                )?.locations,
              };
            })
            .filter(
              teamMember =>
                teamMember.status !== Constants.TRAINING_PLANS.UNASSIGNED,
            ),
        }),
      );
    }
  }, [assignedTeamMembersList, teamMemberData, dispatch, isSuccess]);

  // Compliance plans
  useEffect(() => {
    if (
      isAssignedCoursesSuccess &&
      complianceStatus?.enrollments !== undefined
    ) {
      dispatch(
        setTeamMembers({
          teamMembers: teamMemberData
            ?.map((teamMember: any) => {
              return {
                userId:
                  (complianceStatus?.enrollments?.[teamMember?.adId] as any)
                    ?.userId ?? teamMember?.adId,
                completedDate:
                  complianceStatus?.enrollments?.[teamMember?.adId]
                    ?.completedDate ?? null,
                courseId:
                  complianceStatus?.enrollments?.[teamMember?.adId]?.courseId ??
                  '',
                dueDate:
                  complianceStatus?.enrollments?.[teamMember?.adId]?.dueDate ??
                  null,
                duration:
                  complianceStatus?.enrollments?.[teamMember?.adId]?.duration ??
                  null,
                enrolledDate:
                  complianceStatus?.enrollments?.[teamMember?.adId]
                    ?.enrolledDate ?? '',
                enrollmentId:
                  complianceStatus?.enrollments?.[teamMember?.adId]
                    ?.enrollmentId ?? '',
                expirationDate:
                  complianceStatus?.enrollments?.[teamMember?.adId]?.certificate
                    ?.expiration ?? '',
                finalScore:
                  complianceStatus?.enrollments?.[teamMember?.adId]
                    ?.finalScore ?? 0,
                hidden:
                  complianceStatus?.enrollments?.[teamMember?.adId]?.hidden ??
                  false,
                locations:
                  (complianceStatus?.enrollments?.[teamMember?.adId] as any)
                    ?.locations ?? teamMember?.locations,
                mostRecentCompletedDate:
                  complianceStatus?.enrollments?.[teamMember?.adId]
                    ?.mostRecentCompletedDate ?? null,
                name: teamMember?.name,
                pathwayCourseId:
                  complianceStatus?.enrollments?.[teamMember?.adId]
                    ?.pathwayCourseId ?? '',
                percentComplete:
                  complianceStatus?.enrollments?.[teamMember?.adId]
                    ?.percentComplete ?? 0,
                startedDate:
                  complianceStatus?.enrollments?.[teamMember?.adId]
                    ?.startedDate ?? '',
                status:
                  complianceStatus?.enrollments?.[teamMember?.adId]?.status ??
                  '',
              };
            })
            .filter(
              (value, index, self) =>
                index ===
                self.findIndex(
                  user => user.userId === value.userId && !user.hidden,
                ),
            ),
        }),
      );
    }
  }, [complianceStatus, teamMemberData, dispatch, isAssignedCoursesSuccess]);

  const isFetching =
    oldReportsTeamMembersIsFetching ||
    xpReportsTeamMembersStatusesIsFetching ||
    isFetchingAssignedTeamMembers;
  const error =
    oldReportsTeamMembersError ||
    xpReportsTeamMembersError ||
    assignedTeamMemberError;

  const operatorLocations = operators?.find(
    operator => operator.id === assignedTeamMembersList?.checklist?.ownerId,
  )?.locations;

  const filterLocations = useMemo(() => {
    if (isLicenseeUser) {
      return [activeLicenseeLocation.number];
    } else if (isCompliance) {
      return locationsWithAtLeastTrainer;
    } else {
      return (
        arrayIntersect(locationsWithAtLeastTrainer, operatorLocations ?? []) ??
        []
      );
    }
  }, [
    activeLicenseeLocation.number,
    isCompliance,
    isLicenseeUser,
    locationsWithAtLeastTrainer,
    operatorLocations,
  ]);

  // Clear Team Members Filters
  useEffect(() => {
    dispatch(clearTeamMembersCheckboxFilters());
    dispatch(clearTeamMembersSearchFilter());
  }, [dispatch]);

  useEffect(() => {
    // We can't call `refetchAssignedTeamMembers` in compliance plans because we don't call `useGetAssignedStatusQuery` on component mount
    if (isCompliance) {
      return;
    }
    isSuccess && refetchAssignedTeamMembers();
    // After we refetch the team members, we set the state back to false
    history.replace({
      state: { refetch: false },
      search: history.location.search,
    });
    // @ts-ignore
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.refetch, isCompliance]);

  return {
    assignedTeamMemberError,
    assignedTeamMembersList,
    error,
    filterLocations,
    isFetching,
    isFoodSafety,
    plan,
    isLicenseePlan,
    refetchAssignedTeamMembers,
    stepCount,
  };
};

export default useGetTeamMembersData;
