import Constants from 'constants/index';
import ManageTranslationsPopup from '@/components/popups/ManageTranslationsModal/ManageTranslations';
import useBugsnagNotify from '@/hooks/useBugsnagNotify';
import toast from 'react-hot-toast';
import { useUpdateTrainingPlanMutation } from '@/services/pathwayApi';
import { selectUserLanguage } from '@/store/user/selectors';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ToastMessageBlock from '@/components/Toasts/SuccessToast';
import { getNameFromLanguage } from '@/utils/language';
import { ChecklistDTO } from '@cfacorp-pathway/xp-api-typescript-client';

interface EditTranslationsProps {
  editTranslations: boolean;
  plan: ChecklistDTO;
  refetchPlans: () => void;
  setEditTranslations: (show: boolean) => void;
}

const EditTranslations: React.FC<EditTranslationsProps> = ({
  editTranslations,
  plan,
  refetchPlans,
  setEditTranslations,
}) => {
  const { t } = useTranslation();
  const [updateTrainingPlan] = useUpdateTrainingPlanMutation();
  const { notifyBugsnag } = useBugsnagNotify();
  const userLanguage = useSelector(selectUserLanguage);

  const onSaveTranslations = (updatedTranslations: any) => {
    const payload = {
      locations: updatedTranslations?.locations,
      checklist: {
        ...updatedTranslations,
      },
    };

    updateTrainingPlan(payload)
      .unwrap()
      .then(() => {
        setEditTranslations(false);
        toast.custom(toastObj => (
          <ToastMessageBlock id={toastObj.id}>
            {`${getNameFromLanguage(updatedTranslations?.name)} ${t(
              'TrainingPlans.translationToastText',
            )} ${
              userLanguage === Constants.LANGUAGE.ENGLISH_LANGUAGE_CODE
                ? Constants.LANGUAGE_OPTIONS.SPANISH
                : Constants.LANGUAGE_OPTIONS.INGLES
            }`}
          </ToastMessageBlock>
        ));
        refetchPlans();
      })
      .catch(err => {
        notifyBugsnag(err);
      });
  };

  return (
    <ManageTranslationsPopup
      isOpen={editTranslations}
      onClose={() => setEditTranslations(false)}
      onSave={onSaveTranslations}
      translatableObject={plan}
    />
  );
};
export default EditTranslations;
