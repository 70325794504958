import { ContentReference } from '@cfacorp-pathway/xp-api-typescript-client';
import { useDispatch } from 'react-redux';
import { TabItem, Tabs } from 'cfa-react-components';
import styled from 'styled-components';
import { setChosenCountryTab } from '@/store/document-transient/slice';
import { COUNTRIES } from '@/utils/document-utils/countries';

type ChooseDocumentCountryTabsProps = {
  activeCountryTab?: string;
  countryHasResource: string[];
};

const ChooseDocumentCountryTabs = ({
  activeCountryTab,
  countryHasResource,
}: ChooseDocumentCountryTabsProps) => {
  const dispatch = useDispatch();

  const handleChange = (tab: string) => {
    dispatch(setChosenCountryTab(tab as ContentReference.country));
  };

  if (countryHasResource?.length <= 1) {
    return null;
  }

  return (
    <TabsContainer>
      <CountryTabs
        activeItemKey={activeCountryTab!}
        color="secondary"
        onChange={handleChange}
      >
        {COUNTRIES.filter(({ id }) => countryHasResource.includes(id)).map(
          ({ id, label }) => (
            <CountryTabItem key={id} itemKey={id}>
              {label || id}
            </CountryTabItem>
          ),
        )}
      </CountryTabs>
    </TabsContainer>
  );
};

const TabsContainer = styled.div`
  margin: 0 -32px 32px;
  width: calc(100% + 64px);
  box-shadow: ${({ theme }) => theme.boxShadow.elevation6};
`;

const CountryTabs = styled(Tabs)`
  .cfa-tabs-list {
    box-sizing: border-box;
    border-bottom: 1px solid;
    border-color: ${({ theme }) => theme.grayScale.gray2};
    padding: 0 32px;
  }
  .cfa-horizontal-slider {
    width: 92px !important;
  }
`;

const CountryTabItem = styled(TabItem)`
  & > button {
    padding: 0 32px;
    margin-bottom: 20px;
    margin-top: 6px;
  }
`;

export default ChooseDocumentCountryTabs;
