import Constants from 'constants/index';
import { isApiError } from 'utils/request';
import {
  ChecklistTemplateDTO,
  OperatorDTO,
} from '@cfacorp-pathway/xp-api-typescript-client';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import TemplateModal from '../CopyTemplateModal/TemplateModal';
import PreviewTemplateModal from '../CopyTemplateModal/PreviewTemplateModal';
import CopyTemplatePopup from '../CopyTemplateModal/CopyTemplatePopup';
import { useAmplitudeTrack } from '@/amplitude/useAmplitude';
import AddPlanWithOperators from '@/components/popups/AddPlanWithOperators';
import {
  useAddTrainingPlanMutation,
  useDuplicateTemplateTrainingPlanMutation,
  useGetTemplatesQuery,
} from '@/services/pathwayApi';
import useBugsnagNotify from '@/hooks/useBugsnagNotify';
import GenericError from '@/components/Error/GenericError';
import {
  isUserLicensee,
  selectAllLocationsWithAtLeastLeaderPermissions,
  selectLocationsWithAdminPermission,
  selectUserId,
  selectUserPermissions,
} from '@/store/user/selectors';
import { selectActiveLicenseeLocation } from '@/store/licenseeLocationPicker/selector';
import { getNameFromLanguage } from '@/utils/language';
import { LanguageObject } from '@/types/types';

interface ModalsProps {
  copiedTemplateId: string;
  handlePreviewPlan: (template: ChecklistTemplateDTO) => void;
  handleTemplateBackButton: () => void;
  isFetchingOperators: boolean;
  onShowCopyPlan: (id: string) => void;
  operators: OperatorDTO[];
  previewTemplate: any;
  refetch: () => void;
  setShowCopyTemplatePopup: (show: boolean) => void;
  setShowCustomPlan: (show: boolean) => void;
  setShowInvalidNameError: (show: boolean) => void;
  setShowPreview: (show: boolean) => void;
  setShowTemplateModal: (show: boolean) => void;
  showCopyTemplatePopup: boolean;
  showCustomPlan: boolean;
  showPreview: boolean;
  showTemplateModal: boolean;
}

const Modals: React.FC<ModalsProps> = ({
  copiedTemplateId,
  handlePreviewPlan,
  handleTemplateBackButton,
  isFetchingOperators,
  onShowCopyPlan,
  operators,
  previewTemplate,
  refetch,
  setShowCopyTemplatePopup,
  setShowCustomPlan,
  setShowInvalidNameError,
  setShowPreview,
  setShowTemplateModal,
  showCopyTemplatePopup,
  showCustomPlan,
  showPreview,
  showTemplateModal,
}) => {
  const history = useHistory();
  const { notifyBugsnag } = useBugsnagNotify();
  const [addTrainingPlan] = useAddTrainingPlanMutation();
  const [duplicateTemplateTrainingPlan] =
    useDuplicateTemplateTrainingPlanMutation();
  const track = useAmplitudeTrack();
  const isLicenseeUser = useSelector(isUserLicensee);
  const locationsWithAtLeastLeader = useSelector(
    selectAllLocationsWithAtLeastLeaderPermissions,
  );
  const locationsWithAtLeastAdmin = useSelector(
    selectLocationsWithAdminPermission,
  );
  const selectedLicenseeLocation = useSelector(selectActiveLicenseeLocation);
  const userId = useSelector(selectUserId);
  const userPermissions = useSelector(selectUserPermissions);
  const userIsStakeholder = userPermissions.hasOwnProperty(
    Constants.USER_PERMISSIONS.STAKEHOLDER,
  );
  const { data: templates, error: templatesError } = useGetTemplatesQuery();
  const operatorsWhereUserIsLeader = operators?.filter(operator =>
    operator?.locations?.some(operatorLocation =>
      locationsWithAtLeastLeader.includes(operatorLocation),
    ),
  );

  if (isApiError(templatesError)) {
    notifyBugsnag(templatesError);
    return <GenericError />;
  }

  const isValidPlanName = (planName: string, assignedOperator: string) => {
    const noAssignedOperatorAndUserIsNotStakeholder =
      !assignedOperator && !userIsStakeholder;

    if (!planName || noAssignedOperatorAndUserIsNotStakeholder) {
      setShowInvalidNameError(true);
      console.error('invalid name or missing operator');
      notifyBugsnag(
        new Error(
          `Build plans error - no plan name or no operator: ${JSON.stringify({
            planName,
            assignedOperator,
          })}`,
        ),
      );
      return false;
    }
    return true;
  };

  const onAddPlan = (name: string, operator: OperatorDTO) => {
    let ownerId = isLicenseeUser
      ? selectedLicenseeLocation.number
      : operator?.id;
    const licenseeLocation = isLicenseeUser
      ? selectedLicenseeLocation.number
      : null;
    const trimmedName = name?.trim();

    if (!ownerId && locationsWithAtLeastAdmin.length > 0) {
      console.warn(
        'No operator id found for users locations, using current user Id',
      );
      ownerId = userId;
    }
    if (!isValidPlanName(trimmedName, ownerId!)) {
      return;
    }

    addTrainingPlan({
      // If a user's only permission is stakeholder, we use the user id otherwise we use the owner id
      location: licenseeLocation!,
      ownerId: userIsStakeholder ? (ownerId ?? userId) : ownerId!,
      name: {
        en: trimmedName,
        es: trimmedName,
      },
    })
      .unwrap()
      .then(({ id }) => {
        // fire amplitude event
        if (track !== null) {
          track('training_plan_created', {
            training_plan_id: id ?? 'empty',
            owner: ownerId ?? 'empty',
          });
        }
        refetch();
        history.push(
          `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PLAN_PATH_NAME}/${id}`,
        );
      })
      .catch(err => {
        notifyBugsnag(err);
      });
  };

  const onCopyPlan = ({
    name,
    assignedOperator,
  }: {
    name: string;
    assignedOperator: OperatorDTO;
  }) => {
    const licenseeLocation = isLicenseeUser
      ? selectedLicenseeLocation.number
      : null;
    const trimmedName = name?.trim();
    let ownerId = assignedOperator?.id;
    if (!ownerId && locationsWithAtLeastAdmin.length > 0) {
      console.warn(
        'No operator id found for users locations, using current user Id',
      );
      ownerId = userId;
    }
    if (!isValidPlanName(trimmedName, ownerId!)) {
      return;
    }
    duplicateTemplateTrainingPlan({
      location: licenseeLocation!,
      ownerId: ownerId!,
      checklistId: copiedTemplateId,
      name: {
        en: name,
        es: name,
      },
    })
      .unwrap()
      .then(({ id }: any) => {
        // fire amplitude event
        if (track !== null) {
          track('training_template_copied', {
            template_id: copiedTemplateId ?? 'empty',
            operator: assignedOperator ?? 'empty',
          });
        }
        refetch();
        history.push(
          `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PLAN_PATH_NAME}/${id}`,
        );
      })
      .catch(err => {
        notifyBugsnag(err);
      });
  };

  return (
    <>
      {!!operators && (
        <AddPlanWithOperators
          isLicenseeUser={isLicenseeUser}
          open={showCustomPlan}
          operators={operatorsWhereUserIsLeader as OperatorDTO[]}
          setOpen={setShowCustomPlan}
          submitHandler={({ assignedOperator, name }) => {
            onAddPlan(name, assignedOperator);
            setShowCustomPlan(false);
          }}
        />
      )}
      {!!templates?.length && (
        <>
          <TemplateModal
            isOpen={showTemplateModal}
            onClose={() => setShowTemplateModal(false)}
            onCopy={onShowCopyPlan}
            onPreviewTemplateClick={handlePreviewPlan}
            templates={templates}
          />
          <PreviewTemplateModal
            isOpen={showPreview}
            onClose={() => setShowPreview(false)}
            onCopy={onShowCopyPlan}
            onTemplateBackButtonClick={handleTemplateBackButton}
            template={previewTemplate}
          />
        </>
      )}
      {!isFetchingOperators && (
        <CopyTemplatePopup
          isOpen={showCopyTemplatePopup}
          operators={operatorsWhereUserIsLeader as OperatorDTO[]}
          planName={
            getNameFromLanguage(
              templates?.find(template => template.id === copiedTemplateId)
                ?.name as LanguageObject,
            ) ?? ''
          }
          reopenTemplates={() => setShowTemplateModal(true)}
          setOpen={setShowCopyTemplatePopup}
          submitHandler={onCopyPlan}
        />
      )}
    </>
  );
};

export default Modals;
