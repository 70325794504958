import Constants from '@/constants';

export const COUNTRIES: Array<{ id: string; label?: string }> = [
  Constants.SUPPORTED_COUNTRIES.US,
  Constants.SUPPORTED_COUNTRIES.PR,
  Constants.SUPPORTED_COUNTRIES.CA,
  Constants.SUPPORTED_COUNTRIES.GB,
];

/**
 * @param code in [US, GB, PR, CA]
 * @returns label: US, UK, PR, CA
 */
export const getCountryLabel = (code: string): string => {
  if (code === Constants.SUPPORTED_COUNTRIES.GB.id) {
    return Constants.SUPPORTED_COUNTRIES.GB.label;
  }
  return code;
};
