import { RootState } from '..';

export const selectTransitentDocumentState = (state: RootState) =>
  state.documentTransient;

export const selectIsPrinting = (state: RootState) =>
  state.documentTransient.isPrinting;

export const selectIsTranslating = (state: RootState) =>
  state.documentTransient.isTranslating;

export const selectChosenDocumentCountry = (state: RootState) =>
  state.documentTransient.chosenDocumentCountry;

export const selectChosenCountryTab = (state: RootState) =>
  state.documentTransient.chosenCountryTab;
