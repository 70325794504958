import ConfirmationModal from '@/components/popups/ConfirmationModal';
import { LanguageObject } from '@/types';
import { getNameFromLanguage } from '@/utils/language';
import React from 'react';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import ToastMessageBlock from '@/components/Toasts/SuccessToast';
import { useDeleteTrainingPlanMutation } from '@/services/pathwayApi';
import useBugsnagNotify from '@/hooks/useBugsnagNotify';
import { ChecklistDTO } from '@cfacorp-pathway/xp-api-typescript-client';

interface DeletePlanProps {
  plan: ChecklistDTO;
  refetchPlans: () => void;
  setShowDeletePlan: (show: boolean) => void;
  showDeletePlan: boolean;
}

const DeletePlan: React.FC<DeletePlanProps> = ({
  plan,
  refetchPlans,
  setShowDeletePlan,
  showDeletePlan,
}) => {
  const { t } = useTranslation();
  const { notifyBugsnag } = useBugsnagNotify();
  const [deleteTrainingPlan] = useDeleteTrainingPlanMutation();

  const onDeletePlan = () => {
    deleteTrainingPlan({ id: plan.id! })
      .unwrap()
      .then(() => {
        refetchPlans();
        toast.custom(toastObj => (
          <ToastMessageBlock id={toastObj.id}>
            {`${getNameFromLanguage(plan.name as LanguageObject)} ${t('TrainingPlans.toastMessage.deleted')}`}
          </ToastMessageBlock>
        ));
      })
      .catch(err => {
        notifyBugsnag(err);
      });
  };

  return (
    <ConfirmationModal
      bodyText={t('Generic.deleteObject', {
        name: getNameFromLanguage(plan.name as LanguageObject) ?? '',
        object: t('Generic.plan').toLowerCase(),
      })}
      headerText={t('Generic.deleteHeader', {
        type: t('Generic.plan'),
      })}
      isOpen={showDeletePlan}
      onClose={() => setShowDeletePlan(false)}
      primaryButtonHandler={() => {
        onDeletePlan();
        setShowDeletePlan(false);
      }}
      primaryButtonText={t('Button.delete')}
      primaryButtonVariant="destructive"
      secondaryButtonHandler={() => setShowDeletePlan(false)}
      secondaryButtonText={t('Button.cancel')}
    />
  );
};
export default DeletePlan;
