import CheckboxFilterSection, {
  Label,
} from '@/components/Cards/StickyFilterCard/CheckboxFilterSection';
import FilterAndSortButton from '@/components/FilterAndSortButton/FilterAndSortButton';
import SearchFilterHeader from '@/components/SearchFilterHeader/SearchFilterHeader';
import {
  addManagePlansCategoryFilter,
  clearManagePlansSearchFilter,
  removeManagePlansFilter,
  setManagePlansSearchFilter,
  setManagePlansSort,
} from '@/store/managePlansFilter/slice';
import { DropdownOptions } from '@/types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

interface FilterAndSearchProps {
  categoryLabels: Label;
  currentFilters: string[];
  filteredAndSortedPlans: any[];
  isFetchingPlans: boolean;
  isSmAndDown: boolean;
  searchFilter: string;
  sort: string;
  sortOptions: DropdownOptions[];
}

const FilterAndSearch: React.FC<FilterAndSearchProps> = ({
  categoryLabels,
  currentFilters,
  filteredAndSortedPlans,
  isFetchingPlans,
  isSmAndDown,
  searchFilter,
  sort,
  sortOptions,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <>
      <SearchFilterHeader
        onChange={e =>
          dispatch(setManagePlansSearchFilter({ searchFilter: e.target.value }))
        }
        onClear={() => dispatch(clearManagePlansSearchFilter())}
        searchPlaceholder={t('TrainingPlans.filtering.searchManagePlans')}
        searchValue={searchFilter}
        title={t('TrainingPlans.tabManagePlans')}
      />
      {!!isSmAndDown && !isFetchingPlans && (
        <FilterAndSortButton
          onSortChange={option => {
            dispatch(setManagePlansSort({ sort: option.value }));
          }}
          sortOptions={sortOptions}
          sortValue={sortOptions?.find(option => option.value === sort)}
          text={`${t('TrainingPlans.filtering.show')} ${
            filteredAndSortedPlans?.length ?? 0
          } ${t('TrainingPlans.filtering.results')}`}
        >
          <CheckboxFilterSection
            labels={categoryLabels}
            onChange={value => {
              if (!!currentFilters.includes(value)) {
                dispatch(removeManagePlansFilter({ filter: value }));
              } else {
                dispatch(addManagePlansCategoryFilter({ filter: value }));
              }
            }}
            title={t('Browse.categories')}
          />
        </FilterAndSortButton>
      )}
    </>
  );
};
export default FilterAndSearch;
