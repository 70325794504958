import { useBreakpoints, useMediaQuery } from 'cfa-react-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import StickyFilterCard from '@/components/Cards/StickyFilterCard/StickyFilterCard';
import { selectFilters } from '@/store/teamMembersFilter/selector';
import CheckboxFilterSection, {
  Label,
} from '@/components/Cards/StickyFilterCard/CheckboxFilterSection';
import {
  addTeamMembersLocationFilter,
  addTeamMembersStatusFilter,
  removeTeamMembersFilter,
} from '@/store/teamMembersFilter/slice';

interface FilterWebProps {
  filterLocations: string[];
  statusLabels: Label;
  isLicenseePlan?: boolean;
}

const FilterWeb: React.FC<FilterWebProps> = ({
  filterLocations,
  statusLabels,
  isLicenseePlan,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const breakpoints = useBreakpoints();
  const isSmAndDown = useMediaQuery(breakpoints.down('sm'));
  const currentFilters = useSelector(selectFilters);

  return (
    !isSmAndDown && (
      <StickyFilterCard>
        {!!filterLocations &&
          filterLocations?.length > 1 &&
          !isLicenseePlan && (
            <CheckboxFilterSection
              labels={filterLocations.reduce(
                (acc, loc) => ({
                  ...acc,
                  [loc]: {
                    translationString: loc,
                    value: !!(currentFilters as string[]).includes(loc),
                  },
                }),
                {},
              )}
              onChange={value => {
                if (!!(currentFilters as string[]).includes(value)) {
                  dispatch(removeTeamMembersFilter({ filter: value }));
                } else {
                  dispatch(addTeamMembersLocationFilter({ filter: value }));
                }
              }}
              title={t('Generic.locations')}
            />
          )}
        <CheckboxFilterSection
          labels={statusLabels}
          onChange={value => {
            if (!!(currentFilters as string[]).includes(value)) {
              dispatch(removeTeamMembersFilter({ filter: value }));
            } else {
              dispatch(addTeamMembersStatusFilter({ filter: value }));
            }
          }}
          title={t('Generic.status')}
        />
      </StickyFilterCard>
    )
  );
};

export default FilterWeb;
