import { UserClaims } from '@okta/okta-auth-js';
import { z } from 'zod';

export const idTokenClaimsSchema = z.object({
  sub: z.string(),
  name: z.string(),
  email: z.string(),
  ver: z.number(),
  iss: z.string(),
  aud: z.string(),
  iat: z.number(),
  exp: z.number(),
  jti: z.string(),
  amr: z.array(z.string()),
  idp: z.string(),
  nonce: z.string(),
  preferredUsername: z.string(),
  authTime: z.number(),
  atHash: z.string(),
  cfaLocMlmCode: z.array(z.string()).optional(),
  lastName: z.string(),
  cfaPerms: z
    .object({
      P20: z.record(z.array(z.string())),
    })
    .optional(),
  cfaLocations: z.array(z.string()).optional(),
  login: z.string(),
  title: z.string().optional(),
  employeeNumber: z.string().optional(),
  cfaGroups: z.array(z.string()).optional(),
  cfaLocConceptType: z.array(z.string()).optional(),
  cfaLocSubTypeCode: z.array(z.string()).optional(),
  cfaAud: z.string().optional(),
  cfaLocOperatingAs: z.array(z.string()).optional(),
  cfaGuid: z.string().optional(),
  cfaLocRegionName: z.array(z.string()).optional(),
  department: z.string().optional(),
  cfaLocOpTeamName: z.array(z.string()).optional(),
  legalFirstName: z.string().optional(),
  cfaLogin: z.string().optional(),
  cfaLocIsoCountry: z.array(z.string()).optional(),
  cfaLocMarketName: z.array(z.string()).optional(),
  cfaLocTypeCode: z.array(z.string()).optional(),
  operatorNumber: z.string().optional(),
  cfaAppTag: z.string().optional(),
  firstName: z.string(),
  cfaLocState: z.array(z.string()).optional(),
  userType: z.string().optional(),
  cfaLocDistCtr: z.array(z.string()).optional(),
});

export type IdTokenClaims = z.infer<typeof idTokenClaimsSchema>;

export const accessTokenClaimsSchema = z.object({
  ver: z.number(),
  jti: z.string(),
  iss: z.string(),
  aud: z.string(),
  sub: z.string(),
  iat: z.number(),
  exp: z.number(),
  cid: z.string(),
  uid: z.string(),
  scp: z.array(z.string()),
  authTime: z.number(),
  cfaGroups: z.array(z.string()).optional(),
  cfaPerms: z
    .object({
      P20: z.record(z.array(z.string())),
    })
    .optional(),
  cfaLocIsoCountry: z.array(z.string()).optional(),
  cfaLocList: z.array(z.string()).optional(),
  cfaAud: z.string().optional(),
  nickname: z.string(),
  cfaGuid: z.string().optional(),
  userType: z.string().optional(),
  cfaAppTag: z.string().optional(),
  familyName: z.string(),
  email: z.string(),
  employeeNumber: z.string().optional(),
});

export type AccessTokenClaims = z.infer<typeof accessTokenClaimsSchema>;

const toCamelCase = (input: string): string => {
  if (!input.includes('-') && !input.includes('_')) {
    return input;
  }
  return input
    .toLowerCase()
    .replace(/[-_](.)/g, (_, char) => char.toUpperCase());
};

export const parseIdUserClaims = (
  claims?: UserClaims,
): IdTokenClaims | undefined => {
  if (!claims) {
    return undefined;
  }

  const parsedClaims = Object.entries(claims).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [toCamelCase(key)]: value,
    }),
    {} as Record<string, unknown>,
  );
  return idTokenClaimsSchema.parse(parsedClaims);
};

export const parseAccessTokenClaims = (
  claims?: UserClaims,
): AccessTokenClaims | undefined => {
  if (!claims) {
    return undefined;
  }

  const parsedClaims = Object.entries(claims).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [toCamelCase(key)]: value,
    }),
    {} as Record<string, unknown>,
  );

  return accessTokenClaimsSchema.parse(parsedClaims);
};
