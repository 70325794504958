import { useTranslation } from 'react-i18next';
import ConfirmationModal from 'components/popups/ConfirmationModal';

interface ValidationErrorPopupProps {
  showValidationError: boolean;
  setShowValidationError: (show: boolean) => void;
}

const ValidationErrorPopup: React.FC<ValidationErrorPopupProps> = ({
  showValidationError,
  setShowValidationError,
}) => {
  const { t } = useTranslation();

  return (
    <ConfirmationModal
      bodyText={t('TrainingPlans.buildQuizzes.validateRequiredFields')}
      headerText={t('TrainingPlans.buildQuizzes.missingRequiredFields')}
      isOpen={showValidationError}
      onClose={() => setShowValidationError(false)}
      primaryButtonColor="secondary"
      primaryButtonHandler={() => setShowValidationError(false)}
      primaryButtonText={t('Button.okay')}
    />
  );
};

export default ValidationErrorPopup;
