import { mapCategoryToColor } from 'utils/categoryUtils';
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { QuizDefault } from 'icons';
import {
  Button,
  Card,
  CardContent,
  Typography,
  useBreakpoints,
  useMediaQuery,
} from 'cfa-react-components';
import { CustomQuizDTO } from '@cfacorp-pathway/xp-api-typescript-client';
import { getNameFromLanguage } from '@/utils/language';

interface QuizLibraryCardProps {
  onAddQuiz: (quiz: CustomQuizDTO) => void;
  onPreviewQuiz: (quiz: CustomQuizDTO) => void;
  quiz: CustomQuizDTO;
  quizAddButtonTestId: string;
  quizNameTestId: string;
}

interface QuizCardStyleProps {
  $backgroundColor?: string;
  $isSmAndUp?: boolean;
}

const QuizLibraryCard: React.FC<QuizLibraryCardProps> = ({
  onAddQuiz,
  onPreviewQuiz,
  quiz,
  quizAddButtonTestId = 'quizAddButtonTest',
  quizNameTestId = 'quizNameTest',
}) => {
  const { t } = useTranslation();
  const breakpoints = useBreakpoints();
  const isSmAndUp = useMediaQuery(breakpoints.up('sm'));
  const quizName = getNameFromLanguage(quiz?.name!);

  return (
    <StyledQuizCard>
      <QuizCardImageWrapper $backgroundColor={mapCategoryToColor('default')}>
        <QuizDefault />
      </QuizCardImageWrapper>
      <QuizCardContent $isSmAndUp={isSmAndUp}>
        <StyledQuizLibraryCardLeft>
          <StyledQuizLibraryCardName data-testid={quizNameTestId}>
            {quizName}
          </StyledQuizLibraryCardName>
        </StyledQuizLibraryCardLeft>
        <StyledQuizLibraryCardRight $isSmAndUp={isSmAndUp}>
          <StyledQuizButton
            color="secondary"
            data-testid={'quizPreviewButtonTest'}
            onClick={() => onPreviewQuiz(quiz)}
            variant="outlined"
          >
            {t('Button.preview')}
          </StyledQuizButton>
          <StyledQuizButton
            color="secondary"
            data-testid={quizAddButtonTestId}
            onClick={() => onAddQuiz(quiz)}
            variant="filled"
          >
            {t('Button.add')}
          </StyledQuizButton>
        </StyledQuizLibraryCardRight>
      </QuizCardContent>
    </StyledQuizCard>
  );
};

const StyledQuizCard = styled(Card)`
  animation: fadeIn linear 0.3s;
  margin: 8px 0;
  flex-direction: row;
  padding: 0;
  height: auto;
  position: relative;
  overflow: visible;
`;

const QuizCardImageWrapper = styled.div<QuizCardStyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  background: ${props => props.$backgroundColor};
  flex-shrink: 0;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
  padding: 1.5rem 1rem;
`;

const QuizCardContent = styled(CardContent)<QuizCardStyleProps>`
  display: flex;
  flex-direction: ${({ $isSmAndUp }) => ($isSmAndUp ? 'row' : 'column')};
  align-items: ${({ $isSmAndUp }) => ($isSmAndUp ? 'center' : 'flex-start')};
  justify-content: space-between;
  width: 100%;
  padding: 16px !important;
`;

const StyledQuizLibraryCardLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-grow: 1;
  max-width: 100%;
  overflow: hidden;
  margin-right: 1rem;
`;

const StyledQuizLibraryCardRight = styled.div<QuizCardStyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: ${({ $isSmAndUp }) => ($isSmAndUp ? '0' : '1rem')};
  gap: 1rem;
  flex-direction: row;
  width: ${({ $isSmAndUp }) => ($isSmAndUp ? 'auto' : '100%')};
`;

const StyledQuizLibraryCardName = styled(Typography)`
  font-weight: 500;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  min-width: 0;
  max-width: 100%;
`;

const StyledQuizButton = styled(Button)`
  min-width: unset;
`;

export default QuizLibraryCard;
