import {
  Container as DragAndDropContainer,
  DropResult,
} from 'react-smooth-dnd';
import { arrayMoveImmutable } from 'array-move';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DocumentDTO } from '@cfacorp-pathway/xp-api-typescript-client';
import Subtitle from '../Subtitle';
import { IntlSubcategoryDTO } from '@/services/content-api-types';
import {
  setDocumentForDeletion,
  setDocumentForVisibilityToggle,
  setDocumentVisibilityForConfirmationPopup,
  setSelectedCategoryId,
  setSelectedSubcategoryIndex,
  setSelectedSubtitleIndex,
  setShowAddAdminAddProcedureToSubtitle,
} from '@/store/admin/slice';
import { LanguageObject } from '@/types';

interface SubtitleDragAndDropProps {
  adminCategoryId: string;
  editSection: boolean;
  index: string;
  onHandleDeleteSection: (idx: any, name: LanguageObject) => void;
  onHandleEditSection: any;
  onSaveSubtitles: (updatedSubtitles: IntlSubcategoryDTO[], index: any) => void;
  openInNewTab: boolean;
  RenderDocumentEndContent: () => void;
  selectedCountry: string;
  setShowDeleteResource: (show: boolean) => void;
  setShowToggleResourceVisibility: (show: boolean) => void;
  showAdminFunctionality: boolean;
  subtitles: IntlSubcategoryDTO[];
  triggerEnableCancelButton: () => void;
  userIsAdmin: boolean;
}

const SubtitleDragAndDrop: React.FC<SubtitleDragAndDropProps> = ({
  adminCategoryId,
  editSection,
  index,
  onHandleDeleteSection,
  onHandleEditSection,
  onSaveSubtitles,
  openInNewTab,
  RenderDocumentEndContent,
  selectedCountry,
  setShowDeleteResource,
  setShowToggleResourceVisibility,
  showAdminFunctionality,
  subtitles,
  triggerEnableCancelButton,
  userIsAdmin,
}) => {
  const dispatch = useDispatch();
  const [mutableSubtitles, setMutableSubtitles] =
    useState<IntlSubcategoryDTO[]>();

  useEffect(() => {
    if (subtitles) {
      setMutableSubtitles(subtitles);
    }
  }, [subtitles]);

  const updateArrayIndex = (
    array: IntlSubcategoryDTO[],
    removedIndex: number,
    addedIndex: number,
  ) => {
    if (removedIndex < 0 || addedIndex < 0) {
      return;
    }

    return arrayMoveImmutable(array, removedIndex, addedIndex).map(item => {
      return { ...item };
    });
  };

  const onDrop = (dropResult: DropResult) => {
    if (
      dropResult.removedIndex === null ||
      dropResult.removedIndex === dropResult.addedIndex
    ) {
      return;
    }
    const { removedIndex, addedIndex } = dropResult;

    const updatedSubtitles = updateArrayIndex(
      mutableSubtitles!,
      removedIndex,
      addedIndex!,
    );
    setMutableSubtitles(updatedSubtitles as IntlSubcategoryDTO[]);
    onSaveSubtitles(updatedSubtitles as any, index);
  };

  const handleAdminAddResourceToNewSubtitle = (
    subcategoryIndex: any,
    subtitleIndex: any,
  ) => {
    dispatch(setSelectedCategoryId(adminCategoryId));
    dispatch(setSelectedSubcategoryIndex(subcategoryIndex));
    dispatch(setSelectedSubtitleIndex(subtitleIndex));
    dispatch(setShowAddAdminAddProcedureToSubtitle(true));
  };

  const deleteDocument = (
    subcategoryIndex: string,
    subtitleIndex: number,
    document: DocumentDTO,
    documentIsMarkedAsVisible: boolean,
    documentIndex: string,
  ) => {
    dispatch(setSelectedCategoryId(adminCategoryId));
    dispatch(setSelectedSubcategoryIndex(subcategoryIndex));
    dispatch(setSelectedSubtitleIndex(subtitleIndex));
    dispatch(setDocumentForDeletion({ ...document, index: documentIndex }));
    dispatch(
      setDocumentVisibilityForConfirmationPopup(documentIsMarkedAsVisible),
    );
    setShowDeleteResource(true);
  };

  const toggleDocument = (
    subcategoryIndex: any,
    subtitleIndex: any,
    document: DocumentDTO,
    documentIsMarkedAsVisible: boolean,
  ) => {
    dispatch(setSelectedSubcategoryIndex(subcategoryIndex));
    dispatch(setSelectedSubtitleIndex(subtitleIndex));
    dispatch(setDocumentForVisibilityToggle(document));
    dispatch(
      setDocumentVisibilityForConfirmationPopup(documentIsMarkedAsVisible),
    );
    setShowToggleResourceVisibility(true);
  };

  return (
    <StyledContainerWrapper>
      {/** @ts-ignore it's yelling about children but we need children for the drag and drop */}
      <DragAndDropContainer
        className="StyledDragAndDropContainer"
        dragHandleSelector=".subtitle-drag-handle"
        onDrop={e => onDrop(e)}
      >
        {!editSection &&
          mutableSubtitles?.map((mutableSubtitle, subtitleIndex) => {
            return (
              <Subtitle
                countryName={selectedCountry!}
                handleDocumentDelete={(
                  document,
                  documentIsMarkedAsVisible,
                  documentIndex,
                ) => {
                  deleteDocument(
                    index,
                    subtitleIndex,
                    document,
                    documentIsMarkedAsVisible,
                    documentIndex,
                  );
                }}
                handleToggleDocument={(document, documentIsMarkedAsVisible) =>
                  toggleDocument(
                    index,
                    subtitleIndex,
                    document,
                    documentIsMarkedAsVisible,
                  )
                }
                key={`subtitle-${subtitleIndex}`}
                onAdminAddResourceToSubtitle={() =>
                  handleAdminAddResourceToNewSubtitle(index, subtitleIndex)
                }
                onDeleteSubtitle={onHandleDeleteSection}
                onEditSubtitle={onHandleEditSection}
                // @ts-ignore
                onSaveSubtitles={onSaveSubtitles}
                openInNewTab={openInNewTab!}
                RenderDocumentEndContent={
                  RenderDocumentEndContent as () => void
                }
                showAdminFunctionality={showAdminFunctionality}
                showSubtitleEditFunctionality={true}
                subcategoryIndex={index}
                // @ts-ignore
                subtitle={mutableSubtitle}
                subtitleIndex={subtitleIndex}
                subtitles={mutableSubtitles}
                triggerEnableCancelButton={triggerEnableCancelButton}
                userIsAdmin={userIsAdmin}
              />
            );
          })}
      </DragAndDropContainer>
    </StyledContainerWrapper>
  );
};

const StyledContainerWrapper = styled.div`
  width: 100%;
  border-top: ${({ theme }) => `1px solid ${theme.grayScale.gray2}`};
  padding-top: 15px;
`;

export default SubtitleDragAndDrop;
