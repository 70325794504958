import React from 'react';
import { StepWizardInstance } from './stepTypes';
import Constants from '@/constants';
import CategoryCard from '@/pages/Explore/Operations/components/CategoryCard';
import CategorySection from '@/pages/Explore/Operations/components/CategorySection';
import { IntlCategoryEntity } from '@/services/content-api-types';

interface Step1Props {
  stepWizard: StepWizardInstance;
  categories: IntlCategoryEntity[];
  featured: IntlCategoryEntity[];
  setCategorySelected: (category: string) => void;
  setSearchTermDisplayed: (term: string) => void;
}

const StepOneCategories: React.FC<Step1Props> = ({
  stepWizard,
  categories,
  featured,
  setCategorySelected,
  setSearchTermDisplayed,
}) => {
  const renderCategoryList = (
    categoryList: IntlCategoryEntity[],
    sectionTitle: string,
  ) => {
    if (!categoryList?.length) {
      return null;
    }
    return (
      <CategorySection data-testid="featuredWrapper" section={sectionTitle}>
        {categoryList.map(item => (
          <CategoryCard
            categoryIcon={item.icon!}
            categoryId={item.id!}
            categoryName={item.name!}
            key={item.id}
            onClick={() => {
              stepWizard.nextStep();
              setCategorySelected(item.id!);
              setSearchTermDisplayed('');
            }}
          />
        ))}
      </CategorySection>
    );
  };

  return (
    <>
      {renderCategoryList(featured, Constants.CATEGORY_SECTION_NAME.FEATURED)}
      {renderCategoryList(
        categories,
        Constants.CATEGORY_SECTION_NAME.CATEGORIES,
      )}
    </>
  );
};

export default StepOneCategories;
