import Constants from 'constants/index';
import { useBreakpoints, useMediaQuery } from 'cfa-react-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import FilterAndSortButton from '@/components/FilterAndSortButton/FilterAndSortButton';
import CheckboxFilterSection, {
  Label,
} from '@/components/Cards/StickyFilterCard/CheckboxFilterSection';
import { useSortOptions } from '@/hooks/useSortOptions';
import {
  selectFilters,
  selectSort,
  selectSortedAndFilteredAndPaginatedTeamMembers,
} from '@/store/teamMembersFilter/selector';
import {
  addTeamMembersLocationFilter,
  addTeamMembersStatusFilter,
  removeTeamMembersFilter,
  setTeamMembersSort,
} from '@/store/teamMembersFilter/slice';
import { selectPlansToggleLicensee } from '@/store/licensee/selector';

interface FilterAndSortMobileProps {
  filterLocations: string[];
  statusLabels: Label;
}
const FilterAndSortMobile: React.FC<FilterAndSortMobileProps> = ({
  filterLocations,
  statusLabels,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const breakpoints = useBreakpoints();
  const isSmAndDown = useMediaQuery(breakpoints.down('sm'));
  const sortOptions = useSortOptions();
  const currentFilters: string[] = useSelector(selectFilters);
  const sort = useSelector(selectSort);
  const teamMembers = useSelector(
    selectSortedAndFilteredAndPaginatedTeamMembers,
  );
  const isLicenseePlans = useSelector(selectPlansToggleLicensee);

  return (
    !!isSmAndDown && (
      <FilterAndSortButton
        onSortChange={option => {
          dispatch(setTeamMembersSort({ sort: option.value }));
        }}
        sortOptions={sortOptions}
        sortValue={sortOptions?.find(option => option.value === sort)}
        text={`${t('TrainingPlans.filtering.show')} ${
          teamMembers?.length ?? 0
        } ${t('TrainingPlans.filtering.results')}`}
        top={Constants.HEIGHT.MOBILE_TOP_NAV}
      >
        {!!filterLocations &&
          filterLocations?.length > 1 &&
          !isLicenseePlans && (
            <CheckboxFilterSection
              labels={filterLocations.reduce(
                (acc, loc) => ({
                  ...acc,
                  [loc]: {
                    translationString: loc,
                    value: !!(currentFilters as string[]).includes(loc),
                  },
                }),
                {},
              )}
              onChange={value => {
                if (!!(currentFilters as string[]).includes(value)) {
                  dispatch(removeTeamMembersFilter({ filter: value }));
                } else {
                  dispatch(addTeamMembersLocationFilter({ filter: value }));
                }
              }}
              title={t('Generic.locations')}
            />
          )}
        <CheckboxFilterSection
          labels={statusLabels}
          onChange={value => {
            if (!!(currentFilters as string[]).includes(value)) {
              dispatch(removeTeamMembersFilter({ filter: value }));
            } else {
              dispatch(addTeamMembersStatusFilter({ filter: value }));
            }
          }}
          title={t('Browse.categories')}
        />
      </FilterAndSortButton>
    )
  );
};

export default FilterAndSortMobile;
