import PrintReport from '@/pages/Reports/Plans/Tab/PrintReport';
import useGetPlansWithLocations from '@/utils/reportPrint/useGetPlansWithLocations';
import { useEffect, useState } from 'react';
import { ReportToPrint } from '@/types';
import { ChecklistDTO } from '@cfacorp-pathway/xp-api-typescript-client';
import { getNameFromLanguage } from '@/utils/language';

interface PrintProps {
  plan: ChecklistDTO;
  printReport: boolean;
  resetReport: () => void;
}

const Print: React.FC<PrintProps> = ({ plan, printReport, resetReport }) => {
  const { planLocations } = useGetPlansWithLocations();
  const [reportToPrint, setReportToPrint] = useState<ReportToPrint>();
  useEffect(() => {
    if (printReport) {
      handlePrintReport({
        category: plan.category!,
        id: plan.id!,
        isCompliancePlan: false,
        locations: planLocations!,
        planName: getNameFromLanguage(plan.name!),
      });
      resetReport();
    }
  }, [plan, planLocations, printReport, resetReport]);

  const handlePrintReport = ({
    category,
    id,
    isCompliancePlan,
    locations,
    planName,
  }: {
    category: string;
    id: string;
    isCompliancePlan: boolean;
    locations: string[];
    planName: string;
  }) => {
    setReportToPrint({
      category,
      id,
      isCompliancePlan,
      locations,
      planName,
    });
  };

  return (
    <PrintReport
      planLocations={planLocations}
      reportToPrint={reportToPrint as ReportToPrint}
      turnOffPrintTrigger={() =>
        setReportToPrint({
          category: '',
          id: '',
          isCompliancePlan: false,
          locations: [],
          planName: '',
        })
      }
    />
  );
};
export default Print;
