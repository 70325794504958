import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Divider,
  Typography,
  Checkbox,
} from 'cfa-react-components';
import { useTranslation } from 'react-i18next';
import { IconAlertOctagonFilled } from '@tabler/icons-react';

type PrintReportModalProps = PropsWithChildren<{
  bodyText: string;
  handleToggleDoBCheckbox: () => void;
  headerText: string;
  isFoodSafety: boolean;
  isOpen: boolean;
  noLocationsSelected: boolean;
  onClose: () => void;
  primaryButtonHandler: () => void;
  primaryButtonText: string;
  secondaryButtonHandler: () => void;
  secondaryButtonText: string;
  selectedReportLocations: string[];
  showDoBChecked: boolean;
  isPrintDisabled?: boolean;
}>;

const PrintReportModal: React.FC<PrintReportModalProps> = ({
  bodyText,
  children,
  handleToggleDoBCheckbox,
  headerText,
  isFoodSafety,
  isOpen,
  noLocationsSelected,
  onClose,
  primaryButtonHandler,
  primaryButtonText,
  secondaryButtonHandler,
  secondaryButtonText,
  selectedReportLocations,
  showDoBChecked,
  isPrintDisabled,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      data-testid="PrintReportModal"
      onClose={onClose}
      show={isOpen}
      size="sm"
    >
      <>
        <StyledModalHeader data-testid="PrintReportModalHeaderText">
          {headerText}
        </StyledModalHeader>
        {/** @ts-ignore it's complaining about children but we set the html inline */}
        <ModalBody dangerouslySetInnerHTML={{ __html: bodyText }} />
        <ChildrenWrapper>
          <StyledDivider orientation="horizontal" variant="fullLength" />
          {children && (
            <>
              <StyledTypography variant={'body1'}>
                {t('Generic.locations')}
              </StyledTypography>
              {children}
              {!!noLocationsSelected && !selectedReportLocations.length && (
                <NoLocationWarning color="primary" variant="caption1">
                  <StyledErrorIcon />
                  {t('Generic.selectAtLeastOne')}
                </NoLocationWarning>
              )}
            </>
          )}
        </ChildrenWrapper>
        <OptionsWrapper>
          {!!isFoodSafety && (
            <>
              <OptionsTypography variant={'body1'}>
                {t('Generic.options')}
              </OptionsTypography>
              {/** @ts-ignore we are only using these props */}
              <Checkbox
                checked={showDoBChecked}
                label={t('Generic.showDoB')}
                onChange={handleToggleDoBCheckbox}
              />
            </>
          )}
          <StyledDivider orientation="horizontal" variant="fullLength" />
        </OptionsWrapper>
        <StyledModalFooter>
          {secondaryButtonHandler && secondaryButtonText && (
            <CancelButton
              color={'secondary'}
              data-testid="ConfirmationPopUpSecondaryButton"
              onClick={secondaryButtonHandler}
              variant="outlined"
            >
              {secondaryButtonText}
            </CancelButton>
          )}
          <StyledButton
            color={'secondary'}
            data-testid="ConfirmationPopUpPrimaryButton"
            disabled={isPrintDisabled}
            onClick={primaryButtonHandler}
            variant={'filled'}
          >
            {primaryButtonText}
          </StyledButton>
        </StyledModalFooter>
      </>
    </Modal>
  );
};

const StyledModalHeader = styled(ModalHeader)`
  font-size: 24px;
`;

const ChildrenWrapper = styled.div`
  padding: 0 var(--cfa-modal-padding-x);
`;

const OptionsWrapper = styled.div`
  padding: 0 var(--cfa-modal-padding-x);
`;

const StyledTypography = styled(Typography)`
  font-weight: 700;
  padding-bottom: 1em;
`;

const OptionsTypography = styled(Typography)`
  font-weight: 700;
  padding: 1em 0;
`;

const StyledDivider = styled(Divider)`
  padding: 0 var(--cfa-modal-padding-x);
  margin: 24px 0;
`;

const NoLocationWarning = styled(Typography)`
  padding-top: 0.5em;
`;

const StyledErrorIcon = styled(IconAlertOctagonFilled)`
  margin-right: 8px;
  height: 16px;
  width: 16px;
`;

// important needed to override design system footer modal styles
const StyledModalFooter = styled(ModalFooter)`
  flex-direction: row !important;
  flex-flow: unset;
  padding-top: 0;
`;

const StyledButton = styled(Button)`
  min-width: 163px;
`;

const CancelButton = styled(Button)`
  min-width: 163px;
  margin-right: 0.5em;
`;

export default PrintReportModal;
