import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ConfirmationModal from '../popups/ConfirmationModal';
import { FeaturePath, useFeaturePermission } from '@/utils/permissions';
import { isUserInternational } from '@/store/user/selectors';

/**
 * Higher‑order component to prevent unauthorized access to a route.
 *
 * @param Component The component to wrap.
 * @param featurePath The path to the feature in the feature set.
 */
export const withRoles =
  <T extends React.ElementType>(Component: T, featurePath: FeaturePath) =>
  (props: React.ComponentProps<T>) => {
    const { t } = useTranslation();
    const history = useHistory();
    const userInternational = useSelector(isUserInternational);
    const featureEnabled = useFeaturePermission(featurePath);

    if (userInternational && !featureEnabled) {
      return (
        <ConfirmationModal
          bodyText={t('LoadingResourceError.unauthorizedParagraph')}
          headerText={t('GenericError.unauthorized')}
          isError
          isOpen
          onClose={() => history.push('/')}
          primaryButtonHandler={() => history.push('/')}
          primaryButtonText={t('Button.returnToHomepage')}
          primaryButtonVariant="danger"
        />
      );
    } else if (!featureEnabled) {
      return (
        <ConfirmationModal
          bodyText={t('UserNotAssignedToRoleError.errorParagraph')}
          headerText={t('GenericError.unauthorized')}
          isError
          isOpen
          onClose={() => history.push('/')}
          primaryButtonHandler={() => history.push('/')}
          primaryButtonText={t('Button.returnToHomepage')}
          primaryButtonVariant="danger"
        />
      );
    } else {
      return <Component {...props} />;
    }
  };
