import { IconPlus } from '@tabler/icons-react';
import { Button, Icon, Typography } from 'cfa-react-components';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface AddSectionProps {
  onAddSection: () => void;
}

const AddSection: React.FC<AddSectionProps> = ({ onAddSection }) => {
  const { t } = useTranslation();
  return (
    <AddSectionButton color="secondary" onClick={onAddSection} variant="text">
      <StyledPlusIcon icon={IconPlus} size="sm" />
      <Typography
        color="secondary"
        data-testid="AddSubcategorySection"
        variant="body1"
      >
        {t('Admin.addSection')}
      </Typography>
    </AddSectionButton>
  );
};

const AddSectionButton = styled(Button)`
  min-width: 0;
  padding: 0 1em !important;
`;

const StyledPlusIcon = styled(Icon)`
  margin-right: 8px;
  color: ${({ theme }) => theme.primaryPalette.navyBlue};
  cursor: pointer;
`;

export default AddSection;
