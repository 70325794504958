import { itemByLanguage } from '@/utils/language';
import { IconPrinter, IconUserPlus } from '@tabler/icons-react';
import PopoverMenuButton from 'components/PopoverMenuButton/PopoverMenuButton';
import PopoverMenuButtonItem from 'components/PopoverMenuButton/PopoverMenuButtonItem';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NonNullComplianceCourse } from './utils';
import styled from 'styled-components';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface ButtonsProps {
  course: NonNullComplianceCourse;
  onPrintReport: (id: string) => void;
  onShowEnableCompliancePlan: (id: string, name: string) => void;
}

const Buttons: React.FC<ButtonsProps> = ({
  course,
  onPrintReport,
  onShowEnableCompliancePlan,
}) => {
  const { t } = useTranslation();
  const { plansRedesign } = useFlags();

  if (course.enabled && plansRedesign) {
    return (
      <StyledPrinterIcon
        data-testid="PrintReport"
        onClick={e => {
          e.preventDefault();
          onPrintReport(course.id);
        }}
      />
    );
  }

  return (
    <PopoverMenuButton dataTestId="PlanCardMenu">
      <PopoverMenuButtonItem
        dataTestId="EnablePlan"
        icon={<IconUserPlus />}
        isVisible={!course.enabled}
        onClick={() =>
          onShowEnableCompliancePlan(
            course.id,
            itemByLanguage(course.courseName, ''),
          )
        }
        text={t('Button.enablePlan')}
      />
      <PopoverMenuButtonItem
        dataTestId="PrintReport"
        icon={<IconPrinter />}
        onClick={() => onPrintReport(course.id)}
        text={t('Button.printReport')}
      />
    </PopoverMenuButton>
  );
};

const StyledPrinterIcon = styled(IconPrinter)`
  pointer-events: auto;
  margin: 10px;
  color: ${({ theme }) => theme.grayScale.gray2};
`;

export default Buttons;
