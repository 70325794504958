import SearchFilterHeader from '@/components/SearchFilterHeader/SearchFilterHeader';
import { selectSearchFilter } from '@/store/teamMembersFilter/selector';
import {
  clearTeamMembersSearchFilter,
  setTeamMembersSearchFilter,
} from '@/store/teamMembersFilter/slice';
import { TextFieldType } from 'cfa-react-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const Search = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const searchFilter: string = useSelector(selectSearchFilter);
  return (
    <SearchFilterHeader
      onChange={(e: React.ChangeEvent<TextFieldType>) =>
        dispatch(setTeamMembersSearchFilter({ searchFilter: e.target.value }))
      }
      onClear={() => dispatch(clearTeamMembersSearchFilter())}
      searchPlaceholder={t('TrainingPlans.teamMembers.searchbarPlaceholder')}
      searchValue={searchFilter}
      title={t('Reports.tabTeamMembers')}
    />
  );
};
export default Search;
