import styled from 'styled-components';
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  useBreakpoints,
  useMediaQuery,
  TextFieldType,
} from 'cfa-react-components';
import React from 'react';

interface ConfirmationModalProps {
  bodyText: string;
  bodyTextColor?: string;
  children?: React.ReactNode;
  className?: string;
  headerText: string;
  isDisabled?: boolean;
  isError?: boolean;
  isOpen: boolean;
  onClose?: (e: React.ChangeEvent<TextFieldType>) => void;
  overlayProps?: {};
  primaryButtonColor?: string;
  primaryButtonHandler?: (e: React.ChangeEvent<TextFieldType>) => void;
  primaryButtonText: string;
  primaryButtonVariant?: string;
  secondaryButtonHandler?: (e: React.ChangeEvent<TextFieldType>) => void;
  secondaryButtonColor?: string;
  secondaryButtonText?: string;
  showCloseButton?: boolean;
}

interface ModalBodyProps {
  $bodyTextColor: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  bodyText,
  bodyTextColor,
  children,
  className,
  headerText,
  isDisabled,
  isError,
  isOpen,
  onClose,
  overlayProps,
  primaryButtonColor = 'secondary',
  primaryButtonHandler,
  primaryButtonText,
  primaryButtonVariant = 'filled',
  secondaryButtonColor = 'secondary',
  secondaryButtonHandler,
  secondaryButtonText,
  showCloseButton = true,
}) => {
  const breakpoints = useBreakpoints();
  const isSmAndDown = useMediaQuery(breakpoints.down('sm'));

  return (
    <Modal
      className={className}
      data-testid="ConfirmationModal"
      onClose={onClose as () => void}
      overlayProps={overlayProps}
      show={isOpen}
      size="md"
    >
      <>
        <StyledModalHeader
          data-testid="ConfirmationModalHeaderText"
          showCloseButton={showCloseButton}
        >
          {headerText}
        </StyledModalHeader>
        {/** @ts-ignore overload error but we only need the props below */}
        <StyledModalBody
          $bodyTextColor={bodyTextColor!}
          dangerouslySetInnerHTML={{ __html: bodyText }}
        />
        {children && <ChildrenWrapper>{children}</ChildrenWrapper>}
        <ModalFooter>
          {secondaryButtonHandler && secondaryButtonText && (
            <DialogButton
              $isDesktop={!isSmAndDown}
              color={secondaryButtonColor}
              data-testid="ConfirmationPopUpSecondaryButton"
              onClick={secondaryButtonHandler}
              variant="outlined"
            >
              {secondaryButtonText}
            </DialogButton>
          )}
          <DialogButton
            $isDesktop={!isSmAndDown}
            $isSingleButton={!(secondaryButtonHandler && secondaryButtonText)}
            color={primaryButtonColor}
            data-testid="ConfirmationPopUpPrimaryButton"
            disabled={isDisabled}
            onClick={primaryButtonHandler}
            variant={isError ? 'destructive' : primaryButtonVariant}
          >
            {primaryButtonText}
          </DialogButton>
        </ModalFooter>
      </>
    </Modal>
  );
};

const StyledModalHeader = styled(ModalHeader)`
  font-size: 24px;
`;

const StyledModalBody = styled(ModalBody)<ModalBodyProps>`
  color: ${({ $bodyTextColor, theme }) =>
    $bodyTextColor === 'success' ? theme?.semanticColors?.success : 'default'};
`;

const ChildrenWrapper = styled.div`
  margin: 0 auto;
`;

const DialogButton = styled(Button)`
  ${({ $isDesktop, $isSingleButton }) =>
    $isDesktop && !$isSingleButton && 'flex: 1;'}
`;

export default ConfirmationModal;
