import Constants from 'constants/index';
import FilterAndSortButton from '@/components/FilterAndSortButton/FilterAndSortButton';
import {
  addTeamMembersLocationFilter,
  addTeamMembersStatusFilter,
  removeTeamMembersFilter,
  setTeamMembersSort,
} from '@/store/teamMembersFilter/slice';
import { DropdownOptions } from '@/types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CheckboxFilterSection, {
  Label,
} from '@/components/Cards/StickyFilterCard/CheckboxFilterSection';

interface FilterAndSortProps {
  currentFilters: string[];
  isSmAndDown: boolean;
  filterLocations: string[];
  sort: string;
  sortOptions: DropdownOptions[];
  statusLabels: Label;
  teamMembers: any;
}

const FilterAndSort: React.FC<FilterAndSortProps> = ({
  currentFilters,
  isSmAndDown,
  filterLocations,
  sort,
  sortOptions,
  statusLabels,
  teamMembers,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    !!isSmAndDown && (
      <FilterAndSortButton
        onSortChange={option => {
          dispatch(setTeamMembersSort({ sort: option.value }));
        }}
        sortOptions={sortOptions}
        sortValue={sortOptions?.find(option => option.value === sort)}
        text={`${t('TrainingPlans.filtering.show')} ${
          teamMembers?.length ?? 0
        } ${t('TrainingPlans.filtering.results')}`}
        top={Constants.HEIGHT.MOBILE_TOP_NAV}
      >
        {!!filterLocations && filterLocations?.length > 1 && (
          <CheckboxFilterSection
            labels={filterLocations.reduce(
              (acc, loc) => ({
                ...acc,
                [loc]: {
                  translationString: loc,
                  value: !!currentFilters.includes(loc),
                },
              }),
              {},
            )}
            onChange={value => {
              if (!!currentFilters.includes(value)) {
                dispatch(removeTeamMembersFilter({ filter: value }));
              } else {
                dispatch(addTeamMembersLocationFilter({ filter: value }));
              }
            }}
            title={t('Generic.locations')}
          />
        )}
        <CheckboxFilterSection
          labels={statusLabels}
          onChange={value => {
            if (!!currentFilters.includes(value)) {
              dispatch(removeTeamMembersFilter({ filter: value }));
            } else {
              dispatch(addTeamMembersStatusFilter({ filter: value }));
            }
          }}
          title={t('Browse.categories')}
        />
      </FilterAndSortButton>
    )
  );
};

export default FilterAndSort;
