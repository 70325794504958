import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { IconCornerDownRight, IconWorld } from '@tabler/icons-react';
import { TextField, TextFieldType, Typography } from 'cfa-react-components';
import SquareIconButton from 'components/SquareIconButton/SquareIconButton';
import { PropsWithChildren } from 'react';

type TranslationsItemProps = PropsWithChildren<{
  answers?: any[];
  id?: number;
  name: string;
  notes?: string;
  notesTranslation?: string;
  onChangeAnswer?: (e: React.ChangeEvent<TextFieldType>, index: number) => void;
  onChangeName?: (e: React.ChangeEvent<TextFieldType>) => void;
  onChangeNotes?: (e: React.ChangeEvent<TextFieldType>) => void;
  onChangeUrl?: (e: React.ChangeEvent<TextFieldType>, index: number) => void;
  onTranslateAnswer?: (index: number) => void;
  onTranslateName?: () => void;
  onTranslateNotes?: () => void;
  targetLanguage: string;
  translation: string;
  type: string;
  urls?: any[];
  userLanguage: string;
}>;

interface ColumnContainerStyleProps {
  $indented?: boolean;
}

const TranslationsItem: React.FC<TranslationsItemProps> = ({
  answers,
  children,
  id,
  name,
  notes,
  notesTranslation,
  onChangeAnswer,
  onChangeName,
  onChangeNotes,
  onChangeUrl,
  onTranslateAnswer,
  onTranslateName,
  onTranslateNotes,
  targetLanguage,
  translation,
  type,
  urls,
  userLanguage,
}) => {
  const { t } = useTranslation();
  return (
    <div data-testid={`${name}-${type}`}>
      <StyledColumnContainer>
        <StyledRowContainer>
          <StyledLeftSideSectionContainer>
            {type === 'section' ? (
              <Typography variant="body1">
                {name ||
                  t(`TrainingPlans.manageTranslations.noExistingTranslation`)}
              </Typography>
            ) : (
              <>
                <Typography variant="overline3">
                  {type === 'quiz' || type === 'quizStep'
                    ? t(`Generic.quizName`)
                    : type === 'question' && id
                      ? `${t('Generic.question')} ${id + 1}`
                      : t(`Generic.${type}`)}
                </Typography>
                <Typography variant="body1">
                  {name ||
                    t(`TrainingPlans.manageTranslations.noExistingTranslation`)}
                </Typography>
              </>
            )}
          </StyledLeftSideSectionContainer>
          {type !== 'resource' && type !== 'quizStep' && (
            <StyledRightSideSectionContainer>
              <SquareIconButton
                data-testid="TranslationItemButton"
                disabled={!name}
                onClick={onTranslateName}
              >
                <IconWorld />
              </SquareIconButton>
            </StyledRightSideSectionContainer>
          )}
        </StyledRowContainer>
        <StyledRowContainer>
          <StyledIconCornerDownRight />
          {type === 'plan' && (
            <TextField
              fullWidth
              label={t(
                'TrainingPlans.manageTranslations.currentLanguageTranslation',
              )}
              name="plan"
              onChange={onChangeName}
              placeholder={t(
                'TrainingPlans.manageTranslations.nameTranslationPlaceholder',
              )}
              value={translation ?? ''}
            />
          )}
          {type === 'quiz' && (
            <TextField
              fullWidth
              label={t(
                'TrainingPlans.manageTranslations.currentLanguageTranslation',
              )}
              name="quiz"
              onChange={onChangeName}
              placeholder={t(
                'TrainingPlans.manageTranslations.nameTranslationPlaceholder',
              )}
              value={translation ?? ''}
            />
          )}
          {type === 'section' && (
            <TextField
              fullWidth
              label={t(
                'TrainingPlans.manageTranslations.currentLanguageTranslation',
              )}
              name="section"
              onChange={onChangeName}
              placeholder={t(
                'TrainingPlans.manageTranslations.sectionTranslationPlaceholder',
              )}
              value={translation ?? ''}
            />
          )}
          {type === 'task' && (
            <TextField
              fullWidth
              label={t(
                'TrainingPlans.manageTranslations.currentLanguageTranslation',
              )}
              name="task"
              onChange={onChangeName}
              placeholder={t(
                'TrainingPlans.manageTranslations.taskTranslationPlaceholder',
              )}
              value={translation ?? ''}
            />
          )}
          {type === 'question' && (
            <TextField
              fullWidth
              label={t(
                'TrainingPlans.manageTranslations.currentLanguageTranslation',
              )}
              name="question"
              onChange={onChangeName}
              placeholder={t(
                'TrainingPlans.manageTranslations.questionTranslationPlaceholder',
              )}
              value={translation ?? ''}
            />
          )}
          {(type === 'resource' || type === 'quizStep') && (
            <VerticalCenterTypography variant="body1">
              {translation || name}
            </VerticalCenterTypography>
          )}
        </StyledRowContainer>
        <StyledChildrenContainer>{children}</StyledChildrenContainer>
      </StyledColumnContainer>
      {notes && (
        <StyledColumnContainer $indented>
          <StyledRowContainer>
            <StyledLeftSideSectionContainer>
              <Typography variant="overline3">{t('Generic.notes')}</Typography>
              <Typography variant="body1">
                {notes ||
                  t(`TrainingPlans.manageTranslations.noExistingTranslation`)}
              </Typography>
            </StyledLeftSideSectionContainer>
            <StyledRightSideSectionContainer>
              <SquareIconButton onClick={onTranslateNotes}>
                <IconWorld />
              </SquareIconButton>
            </StyledRightSideSectionContainer>
          </StyledRowContainer>
          <StyledRowContainer>
            <StyledIconCornerDownRight />
            <TextField
              fullWidth
              label={t(
                'TrainingPlans.manageTranslations.currentLanguageTranslation',
              )}
              name="notes"
              onChange={onChangeNotes}
              placeholder={t(
                'TrainingPlans.manageTranslations.notesTranslationPlaceholder',
              )}
              value={notesTranslation ?? ''}
            />
          </StyledRowContainer>
        </StyledColumnContainer>
      )}
      {urls?.map((url, index) => {
        const link = url.urlSet?.[userLanguage];
        const linkTranslation = url.urlSet?.[targetLanguage];
        return (
          link && (
            <StyledColumnContainer
              $indented
              key={url.urlSet?.[userLanguage] + id}
            >
              <StyledRowContainer>
                <StyledLeftSideSectionContainer>
                  <Typography variant="overline3">
                    {t('Generic.link')}
                  </Typography>
                  <Typography variant="body1">
                    {link ||
                      t(
                        `TrainingPlans.manageTranslations.noExistingTranslation`,
                      )}
                  </Typography>
                </StyledLeftSideSectionContainer>
              </StyledRowContainer>
              <StyledRowContainer>
                <StyledIconCornerDownRight />
                <TextField
                  fullWidth
                  name="link"
                  onChange={e => onChangeUrl?.(e, index)}
                  placeholder={t(
                    'TrainingPlans.manageTranslations.urlTranslationPlaceholder',
                  )}
                  value={linkTranslation ?? ''}
                />
              </StyledRowContainer>
            </StyledColumnContainer>
          )
        );
      })}
      {answers &&
        answers.map((answerData, index) => {
          return (
            <StyledColumnContainer
              key={`${answerData.answer?.[userLanguage]}-${index}`}
            >
              <StyledRowContainer>
                <StyledLeftSideSectionContainer>
                  <Typography variant="overline3">{`${t('Generic.answer')} ${
                    index + 1
                  }`}</Typography>
                  <Typography variant="body1">
                    {answerData.answer?.[userLanguage]}
                  </Typography>
                </StyledLeftSideSectionContainer>
                <StyledRightSideSectionContainer>
                  <SquareIconButton
                    color="secondary"
                    onClick={() => onTranslateAnswer?.(index)}
                  >
                    <IconWorld />
                  </SquareIconButton>
                </StyledRightSideSectionContainer>
              </StyledRowContainer>
              <StyledRowContainer>
                <StyledIconCornerDownRight />
                <TextField
                  fullWidth
                  label={t(
                    'TrainingPlans.manageTranslations.currentLanguageTranslation',
                  )}
                  name="answer"
                  onChange={e => onChangeAnswer?.(e, index)}
                  placeholder={t(
                    'TrainingPlans.manageTranslations.answerTranslationPlaceholder',
                  )}
                  value={answerData.answer?.[targetLanguage] ?? ''}
                />
              </StyledRowContainer>
            </StyledColumnContainer>
          );
        })}
    </div>
  );
};

export default TranslationsItem;

const VerticalCenterTypography = styled(Typography)`
  align-self: center;
`;

const StyledIconCornerDownRight = styled(IconCornerDownRight)`
  margin-right: 8px;
  height: 32px;
  width: 32px;
  margin-top: -4px;
`;
export const StyledColumnContainer = styled.div<ColumnContainerStyleProps>`
  margin: 24px 0;
  padding-left: ${({ $indented }) => ($indented ? '40px' : '0')};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  &:first-child {
    margin-top: 0px;
  }
  &:last-child {
    margin-bottom: 0px;
  }
`;

export const StyledRowContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const StyledLeftSideSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
`;

export const StyledRightSideSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 4px;
`;

export const StyledChildrenContainer = styled.div`
  width: 100%;
`;
