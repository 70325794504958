import { useTranslation } from 'react-i18next';
import ConfirmationModal from 'components/popups/ConfirmationModal';

interface DeleteQuizPopupProps {
  onDeleteQuizAndClosePopup: () => void;
  quizName: string;
  setShowDeleteQuizPopup: (show: boolean) => void;
  showDeleteQuizPopup: boolean;
}

const DeleteQuizPopup: React.FC<DeleteQuizPopupProps> = ({
  showDeleteQuizPopup,
  setShowDeleteQuizPopup,
  onDeleteQuizAndClosePopup,
  quizName,
}) => {
  const { t } = useTranslation();

  return (
    <ConfirmationModal
      bodyText={t('Generic.deleteImpactedObject', {
        name: quizName,
        object: t('Generic.quiz').toLowerCase(),
        impactedObject: t('Generic.plans'),
      })}
      headerText={t('Generic.deleteHeader', {
        type: t('Generic.quiz'),
      })}
      isOpen={showDeleteQuizPopup}
      onClose={() => setShowDeleteQuizPopup(false)}
      primaryButtonColor={'primary'}
      primaryButtonHandler={onDeleteQuizAndClosePopup}
      primaryButtonText={t('Button.delete')}
      primaryButtonVariant="destructive"
      secondaryButtonHandler={() => setShowDeleteQuizPopup(false)}
      secondaryButtonText={t('Button.cancel')}
    />
  );
};

export default DeleteQuizPopup;
