import { Checkbox } from 'cfa-react-components';

interface CheckboxListProps {
  id: string;
  idx: number;
  selectedLocations: string[];
  setSelectedLocations: (locations: string[]) => void;
}

const CheckboxList: React.FC<CheckboxListProps> = ({
  id,
  idx,
  selectedLocations,
  setSelectedLocations,
}) => {
  return (
    // @ts-ignore (overload props error for props we aren't using)
    <Checkbox
      checked={selectedLocations?.includes(id)}
      data-testid="Checkbox"
      key={idx}
      label={id}
      onChange={() => {
        if (selectedLocations.includes(id)) {
          setSelectedLocations(
            selectedLocations.filter(location => location !== id),
          );
        } else {
          setSelectedLocations([...selectedLocations, id]);
        }
      }}
      style={{ marginBottom: '0.5em' }}
    />
  );
};

export default CheckboxList;
