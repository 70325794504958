import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  Modal,
  ModalHeader,
  Button,
  ModalBody,
  ModalFooter,
} from 'cfa-react-components';
import { DatePicker } from '@cfa/react-core';
import { isValidYear } from '@/utils/date';
import { format, isValid } from 'date-fns';

interface DueDatePopUpProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (date: any) => void;
  triggerDueDateReset?: boolean;
}

const DueDatePopUp: React.FC<DueDatePopUpProps> = ({
  isOpen,
  onClose,
  onSave,
  triggerDueDateReset,
}) => {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState<any | null>(null);
  const [checkboxClicked, setCheckboxClicked] = useState(false);
  const [disableDatePicker, setDisableDatePicker] = useState(false);

  useEffect(() => {
    setSelectedDate(null);
    setCheckboxClicked(false);
    setDisableDatePicker(false);
  }, [isOpen]);

  useEffect(() => {
    setSelectedDate(null);
    setCheckboxClicked(false);
    setDisableDatePicker(false);
  }, [triggerDueDateReset]);

  const onDateChange = (date: any | null) => {
    // we only receive a date from the Date Picker when there is at least 1 number typed into the year section, we check to make sure the year is 4 digits here
    if (!!isValidYear(date?.year)) {
      const formattedDate = format(
        date.toDate(),
        "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
      );
      // checks to make sure date is a proper date format
      if (isValid(new Date(formattedDate))) {
        setSelectedDate(formattedDate);
      }
    } else {
      setSelectedDate(null);
    }
  };

  const onCheckboxClick = () => {
    setCheckboxClicked(prev => !prev);
    setDisableDatePicker(prev => !prev);
  };

  return (
    <>
      <Modal
        data-testid="DueDatePopUp"
        onClose={onClose}
        show={isOpen}
        size="md"
      >
        <ModalHeader>
          {t('TrainingPlans.accountability.setDueDate')}
        </ModalHeader>
        <ModalBody>
          <StyledDatePickerOuterWrapper>
            <StyledDatePickerInnerWrapper>
              <DatePicker
                data-testid="DatePicker"
                isDisabled={disableDatePicker}
                isRequired={!checkboxClicked}
                isInvalid={checkboxClicked ? false : undefined}
                label={t('TrainingPlans.accountability.enterDueDate')}
                onChange={date => onDateChange(date)}
                shouldForceLeadingZeros={false}
              />
              {/* @ts-ignore (we only need these properties) */}
              <Checkbox
                data-testid="DueDateCheckbox"
                label={t('TrainingPlans.accountability.due.noDueDate')}
                onChange={onCheckboxClick}
                style={{ marginTop: '2em' }}
              />
            </StyledDatePickerInnerWrapper>
          </StyledDatePickerOuterWrapper>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            data-testid="ButtonSave"
            disabled={!(selectedDate || checkboxClicked)}
            id="SaveDueDate"
            onClick={() => onSave(checkboxClicked ? null : selectedDate)}
          >
            {t('Button.save')}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const StyledDatePickerOuterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const StyledDatePickerInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  text-align: start;
`;

export default DueDatePopUp;
