import { FC } from 'react';
import Document from './Document';
import DocumentError from './DocumentError';
import LoadingOverlay from '@/components/LoadingOverlay/LoadingOverlay';
import ChooseDocumentModal from '@/components/popups/ChooseDocumentModal';
import {
  useAdjustCssForHeadless,
  useCollapseSidebar,
  useDocument,
  useDocumentErrorListener,
  useDocumentParams,
  useWindowScrollEvents,
} from '@/utils/document-utils';

const SignalDocument: FC = () => {
  const { documentId } = useDocumentParams();
  const { icon, chooseReference, error, isFetching } = useDocument(
    documentId,
    false,
  );
  useCollapseSidebar();
  useWindowScrollEvents();
  useAdjustCssForHeadless();

  const otherError = useDocumentErrorListener();

  if (error) {
    return (
      <DocumentError
        documentId={documentId}
        error={error}
        otherError={otherError}
      />
    );
  }

  return (
    <>
      {isFetching && <LoadingOverlay isOpen />}
      <ChooseDocumentModal
        icon={icon}
        isOpen={chooseReference.modalOpen}
        onClick={doc => chooseReference.setChosenReference(doc)}
        references={chooseReference.references}
      />
      <Document />
    </>
  );
};

export default SignalDocument;
