import Constants from 'constants/index';
import { useTranslation } from 'react-i18next';
import { ChecklistDTO } from '@cfacorp-pathway/xp-api-typescript-client';
import PlanCardDate from '../PlanCardComponents/PlanCardDate';
import PlanCard from '../PlanCard';
import PlanCardContent from '../PlanCardContent/PlanCardContent';
import PlanCardTitle from '../PlanCardComponents/PlanCardTitle';
import PlanCardLabel from '../PlanCardComponents/PlanCardLabel';
import PlanCardSubHeader from '../PlanCardComponents/PlanCardSubHeader';
import { getNameFromLanguage } from '@/utils/language';
import { LanguageObject } from '@/types/types';
import { mapCategoryToAliasTranslation } from '@/utils/categoryUtils';

interface ManagePlansPlanCardProps {
  assignedUsers: number;
  completedUsers: number;
  plan: ChecklistDTO;
}

const ManagePlansPlanCard: React.FC<ManagePlansPlanCardProps> = ({
  assignedUsers,
  completedUsers,
  plan,
}) => {
  const { t } = useTranslation();
  const percentComplete = (completedUsers / assignedUsers) * 100;
  return (
    <>
      {!!plan && (
        <>
          <PlanCard
            category={plan?.category}
            dataTestId={'TeamPlanCard'}
            link={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${plan?.id}/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}`}
            progress={percentComplete}
          >
            <PlanCardContent>
              <PlanCardLabel>
                {t(mapCategoryToAliasTranslation(plan?.category))}
              </PlanCardLabel>
              <PlanCardTitle>
                {getNameFromLanguage(plan?.name as LanguageObject)}
              </PlanCardTitle>
              {!!assignedUsers && completedUsers === assignedUsers ? (
                <PlanCardSubHeader
                  className="success"
                  dataTestId="PlanTaskCount"
                >
                  {t('Generic.completedByAll')}
                </PlanCardSubHeader>
              ) : (
                <PlanCardSubHeader dataTestId="PlanTaskCount">
                  {`${completedUsers}/${assignedUsers} ${t(
                    'Generic.teamMembersCompleted',
                  )}`}
                </PlanCardSubHeader>
              )}
            </PlanCardContent>
            <PlanCardDate date={plan?.created!} />
          </PlanCard>
        </>
      )}
    </>
  );
};

export default ManagePlansPlanCard;
