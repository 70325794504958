import { FeatureSet } from '@cfacorp-pathway/xp-api-typescript-client';
import { useMemo } from 'react';
import store from '@/store';
import { selectUserFeatureFlags } from '@/store/user/selectors';
import { useAppSelector } from '@/hooks';

/** Recursion depth limiter. */
type Prev = [never, 0, 1, 2, 3, 4];

/**
 * Utility type to create a union of valid dot‑separated paths.
 * The extra type parameter D limits the recursion depth.
 */
type FeaturePaths<T, D extends number = 5> = [D] extends [0]
  ? never
  : {
      [K in keyof T & string]: NonNullable<T[K]> extends { enabled?: boolean }
        ? // Include the key itself, plus any nested paths (if any)
          | K
            | `${K}.${FeaturePaths<
                Omit<NonNullable<T[K]>, 'enabled'>,
                Prev[D]
              >}`
        : never;
    }[keyof T & string];

/** Set of all possible feature paths. */
export type FeaturePath = FeaturePaths<FeatureSet>;

/** Get if a feature is enabled. */
const getEnabled = (featureSet: FeatureSet, path: FeaturePath): boolean => {
  const keys = path.split('.');
  let current: any = featureSet;
  for (const key of keys) {
    if (current === null) {
      return false;
    }
    current = current[key];
  }
  return Boolean(current?.enabled);
};

/** Get if a user has permission to a feature. */
export const getFeaturePermission = (path: FeaturePath) => {
  const featureSet = selectUserFeatureFlags(store.getState());
  return getEnabled(featureSet, path);
};

/**
 * Get if a user has permission to a feature.
 *
 * @param path The path to the feature in the feature set.
 * @returns If the feature is enabled.
 */
export const useFeaturePermission = (path: FeaturePath) => {
  const features = useAppSelector(selectUserFeatureFlags);
  return useMemo(() => getEnabled(features, path), [features, path]);
};
