import CheckboxFilterSection, {
  Label,
} from '@/components/Cards/StickyFilterCard/CheckboxFilterSection';
import StickyFilterCard from '@/components/Cards/StickyFilterCard/StickyFilterCard';
import {
  addTeamMembersLocationFilter,
  addTeamMembersStatusFilter,
  removeTeamMembersFilter,
} from '@/store/teamMembersFilter/slice';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

interface FilterProps {
  currentFilters: string[];
  filterLocations: string[];
  isSmAndDown: boolean;
  statusLabels: Label;
}

const Filter: React.FC<FilterProps> = ({
  currentFilters,
  filterLocations,
  isSmAndDown,
  statusLabels,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    !isSmAndDown && (
      <StickyFilterCard>
        {!!filterLocations && filterLocations?.length > 1 && (
          <CheckboxFilterSection
            labels={filterLocations.reduce(
              (acc, loc) => ({
                ...acc,
                [loc]: {
                  translationString: loc,
                  value: !!currentFilters.includes(loc),
                },
              }),
              {},
            )}
            onChange={value => {
              if (!!currentFilters.includes(value)) {
                dispatch(removeTeamMembersFilter({ filter: value }));
              } else {
                dispatch(addTeamMembersLocationFilter({ filter: value }));
              }
            }}
            title={t('Generic.locations')}
          />
        )}
        <CheckboxFilterSection
          labels={statusLabels}
          onChange={value => {
            if (!!currentFilters.includes(value)) {
              dispatch(removeTeamMembersFilter({ filter: value }));
            } else {
              dispatch(addTeamMembersStatusFilter({ filter: value }));
            }
          }}
          title={t('Generic.status')}
        />
      </StickyFilterCard>
    )
  );
};
export default Filter;
