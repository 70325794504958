import Constants from 'constants/index';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ChecklistDTO } from '@cfacorp-pathway/xp-api-typescript-client';
import { IconBooks, IconNotes } from '@tabler/icons-react';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import NoMessage from '@/components/NoMessage/NoMessage';
import BuildPlansPlanCard from '@/components/Cards/PlanCard/PlanCards/BuildPlansPlanCard';
import {
  selectFilters,
  selectPagination,
  selectSearchFilter,
} from '@/store/buildPlansFilter/selector';
import LoadMorePaginator from '@/components/LoadMorePaginator/LoadMorePaginator';
import Fab from '@/components/Fab/Fab';
import FabPopup from '@/components/Fab/FabPopup';
import FabPopupItem from '@/components/Fab/FabPopupItem';
import ToastMessageBlock from '@/components/Toasts/SuccessToast';
import {
  useAssignUserToChecklistMutation,
  useDeleteTrainingPlanMutation,
  useDuplicateTrainingPlanMutation,
  useUpdateTrainingPlanMutation,
} from '@/services/pathwayApi';
import useBugsnagNotify from '@/hooks/useBugsnagNotify';
import { getNameFromLanguage } from '@/utils/language';
import { LanguageObject } from '@/types/types';
import {
  selectUserLanguage,
  selectUserPermissions,
} from '@/store/user/selectors';
import { loadMorePlans } from '@/store/buildPlansFilter/slice';

interface PlanCardsProps {
  hideAddPlanPopUp: () => void;
  isFetching: boolean;
  isFetchingOperators: boolean;
  plans: ChecklistDTO[];
  onShowCustomPlanPopup: () => void;
  onShowTemplateModal: () => void;
  refetch: () => void;
  setIsOpen: (open: boolean) => void;
  setShowAddPlanPopUp: (show: boolean) => void;
  setShowInvalidNameError: (show: boolean) => void;
  showAddPlanPopUp: boolean;
}

interface PlanWithOperator extends ChecklistDTO {
  operatorId: string;
}

const PlanCards: React.FC<PlanCardsProps> = ({
  hideAddPlanPopUp,
  isFetching,
  isFetchingOperators,
  onShowCustomPlanPopup,
  onShowTemplateModal,
  plans,
  refetch,
  setIsOpen,
  setShowAddPlanPopUp,
  setShowInvalidNameError,
  showAddPlanPopUp,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { notifyBugsnag } = useBugsnagNotify();
  const { showing, total } = useSelector(selectPagination);
  const searchFilter = useSelector(selectSearchFilter);
  const currentFilters: string[] = useSelector(selectFilters);
  const userPermissions = useSelector(selectUserPermissions);
  const userLanguage = useSelector(selectUserLanguage);
  const [assignUserToChecklist] = useAssignUserToChecklistMutation();
  const [deleteTrainingPlan] = useDeleteTrainingPlanMutation();
  const [duplicateTrainingPlan] = useDuplicateTrainingPlanMutation();
  const [updateTrainingPlan] = useUpdateTrainingPlanMutation();
  const userIsStakeholder = userPermissions.hasOwnProperty(
    Constants.USER_PERMISSIONS.STAKEHOLDER,
  );

  const onAssignTeamMembers = ({
    dueDate,
    members,
    planId,
  }: {
    dueDate: string;
    members: string | string[];
    planId: string;
  }) => {
    const payload = Array.isArray(members)
      ? { userIds: members, dueDate }
      : { userIds: [members], dueDate };
    const numberOfTeamMembersToAssign = payload.userIds.length;

    assignUserToChecklist({ body: payload, id: planId })
      .unwrap()
      .then(() => {
        history.push({
          pathname: `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${planId}/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}`,
          state: { refetch: true },
        });
        toast.custom(toastObj => (
          <ToastMessageBlock id={toastObj.id}>
            {numberOfTeamMembersToAssign === 1
              ? t('TrainingPlans.toastMessage.assignedTeamMember')
              : t('TrainingPlans.toastMessage.assignedTeamMembers', {
                  count: numberOfTeamMembersToAssign,
                })}
          </ToastMessageBlock>
        ));
      })
      .catch(err => {
        notifyBugsnag(err);
      });
  };

  const onDeletePlan = (planId: string, planName: string) => {
    deleteTrainingPlan({ id: planId })
      .unwrap()
      .then(() => {
        refetch();
        toast.custom(toastObj => (
          <ToastMessageBlock id={toastObj.id}>
            {`${planName} ${t('TrainingPlans.toastMessage.deleted')}`}
          </ToastMessageBlock>
        ));
        setIsOpen(true);
      })
      .catch(err => {
        notifyBugsnag(err);
      });
  };

  const isValidPlanName = (planName: string, assignedOperator: string) => {
    const noAssignedOperatorAndUserIsNotStakeholder =
      !assignedOperator && !userIsStakeholder;

    if (!planName || noAssignedOperatorAndUserIsNotStakeholder) {
      setShowInvalidNameError(true);
      console.error('invalid name or missing operator');
      notifyBugsnag(
        new Error(
          `Build plans error - no plan name or no operator: ${JSON.stringify({
            planName,
            assignedOperator,
          })}`,
        ),
      );
      return false;
    }
    return true;
  };

  const onDuplicatePlan = (
    planId: string,
    operatorId: string,
    name: string,
  ) => {
    const trimmedName = name?.trim();

    if (!isValidPlanName(trimmedName, operatorId)) {
      return;
    }

    duplicateTrainingPlan({
      ownerId: operatorId,
      checklistId: planId,
      name: {
        en: trimmedName,
        es: trimmedName,
      },
    })
      .unwrap()
      .then(() => {
        refetch();
        toast.custom(toastObj => (
          <ToastMessageBlock id={toastObj.id}>
            {`${trimmedName} ${t('TrainingPlans.toastMessage.duplicated')}`}
          </ToastMessageBlock>
        ));
        setIsOpen(true);
      })
      .catch(err => {
        notifyBugsnag(err);
      });
  };

  const onRenamePlan = (newName: string, plan: PlanWithOperator) => {
    const trimmedName = newName?.trim();
    if (!isValidPlanName(trimmedName, plan.ownerId!)) {
      return;
    }
    const payload = {
      operatorId: plan.operatorId,
      checklist: {
        ...plan,
        name: {
          ...plan.name,
          [userLanguage]: trimmedName,
        },
      },
    };
    updateTrainingPlan(payload)
      .unwrap()
      .then(() => {
        refetch();
        toast.custom(toastObj => (
          <ToastMessageBlock id={toastObj.id}>
            {`${trimmedName} ${t('TrainingPlans.toastMessage.renamed')}`}
          </ToastMessageBlock>
        ));
        setIsOpen(true);
      })
      .catch(err => {
        notifyBugsnag(err);
      });
  };

  const onSaveTranslations = (updatedTranslations: any) => {
    const payload = {
      locations: updatedTranslations?.locations,
      checklist: {
        ...updatedTranslations,
      },
    };

    updateTrainingPlan(payload)
      .unwrap()
      .then(() => {
        toast.custom(toastObj => (
          <ToastMessageBlock id={toastObj.id}>
            {`${getNameFromLanguage(updatedTranslations?.name)} ${t(
              'TrainingPlans.translationToastText',
            )} ${
              userLanguage === Constants.LANGUAGE.ENGLISH_LANGUAGE_CODE
                ? Constants.LANGUAGE_OPTIONS.SPANISH
                : Constants.LANGUAGE_OPTIONS.INGLES
            }`}
          </ToastMessageBlock>
        ));
        refetch();
      })
      .catch(err => {
        notifyBugsnag(err);
      });
  };

  return (
    <PlanCardsList>
      {!plans?.length &&
        !isFetching &&
        !isFetchingOperators &&
        !currentFilters?.length &&
        !searchFilter && (
          <NoMessage message={<Trans i18nKey={'TrainingPlans.createPlan'} />} />
        )}
      {(!!currentFilters?.length || !!searchFilter) && !total && (
        <NoMessage message={t('TrainingPlans.noPlansResults')} />
      )}
      {!isFetchingOperators && (
        <Fab
          hasMenu={true}
          isOpen={showAddPlanPopUp}
          onClick={() => setShowAddPlanPopUp(!showAddPlanPopUp)}
          onClose={hideAddPlanPopUp}
        >
          <FabPopup>
            <FabPopupItem
              data-testid="CustomPlan"
              icon={<IconNotes />}
              onClick={onShowCustomPlanPopup}
              title={t('TrainingPlans.addPlanMenu.buildCustomPlan')}
            />
            <FabPopupItem
              data-testid="TemplateLibraryPlan"
              icon={<IconBooks />}
              onClick={onShowTemplateModal}
              title={t('TrainingPlans.addPlanMenu.choosePlanFromLibrary')}
            />
          </FabPopup>
        </Fab>
      )}
      {!!plans?.length && !isFetching && !isFetchingOperators && (
        <>
          {plans.map((plan, idx) => (
            <BuildPlansPlanCard
              dataTestId="TeamPlanCard"
              key={idx}
              onAssignTeamMembers={(
                members: string | string[],
                dueDate: string,
              ) =>
                onAssignTeamMembers({
                  dueDate,
                  members,
                  planId: (plan as ChecklistDTO).id!,
                })
              }
              onDeletePlan={() =>
                onDeletePlan(
                  (plan as ChecklistDTO).id!,
                  getNameFromLanguage(
                    (plan as ChecklistDTO).name as LanguageObject,
                  ),
                )
              }
              onDuplicatePlan={(
                planId: string,
                operatorId: string,
                name: string,
              ) => onDuplicatePlan(planId, operatorId, name)}
              onRenamePlan={(newName: string) =>
                onRenamePlan(newName, plan as PlanWithOperator)
              }
              onSaveTranslations={(updatedTranslations: any) =>
                onSaveTranslations(updatedTranslations)
              }
              plan={plan}
            />
          ))}
          <LoadMorePaginator
            onClick={() => dispatch(loadMorePlans())}
            showing={showing}
            showingText={t('TrainingPlans.showingXOfYPlans', {
              showing,
              total,
            })}
            total={total}
          />
        </>
      )}
    </PlanCardsList>
  );
};

const PlanCardsList = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  max-width: 100%;
  gap: 8px;
`;

export default PlanCards;
