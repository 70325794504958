import { FC, PropsWithChildren } from 'react';
import { ErrorBoundary } from '@/utils/bugsnag';
import GenericError from '@/components/Error/GenericError';

const ErrorBoundaries: FC<PropsWithChildren> = ({ children }) => {
  return (
    <ErrorBoundary FallbackComponent={GenericError}>{children}</ErrorBoundary>
  );
};

export default ErrorBoundaries;
