import { Typography } from 'cfa-react-components';
import styled from 'styled-components';

interface NoMessageProps {
  message: React.ReactNode;
}
const NoMessage: React.FC<NoMessageProps> = ({ message }) => {
  return <StyledNoMessageText variant="body2">{message}</StyledNoMessageText>;
};

const StyledNoMessageText = styled(Typography)`
  color: ${({ theme }) => theme.grayScale.gray7};
  font-size: 16px;
`;

export default NoMessage;
