// Convert YYYY-MM-DD to DD/MM/YYYY
export const convertDateForReports = (string: string) => {
  return new Date(string).toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
};

export const isDateExpired = (date: string | undefined) => {
  if (!date) {
    return;
  }
  const expirationDate = new Date(date);
  const today = new Date();
  // Set time to 00:00:00 to only compare dates, not times
  today.setHours(0, 0, 0, 0);

  return expirationDate < today;
};

export const currentYear = new Date().getFullYear();

export const isValidYear = (year: number | null) => {
  return Number.isInteger(year) && /^[0-9]{4}$/.test(year?.toString()!);
};
