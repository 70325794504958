import styled from 'styled-components';
import {
  Dropdown,
  Link,
  Switch,
  Typography,
  useBreakpoints,
  useMediaQuery,
} from 'cfa-react-components';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { DropdownOptions } from '@/types/types';

interface SortFilterHeaderProps {
  className?: string;
  handleOnCompletedPlansOnlyToggle?: () => void;
  label: string;
  onChange: (value: DropdownOptions | null) => void;
  onClear: () => void;
  options: DropdownOptions[];
  showClear: boolean;
  showCompletedPlansOption: boolean;
  showMyCompletedPlansOnlySwitchValue: boolean;
  text: string;
  value: DropdownOptions | undefined;
}

const SortFilterHeader: React.FC<SortFilterHeaderProps> = ({
  className,
  handleOnCompletedPlansOnlyToggle,
  label,
  onChange,
  onClear,
  options,
  showClear,
  showCompletedPlansOption,
  showMyCompletedPlansOnlySwitchValue,
  text,
  value,
}) => {
  const breakpoints = useBreakpoints();
  const isSmAndDown = useMediaQuery(breakpoints.down('sm'));
  const { t } = useTranslation();

  return (
    <SortFilterContainer
      className={className}
      data-testid={'SearchFilterHeader'}
    >
      <SortFilterLeftContainer>
        <Typography fontWeight="bold" variant="body1">{`${text}`}</Typography>
        {!!showClear && (
          <ClearButton onClick={onClear} size="body2" variant="standalone">
            {t('Button.clearAll')}
          </ClearButton>
        )}
      </SortFilterLeftContainer>
      {!!showCompletedPlansOption && (
        <div>
          <StyledLeftSideSwitchLabel>
            <ToggleLabel variant="body1">
              {t('TrainingPlans.filtering.includeCompleted')}
            </ToggleLabel>
            {/** @ts-ignore we only need the properties below */}
            <Switch
              checked={!!showMyCompletedPlansOnlySwitchValue}
              crossOrigin={undefined}
              data-testid="Switch-Completed"
              onChange={handleOnCompletedPlansOnlyToggle}
              style={{ marginLeft: '16px' }}
            />
          </StyledLeftSideSwitchLabel>
        </div>
      )}
      {!isSmAndDown && (
        <FilterDropdownContainer>
          <FilterLabel variant="body1">{label}</FilterLabel>
          <FilterDropdown
            getOptionId={option => option.id}
            getOptionText={option => `${option.translationString}`}
            onChange={onChange}
            options={options}
            renderOption={option => `${option.translationString}`}
            value={value!}
          />
        </FilterDropdownContainer>
      )}
    </SortFilterContainer>
  );
};

const StyledLeftSideSwitchLabel = styled.div`
  display: flex;
  flex-direction: row;
`;

const ToggleLabel = styled(Typography)`
  color: ${({ theme }) => theme.grayScale.gray7};
`;

const ClearButton = styled(Link)`
  text-decoration: underline !important;
  text-wrap: nowrap;
`;

const SortFilterLeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const SortFilterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
`;

const FilterDropdownContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const FilterLabel = styled(Typography)`
  text-wrap: nowrap;
`;

const FilterDropdown = styled(Dropdown<DropdownOptions>)`
  width: 160px;
  & > label {
    display: none;
  }
`;

export default SortFilterHeader;
