import { Typography } from 'cfa-react-components';
import styled from 'styled-components';
import { device } from '@/utils/device';
import React from 'react';

interface CategorySectionProps {
  className?: string;
  children?: React.ReactNode;
  section: string;
  'data-testid'?: string;
}

const CategorySection: React.FC<CategorySectionProps> = ({
  section,
  'data-testid': dataTestId,
  className,
  children,
}) => {
  return (
    <CategorySectionContainer className={className} data-testid={dataTestId}>
      <CardSectionText variant="overline1">{section}</CardSectionText>
      <CategoryCardGroup>{children}</CategoryCardGroup>
    </CategorySectionContainer>
  );
};

const CategorySectionContainer = styled.div`
  margin: 32px 0;
`;

const CardSectionText = styled(Typography)`
  margin-bottom: 8px;
`;

const CategoryCardGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
  @media (${device.mobile}) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
export default CategorySection;
