import Constants from 'constants/index';
import { Divider, Surface, Typography } from 'cfa-react-components';
import styled from 'styled-components';
import { ReactNode } from 'react';
import { useDeviceInfo } from '@/utils/device';

interface StickyMenuCardProps {
  header: string;
  top: string;
  dataTestId: string;
  children: ReactNode;
}

const StickyMenuCard: React.FC<StickyMenuCardProps> = ({
  header,
  top,
  dataTestId,
  children,
}) => {
  const { isDesktop } = useDeviceInfo();
  return (
    <MenuCard
      $isDesktop={isDesktop}
      $top={top}
      data-testid={dataTestId}
      elevation={1}
      radii="sm"
      variant="outlined"
    >
      <Typography fontWeight="medium" variant="body1">
        {header}
      </Typography>
      <Divider variant="fullLength" />
      {children}
    </MenuCard>
  );
};

const MenuCard = styled(Surface)`
  position: sticky;
  top: ${({ $top, $isDesktop }) =>
    $isDesktop ? $top : `calc(${$top} + ${Constants.HEIGHT.MOBILE_TOP_NAV})`};
  // this calc is to prevent the card from overflowing and also going over the topnav
  max-height: ${({ $top, $isDesktop }) =>
    $isDesktop
      ? `calc(100vh - ${$top} - ${Constants.HEIGHT.DESKTOP_TOP_NAV} - ${Constants.HEIGHT.STICKY_MENU_MARGIN} - ${Constants.HEIGHT.STICKY_MENU_MARGIN})`
      : `calc(100vh - ${$top} - ${Constants.HEIGHT.MOBILE_TOP_NAV} - ${Constants.HEIGHT.DESKTOP_TOP_NAV} - ${Constants.HEIGHT.STICKY_MENU_MARGIN} - ${Constants.HEIGHT.STICKY_MENU_MARGIN})`};
  z-index: 4;
  width: 270px;
  min-width: 270px;
  flex-shrink: 1;
  padding: 16px;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 16px 0;
  gap: 12px;
  overflow-y: auto;
`;

export default StickyMenuCard;
