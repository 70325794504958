import styled from 'styled-components';
import {
  IconSquareRoundedPlus,
  IconSquareRoundedCheckFilled,
} from '@tabler/icons-react';
import { Card } from 'cfa-react-components';
import { Ribbon } from 'icons';

interface TeamMemberCardProps {
  id: string;
  isAssigned: boolean;
  isTrainingMode: boolean;
  name: string;
  onAssignTeamMember: (id: string) => void;
  originallyAssigned: boolean;
}

const TeamMemberCard: React.FC<TeamMemberCardProps> = ({
  id,
  isAssigned,
  isTrainingMode,
  name,
  onAssignTeamMember,
  originallyAssigned,
}) => {
  return (
    <StyledTeamMemberWrapper>
      <StyledTeamMemberCard elevation={1}>
        {name}
        {originallyAssigned && isTrainingMode ? (
          <div>
            <StyledRibbon />
            <StyledCompleteDisabled />
          </div>
        ) : originallyAssigned ? (
          <StyledCompleteDisabled />
        ) : isAssigned ? (
          <StyledCompleteFilled
            data-testid="AddTeamMember"
            onClick={() => onAssignTeamMember(id)}
          />
        ) : (
          <StyledAddBlue
            data-testid="AddTeamMember"
            onClick={() => onAssignTeamMember(id)}
          />
        )}
      </StyledTeamMemberCard>
    </StyledTeamMemberWrapper>
  );
};

const StyledAddBlue = styled(IconSquareRoundedPlus)`
  width: 32px;
  height: 32px;
  cursor: pointer;
  color: ${({ theme }) => theme.primaryPalette.navyBlue};
`;
const StyledCompleteDisabled = styled(IconSquareRoundedCheckFilled)`
  width: 32px;
  height: 32px;
  color: ${({ theme }) => theme.grayScale.gray2};
`;
const StyledRibbon = styled(Ribbon)`
  width: 32px;
  height: 32px;
  margin-right: 1em;
`;
const StyledCompleteFilled = styled(IconSquareRoundedCheckFilled)`
  width: 32px;
  height: 32px;
  cursor: pointer;
  color: ${({ theme }) => theme.semanticColors.success};
`;
const StyledTeamMemberWrapper = styled.div`
  margin-bottom: 1em;
`;
const StyledTeamMemberCard = styled(Card)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`;

export default TeamMemberCard;
