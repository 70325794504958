import { Card, CardContent, Typography } from 'cfa-react-components';
import { FC } from 'react';
import { Document } from '@cfacorp-pathway/xp-api-typescript-client';
import styled from 'styled-components';
import GenericError from '@/components/Error/GenericError';
import { useDeviceInfo } from '@/utils/device';
import { useBrightcoveVideo } from '@/utils/document-utils';

interface BrightcoveProps {
  document: Document;
}

const Brightcove: FC<BrightcoveProps> = ({ document }) => {
  const { isDesktop: isDesktopWidth } = useDeviceInfo();
  const { url, isLoading, error } = useBrightcoveVideo(document.contentUrl);

  if (error) {
    return <GenericError />;
  }

  return (
    <StyledVideoContainer>
      <StyledVideoTitle $isDesktop={isDesktopWidth}>
        {document.name}
      </StyledVideoTitle>
      {!isLoading && (
        <StyledVideoCard $isDesktop={isDesktopWidth} elevation={1}>
          <StyledCardContent>
            <Iframe allowFullScreen src={url} />
          </StyledCardContent>
        </StyledVideoCard>
      )}
    </StyledVideoContainer>
  );
};

const Iframe = styled.iframe`
  width: 100%;
  aspect-ratio: 16 / 9;
`;

const StyledCardContent = styled(CardContent)`
  display: flex;
`;

const StyledVideoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledVideoCard = styled(Card)<{ $isDesktop: boolean }>`
  animation: fadeIn linear 0.3s;
  min-width: ${({ $isDesktop }) => ($isDesktop ? '840px !important' : null)};
  margin: ${({ $isDesktop }) => ($isDesktop ? '0 auto 2em auto' : '0.25em 0')};
`;

const StyledVideoTitle = styled(Typography)<{ $isDesktop: boolean }>`
  color: ${({ theme }) => theme.grayScale.gray6};
  font-size: 32px;
  font-weight: 700;
  padding: 10px 0 24px 0;
  line-height: 40px;
  margin-top: 20px;
  margin-left: ${({ $isDesktop }) => ($isDesktop ? null : '1em')};
  text-align: ${({ $isDesktop }) => ($isDesktop ? 'center' : 'left')};
`;

export default Brightcove;
