import { getNameFromLanguage } from 'utils/language';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardContent,
  Icon,
  RadioButton,
  RadioGroup,
  Typography,
  useBreakpoints,
  useMediaQuery,
} from 'cfa-react-components';
import GenericError from 'components/Error/GenericError';
import { IconCircleCheckFilled } from '@tabler/icons-react';
import { CustomQuizQuestionDefinitionDTO } from '@cfacorp-pathway/xp-api-typescript-client';
import React from 'react';

interface QuizPreviewCardProps {
  quiz: any;
}

interface QuizCardStyleProps {
  $correctAnswer?: boolean;
  $index?: number;
  $isCorrect?: boolean;
  $isSmAndUp?: boolean;
}

const QuizPreviewCard: React.FC<QuizPreviewCardProps> = ({ quiz }) => {
  const { t } = useTranslation();
  const breakpoints = useBreakpoints();
  const isSmAndUp = useMediaQuery(breakpoints.up('sm'));

  if (!quiz) {
    return <GenericError />;
  }
  if (!quiz.questions?.length) {
    return (
      <Card elevation={2}>
        <CardContent>
          <StyledNoQuestions>
            {t('TrainingPlans.previewQuiz.noQuestions')}
          </StyledNoQuestions>
          <StyledNoQuestionsInstructions>
            {t('TrainingPlans.previewQuiz.noQuestionsHelpText')}
          </StyledNoQuestionsInstructions>
        </CardContent>
      </Card>
    );
  }
  return quiz?.questions?.map(
    (questionData: CustomQuizQuestionDefinitionDTO, questionIndex: number) => (
      <StyledQuizQuestionCard
        $index={questionIndex}
        elevation={2}
        key={questionIndex}
      >
        <CardContent>
          <StyledQuestionName>
            {getNameFromLanguage(questionData.question)}
          </StyledQuestionName>
          {questionData?.answers &&
            questionData.answers.map((answerData, answerIndex) => (
              <StyledAnswersWrapper key={answerIndex}>
                <RadioGroup orientation="vertical">
                  <StyledAnswerWrapper $correctAnswer={answerData.correct}>
                    <StyledRadioButton
                      $isCorrect={answerData.correct}
                      disabled={!answerData.correct}
                      label={getNameFromLanguage(answerData.answer)}
                      // @ts-ignore
                      value={answerData.correct}
                    />
                    {answerData.correct && (
                      <StyledCorrectText $isSmAndUp={isSmAndUp}>
                        {t('TrainingPlans.previewQuiz.correctAnswer')}
                        <StyledCircleCheckIcon icon={IconCircleCheckFilled} />
                      </StyledCorrectText>
                    )}
                  </StyledAnswerWrapper>
                </RadioGroup>
              </StyledAnswersWrapper>
            ))}
        </CardContent>
      </StyledQuizQuestionCard>
    ),
  );
};

const StyledQuizQuestionCard = styled(Card)<QuizCardStyleProps>`
  margin-top: ${({ $index }) => ($index ? '18px' : null)};
`;

const StyledCorrectText = styled(Typography)<QuizCardStyleProps>`
  display: flex;
  font-weight: 500;
  color: ${({ theme }) => theme.semanticColors.success};
  align-items: center;
  gap: 8px;
  padding-left: ${({ $isSmAndUp }) => ($isSmAndUp ? '0' : '36px')};
  margin: 4px;
`;

const StyledAnswersWrapper = styled.div`
  justify-content: center;
  align-items: baseline;
  flex-direction: 'row'};
`;

const StyledAnswerWrapper = styled.div<QuizCardStyleProps>`
  background: ${({ $correctAnswer, theme }) =>
    $correctAnswer && theme.designSystem.successBackground};
  border: ${({ $correctAnswer, theme }) =>
    $correctAnswer
      ? `1px solid ${theme.semanticColors.success}`
      : '1px solid transparent'};
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-right: 8px;
`;

const StyledCircleCheckIcon = styled(Icon)`
  width: 20px;
  height: 20px;
`;

const StyledRadioButton = styled(RadioButton)<QuizCardStyleProps>`
  padding-left: 8px;
  margin-top: 8px;
  .radio {
    border-color: ${({ $isCorrect, theme }) =>
      $isCorrect ? `${theme.semanticColors.success}` : null} !important;
  }
  .cfa-radiobutton-inner-circle {
    border: ${({ $isCorrect, theme }) =>
      $isCorrect
        ? `6px solid ${theme.semanticColors.success}`
        : null} !important;
  }
`;

const StyledNoQuestions = styled(Typography)`
  text-align: center;
  padding: 16px 0;
  color: ${({ theme }) => theme.semanticColors.information};
`;

const StyledNoQuestionsInstructions = styled(Typography)`
  text-align: center;
  color: ${({ theme }) => theme.grayScale.gray4};
`;

const StyledQuestionName = styled(Typography)`
  text-align: left;
  font-weight: bold;
  padding: 16px 0;
  color: ${({ theme }) => theme.grayScale.gray6};
`;

export default QuizPreviewCard;
