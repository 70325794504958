import { useEffect, useMemo } from 'react';
import { IframeView } from '../common';
import Constants from '@/constants';
import { useAppSelector } from '@/hooks';
import { selectUserId } from '@/store/user/selectors';
import { useTrainingPlanTimer } from '@/utils/document-utils';

interface TrainingPlanProps {
  planId?: string;
  refFileId?: string;
  refVersionId?: string;
  refUrl?: string;
  stepId?: string;
  stepStatus?: string;
  userIds?: string[];
}

const TrainingPlan: React.FC<TrainingPlanProps> = ({
  planId = '',
  refFileId,
  refVersionId,
  refUrl,
  stepId = '',
  stepStatus = '',
  userIds,
}) => {
  const userId = useAppSelector(selectUserId);

  const timerUserIds = useMemo(
    () => (userIds ? userIds : [userId]),
    [userIds, userId],
  );

  const { startTimer, stopTimer } = useTrainingPlanTimer(
    planId,
    stepId,
    timerUserIds,
    stepStatus,
  );

  useEffect(() => {
    startTimer();
    return () => stopTimer();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const url =
    refVersionId && refFileId
      ? `${Constants.PATHWAY_API_CONTENT_URL}/${refVersionId}/${refFileId}/index.html`
      : refUrl; // use reference url for Game resource

  return <IframeView url={url} />;
};

export default TrainingPlan;
