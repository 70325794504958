import 'react-smartbanner/dist/main.css';
import { getNameFromLanguage } from 'utils/language';
import { useEffect, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { BannerMessage } from 'cfa-react-components';
import { useDispatch, useSelector } from 'react-redux';
import { selectWebAlertBannerLastDismissed } from 'store/webAlertBanner/selectors';
import { dismissWebAlertBanner } from 'store/webAlertBanner/slice';

const WebAlertBannerContainer = () => {
  const dispatch = useDispatch();
  const lastDismissed = useSelector(selectWebAlertBannerLastDismissed);
  const { webAlertBanner } = useFlags();
  const [showAlertBanner, setShowAlertBanner] = useState(false);

  useEffect(() => {
    if (webAlertBanner) {
      if (webAlertBanner.status && webAlertBanner.lastUpdate > lastDismissed) {
        setShowAlertBanner(true);
      } else {
        setShowAlertBanner(false);
      }
    }
  }, [lastDismissed, webAlertBanner]);

  const closeBanner = () => {
    dispatch(dismissWebAlertBanner());
    setShowAlertBanner(false);
  };

  return (
    <BannerMessage onClose={closeBanner} severity="info" show={showAlertBanner}>
      {getNameFromLanguage(webAlertBanner?.message)}
    </BannerMessage>
  );
};

export default WebAlertBannerContainer;
