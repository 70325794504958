import Constants from 'constants/index';
import PopoverMenuButton from 'components/PopoverMenuButton/PopoverMenuButton';
import PopoverMenuButtonItem from 'components/PopoverMenuButton/PopoverMenuButtonItem';
import {
  IconCopy,
  IconEdit,
  IconTrash,
  IconUserPlus,
  IconWorld,
} from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from 'components/popups/ConfirmationModal';
import InputDialog from 'components/Dialogs/InputDialog';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectActiveLicenseeLocation } from 'store/licenseeLocationPicker/selector';
import {
  selectAllLocationsWithAtLeastTrainer,
  isUserLicensee,
} from 'store/user/selectors';
import { useGetOperatorsQuery } from 'services/pathwayApi';
import { ChecklistDTO } from '@cfacorp-pathway/xp-api-typescript-client';
import PlanCardTitle from '../PlanCardComponents/PlanCardTitle';
import PlanCardLabel from '../PlanCardComponents/PlanCardLabel';
import PlanCardSubHeader from '../PlanCardComponents/PlanCardSubHeader';
import PlanCard from '../PlanCard';
import PlanCardContent from '../PlanCardContent/PlanCardContent';
import PlanCardDate from '../PlanCardComponents/PlanCardDate';
import { arrayIntersect } from '@/utils/keepDuplicatesFromTwoArrays';
import { getNameFromLanguage } from '@/utils/language';
import { getTimeString } from '@/utils/time';
import DueDatePopUp from '@/components/popups/DueDatePopUp';
import AddTeamMembersPopUp from '@/components/popups/AddTeamMemberPopUp/AddTeamMembersPopUp';
import { LanguageObject } from '@/types/types';
import { mapCategoryToAliasTranslation } from '@/utils/categoryUtils';
import ManageTranslationsPopup from '@/components/popups/ManageTranslationsModal/ManageTranslations';

interface BuildPlansPlanCardProps {
  className?: string;
  dataTestId: string;
  onAssignTeamMembers: (members: string | string[], dueDate: string) => void;
  onDeletePlan: () => void;
  onDuplicatePlan: (id: string, ownerId: string, name: string) => void;
  onRenamePlan: (name: string) => void;
  onSaveTranslations: (updatedTranslations: any) => void;
  plan: ChecklistDTO;
}

const BuildPlansPlanCard: React.FC<BuildPlansPlanCardProps> = ({
  className,
  dataTestId = 'BuildPlansPlanCard',
  onAssignTeamMembers,
  onDeletePlan,
  onDuplicatePlan,
  onRenamePlan,
  onSaveTranslations,
  plan,
}) => {
  const { t } = useTranslation();
  const isLicenseeUser = useSelector(isUserLicensee);
  const activeLicenseeLocation = useSelector(selectActiveLicenseeLocation);
  const [showDeletePlanPopup, setShowDeletePlanPopup] = useState(false);
  const [showDuplicatePlanPopup, setShowDuplicatePlanPopup] = useState(false);
  const [showRenamePlanPopup, setShowRenamePlanPopup] = useState(false);
  const [showAddTeamMembersPopup, setShowAddTeamMembersPopup] = useState(false);
  const [showManageTranslationsPopup, setShowManageTranslationsPopup] =
    useState(false);
  const [showDueDatePopup, setShowDueDatePopup] = useState(false);

  const [selectedId, setSelectedId] = useState('');

  const locationsWithAtLeastTrainer = useSelector(
    selectAllLocationsWithAtLeastTrainer,
  );

  const { data: operators } = useGetOperatorsQuery();
  const operatorLocations = operators?.find(
    operator => operator.id === plan?.ownerId,
  )?.locations;

  return (
    <>
      {!!plan && (
        <>
          <PlanCard
            buttons={
              <PopoverMenuButton dataTestId="PlanCardMenu">
                <PopoverMenuButtonItem
                  dataTestId="ThreeDotMenuRenamePlanPopup"
                  icon={<IconEdit />}
                  onClick={() => setShowRenamePlanPopup(true)}
                  text={t('TrainingPlans.manageThreeDotMenu.rename')}
                />
                <PopoverMenuButtonItem
                  dataTestId="ThreeDotMenuAssignPopup"
                  icon={<IconUserPlus />}
                  onClick={() => setShowAddTeamMembersPopup(true)}
                  text={t('Generic.assign')}
                />
                <PopoverMenuButtonItem
                  dataTestId="ThreeDotMenuManageTranslationsPopup"
                  icon={<IconWorld />}
                  onClick={() => setShowManageTranslationsPopup(true)}
                  text={t('Generic.editTranslations')}
                />
                <PopoverMenuButtonItem
                  dataTestId="ThreeDotMenuDuplicatePlanPopup"
                  icon={<IconCopy />}
                  onClick={() => setShowDuplicatePlanPopup(true)}
                  text={t('TrainingPlans.manageThreeDotMenu.duplicate')}
                />
                <PopoverMenuButtonItem
                  dataTestId="ThreeDotMenuDeletePlanPopup"
                  icon={<IconTrash data-testid="PlanDelete" />}
                  isDestructive={true}
                  onClick={() => setShowDeletePlanPopup(true)}
                  text={t('TrainingPlans.manageThreeDotMenu.delete')}
                />
              </PopoverMenuButton>
            }
            category={plan?.category}
            className={className}
            dataTestId={dataTestId}
            link={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PLAN_PATH_NAME}/${plan?.id}`}
          >
            <PlanCardContent>
              <PlanCardLabel>
                {t(mapCategoryToAliasTranslation(plan?.category))}
              </PlanCardLabel>
              <PlanCardTitle>
                {getNameFromLanguage(plan?.name as LanguageObject)}
              </PlanCardTitle>
              <PlanCardSubHeader dataTestId="PlanTaskCount">
                {`${plan?.stepsTotal} ${t('Generic.items')}
                ${
                  !!plan?.estimatedMinutes
                    ? ` · ${t('Generic.around')} ${getTimeString(
                        plan?.estimatedMinutes,
                      )} ${t('TrainingPlans.accountability.toComplete')}`
                    : ''
                }`}
              </PlanCardSubHeader>
            </PlanCardContent>
            <PlanCardDate date={plan?.created!} />
          </PlanCard>
          <ConfirmationModal
            bodyText={t('Generic.deleteObject', {
              name: getNameFromLanguage(plan?.name as LanguageObject) ?? '',
              object: t('Generic.plan').toLowerCase(),
            })}
            headerText={t('Generic.deleteHeader', {
              type: t('Generic.plan'),
            })}
            isOpen={showDeletePlanPopup}
            onClose={() => setShowDeletePlanPopup(false)}
            primaryButtonHandler={() => {
              onDeletePlan();
              setShowDeletePlanPopup(false);
            }}
            primaryButtonText={t('Button.delete')}
            primaryButtonVariant="destructive"
            secondaryButtonHandler={() => setShowDeletePlanPopup(false)}
            secondaryButtonText={t('Button.cancel')}
          />
          <InputDialog
            headerText={t('Generic.giveNewName', { object: t('Generic.plan') })}
            isOpen={showRenamePlanPopup}
            onClose={() => setShowRenamePlanPopup(false)}
            primaryButtonHandler={inputValue => {
              onRenamePlan(inputValue);
              setShowRenamePlanPopup(false);
            }}
            primaryButtonText={t('Button.update')}
            primaryButtonVariant="filled"
            secondaryButtonHandler={() => setShowRenamePlanPopup(false)}
            secondaryButtonText={t('Button.cancel')}
            textInputDefaultValue={getNameFromLanguage(
              plan?.name as LanguageObject,
            )}
            textInputPlaceholder={t('TrainingPlans.planNamePlaceholder')}
          />
          <AddTeamMembersPopUp
            handleNextClick={({ id }: { id: string }) => {
              setShowAddTeamMembersPopup(false);
              setShowDueDatePopup(true);
              setSelectedId(id);
            }}
            isOpen={showAddTeamMembersPopup}
            locations={
              isLicenseeUser
                ? [activeLicenseeLocation.number]
                : arrayIntersect(
                    locationsWithAtLeastTrainer,
                    operatorLocations ?? [],
                  )
            }
            onClose={() => setShowAddTeamMembersPopup(false)}
            planDetails={plan ?? {}}
            teamMemberQueryOptions={{ refetchOnMountOrArgChange: true }}
          />
          <InputDialog
            headerText={t('Generic.giveName', { object: t('Generic.plan') })}
            isDuplicatePlan={showDuplicatePlanPopup}
            isOpen={showDuplicatePlanPopup}
            onClose={() => setShowDuplicatePlanPopup(false)}
            primaryButtonHandler={inputValue => {
              setShowDuplicatePlanPopup(false);
              onDuplicatePlan(plan?.id!, plan?.ownerId!, inputValue);
            }}
            primaryButtonText={t('Button.create')}
            primaryButtonVariant="filled"
            secondaryButtonHandler={() => setShowDuplicatePlanPopup(false)}
            secondaryButtonText={t('Button.cancel')}
            textInputDefaultValue={
              getNameFromLanguage(plan?.name as LanguageObject) +
              ` ${t('TrainingPlans.duplicatePlanInputCopy')}`
            }
            textInputPlaceholder={t('TrainingPlans.planNamePlaceholder')}
          />
          <ManageTranslationsPopup
            isOpen={showManageTranslationsPopup}
            onClose={() => setShowManageTranslationsPopup(false)}
            onSave={onSaveTranslations}
            translatableObject={plan}
          />
          <DueDatePopUp
            isOpen={showDueDatePopup}
            onClose={() => setShowDueDatePopup(false)}
            onSave={(dueDate: string | Date) => {
              setShowDueDatePopup(false);
              onAssignTeamMembers(
                selectedId.includes(',') ? selectedId.split(',') : selectedId,
                dueDate as string,
              );
            }}
          />
        </>
      )}
    </>
  );
};

export default BuildPlansPlanCard;
