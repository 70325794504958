import { memo } from 'react';
import styled from 'styled-components';
import { useHeightForIframe } from '@/utils/document-utils';

interface TridionErrorProps {
  url?: string;
}

const TridionError: React.FC<TridionErrorProps> = ({ url }) => {
  const height = useHeightForIframe();

  return (
    <StyledIFrame
      $calculatedHeight={height}
      allow="fullscreen"
      className="content-iframe"
      src={url}
    />
  );
};

const StyledIFrame = styled.iframe<{ $calculatedHeight: string }>`
  border: 0;
  width: 100%;
  height: ${({ $calculatedHeight }) => $calculatedHeight};
`;

export default memo(TridionError);
