import i18next from 'i18next';
import { ChecklistStatus, ComplianceCourse } from '@/types/types';

export const generateTotalTime = (
  totalMinutes: number,
  hour: string,
  mins: string,
) => {
  const timeSpentHours = Number.isInteger(totalMinutes)
    ? Math.floor(totalMinutes / 60)
    : 0;
  const timeSpentMinutes = Number.isInteger(totalMinutes)
    ? Math.floor(totalMinutes % 60)
    : 0;
  const timeSpentOnPlanString =
    timeSpentHours > 0
      ? `${timeSpentHours} ${hour} ${timeSpentMinutes} ${mins}`
      : `${timeSpentMinutes} ${mins}`;
  return timeSpentOnPlanString;
};

export const generateTotalTimeFromSteps = (
  steps: any[],
  hours: string,
  mins: string,
) => {
  const timeSpentOnPlan = steps
    ?.map(it => it.stepDuration)
    .reduce((acc, a) => acc + Math.round((a ?? 0) / 60), 0); //need to round individual steps
  const timeSpentHrs = Number.isInteger(timeSpentOnPlan)
    ? Math.floor(timeSpentOnPlan / 60)
    : 0;
  const timeSpentMins = Number.isInteger(timeSpentOnPlan)
    ? Math.round(timeSpentOnPlan % 60)
    : 0;
  const timeSpentOnPlanString =
    timeSpentHrs > 0
      ? `${timeSpentHrs} ${hours} ${timeSpentMins} ${mins}`
      : `${timeSpentMins} ${mins}`;
  return timeSpentOnPlanString;
};

export const getTimeString = (time: number) => {
  const timeSpentHrs = Math.floor(time / 60);
  const timeSpentMins = time % 60;
  return `${
    timeSpentHrs > 0 ? timeSpentHrs + ' ' + i18next.t('Generic.hour') : ''
  } ${timeSpentMins} ${i18next.t('Generic.mins')}`;
};

export const getTimeSpentOnPlanForTeamMember = (
  status: any[],
  teamMemberId: string,
) =>
  Math.round(
    status
      ?.find(planStatus => planStatus?.userId === teamMemberId)
      ?.steps?.reduce(
        (acc: string, step: { stepDuration: number }) =>
          acc + Math.round((step?.stepDuration ?? 0) / 60),
        0,
      ) ?? 0,
  );

export const getTimeSpentOnPlan = (
  member: ChecklistStatus | ComplianceCourse,
  isCompliance = false,
) => {
  if (isCompliance) {
    if (!!member?.duration) {
      return getTimeString(Math.ceil(member?.duration / 60));
    } else {
      return null;
    }
  } else {
    return generateTotalTimeFromSteps(
      (member as ChecklistStatus)?.steps,
      i18next.t('Generic.hour'),
      i18next.t('Generic.mins'),
    );
  }
};

export const getHrsFromTotalMins = (totalMinutes: number) =>
  Math.floor(totalMinutes / 60);

export const getMinsFromTotalMins = (totalMinutes: number) => totalMinutes % 60;
