import Constants from 'constants/index';
import {
  isReportsCompliancePlansPath,
  isReportsPlansPath,
  isReportsTeamMembersPath,
} from 'utils/url';
import styled from 'styled-components';
import { TabItem, Tabs } from 'cfa-react-components';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { isUserLicensee } from 'store/user/selectors';
import { useAppSelector } from '@/hooks';
import { useFeaturePermission } from '@/utils/permissions';
import { useFlags } from 'launchdarkly-react-client-sdk';

const ReportsTabs = {
  PLANS: 'plans',
  TEAM_MEMBERS: 'teammembers',
  COMPLIANCE_PLANS: 'complianceplans',
};

const ReportsSubheader = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const isLicenseeUser = useAppSelector(isUserLicensee);
  const reportingPlansEnabled = useFeaturePermission('reporting.plans');
  const reportingTeamMemberEnabled = useFeaturePermission(
    'reporting.teamMember',
  );
  const reportingComplianceEnabled = useFeaturePermission(
    'reporting.compliance',
  );
  const { plansRedesign: plansRedesignFeatureFlag } = useFlags();

  let activeItemKey = '';

  if (isReportsPlansPath(location)) {
    activeItemKey = ReportsTabs.PLANS;
  }
  if (isReportsTeamMembersPath(location)) {
    activeItemKey = ReportsTabs.TEAM_MEMBERS;
  }
  if (isReportsCompliancePlansPath(location)) {
    activeItemKey = ReportsTabs.COMPLIANCE_PLANS;
  }

  return (
    <Tabs activeItemKey={activeItemKey} color="secondary" onChange={() => {}}>
      {!!reportingPlansEnabled && (
        <HeaderTabItem itemKey={ReportsTabs.PLANS}>
          <HeaderLink
            to={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PLANS_PATH_NAME}`}
          >
            {t('Reports.tabPlans')}
          </HeaderLink>
        </HeaderTabItem>
      )}
      {!!reportingTeamMemberEnabled && (
        <HeaderTabItem itemKey={ReportsTabs.TEAM_MEMBERS}>
          <HeaderLink
            to={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}`}
          >
            {t('Reports.tabTeamMembers')}
          </HeaderLink>
        </HeaderTabItem>
      )}
      {!plansRedesignFeatureFlag &&
        !!reportingComplianceEnabled &&
        // remove isLicenseeUser logic once BE ticket P-1219 ticket is completed to fix logic to licensees userFeatureFlag for compliance
        !isLicenseeUser && (
          <HeaderTabItem itemKey={ReportsTabs.COMPLIANCE_PLANS}>
            <HeaderLink
              to={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.COMPLIANCE_PATH_NAME}`}
            >
              {t('Reports.tabCompliancePlans')}
            </HeaderLink>
          </HeaderTabItem>
        )}
    </Tabs>
  );
};

const HeaderTabItem = styled(TabItem)`
  & > button {
    padding: 0;
    margin-bottom: 24px;
    margin-top: 8px;
  }
`;

const HeaderLink = styled(Link)`
  padding: 16px 32px;
`;

export default ReportsSubheader;
