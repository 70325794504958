import { useGetTeamMembersQuery } from '@/services/pathwayApi';
import { useGetUsersForLocationsQuery } from '@/services/xpApi';
import {
  AssignableCourseResult,
  ComplianceCourseEnrollmentEntity,
  PathwayUser,
} from '@cfacorp-pathway/xp-api-typescript-client';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Constants from '@/constants';

export const useTeamMemberCount = (
  locationsWithAtLeastTrainer: string[],
  enrollments: Record<string, ComplianceCourseEnrollmentEntity> = {},
): number => {
  const { licensee } = useFlags();

  const { data: oldAllTeamMembersData } = useGetTeamMembersQuery(
    {
      locations: locationsWithAtLeastTrainer,
    },
    { skip: licensee, refetchOnMountOrArgChange: true },
  );

  const { data: xpAllTeamMembersData } = useGetUsersForLocationsQuery(
    { locations: locationsWithAtLeastTrainer },
    { skip: !licensee },
  );

  const teamMembers: PathwayUser[] | undefined = licensee
    ? xpAllTeamMembersData
    : oldAllTeamMembersData;

  const teamMembersDeduped = Array.from(
    new Set(teamMembers?.map(teamMember => teamMember.adId!)),
  ).map(adId => teamMembers?.find(teamMember => teamMember.adId === adId)!);

  const teamMembersVisible = teamMembersDeduped.filter(teamMember => {
    const enrollment = enrollments[teamMember.adId ?? ''];
    if (!enrollment) {
      return true;
    }
    return !enrollment.hidden;
  });

  return teamMembersVisible.length;
};

export const enrollmentPassed = (
  enrollment: ComplianceCourseEnrollmentEntity,
) =>
  enrollment.status === Constants.LEARN_UPON_TRAINING_PLANS.COMPLETED ||
  enrollment.status === Constants.LEARN_UPON_TRAINING_PLANS.PASSED;

export type NonNullComplianceCourse = {
  [Property in keyof AssignableCourseResult]-?: AssignableCourseResult[Property];
};
