import Constants from 'constants/index';
import { Security } from '@okta/okta-react';
import { basicLogger } from 'launchdarkly-js-client-sdk';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import oktaAuth, { restoreOriginalUri } from 'okta/oktaConfig';
import { Provider } from 'react-redux';
import { Route, Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as ReactCoreThemeProvider } from '@cfa/react-core/theme';
import { initializeApp } from './initializeApp';
import LaunchDarklySetup from './LaunchDarklySetup';
import CustomLoginCallback from '@/okta/CustomLoginCallback';
import { AmplitudeProvider } from '@/amplitude/AmplitudeContext';
import DocumentHead from '@/components/document/DocumentHead';
import ErrorBoundaries from '@/components/Error/ErrorBoundaries';
import LoadingOverlay from '@/components/LoadingOverlay/LoadingOverlay';
import Layout from '@/pages/Layout/Layout';
import store, { persistor } from '@/store';
import theme from '@/styles/theme';
import isBrowserSupported from '@/utils/isBrowserSupported';
import { getNameFromLanguage } from '@/utils/language';
import ScrollToTop from '@/utils/ScrollToTop';
import { useInitializeTranslation, useUserAuth } from '@/hooks/app';
import { DatadogRumSetup } from '@/utils/datadog';
import history from '@/utils/history';
import { getAuthFromNative } from '@/utils/auth';

if (isBrowserSupported()) {
  initializeApp();
}

const { isNative } = getAuthFromNative();

const App: React.FC = () => {
  const { user, userAuthenticated } = useUserAuth();
  const translationInitialized = useInitializeTranslation();

  if (!translationInitialized) {
    return null;
  }

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <DatadogRumSetup user={user} />
        <LaunchDarklySetup />
        <AmplitudeProvider options={{ defaultTracking: true }} user={user}>
          <ReactCoreThemeProvider>
            <ThemeProvider theme={theme}>
              <LoadingOverlay isOpen={!userAuthenticated} />
              <ErrorBoundaries>
                <Router history={history}>
                  {/* isNative block is for native mobile view, otherwise web view */}
                  {isNative ? (
                    <>
                      <DocumentHead pageTitle="" />
                      <ScrollToTop />
                      <Layout />
                    </>
                  ) : (
                    <Security
                      oktaAuth={oktaAuth}
                      restoreOriginalUri={restoreOriginalUri}
                    >
                      <DocumentHead pageTitle="" />
                      <ScrollToTop />
                      <Layout />
                      <Route
                        component={CustomLoginCallback}
                        path="/login/callback"
                      />
                    </Security>
                  )}
                </Router>
              </ErrorBoundaries>
            </ThemeProvider>
          </ReactCoreThemeProvider>
        </AmplitudeProvider>
      </PersistGate>
    </Provider>
  );
};

if (window.Cypress) {
  // allows us to connect cypress to redux
  window.store = store;
  window.persistor = persistor;
  window.utils = { getNameFromLanguage };
}

const AppWithLaunchDarkly = withLDProvider({
  clientSideID: Constants.LAUNCH_DARKLY_CLIENT_ID!,
  options: {
    useReport: true,
    logger: basicLogger({ level: 'warn' }),
  },
})(App);

export default AppWithLaunchDarkly;
