import Constants from 'constants/index';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import React from 'react';
import ConfirmationModal from '@/components/popups/ConfirmationModal';
import {
  exitTrainingMode,
  hideProgressModal,
  resetTrainingModeData,
} from '@/store/trainingMode/slice';
import {
  setHeader,
  setHeaderLabel,
  setHeaderSubtext,
} from '@/store/header/slice';
import { selectSideBarReducerState } from '@/store/sideBar/selectors';
import {
  selectShowProgressModal,
  selectTrainingModeData,
} from '@/store/trainingMode/selectors';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface ConfirmationModalsProps {
  isOpen: boolean;
}

const ConfirmationModals: React.FC<ConfirmationModalsProps> = ({ isOpen }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { plansRedesign: plansRedesignFeatureFlag } = useFlags();
  const sideBar = useSelector(selectSideBarReducerState);
  const showProgressModal = useSelector(selectShowProgressModal);
  const { planName } = useSelector(selectTrainingModeData);

  const onExit = () => {
    dispatch(setHeader(''));
    dispatch(setHeaderLabel(''));
    dispatch(setHeaderSubtext(''));
    dispatch(hideProgressModal());
    dispatch(exitTrainingMode());
    dispatch(resetTrainingModeData());

    switch (sideBar.linkClicked) {
      case Constants.SIDEBAR.EXPLORE:
        history.push({
          pathname: '/',
        });
        break;
      case Constants.SIDEBAR.SETTINGS:
        history.push({
          pathname: `/${Constants.ROUTE_PATH_NAMES.PROFILE_PATH_NAME}`,
        });
        break;
      case Constants.SIDEBAR.TRAINING:
        history.push({
          pathname: `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.BUILD_PATH_NAME}`,
        });
        break;

      default:
        history.push({
          pathname: '/',
        });
    }
  };

  const goToExplorePage = () => {
    dispatch(setHeader(''));
    dispatch(setHeaderLabel(''));
    dispatch(setHeaderSubtext(''));
    dispatch(exitTrainingMode());
    dispatch(resetTrainingModeData());
    history.push({
      pathname: '/',
      state: {
        fromTrainingMode: true,
      },
    });
  };

  return (
    <>
      <ConfirmationModal
        bodyText={
          plansRedesignFeatureFlag
            ? t('TrainingPlans.trainTogether.exitModalBody')
            : t('TrainingPlans.trainingMode.exitModal')
        }
        headerText={
          plansRedesignFeatureFlag
            ? t('TrainingPlans.trainTogether.exitModalHeader')
            : t('TrainingPlans.trainingMode.exitTrainingMode')
        }
        isOpen={showProgressModal}
        onClose={() => dispatch(hideProgressModal())}
        primaryButtonColor={'secondary'}
        primaryButtonHandler={onExit}
        primaryButtonText={t('Button.exit')}
        secondaryButtonHandler={() => dispatch(hideProgressModal())}
        secondaryButtonText={t('Button.stay')}
      />
      <ConfirmationModal
        bodyText={
          plansRedesignFeatureFlag
            ? t('TrainingPlans.trainTogether.completedPlanModal', {
                planName: planName,
              })
            : t('TrainingPlans.trainingMode.completedPlanModal', {
                planName: planName,
              })
        }
        headerText={t('Generic.planCompleted')}
        isOpen={isOpen}
        onClose={goToExplorePage}
        primaryButtonColor={'secondary'}
        primaryButtonHandler={goToExplorePage}
        primaryButtonText={t('Button.close')}
      />
    </>
  );
};

export default ConfirmationModals;
