import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  AssignableCourseResult,
  ComplianceConfigurationDTO,
} from '@cfacorp-pathway/xp-api-typescript-client';
import { selectUserIsStaff } from '@/store/user/selectors';
import {
  useGetReportsPlansAssignableQuery,
  useGetIsCourseEnabledQuery,
  useGetOperatorsQuery as useGetOperatorsQueryOld,
} from '@/services/pathwayApi';
import {
  useGetOperatorCourseSettingsQuery,
  useGetOperatorsQuery,
} from '@/services/xpApi';
import { arrayIntersect } from '@/utils/keepDuplicatesFromTwoArrays';

// For ReportsCompliancePlansTab compliance plans list
const useGetCompliancePlans = (
  locationsWithAtLeastLeaderPermission: string[],
) => {
  const { xpApi: xpApiFeatureFlag } = useFlags();

  const [compliancePlans, setCompliancePlans] = useState<
    AssignableCourseResult[]
  >([]);

  const isStaff = useSelector(selectUserIsStaff);

  // content or xp api: content api used only (To be replaced/cleaned when xpApi ready). Get checklists and compliance plans/courses
  const { data: oldUnOrderedPlans, isFetching: isFetchingPlansOld } =
    useGetReportsPlansAssignableQuery(
      { locations: undefined },
      { skip: !!xpApiFeatureFlag },
    );

  const { data: xpUnOrderedPlans, isFetching: isFetchingPlansXp } =
    useGetReportsPlansAssignableQuery(
      // TODO replace with proxy when created by BE P-606
      { locations: undefined },
      {
        skip: !xpApiFeatureFlag,
      },
    );

  const isFetchingPlans = xpApiFeatureFlag
    ? isFetchingPlansXp
    : isFetchingPlansOld;

  const unOrderedPlans = xpApiFeatureFlag
    ? xpUnOrderedPlans
    : oldUnOrderedPlans;

  // content or xp api: get operators
  const { data: oldOperators } = useGetOperatorsQueryOld(undefined, {
    skip: isStaff || !!xpApiFeatureFlag,
  });

  const { data: xpOperators } = useGetOperatorsQuery(undefined, {
    skip: isStaff || !xpApiFeatureFlag,
  });

  const operators = xpApiFeatureFlag ? xpOperators : oldOperators;

  const operatorId = useMemo(() => {
    const OperatorsOfLocationsWithAtLeastLeader = operators?.filter(
      operator =>
        arrayIntersect(
          operator?.locations ?? [],
          locationsWithAtLeastLeaderPermission,
        ).length > 0,
    );
    return OperatorsOfLocationsWithAtLeastLeader?.[0]?.id ?? operators?.[0]?.id;
  }, [operators, locationsWithAtLeastLeaderPermission]);

  // content or xp api: get first operator courses' settings (enabled status)
  const { data: oldComplianceStatusData } = useGetIsCourseEnabledQuery(
    operatorId!,
    {
      skip: isStaff || !operators?.length || !!xpApiFeatureFlag,
    },
  );

  const { data: xpComplianceStatusData } = useGetOperatorCourseSettingsQuery(
    {
      operatorId: operatorId ?? '',
    },
    {
      skip: isStaff || !operators?.length || !xpApiFeatureFlag,
    },
  );

  const complianceStatusData = xpApiFeatureFlag
    ? xpComplianceStatusData
    : oldComplianceStatusData;

  // Check if compliance plan is enabled
  useEffect(() => {
    if (!unOrderedPlans || (!isStaff && !complianceStatusData)) {
      return;
    }

    // if is Staff, no operators and compliance status data used (skip calls)
    const compliancePlansWithStatus = isStaff
      ? unOrderedPlans?.courses
      : unOrderedPlans?.courses // pull operator course setting/status for compliance plans if not staff
          ?.filter(plan => plan?.courseID)
          .map(course => ({
            ...course,
            enabled:
              (
                complianceStatusData as Record<
                  string,
                  ComplianceConfigurationDTO
                >
              )?.[course?.id!]?.enabled ?? false,
          }));

    setCompliancePlans(compliancePlansWithStatus as AssignableCourseResult[]);
  }, [complianceStatusData, isStaff, unOrderedPlans]);

  return {
    compliancePlans,
    isFetchingPlans,
  };
};

export default useGetCompliancePlans;
