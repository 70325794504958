import { useEffect, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';

const HEADLESS_ATTR = 'data-headless';
const TOC_HEADING_TAG = 'h2';
const STICK_CLASS = 'heading-sticky';
const THEAD_STICK_CLASS = 'thead-sticky-offset';
const THEAD_NO_STICK = 'thead-no-sticky';
const TRIDION_DOCUMENT_CLASS = '_tridionDocument';

// get top bar height based on if docuent is headless or not
const getTopBarHeight = () => {
  if (document.documentElement.hasAttribute(HEADLESS_ATTR)) {
    return 0;
  }
  return 60;
};

/** This makes css fixes for tridion documents in headless mode. */
export const useAdjustCssForHeadless = () => {
  useEffect(() => {
    const documentElement = document.documentElement;
    documentElement.setAttribute(HEADLESS_ATTR, 'true');

    const style = document.createElement('style');

    style.textContent = `
      .heading-sticky {
        top: 0px !important;
      }
    `;

    document.head.appendChild(style);
  }, []);
};

const filterHeadings = (headings: NodeListOf<Element>) => {
  return [...headings].filter(
    heading => !heading.innerHTML.includes('Front Matter'),
  );
};

export const getHeadingElements = () => {
  const headings = document.querySelectorAll(
    `[class^='${TRIDION_DOCUMENT_CLASS}'] ${TOC_HEADING_TAG}`,
  );
  return filterHeadings(headings);
};

export const getHeadingElementsFromHtmlContent = (htmlContent?: string) => {
  if (!htmlContent) {
    return [];
  }
  const html = new DOMParser().parseFromString(htmlContent, 'text/html');
  const headings = html.querySelectorAll(TOC_HEADING_TAG);
  return filterHeadings(headings);
};

// add stickey offset class to table head if no h2 heading
const addStickyOffsetTableHead = (headingElements: Element[]) => {
  const firstHeadingTop = headingElements?.[0]?.getBoundingClientRect()?.top;
  const tableHeads = document.querySelectorAll(
    `[class^='${TRIDION_DOCUMENT_CLASS}'] thead`,
  );
  tableHeads.forEach(thead => {
    if (!thead) {
      return;
    }
    const cells = thead.querySelectorAll('th');
    const isTheadHasContent = [...cells].some(cell => {
      const text = cell.innerHTML?.replace(/&nbsp;/g, '')?.trim();
      return !!text;
    });
    if (!isTheadHasContent) {
      if (!thead.classList.contains(THEAD_NO_STICK)) {
        thead.classList.add(THEAD_NO_STICK);
      }
    } else if (
      (!firstHeadingTop ||
        thead.getBoundingClientRect().top < firstHeadingTop) &&
      !thead.classList.contains(THEAD_STICK_CLASS)
    ) {
      thead.classList.add(THEAD_STICK_CLASS);
    }
  });
};

export type HeadingItem = {
  id: string;
  text: string | null;
};

export const buildTableOfContentsItems = (headingElements: Element[]) => {
  const headings: HeadingItem[] = [];
  headingElements.forEach(headingEl => {
    const headingObject = {
      id: headingEl.id,
      text: headingEl.textContent,
    };
    headings.push(headingObject);
  });

  return headings;
};

export const removeTopElementStickyClass = () => {
  const headingElements = getHeadingElements();
  const topElement = [...headingElements].findLast(
    el => el.getBoundingClientRect().top <= getTopBarHeight(),
  );

  if (topElement && topElement.classList.contains(STICK_CLASS)) {
    topElement.classList.remove(STICK_CLASS);
  }
};

export const useTableOfContents = (
  isTocItemClicked: boolean,
  htmlContent?: string,
) => {
  const [tocItems, setTableOfContentsArray] = useState<HeadingItem[]>([]);

  useEffect(() => {
    if (!htmlContent) {
      return;
    }
    const tocElements = getHeadingElementsFromHtmlContent(htmlContent);
    const tocArray = buildTableOfContentsItems(tocElements);
    if (tocArray?.length) {
      setTableOfContentsArray(tocArray);
    }
    setTimeout(() => {
      addStickyOffsetTableHead(getHeadingElements());
    }, 200);
  }, [htmlContent]);

  const onScrollListener = useMemo(
    () =>
      debounce(() => {
        const headingElements = getHeadingElements();
        const topElement = [...headingElements].findLast(el => {
          const top = el.getBoundingClientRect().top;
          return top <= getTopBarHeight();
        });

        if (topElement && topElement.classList.contains(STICK_CLASS)) {
          if (isTocItemClicked) {
            topElement.classList.remove(STICK_CLASS);
          }
          return;
        }

        headingElements.forEach(headingEl => {
          if (headingEl.classList.contains(STICK_CLASS)) {
            headingEl.classList.remove(STICK_CLASS);
          }
        });
        if (topElement && !isTocItemClicked) {
          topElement.classList.add(STICK_CLASS);
        }
      }, 5),
    [isTocItemClicked],
  );

  useEffect(() => {
    window.addEventListener('scroll', onScrollListener);

    return () => {
      window.removeEventListener('scroll', onScrollListener);
    };
  }, [onScrollListener]);

  return { tocItems };
};
