import LicenseeLocationPicker from './LicenseeLocationPicker/LicenseeLocationPicker';

interface LayoutWrapperProps {
  children: React.ReactNode;
}

const LayoutWrapper: React.FC<LayoutWrapperProps> = ({ children }) => {
  return (
    <>
      {children}
      <LicenseeLocationPicker />
    </>
  );
};

export default LayoutWrapper;
