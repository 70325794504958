import { OktaUserRaw } from '@/types';
import { getHighestUserPermission } from '@/utils/user';
import { createInstance } from '@amplitude/analytics-browser';
import { BrowserOptions } from '@amplitude/analytics-types';
import Constants from 'constants/index';
import get from 'lodash/get';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAnalyticsEnabled } from 'store/user/selectors';
import { addPlatformPlugin } from './AmplitudePlatformPlugin';
import { AmplitudeContext, Track } from './useAmplitude';

interface AmplitudeProviderProps extends PropsWithChildren {
  user?: OktaUserRaw;
  options?: BrowserOptions;
}

export const AmplitudeProvider: React.FC<AmplitudeProviderProps> = ({
  user,
  options,
  children,
}) => {
  const analyticsEnabled = useSelector(selectAnalyticsEnabled);

  const [track, setTrack] = useState<Track | null>(null);

  useEffect(() => {
    const apiKey = Constants.AMPLITUDE_API_KEY;
    if (analyticsEnabled && apiKey && options && user && !track) {
      const userId = user?.['cfa-guid'];
      const userType = user?.['userType'];
      const userPerms = user?.['cfa_perms'];
      const permissionLevel = getHighestUserPermission(
        get(user?.cfa_perms, Constants.APPLICATION_SYSTEM_TAG),
      );
      const userLocations = user?.['cfa-locations'];

      const amplitudeInstance = createInstance();
      amplitudeInstance.add(addPlatformPlugin() as any);
      amplitudeInstance.init(apiKey, userId, options);
      setTrack(() => (eventName: string, properties: object) => {
        const date = new Date().toLocaleDateString();
        const time = new Date().toLocaleTimeString();
        const version = Constants.APP_VERSION;
        const env = Constants.ENV;

        amplitudeInstance.track('UserAction', {
          action: eventName,
          date: date,
          permission_level: permissionLevel,
          time: time,
          version: version,
          user_type: userType,
          user_perms: userPerms,
          user_locations: userLocations,
          env: env,
          ...properties,
        });
      });
    }
  }, [analyticsEnabled, options, track, user]);

  return (
    <AmplitudeContext.Provider value={track}>
      {children}
    </AmplitudeContext.Provider>
  );
};
