export default {
  Countries: {
    US: 'United States of America',
    CA: 'Canada',
    PR: 'Puerto Rico',
    GB: 'United Kingdom',
    iconLabel: {
      PR: 'PR',
      CA: 'Canada',
      GB: 'UK',
    },
  },
  Admin: {
    addCategory: 'Add Category',
    addIcon: 'Add Icon',
    addRecommended: 'Add Recommended Category',
    addSubcategory: 'Add Subcategory',
    addSection: 'Add Section',
    addResource: 'Add Resource',
    addSectionNamePlaceholder: 'Enter Name',
    addSectionTranslationPlaceholder: 'Enter Translation',
    browse: 'Browse',
    CA: 'Canada',
    GB: 'United Kingdom',
    cancelEditCategoryModalBody:
      'Are you sure you want to cancel? All unsaved changes will be lost.',
    categories: 'Categories',
    category: 'Category',
    categoryName: 'Category Name',
    categoryIsVisible: 'Visible',
    categoryHidden: 'Hidden',
    deleteCategoryModalHeader: 'Delete Category',
    deleteCategoryToast: '{{categoryName}} has been successfully deleted.',
    deleteRecommendedCategoryModalHeader: 'Delete Recommended Category',
    deleteSubcategoryToast:
      '{{subcategoryName}} has been successfully deleted.',
    editIcon: 'Edit Icon',
    makeHiddenCategoryBody:
      'Are you sure you want to hide the <strong>{{categoryName}}</strong> category from all <strong>{{countryName}}</strong> Team Members?',
    immediatelyMakeHiddenCategoryBody:
      'Are you sure you want to hide the <strong>{{categoryName}}</strong> category immediately from all <strong>{{countryName}}</strong> Team Members?',
    makeHiddenHeader: 'Make Hidden',
    makeHiddenSubcategoryBody:
      'Are you sure you want to hide the <strong>{{categoryName}}</strong> subcategory from all <strong>{{countryName}}</strong> Team Members?',
    makeHiddenSubtitleBody:
      'Are you sure you want to hide the <strong>{{categoryName}}</strong> subtitle from all <strong>{{countryName}}</strong> Team Members?',
    makeVisibleCategoryBody:
      'Are you sure you want to make the <strong>{{categoryName}}</strong> category available to all <strong>{{countryName}}</strong> Team Members?',
    immediatelyMakeVisibleCategoryBody:
      'Are you sure you want to make the <strong>{{categoryName}}</strong> category immediately available to all <strong>{{countryName}}</strong> Team Members?',
    makeVisibleHeader: 'Make Visible',
    makeVisibleSubcategoryBody:
      'Are you sure you want to make the <strong>{{categoryName}}</strong> subcategory available to all <strong>{{countryName}}</strong> Team Members?',
    makeVisibleSubtitleBody:
      'Are you sure you want to make the <strong>{{categoryName}}</strong> subtitle available to all <strong>{{countryName}}</strong> Team Members?',
    makeHiddenDocumentBody:
      'Are you sure you want to hide the <strong>{{documentName}}</strong> resource from all <strong>{{countryName}}</strong> Team Members?',
    makeVisibleDocumentBody:
      'Are you sure you want to make the <strong>{{documentName}}</strong> resource available to all <strong>{{countryName}}</strong> Team Members?',
    managingCountry: 'Managing Country',
    PR: 'Puerto Rico',
    recommended: 'Recommended',
    recommendedCategory: 'Recommended Category',
    searchIcons: 'Search Icons',
    searchForIcons: 'Search for icons',
    sectionName: 'Section Name',
    selectIcon: 'Select Icon',
    spanishTranslation: 'Spanish Translation',
    subcategory: 'Subcategory',
    subcategories: 'Subcategories',
    updateCategoryToast: '{{categoryName}} has been successfully updated.',
    US: 'United States of America',
    warning: 'Warning',
  },
  Browse: {
    categories: 'Categories',
    recommended: 'Recommended',
  },
  Button: {
    add: 'Add',
    addEllipsis: 'Add...',
    addOption: 'Add Option',
    addQuestion: 'Add Question',
    addTeamMembers: '+ Add Team Members',
    addXObjects: 'Add {{count}} {{object}}',
    back: 'Back',
    backToTrainingMode: 'Back to Training Mode',
    cancel: 'Cancel',
    cancelChanges: 'Cancel Changes',
    choose: 'Choose',
    choosePlan: 'Choose Plan',
    clearAll: 'Clear All',
    close: 'Close',
    complete: 'Complete',
    completed: 'Completed',
    complianceTraining: 'Compliance Training in Pathway 1.0',
    contactSupport: 'Contact CFA HELP',
    continue: 'Continue',
    continueEditing: 'Continue Editing',
    copy: 'Copy',
    create: 'Create',
    delete: 'Delete',
    disable: 'Disable',
    discardChanges: 'Discard Changes and Exit',
    done: 'Done',
    duplicate: 'Duplicate',
    edit: 'Edit',
    enable: 'Enable',
    enablePlan: 'Enable Plan',
    exit: 'Exit',
    exitTrainingMode: 'Exit Training Mode',
    exitTrainTogether: 'Exit Train Together',
    goBack: 'Go Back',
    iUnderstand: 'I Understand',
    loadMore: 'Load More',
    logout: 'Log out',
    makeHidden: 'Make Hidden',
    makeVisible: 'Make Visible',
    markCompleted: 'Mark as Completed',
    next: 'Next',
    no: 'No',
    notNow: 'Not Now',
    ok: 'OK',
    okay: 'Okay',
    preview: 'Preview',
    print: 'Print',
    printReport: 'Print Report',
    reloadThePage: 'Reload the page',
    remove: 'Remove',
    returnToCfa: 'Return to @CFA',
    returnToHomepage: 'Return to Homepage',
    returnToPreviousPage: 'Return to Previous Page',
    retake: 'Retake Quiz',
    restartTraining: 'Restart Training',
    save: 'Save',
    saveQuiz: 'Save Quiz',
    select: 'Select',
    selectAll: 'Select All',
    stay: 'Stay',
    submit: 'Submit',
    trainingMode: 'Training Mode',
    trainTogether: 'Train Together',
    translateAll: 'Translate All',
    unassign: 'Unassign',
    update: 'Update',
    updateDueDate: 'Update Due Date',
    wait: 'Wait',
    yes: 'Yes',
  },
  Generic: {
    admin: 'Admin',
    and: 'and',
    answer: 'Answer',
    around: 'Around',
    appName: 'Pathway',
    ascend: 'Ascend',
    assign: 'Assign Plan',
    at: 'at',
    cancelConfirmation:
      'Are you sure you want to cancel? Any unsaved changes will be lost.',
    chooseVersion: 'Choose Version',
    completedIn: 'Completed in',
    exitConfirmation:
      'Are you sure you want to exit <strong>{{area}}</strong>? Any unsaved changes will be lost.',
    chooseLicenseeLocation: 'Choose Licensee Location',
    change: 'Change',
    choose: 'Choose',
    chooseLocation: 'Choose Location(s)',
    chooseResources: 'Choose Resources',
    chooseTeamMembers: 'Choose Team Members',
    clearSearch: 'Clear Search',
    comingSoon: 'Coming soon...',
    completed: 'Completed',
    completedByAll: 'Completed by all Team Members',
    completionDate: 'Completion Date',
    compliancePlanName: 'Compliance Plan Name',
    created: '(Created: {{createdDate}})',
    dateOfBirth: 'Date of Birth',
    deleteHeader: 'Delete {{type}}',
    deleteImpactedObject:
      'Are you sure you want to permanently delete the <strong>{{name}}</strong> {{object}}?  Deleting this {{object}} will also remove it from any {{impactedObject}} it is a part of.  You cannot undo this action.',
    deleteObject:
      'Are you sure you want to permanently delete the <strong>{{name}}</strong> {{object}}?',
    deleteUnnamedObject:
      'Are you sure you want to permanently delete this {{object}}?',
    disablePlan: 'Disable Plan',
    documentFooter:
      'Last Published: {{lastPublishedDate}}. Confidential and Proprietary Information. Not for Distribution Outside the Chick-fil-A System. © 2003-{{currentYear}} CFA Properties, Inc.',
    edit: 'edit',
    enablePlan: 'Enable Plan',
    expirationDate: 'Expiration Date',
    explore: 'Explore',
    footer: 'Chick-fil-A, Inc. All rights reserved.',
    game: 'game',
    giveName: 'Give Your {{object}} a Name',
    giveNewName: 'Give Your {{object}} a New Name',
    comingSoon2: 'Coming Soon',
    hour: 'hr',
    items: 'Items',
    itemsCompleted: 'Items Completed',
    itemsCompletedIn: 'Items Completed In',
    of: 'of',
    lastScore: 'Last score was {{score}}%',
    link: 'Link',
    linkCopied: 'Link copied to clipboard',
    locations: 'Locations',
    markCompleted: 'Mark as Completed',
    editPlanContent: 'Edit Plan Content',
    editTranslations: 'Edit Translations',
    leadership: 'Leadership',
    loading: 'Loading...',
    marketTest: 'Test',
    mins: 'mins',
    minsSpent: '{{duration}} spent',
    minsSpent2: '{{timeSpent}} mins spent',
    menu: 'Menu',
    na: 'N/A',
    name: 'Name',
    notInCompliance: 'Not in Compliance',
    note: 'Note',
    notes: 'Notes',
    noTranslationsAvailable: 'No translations available',
    operations: 'Operations',
    options: 'Options',
    onThisPage: 'On this page',
    plan: 'Plan',
    plans: 'plans',
    planName: 'Plan Name',
    planCompleted: 'Plan Completed',
    planCompletedIn: 'Plan Completed In',
    plansCompleted: 'Plans Completed',
    popupArials: {
      labelledby: 'PopUpLabel',
      describedby: 'PopUpDesc',
    },
    printDocument: 'Print Document',
    printReport: 'Print Report',
    removeHeader: 'Remove {{type}}',
    removeObject:
      'Are you sure you want to remove the <strong>{{name}}</strong> {{object}}?',
    rename: 'Rename',
    reports: 'Reports',
    resource: 'Resource',
    resources: 'Resources',
    restartTraining: 'Restart Training',
    percentCompleted: '{{percent}}% Completed',
    profile: 'Profile',
    procedure: 'Procedure',
    question: 'Question',
    quiz: 'Quiz',
    quizName: 'Quiz Name',
    scoreAndTimeNotAvailable: 'Score and time spent are not available',
    scoreNotAvailable: 'Score is not available',
    search: 'Search',
    searchLocations: 'Search Locations',
    seeAll: 'See All',
    section: 'Section',
    selectAtLeastOne: 'Select at least one location.',
    settings: 'Settings',
    showDoB: 'Show Team Members’ date of birth',
    status: 'Status',
    task: 'Task',
    teamMember: 'Team Member',
    teamMembers: 'Team Members',
    teamMembersCompleted: 'Team Members Completed',
    this: 'This',
    timeSpentNotAvailable: 'Time spent is not available',
    totalTime: 'Total Time',
    totalTimeSpentOnPlan: 'Total Time Spent On Plan',
    trainingMode: 'Training Mode',
    trainTogether: 'Train Together',
    unassign: 'Unassign',
    unsavedChanges: 'Unsaved Changes',
    url: 'URL',
    video: 'Video',
  },
  GenericError: {
    cfaLogo: 'Chick-fil-A Logo',
    contactSupport: 'contact Customer service',
    error: 'Error',
    ifPersist: 'If the problem persists,',
    oops: 'Oops, something went wrong',
    or: 'or',
    reloadPage: 'Reload page',
    reloadThisPage: 'Reload this page',
    startOrder: 'Start a new order',
    tryReload: 'Please try reloading the page.',
    problemCompletingAction:
      'There was a problem completing this action. (Error code: 403)',
    unauthorized: 'Unauthorized',
  },
  Input: {
    requiredMessage: 'is a required field.',
    Errors: {
      url: 'Please enter a valid URL.',
      task: 'Task name may not be empty.',
    },
  },
  InvalidPlanName: {
    errorHeader: 'Invalid Plan Name',
    paragraphText: 'Plans must have a name.',
  },
  InvalidQuizName: {
    errorHeader: 'Invalid Quiz Name',
    paragraphText: 'Quizzes must have a name.',
  },
  Language: {
    language: 'Language',
    english_language_code: 'en',
    spanish_language_code: 'es',
    english: 'English',
    spanish: 'Spanish',
  },
  Licensee: {
    changeLicenseeLocation: 'Change Licensee Location ({{locationNumber}})',
    licensee: 'Licensee',
    licensees: 'Licensees',
    licenseeLocation: 'Licensee Location',
    licenseeLocations: 'Licensee Locations',
    myRestaurants: 'My Restaurants',
    chooseALicenseeLocation: 'Choose a licensee location',
  },
  LoadingResourceError: {
    errorParagraph:
      'There was a problem loading the resource you are looking for.',
    unauthorizedParagraph:
      'You are not authorized to view this resource. Please consult with your Operator or Leader for more information.',
  },
  Leadership: {
    ascendOnDemand: 'Ascend On-Demand',
    classLength: 'Length',
    hi: 'Hi',
    headerText: 'What would you like to learn today?',
    learnMore: 'Learn More',
    goThere: 'Go There',
    episodes: 'Skills',
    cohortHeader: 'Virtual Learning Groups',
    cohortText:
      'Group learning online for Restaurant leaders that explores both operational and leadership skills',
    continueWatching: 'Continue Watching',
    season: 'Collection',
    operator: 'Operator',
    pick: 'Pick',
  },
  NotFound: {
    button: 'Back to Home',
    error: '404',
    message: 'Page Not Found',
  },
  Reports: {
    backToTeamMembers: 'Back to Team Members',
    compliancePlanTooltip:
      'Further details are not available for compliance plans.',
    enablePlanTooltip: 'To enable this plan please go to the settings page.',
    filtering: {
      aToZ: 'A-Z',
      zToA: 'Z-A',
      searchForPlans: 'Search For Plans',
      searchPlans: 'Search Plans',
      startDate: 'Start Date',
    },
    noPlans:
      'There are no training plans to review. <br /> Plans that have been assigned to Team Members will be shown here.',
    tabCompliancePlans: 'Compliance Plans',
    tabPlans: 'Plans',
    tabTeamMembers: 'Team Members',
  },
  Settings: {
    complianceDisable:
      'Are you sure you want to disable the <strong>{{name}}</strong>? It will be disabled for all of your Operator’s Team Members, including at any of their other locations. <br /> <br /> Please check all local and state regulations to make sure that your Restaurant is in compliance.',
    complianceEnable:
      'Are you sure you want to enable the <strong>{{name}}</strong>? It will be enabled for all locations under your Operator.',
    compliancePlans: 'Compliance Plans',
    needAssistance: 'Need assistance?',
    setCountry: 'Country',
  },
  ResourceNotFoundError: {
    errorParagraph:
      'This resource was not found. Please contact Chick-fil-A HELP online or call <a href="tel:+1-800-232-2677">1-800-232-2677</a>.',
  },
  Search: {
    filterBy: 'Filter:',
    historyRemove: 'Remove from search history',
    historySearchFor: 'Search for',
    noResults: 'No results found',
    numberOfResultsFor: 'results for',
    rateResult: 'Rate this search result',
    restaurantContentTypes: {
      lbm: 'LBM',
      stc: 'STC',
    },
    resultText: 'result',
    resultsText: 'results',
    searchForResources: 'Search for resources',
    showingResultsFooter:
      'Showing {{showingResults}} of {{numberOfResults}} {{resultsText}}',
    showingResultsHeader: 'Search Results',
    showingResultsSuggestion: 'Did you mean',
    submitFeedback: 'Thanks for your help!',
  },
  TrainingPlans: {
    accountability: {
      completedWithScore: 'Completed with a score of {{finalScore}}%',
      due: {
        completedDueDate: 'Completed: {{dueDate}}',
        dueDate: '{{dueDate}}',
        noDueDate: 'No Due Date',
        oneDay: 'Due in 1 day',
        oneWeek: 'Due in 1 week',
        overdue: 'Overdue',
        xDays: 'Due in {{daysLeft}} days',
        xWeeks: 'Due in {{weeksLeft}} weeks',
      },
      enterDueDate: 'Enter Due Date',
      setDueDate: 'Set Due Date',
      timeSpentOnPlan: 'Time Spent On Plan',
      outOfCompliance: 'One or more Team Members may be out of compliance.',
      percentCompleted: '% Completed',
      toComplete: 'to complete',
      timeEstimationTooltip: 'Time should be entered in minutes',
      withScore: 'with a score of {{finalScore}}%',
      enterTime: 'Enter Time',
      estimatedTimeToComplete: 'Estimated Time To Complete Plan',
    },
    addPlanMenu: {
      buildCustomPlan: 'Build Custom Plan',
      chooseOperator: 'Choose Operator',
      choosePlanFromLibrary: 'Choose Plan From Library',
    },
    addResource: {
      clearSearch: 'Add Resource clear search',
      placeholder: 'Search for anything',
    },
    addQuiz: {
      title: 'Choose Quiz',
    },
    previewQuiz: {
      title: 'Preview: {{quizName}}',
      correctAnswer: 'Correct',
      noQuestions: 'No questions found in quiz.',
      noQuestionsHelpText:
        'You can still add this quiz to a plan and add questions later.',
    },
    addTaskFormPlaceholderText: {
      task: 'Type task here',
      notes: 'Enter Note',
      link: 'Enter URL',
    },
    assigned: 'Assigned',
    buildQuizzes: {
      addToPlanHeader: 'Add to Plan',
      addToPlan:
        'Your quiz has been saved. Would you like to add this quiz to a plan now? Team Members can only access a quiz as part of a plan.',
      buildQuestionMultipleChoice: 'Enter options and choose the right answer.',
      buildQuestionTrueFalse: 'Choose the correct answer.',
      changeQuizName: 'Give your quiz a new name',
      chooseOperator: 'Choose Operator',
      correctAnswer: 'Correct answer',
      correctQuestionsThreshold:
        'How many questions must be answered correctly to pass the quiz?',
      createQuizHover: 'Create Quiz',
      enterAnswer: 'Enter Answer',
      enterQuestion: 'Enter Question',
      errorSelectCorrectAnswer: 'Please select correct answer',
      errorSelectPassingGrade: 'Please Select',
      errorQuestion: 'Please enter answer option',
      manageQuiz: 'Manage Quiz',
      missingRequiredFields: 'Missing Required Fields',
      multipleChoice: 'Multiple Choice',
      noQuizzesPopup:
        'There are no quizzes. <br /> You can create quizzes in Plans > Build Quizzes.',
      noQuizzesLoaded:
        'There are no quizzes. <br /> You can add quizzes with the + button below.',
      option: 'Option',
      quizNamePlaceholder: 'Enter quiz name',
      saveBeforeRenamingQuiz:
        'Please save your changes before renaming your quiz.',
      saveBeforeTranslatingQuiz:
        'Please save your changes before translating your quiz.',
      trueOrFalse: 'True or False',
      validateRequiredFields:
        'Please ensure all required fields are populated before saving your quiz.',
    },
    certificationExpires: 'Certification Expires: {{expirationDate}}',
    chooseWhatToInclude: 'Choose what you want to include in the PDF.',
    completedPlans: 'Completed Plans',
    compliancePlanNotPassed: 'Exam not passed yet',
    compliancePlans: 'Compliance Plans',
    compliancePlansTooltip:
      'In order to take a compliance plan, your Operator or Leader needs to enable the plan(s) in Settings.',
    createDuplicate: 'Create Duplicate',
    createPlan:
      'There are no training plans. <br /> You can add plans with the + button below.',
    createPlanPlaceholderText: 'My Training Plan 1',
    createPlanOnBuildPlanPage:
      'There are no training plans to manage. <br /> Leaders can create plans in the Build Plans tab.',
    currentPlans: 'Current Plans',
    deletePlanText: 'Are you sure you want to delete the',
    deletePlanText2: 'plan?',
    deleteSectionItems: 'All items in this section will be deleted.',
    deleteTaskItems: 'This task item will be deleted.',
    deleteTaskToast: '{{taskName}} has been successfully deleted.',
    duplicatePlanInputCopy: '- Copy',
    emptyPlan:
      'This plan does not have any resources, quizzes or tasks yet. <br /> You can add them with the + button below.',
    filtering: {
      filters: 'Filters',
      plan: 'Plan',
      plans: 'Plans',
      sortBy: 'Sort by:',
      searchBuildPlans: 'Search Build Plans',
      searchManagePlans: 'Search Manage Plans',
      searchMyPlans: 'Search My Plans',
      filterAndSort: 'Filter & Sort',
      show: 'Show',
      results: 'Results',
      aToZ: 'A-Z',
      zToA: 'Z-A',
      newest: 'Newest',
      oldest: 'Oldest',
      dueDate: 'Due Date',
      includeCompleted: 'Include Completed:',
    },
    managePlan: 'Manage Plan',
    manageThreeDotMenu: {
      delete: 'Delete Plan',
      deleteResource: 'Delete Resource',
      deleteQuiz: 'Delete Quiz',
      deleteSection: 'Delete section',
      deleteTask: 'Delete Task',
      duplicate: 'Duplicate Plan',
      editResource: 'Edit Resource',
      editSection: 'Edit section',
      editTask: 'Edit Task',
      locations: 'Choose Location',
      rename: 'Rename Plan',
      renameQuiz: 'Rename Quiz',
      renameSection: 'Rename Section',
      trainingMode: 'Training Mode',
    },
    manageTranslations: {
      answerTranslationPlaceholder: 'Translated Answer',
      notesTranslationPlaceholder: 'Translated Notes',
      nameTranslationPlaceholder: 'Translated Name',
      noExistingTranslation: 'No English translation',
      questionTranslationPlaceholder: 'Translated Question',
      sectionTranslationPlaceholder: 'Translated Section',
      translateTocurrentLanguage: 'Translate to Spanish', // note please tranaslate this to spanish as 'Translate to English'
      currentLanguageTranslation: 'Spanish Translation', // please translate to 'English Translation' in Spanish
      taskTranslationPlaceholder: 'Translated Task',
      translateAllTooltip:
        'This will translate all items and overwrite ALL existing text',
      urlTranslationPlaceholder: 'Enter Alternate URL',
    },
    markCompleted1: 'Are you sure you want to mark the',
    markCompleted2: 'plan as completed?',
    noCompletedPlans: 'No plans are completed',
    noCurrentPlans: 'No plans are assigned to you',
    noExpirationDate:
      'Certification expiration date missing. Please contact CFA HELP.',
    noTeamMembersResults:
      'There are no Team Members that match your current selections.',
    noPlansResults: 'There are no plans that match your current selections.',
    noResource: 'This procedure is no longer available.',
    noResourceBuild:
      'This procedure is no longer available.  Please remove it from your plan and contact HELP with any questions.',
    noResourceReference: 'There is no reference id assigned to this resource',
    noTeamMembersAssigned:
      'You do not have any Team Members assigned to this Training Plan yet.',
    noTeamMembersAssignedManagePlans:
      'There are no Team Members assigned to this Training Plan yet. <br /> You can assign Team Members with the + button below.',
    noTrainingPlans:
      'There are no training plans for you to work on at the moment.',
    planCategories: {
      default: 'Default',
      backOfHouse: 'Back of House',
      compliance: 'Compliance',
      frontOfHouse: 'Front of House',
      leadership: 'Leadership',
      hospitality: 'Hospitality',
      onboarding: 'Onboarding',
    },
    planNamePlaceholder: 'Enter a plan name',
    preview: 'Preview',
    quizzes: {
      calculatingQuiz: 'Calculating Quiz',
      congratulations: 'Congratulations',
      quizFail:
        'Sorry, you did not pass. Please retake the quiz. You must pass to complete your training.',
      quizPassed: 'Passed',
      quizResultsDelayed:
        'We’re sorry the quiz results are taking longer than usual to be calculated.',
      quizResultsIssue:
        'We’re sorry but this quiz has run into an issue, please try taking the quiz again.',
      quizSuccessPopup:
        'Congratulations, you passed with a score of {{score}}%',
      quizWaitingForResults:
        'Please wait while your results are being calculated...',
      youDidNotPass: 'You Did Not Pass',
    },
    restartPlan1: 'Are you sure you want this Team Member to restart the',
    restartPlan2:
      'plan? This will reset their progress back to 0 items complete.',
    requiredValue: 'is required',
    sectionName: 'Section Name',
    searchbarPlaceholder: 'Section title',
    showingXOfYLocations: 'Showing {{showing}} of {{total}} Locations',
    showingXOfYPlans: 'Showing {{showing}} of {{total}} Plans',
    showingXOfYQuizzes: 'Showing {{showing}} of {{total}} Quizzes',
    showingXOfYTeamMembers: 'Showing {{showing}} of {{total}} Team Members',
    selectCategory: 'Select Category',
    statusOptions: {
      attended: 'Attended',
      cancelled: 'Cancelled',
      completed: 'Completed',
      failed: 'Failed',
      inProgress: 'In Progress',
      noShow: 'No Show',
      notCompleted: 'Not Completed',
      notStarted: 'Not Started',
      passed: 'Passed',
      pendingReview: 'Pending Review',
      undefined: 'Undefined',
    },
    tabAscend: 'Ascend',
    tabBuild: 'Build Plans',
    tabExplore: 'Explore',
    tabBuildQuizzes: 'Build Quizzes',
    tabMy: 'My Plans',
    tabManagePlans: 'Manage Plans',
    tabManagedPlans: 'Managed Plans',
    tabManagedQuizzes: 'Managed Quizzes',
    tabPlans: 'Plans',
    tasksCompleted: 'Tasks Completed',
    teamMembers: {
      count: 'Team Members - ',
      searchbarPlaceholder: 'Search Team Members',
      unassignConfirmation:
        'Are you sure you want to unassign {{name}}?  This plan will no longer be available.',
    },
    templates: {
      templateModal: 'Choose Plan Template',
    },
    timeSpentOnPlanTooltip:
      'This is an approximation of the total time spent on this plan.',
    toastMessage: {
      assignedTeamMember:
        'You have successfully assigned 1 Team Member to this plan.',
      assignedTeamMembers:
        'You have successfully assigned {{count}} Team Members to this plan.',
      completed1: 'You have successfully completed the',
      completed2: 'Training Plan',
      created: 'has been successfully created.',
      deleted: 'has been successfully deleted.',
      duplicated: 'has been successfully duplicated.',
      enabled: '{{courseName}} has been successfully enabled.',
      nowViewingLocation1: 'Now viewing {{number}} {{name}}',
      nowViewingLocation2: 'You can switch to another location at any time.',
      renamed: 'has been successfully renamed.',
      sectionDeleted: 'section has been successfully deleted.',
      unassigned:
        '{{teamMember}} has been successfully unassigned from this plan.',
      updated: 'has been successfully updated',
      updateDueDate:
        'The Due Date for {{teamMember}} has been successfully updated.',
    },
    trainingMode: {
      completedPlanModal:
        'You have successfully completed the <strong>{{planName}}</strong> Training Plan.  Please hand the device back to your Leader.',
      enterTrainingModeModal:
        'You are now entering Training Mode.  If you choose to hand off your device to a Team Member, they can access any Pathway 2.0 page or Chick-fil-A application from your credentials should they leave the Training Mode experience.',
      estimatedCompletionTime: 'Estimated Completion Time: {{estimatedTime}}',
      exitingModal:
        'You are about to exit training mode. Please hand the device back to your Leader, or return to training mode.',
      exitModal:
        'Your progress has been saved and you are now leaving training mode.  Please hand the device back to your Leader.',
      exitingTrainingMode: 'Exiting Training Mode',
      exitTrainingMode: 'Exit Training Mode',
      selectLanguage: 'Which language do you want this plan to display in?',
      chooseLanguage: 'Choose Language',
      toolTip: 'Please assign a Team Member to this plan to enable.',
      trainingMode: 'Training Mode',
    },
    trainTogether: {
      completedPlanModal:
        'You have successfully completed the <strong>{{planName}}</strong> Training Plan.  Please hand the device back to your Leader.',
      enterModalBody:
        'Train Together lets your team complete training on this device, so they don’t have to log in to their accounts. <br /> <br /> If you choose to hand off your device to a Team Member, they can access any Pathway 2.0 page or Chick-fil-A application from your credentials should they leave the Train Together experience.',
      enterModalHeader: 'Entering Shared Account Mode',
      estimatedCompletionTime: 'Estimated Completion Time: {{estimatedTime}}',
      exitModalBody:
        'Your progress has been saved and you are now leaving Train Together. Please hand the device back to your Leader.',
      exitModalHeader: 'Exit Train Together',
      selectLanguage: 'Which language do you want this plan to display in?',
      chooseLanguage: 'Choose Language',
      toolTip:
        'Let your team complete training on this device, so they don’t have to log in to their accounts.',
      toolTipDisabled: 'Please assign a Team Member to this plan to enable.',
    },
    trainingPlans: 'Training Plans',
    translationToastText: 'has been successfully translated to',
    storePlans: 'Store Plans',
    whichLocations: 'Which location(s) is this plan for?',
    whichLocationsPrintReport:
      'Which location(s) would you like to print this report for?',
  },
  UserNotAssignedToAppError: {
    errorParagraph:
      'You cannot access Pathway 2.0 because you are not assigned this app in Okta. <strong>Please sign in with an authorized account.</strong>',
  },
  UserNotAssignedToRoleError: {
    errorParagraph:
      'You cannot access this page because you do not have the correct permissions assigned in TMUA. Please consult with your Operator or Leader for more information.',
  },
};
