import Constants from 'constants/index';
import AddTeamMembersPopUp from '@/components/popups/AddTeamMemberPopUp/AddTeamMembersPopUp';
import ConfirmationModal from '@/components/popups/ConfirmationModal';
import DueDatePopUp from '@/components/popups/DueDatePopUp';
import {
  enterTrainingMode,
  hideTrainingMode,
  setTrainingModeData,
} from '@/store/trainingMode/slice';
import { LanguageObject } from '@/types';
import uniqBy from 'lodash/uniqBy';
import { mapCategoryToAliasTranslation } from '@/utils/categoryUtils';
import { getNameFromLanguage } from '@/utils/language';
import {
  ChecklistDTO,
  UserChecklistResponse,
  UserChecklistResultDTO,
} from '@cfacorp-pathway/xp-api-typescript-client';
import { RadioButton, RadioGroup } from 'cfa-react-components';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useState } from 'react';
import {
  useAssignUserToChecklistMutation,
  useAssignUserToComplianceCourseMutation,
  useUnassignUserToChecklistMutation,
  useUpdateDueDateMutation,
} from '@/services/pathwayApi';
import { toast } from 'react-hot-toast';
import ToastMessageBlock from '@/components/Toasts/SuccessToast';
import useBugsnagNotify from '@/hooks/useBugsnagNotify';
import { setTeamMembers } from '@/store/teamMembersFilter/slice';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface ModalsProps {
  activeTeamMember: {
    id: string;
    name: string;
  };
  allTeamMembers: ChecklistDTO;
  assignedTeamMembersList: UserChecklistResultDTO;
  filterLocations: string[];
  isCompliance: boolean;
  isDueDateUpdate: boolean;
  onClose: () => void;
  onEnterTrainingMode: () => void;
  plan: UserChecklistResponse | ChecklistDTO | ChecklistDTO[] | undefined;
  planId: string;
  refetchAssignedChecklist: () => void;
  refetchAssignedComplianceTeamMembers: () => void;
  refetchAssignedTeamMembers: () => void;
  refetchCourseReportData: () => void;
  selectedId: string;
  setActiveTeamMember: (teamMember: { id: ''; name: '' }) => void;
  setSelectedId: (id: string) => void;
  setShowAddTeamMembersPopup: (show: boolean) => void;
  setShowDueDatePopup: (show: boolean) => void;
  setShowSelectLanguagePopup: (show: boolean) => void;
  setShowUnassignTeamMemberPopup: (show: boolean) => void;
  showAddTeamMembersPopup: boolean;
  showDueDatePopup: boolean;
  showSelectLanguagePopup: boolean;
  showTrainingModePopup: boolean;
  showUnassignTeamMemberPopup: boolean;
  trainingModeModalIsOpen: boolean;
}

const Modals: React.FC<ModalsProps> = ({
  activeTeamMember,
  allTeamMembers,
  assignedTeamMembersList,
  filterLocations,
  isCompliance,
  isDueDateUpdate,
  onClose,
  onEnterTrainingMode,
  plan,
  planId,
  refetchAssignedChecklist,
  refetchAssignedComplianceTeamMembers,
  refetchAssignedTeamMembers,
  refetchCourseReportData,
  selectedId,
  setActiveTeamMember,
  setSelectedId,
  setShowAddTeamMembersPopup,
  setShowDueDatePopup,
  setShowSelectLanguagePopup,
  setShowUnassignTeamMemberPopup,
  showAddTeamMembersPopup,
  showDueDatePopup,
  showSelectLanguagePopup,
  showTrainingModePopup,
  showUnassignTeamMemberPopup,
  trainingModeModalIsOpen,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { notifyBugsnag } = useBugsnagNotify();
  const history = useHistory();
  const { plansRedesign: plansRedesignFeatureFlag } = useFlags();
  const [updateDueDate] = useUpdateDueDateMutation();
  const [assignUserToComplianceCourse] =
    useAssignUserToComplianceCourseMutation();
  const [assignUserToChecklist] = useAssignUserToChecklistMutation();
  const [unassignUserToChecklist] = useUnassignUserToChecklistMutation();
  const [languageSelected, setLanguageSelected] = useState('English');
  const [trainingModeTeamMembers, setTrainingModeTeamMembers] = useState([]);
  const [temporaryTeamMembers, setTemporaryTeamMembers] = useState<any[]>([]);
  const [triggerDueDateReset, setTriggerDueDateReset] = useState(false);

  const onBack = () => {
    setTrainingModeTeamMembers([]);
    setShowSelectLanguagePopup(false);
    setSelectedId('');
  };

  const onAssignTeamMembers = (members: string | string[], dueDate: string) => {
    const payload = Array.isArray(members)
      ? // @ts-ignore
        { userIds: uniqBy(members), dueDate }
      : { userIds: [members], dueDate };
    const numberOfTeamMembersToAssign = payload.userIds.length;
    assignUserToChecklist({ body: payload, id: planId })
      .unwrap()
      .then(() => {
        refetchAssignedTeamMembers();
        refetchAssignedChecklist();
        toast.custom(toastObj => (
          <ToastMessageBlock id={toastObj.id}>
            {numberOfTeamMembersToAssign === 1
              ? t('TrainingPlans.toastMessage.assignedTeamMember')
              : t('TrainingPlans.toastMessage.assignedTeamMembers', {
                  count: numberOfTeamMembersToAssign,
                })}
          </ToastMessageBlock>
        ));
        setTriggerDueDateReset(prev => !prev);
      })
      .catch(err => {
        notifyBugsnag(err);
      });
    setShowAddTeamMembersPopup(false);
  };

  const onAssignTeamMembersCompliance = (
    members: string | string[],
    unassign?: boolean,
  ) => {
    const payload = Array.isArray(members)
      ? // @ts-ignore
        { userIds: uniqBy(members), assignCourse: unassign ? false : true }
      : { userIds: [members], assignCourse: unassign ? false : true };

    const numberOfTeamMembersToAssign = payload.userIds.length;
    assignUserToComplianceCourse({ body: payload, id: planId })
      .unwrap()
      .then(() => {
        refetchAssignedComplianceTeamMembers();
        refetchCourseReportData();
        unassign
          ? toast.custom(toastObj => (
              <ToastMessageBlock id={toastObj.id}>
                {t('TrainingPlans.toastMessage.unassigned', {
                  teamMember: activeTeamMember.name,
                })}
              </ToastMessageBlock>
            ))
          : toast.custom(toastObj => (
              <ToastMessageBlock id={toastObj.id}>
                {numberOfTeamMembersToAssign === 1
                  ? t('TrainingPlans.toastMessage.assignedTeamMember')
                  : t('TrainingPlans.toastMessage.assignedTeamMembers', {
                      count: numberOfTeamMembersToAssign,
                    })}
              </ToastMessageBlock>
            ));
      })
      .catch(err => {
        notifyBugsnag(err);
      });
    setShowUnassignTeamMemberPopup(false);
  };

  const handleNextClick = (
    isTrainingMode: boolean,
    selectedTeamMembers: any[],
    id?: string,
  ) => {
    /** We temporarily set these so they do not render until we send
     * the user to Train Together below.
     */
    setTemporaryTeamMembers(selectedTeamMembers);
    if (isTrainingMode) {
      dispatch(hideTrainingMode());
      setShowSelectLanguagePopup(true);
    } else if (isCompliance && id) {
      setShowAddTeamMembersPopup(false);
      onAssignTeamMembersCompliance(id.includes(',') ? id.split(',') : id);
    } else {
      setShowAddTeamMembersPopup(false);
      setShowDueDatePopup(true);
      setSelectedId(id!);
    }
  };

  const onNextClick = () => {
    setShowSelectLanguagePopup(false);
    dispatch(enterTrainingMode());
    dispatch(
      setTrainingModeData({
        estimatedMinutes: (plan as ChecklistDTO)?.estimatedMinutes,
        languageSelected,
        planId,
        planLabel: t(
          mapCategoryToAliasTranslation((plan as ChecklistDTO)?.category),
        ),
        planName: getNameFromLanguage(
          (plan as ChecklistDTO)?.name as LanguageObject,
        ),
        teamMembers: trainingModeTeamMembers,
        selectedTeamMembers: temporaryTeamMembers,
        assignedTeamMembersStatus: (
          assignedTeamMembersList as any
        )?.status?.filter(
          (teamMember: any) =>
            teamMember.status !== Constants.TRAINING_PLANS.COMPLETED,
        ),
      }),
    );
    history.push({
      pathname: `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.TRAINING_MODE_PATH_NAME}`,
    });
  };

  const onUpdateTeamMemberDueDate = (
    members: string | string[],
    dueDate: string,
  ) => {
    const payload = Array.isArray(members)
      ? // @ts-ignore
        { userIds: uniqBy(members), dueDate }
      : { userIds: [members], dueDate };

    updateDueDate({ body: payload, id: planId })
      .unwrap()
      .then(() => {
        refetchAssignedTeamMembers();
        refetchAssignedChecklist();
        toast.custom(toastObj => (
          <ToastMessageBlock id={toastObj.id}>
            {t('TrainingPlans.toastMessage.updateDueDate', {
              teamMember: activeTeamMember.name,
            })}
          </ToastMessageBlock>
        ));
        onClose();
        setTriggerDueDateReset(prev => !prev);
      })
      .catch(err => {
        notifyBugsnag(err);
      });
    setShowAddTeamMembersPopup(false);
  };

  const onSave = (dueDate: string, isUpdate: boolean) => {
    setShowDueDatePopup(false);
    if (isUpdate) {
      onUpdateTeamMemberDueDate(activeTeamMember.id, dueDate);
    } else {
      onAssignTeamMembers(
        selectedId.includes(',') ? selectedId.split(',') : selectedId,
        dueDate,
      );
      dispatch(setTeamMembers({ teamMembers: temporaryTeamMembers }));
    }
  };

  const onUnassignTeamMember = () => {
    const payload = {
      userIds: [activeTeamMember.id],
    };

    isCompliance
      ? onAssignTeamMembersCompliance(activeTeamMember.id, true)
      : unassignUserToChecklist({ body: payload, id: planId })
          .unwrap()
          .then(() => {
            refetchAssignedTeamMembers();
            refetchAssignedChecklist();
            toast.custom(toastObj => (
              <ToastMessageBlock id={toastObj.id}>
                {t('TrainingPlans.toastMessage.unassigned', {
                  teamMember: activeTeamMember.name,
                })}
              </ToastMessageBlock>
            ));
          })
          .catch(err => {
            notifyBugsnag(err);
          });
    setShowUnassignTeamMemberPopup(false);
  };

  const onCancelUnassignTeamMember = () => {
    setShowUnassignTeamMemberPopup(false);
    setActiveTeamMember({ id: '', name: '' });
  };

  return (
    <>
      {plan && (
        <AddTeamMembersPopUp
          handleNextClick={({ isTrainingMode, selectedTeamMembers, id }) =>
            handleNextClick(isTrainingMode, selectedTeamMembers, id)
          }
          isOpen={showAddTeamMembersPopup}
          locations={filterLocations}
          onClose={onClose}
          planDetails={plan as any}
        />
      )}
      {isCompliance && (
        <AddTeamMembersPopUp
          handleNextClick={({ isTrainingMode, selectedTeamMembers, id }) =>
            handleNextClick(isTrainingMode, selectedTeamMembers, id)
          }
          isCompliance={isCompliance}
          isOpen={showAddTeamMembersPopup}
          locations={filterLocations}
          onClose={onClose}
          planDetails={allTeamMembers}
        />
      )}
      {plan && (
        <AddTeamMembersPopUp
          handleNextClick={({ isTrainingMode, selectedTeamMembers }) =>
            handleNextClick(isTrainingMode, selectedTeamMembers)
          }
          isOpen={showTrainingModePopup}
          isTrainingMode={true}
          locations={filterLocations}
          onClose={onClose}
          planDetails={plan as any}
        />
      )}

      <DueDatePopUp
        isOpen={showDueDatePopup}
        onClose={onClose}
        onSave={date => onSave(date as any, isDueDateUpdate)}
        triggerDueDateReset={triggerDueDateReset}
      />

      <ConfirmationModal
        bodyText={t('TrainingPlans.teamMembers.unassignConfirmation', {
          name: activeTeamMember.name,
        })}
        headerText={t('Generic.unassign')}
        isOpen={showUnassignTeamMemberPopup}
        onClose={onCancelUnassignTeamMember}
        primaryButtonColor="primary"
        primaryButtonHandler={onUnassignTeamMember}
        primaryButtonText={t('Button.unassign')}
        secondaryButtonHandler={onCancelUnassignTeamMember}
        secondaryButtonText={t('Button.cancel')}
      />

      <ConfirmationModal
        bodyText={
          plansRedesignFeatureFlag
            ? t('TrainingPlans.trainTogether.enterModalBody')
            : t('TrainingPlans.trainingMode.enterTrainingModeModal')
        }
        headerText={
          plansRedesignFeatureFlag
            ? t('TrainingPlans.trainTogether.enterModalHeader')
            : t('TrainingPlans.trainingMode.trainingMode')
        }
        isOpen={trainingModeModalIsOpen}
        onClose={() => dispatch(hideTrainingMode())}
        primaryButtonColor={'secondary'}
        primaryButtonHandler={onEnterTrainingMode}
        primaryButtonText={t('Button.iUnderstand')}
        secondaryButtonHandler={() => dispatch(hideTrainingMode())}
        secondaryButtonText={t('Button.cancel')}
      />

      <ConfirmationModal
        bodyText={
          plansRedesignFeatureFlag
            ? t('TrainingPlans.trainTogether.selectLanguage')
            : t('TrainingPlans.trainingMode.selectLanguage')
        }
        children={
          <StyledLanguageWrapper>
            <RadioGroup
              defaultValue={t('Language.english')}
              orientation="vertical"
            >
              <RadioButton
                label={t('Language.english')}
                onClick={() => setLanguageSelected(t('Language.english'))}
                value={t('Language.english')}
              />
              <RadioButton
                label={t('Language.spanish')}
                onClick={() => setLanguageSelected(t('Language.spanish'))}
                value={t('Language.spanish')}
              />
            </RadioGroup>
          </StyledLanguageWrapper>
        }
        headerText={
          plansRedesignFeatureFlag
            ? t('TrainingPlans.trainTogether.chooseLanguage')
            : t('TrainingPlans.trainingMode.chooseLanguage')
        }
        isOpen={showSelectLanguagePopup}
        onClose={() => setShowSelectLanguagePopup(false)}
        primaryButtonColor={'secondary'}
        primaryButtonHandler={onNextClick}
        primaryButtonText={t('Button.next')}
        secondaryButtonHandler={onBack}
        secondaryButtonText={t('Button.back')}
      />
    </>
  );
};

const StyledLanguageWrapper = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
`;

export default Modals;
