import Constants from 'constants/index';
import {
  isTrainingBuildPath,
  isTrainingMyPlansPath,
  isTrainingQuizzesPath,
  isTrainingTeamPath,
} from 'utils/url';
import styled from 'styled-components';
import { TabItem, Tabs } from 'cfa-react-components';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useFeaturePermission } from '@/utils/permissions';
import { useFlags } from 'launchdarkly-react-client-sdk';

const TrainingTabs = {
  MYPLANS: 'myplans',
  BUILDPLANS: 'buildplans',
  MANAGEPLANS: 'manageplans',
  BUILDQUIZZES: 'buildquizzes',
};

const TrainingSubheader = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { plansRedesign: plansRedesignFeatureFlag } = useFlags();
  const plansEnabled = useFeaturePermission('plans');
  const buildPlansEnabled = useFeaturePermission('plans.create');
  const managePlansEnabled = useFeaturePermission('plans.manage');
  const quizzesEnabled = useFeaturePermission('quizzes.create');
  let activeItemKey = '';

  if (isTrainingBuildPath(location)) {
    activeItemKey = TrainingTabs.BUILDPLANS;
  }
  if (isTrainingMyPlansPath(location)) {
    activeItemKey = TrainingTabs.MYPLANS;
  }
  if (isTrainingTeamPath(location)) {
    activeItemKey = TrainingTabs.MANAGEPLANS;
  }
  if (isTrainingQuizzesPath(location)) {
    activeItemKey = TrainingTabs.BUILDQUIZZES;
  }

  return (
    <Tabs activeItemKey={activeItemKey} color="secondary" onChange={() => {}}>
      {!plansRedesignFeatureFlag && !!buildPlansEnabled && (
        <HeaderTabItem itemKey={TrainingTabs.BUILDPLANS}>
          <HeaderLink
            to={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/build`}
          >
            {t('TrainingPlans.tabBuild')}
          </HeaderLink>
        </HeaderTabItem>
      )}
      {!!plansEnabled && (
        <HeaderTabItem itemKey={TrainingTabs.MYPLANS}>
          <HeaderLink to={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}`}>
            {t('TrainingPlans.tabMy')}
          </HeaderLink>
        </HeaderTabItem>
      )}
      {!!managePlansEnabled && (
        <HeaderTabItem itemKey={TrainingTabs.MANAGEPLANS}>
          <HeaderLink
            to={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.TEAM_PATH_NAME}`}
          >
            {plansRedesignFeatureFlag
              ? t('TrainingPlans.tabManagedPlans')
              : t('TrainingPlans.tabManagePlans')}
          </HeaderLink>
        </HeaderTabItem>
      )}
      {!!quizzesEnabled && (
        <HeaderTabItem itemKey={TrainingTabs.BUILDQUIZZES}>
          <HeaderLink
            to={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.QUIZZES_PATH_NAME}`}
          >
            {plansRedesignFeatureFlag
              ? t('TrainingPlans.tabManagedQuizzes')
              : t('TrainingPlans.tabBuildQuizzes')}
          </HeaderLink>
        </HeaderTabItem>
      )}
    </Tabs>
  );
};

const HeaderTabItem = styled(TabItem)`
  & > button {
    padding: 0;
    margin-bottom: 24px;
    margin-top: 8px;
  }
`;

const HeaderLink = styled(Link)`
  padding: 16px 32px;
`;

export default TrainingSubheader;
