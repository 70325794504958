import styled from 'styled-components';
import { IconPrinter, IconUsersGroup } from '@tabler/icons-react';
import { Button, Tooltip } from 'cfa-react-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { showPrintReport } from 'store/printReport/slice';
import { showTrainingMode } from 'store/trainingMode/slice';
import { useLocation } from 'react-router-dom';
import { useGetAssignedStatusQuery } from 'services/pathwayApi';
import { selectAllLocationsWithAtLeastTrainer } from 'store/user/selectors';
import { useFlags } from 'launchdarkly-react-client-sdk';

const ManageTeamMembersButtons = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const { plansRedesign: plansRedesignFeatureFlag } = useFlags();
  const locationsWithAtLeastTrainer = useSelector(
    selectAllLocationsWithAtLeastTrainer,
  );

  const planId = location.pathname.split('/')?.[2];
  const { data: assignedTeamMemebers } = useGetAssignedStatusQuery(
    {
      checklist: planId as string,
      location: locationsWithAtLeastTrainer,
    },
    {
      skip: !planId,
    },
  );
  const hasTeamMembersAssigned =
    assignedTeamMemebers?.status && assignedTeamMemebers?.status?.length > 0;

  return (
    <TrainingModeWrapper>
      {!hasTeamMembersAssigned ? (
        <Tooltip
          content={
            plansRedesignFeatureFlag
              ? t('TrainingPlans.trainTogether.toolTipDisabled')
              : t('TrainingPlans.trainingMode.toolTip')
          }
          placement="left"
          showOnElementEvents={['hover']}
        >
          <span>
            <Button color="secondary" data-testid="TrainingModeButton" disabled>
              {plansRedesignFeatureFlag
                ? t('Button.trainTogether')
                : t('Button.trainingMode')}
            </Button>
          </span>
        </Tooltip>
      ) : plansRedesignFeatureFlag ? (
        <Tooltip
          content={t('TrainingPlans.trainTogether.toolTip')}
          placement="left"
          showOnElementEvents={['hover']}
        >
          <span>
            <Button
              color="secondary"
              data-testid="TrainingModeButton"
              onClick={() => dispatch(showTrainingMode())}
            >
              <IconUsersGroup style={{ marginRight: '0.5em' }} />
              {t('Button.trainTogether')}
            </Button>
          </span>
        </Tooltip>
      ) : (
        <Button
          color="secondary"
          data-testid="TrainingModeButton"
          onClick={() => dispatch(showTrainingMode())}
        >
          {plansRedesignFeatureFlag
            ? t('Button.trainTogether')
            : t('Button.trainingMode')}
        </Button>
      )}
      <IconPrinter onClick={() => dispatch(showPrintReport())} />
    </TrainingModeWrapper>
  );
};

const TrainingModeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  gap: 1rem;
`;

export default ManageTeamMembersButtons;
