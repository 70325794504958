import {
  ContentReference,
  Document,
} from '@cfacorp-pathway/xp-api-typescript-client';
import {
  createSlice,
  PayloadAction,
  ActionReducerMapBuilder,
} from '@reduxjs/toolkit';
import { setDocument, clearDocument } from '../document-persisted/slice';

/** Document state not persisted in local storage. */
interface DocumentTransientState {
  isPrinting: boolean;
  isTranslating: boolean;
  chosenLinkDocument: Document | null;
  chosenCountryTab?: ContentReference.country | null;
  chosenDocumentCountry?: ContentReference.country | null;
}

const initialState: DocumentTransientState = {
  isPrinting: false,
  isTranslating: false,
  chosenLinkDocument: null,
};

export const slice = createSlice({
  name: 'documentTransient',
  initialState,
  reducers: {
    setIsPrinting: (state, action: PayloadAction<boolean>) => {
      state.isPrinting = action.payload;
    },
    translationButtonPress: state => {
      state.isTranslating = !state.isTranslating;
    },
    setChosenCountryTab: (
      state,
      action: PayloadAction<ContentReference.country>,
    ) => {
      state.chosenCountryTab = action.payload;
    },
    setChosenDocumentCountry: (
      state,
      action: PayloadAction<ContentReference.country>,
    ) => {
      state.chosenDocumentCountry = action.payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<DocumentTransientState>) => {
    builder.addCase(setDocument, (state, action) => {
      const { document, routedFrom, country } = action.payload;
      if (routedFrom === 'link') {
        state.chosenLinkDocument = document;
        state.chosenDocumentCountry = country;
        state.chosenCountryTab = null;
      }
    });
    builder.addCase(clearDocument, () => {
      return initialState;
    });
  },
});

export const { setIsPrinting, translationButtonPress, setChosenCountryTab } =
  slice.actions;

export default slice.reducer;
