import { FC, PropsWithChildren } from 'react';
import { DocumentTitle } from '..';
import {
  DocumentImage,
  DocumentImageOuterCircle,
  DocumentImageWrapper,
  MobileImageWrapper,
  PrintTitle,
} from '../wrappers';
import { useAppSelector } from '@/hooks';
import Constants from '@/constants';
import { Breadcrumb } from '@/types/types';
import { useIsMobile } from '@/utils/document-utils';

interface TridionTitleProps {
  breadcrumbs?: Breadcrumb[];
  icon?: string | null;
  isDesktopView?: boolean;
  tags: string[];
  title: string;
}

export const TridionTitle: FC<TridionTitleProps> = ({
  isDesktopView,
  breadcrumbs,
  tags,
  icon,
  title,
}) => {
  const isPrinting = useAppSelector(
    state => state.documentTransient.isPrinting,
  );

  // desktop view: >= 768px (breakpoint md and up)
  const isMobile = useIsMobile(isDesktopView);

  if (isPrinting) {
    return <PrintTitle>{title}</PrintTitle>;
  }

  const MobileWrapper: FC<PropsWithChildren> = ({ children }) =>
    isMobile ? <MobileImageWrapper>{children}</MobileImageWrapper> : children;

  return (
    <>
      <DocumentTitle
        breadcrumbs={breadcrumbs}
        isMobile={isMobile}
        tags={tags}
        title={title}
      />
      <MobileWrapper>
        <DocumentImageWrapper $isMobile={isMobile}>
          <DocumentImageOuterCircle>
            <DocumentImage
              alt="document icon"
              src={`${Constants.PATHWAY_CDN_IMG.PROCEDURES}${
                icon === null || icon === undefined || icon === 'placeholder'
                  ? 'Chickfila'
                  : icon
              }.svg`}
            />
          </DocumentImageOuterCircle>
        </DocumentImageWrapper>
      </MobileWrapper>
    </>
  );
};
