import { useEffect, useState } from 'react';
import isFunction from 'lodash/isFunction';
import { useDispatch } from 'react-redux';
import { setActiveLicenseeLocation } from 'store/licenseeLocationPicker/slice';

export type NativeMessage = {
  type: string;
} & Record<string, any>;

export const sendNativeMessage = (message: NativeMessage) => {
  const isReactNativeWebView = isFunction(
    window?.ReactNativeWebView?.postMessage,
  );
  if (isReactNativeWebView) {
    window?.ReactNativeWebView?.postMessage(JSON.stringify(message));
  }
};

export const useNativeListener = (listener: (ev: MessageEvent<any>) => any) => {
  useEffect(() => {
    window.addEventListener('message', listener);
    return () => window.removeEventListener('message', listener);
  }, [listener]);
};

export const useNativeToken = () => {
  const [token, setToken] = useState();
  useEffect(() => {
    const listener = (message: MessageEvent<any>) => {
      if (message.data?.type === 'accessToken') {
        setToken(message.data?.payload);
      }
    };
    window.addEventListener('message', listener);
    return () => window.removeEventListener('message', listener);
  }, []);
  return token;
};

/** Payload for the "locationPicker" event sent from native. */
export interface LocationPickerEventPayload {
  /** Location from native. */
  location: string;
}

/** Subscribe to the "locationPicker" event coming from native.  */
export const useNativeLocationPickerListener = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const listener = (e: CustomEvent<LocationPickerEventPayload>) => {
      dispatch(setActiveLicenseeLocation({ number: e.detail.location }));
    };

    document.addEventListener('locationPicker', listener as EventListener);
    return () =>
      document.removeEventListener('locationPicker', listener as EventListener);
  }, [dispatch]);
};
