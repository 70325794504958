import Constants from 'constants/index';
import LoadMorePaginator from '@/components/LoadMorePaginator/LoadMorePaginator';
import NoMessage from '@/components/NoMessage/NoMessage';
import TeamMemberCard from '@/components/PlanCards/TeamMemberCard';
import {
  ChecklistDTO,
  UserChecklistResultDTO,
} from '@cfacorp-pathway/xp-api-typescript-client';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { getNameFromLanguage } from '@/utils/language';
import { LanguageObject } from '@/types';
import { getTimeSpentOnPlan } from '@/utils/time';
import { loadMoreTeamMembers } from '@/store/teamMembersFilter/slice';
import { Trans, useTranslation } from 'react-i18next';

interface TeamMemberCardsProps {
  assignedTeamMembersList: UserChecklistResultDTO;
  currentFilters: string[];
  isCompliance: boolean;
  isFoodSafety: boolean;
  onUnassign: (id: string, name: string) => void;
  onUpdateDueDate: (id: string, name: string) => void;
  plan: ChecklistDTO;
  planId: string;
  refetchAssignedChecklist: () => void;
  refetchAssignedTeamMembers: () => void;
  showing: number;
  stepCount: number;
  teamMembers: any[];
  total: number;
}

const TeamMemberCards: React.FC<TeamMemberCardsProps> = ({
  assignedTeamMembersList,
  currentFilters,
  isCompliance,
  isFoodSafety,
  onUnassign,
  onUpdateDueDate,
  plan,
  planId,
  refetchAssignedChecklist,
  refetchAssignedTeamMembers,
  showing,
  stepCount,
  teamMembers,
  total,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <TeamMembersList>
      {!!teamMembers?.length && (
        <>
          {teamMembers &&
            teamMembers.map((member: any, index: number) => {
              return (
                <TeamMemberCard
                  completedStepCount={
                    assignedTeamMembersList?.status?.find(
                      (userStatus: any) => userStatus.userId === member.userId,
                    )?.stepsComplete || 0
                  }
                  completionDate={
                    isCompliance ? member.completedDate : member.completionDate
                  }
                  expirationDate={member.expirationDate}
                  finalScore={member?.finalScore}
                  id={member.userId ?? ''}
                  isComplete={
                    member?.status === Constants.TRAINING_PLANS.COMPLETED ||
                    member?.status ===
                      Constants.LEARN_UPON_TRAINING_PLANS.COMPLETED ||
                    member?.status ===
                      Constants.LEARN_UPON_TRAINING_PLANS.PASSED
                  }
                  isCompliance={isCompliance}
                  isFailed={
                    member?.status ===
                    Constants.LEARN_UPON_TRAINING_PLANS.FAILED
                  }
                  isFoodSafety={isFoodSafety}
                  key={index}
                  name={member.name ?? ''}
                  onRefetch={() => {
                    refetchAssignedTeamMembers();
                    refetchAssignedChecklist();
                  }}
                  onUnassign={(id, name) => onUnassign(id, name)}
                  onUpdateDueDate={(id, name) => onUpdateDueDate(id, name)}
                  percentComplete={member?.percentComplete}
                  planId={planId}
                  planName={
                    getNameFromLanguage(plan?.name as LanguageObject) ?? ''
                  }
                  selectedDueDate={new Date(member.dueDate).getTime()}
                  stepCount={stepCount}
                  timeSpentOnPlan={getTimeSpentOnPlan(member, isCompliance)!}
                />
              );
            })}
          <LoadMorePaginator
            onClick={() => dispatch(loadMoreTeamMembers())}
            showing={showing}
            showingText={t('TrainingPlans.showingXOfYTeamMembers', {
              showing: showing ?? 0,
              total: total ?? 0,
            })}
            total={total ?? 0}
          />
        </>
      )}
      {!teamMembers?.length && !currentFilters?.length && (
        <NoMessage
          message={
            <Trans i18nKey={'TrainingPlans.noTeamMembersAssignedManagePlans'} />
          }
        />
      )}
      {!!currentFilters?.length && !total && (
        <NoMessage message={t('TrainingPlans.noTeamMembersResults')} />
      )}
    </TeamMembersList>
  );
};
const TeamMembersList = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  max-width: 100%;
`;

export default TeamMemberCards;
