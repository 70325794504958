import { device } from 'utils/device';
import { useState } from 'react';
import styled from 'styled-components';
import SectionsAndSteps from './SectionsAndSteps';
import ConfirmationModals from './ConfirmationModals';

export const TrainingMode = () => {
  const [showPlanCompleteModal, setShowPlanCompleteModal] = useState(false);

  return (
    <StyledInnerPage>
      <SectionsAndSteps
        showPlanCompleteModal={() => setShowPlanCompleteModal(true)}
      />
      <ConfirmationModals isOpen={showPlanCompleteModal} />
    </StyledInnerPage>
  );
};

const StyledInnerPage = styled.div`
  width: 50%;
  margin: auto;
  margin-top: 3em;

  @media ${device.mobile} {
    width: 100%;
    padding: 0 1em;
  }
`;
