import styled from 'styled-components';
import { Breadcrumbs as CfaBreadcrumbs } from 'cfa-react-components';
import { Link } from 'react-router-dom';
import { Breadcrumb } from '@/types/types';

interface BreadcrumbLinksProps {
  breadcrumbs: Breadcrumb[];
  className?: string;
  'data-testid'?: string;
  onClick?: () => void;
}

const BreadcrumbLinks: React.FC<BreadcrumbLinksProps> = ({
  breadcrumbs,
  className,
  'data-testid': DataTestId,
  onClick,
}) => {
  const mapBreadCrumbsToLinks = () => {
    return breadcrumbs?.map(breadcrumb => {
      if (breadcrumb?.to) {
        return {
          ...breadcrumb,
          as: Link,
        };
      } else {
        return {
          ...breadcrumb,
        };
      }
    });
  };

  const mappedBreadcrumbs = mapBreadCrumbsToLinks();

  return (
    <PageHeaderContainer className={className} data-testid={DataTestId}>
      {breadcrumbs?.length > 0 && (
        <PathBreadcrumbs
          breadcrumbs={mappedBreadcrumbs as any}
          onClick={onClick}
          overrideMarginLeft
        />
      )}
    </PageHeaderContainer>
  );
};

const PageHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const PathBreadcrumbs = styled(CfaBreadcrumbs)`
  & a,
  button,
  p {
    text-transform: capitalize;
  }
`;

export default BreadcrumbLinks;
