import Fab from '@/components/Fab/Fab';
import FabPopup from '@/components/Fab/FabPopup';
import FabPopupItem from '@/components/Fab/FabPopupItem';
import { selectAllLocationsWithAtLeastLeaderPermissions } from '@/store/user/selectors';
import { IconBooks, IconNotes } from '@tabler/icons-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

interface CreatePlanFabProps {
  setShowAddPlanPopUp: (show: boolean) => void;
  setShowCustomPlan: (show: boolean) => void;
  setShowTemplateModal: (show: boolean) => void;
  showAddPlanPopUp: boolean;
}

const CreatePlanFab: React.FC<CreatePlanFabProps> = ({
  setShowAddPlanPopUp,
  setShowCustomPlan,
  setShowTemplateModal,
  showAddPlanPopUp,
}) => {
  const { t } = useTranslation();
  const { plansRedesign: plansRedesignFeatureFlag } = useFlags();
  const locationsWithAtLeastLeaderPermission = useSelector(
    selectAllLocationsWithAtLeastLeaderPermissions,
  );
  const onShowCustomPlanPopup = () => {
    setShowCustomPlan(true);
    setShowAddPlanPopUp(false);
  };

  const onShowTemplateModal = () => {
    setShowTemplateModal(true);
    setShowAddPlanPopUp(false);
  };

  return (
    plansRedesignFeatureFlag &&
    !!locationsWithAtLeastLeaderPermission.length && (
      <Fab
        hasMenu={true}
        isOpen={showAddPlanPopUp}
        onClick={() => setShowAddPlanPopUp(!showAddPlanPopUp)}
        onClose={() => setShowAddPlanPopUp(false)}
      >
        <FabPopup>
          <FabPopupItem
            data-testid="CustomPlan"
            icon={<IconNotes />}
            onClick={onShowCustomPlanPopup}
            title={t('TrainingPlans.addPlanMenu.buildCustomPlan')}
          />
          <FabPopupItem
            data-testid="TemplateLibraryPlan"
            icon={<IconBooks />}
            onClick={onShowTemplateModal}
            title={t('TrainingPlans.addPlanMenu.choosePlanFromLibrary')}
          />
        </FabPopup>
      </Fab>
    )
  );
};

export default CreatePlanFab;
