import useBugsnagNotify from 'hooks/useBugsnagNotify';
import styled from 'styled-components';
import FilterAndSortMobile from './FilterAndSortMobile';
import PrintReport from './PrintReport';
import TeamMembersList from './TeamMembersList';
import { withRoles } from '@/components/ConfirmationModal/withRoles';
import GenericError from '@/components/Error/GenericError';
import LoadingOverlay from '@/components/LoadingOverlay/LoadingOverlay';
import useGetStatusLabels from '@/hooks/useGetStatusLabels';
import useGetTeamMembersData from '@/utils/reportPrint/useGetTeamMembersData';
import { isApiError } from '@/utils/request';

const ReportsPlansView = () => {
  const { notifyBugsnag } = useBugsnagNotify();

  const {
    assignedTeamMemberError,
    assignedTeamMembersList,
    error,
    filterLocations,
    isFetching,
    isFoodSafety,
    plan,
    isLicenseePlan,
    refetchAssignedTeamMembers,
    stepCount,
  } = useGetTeamMembersData();

  const statusLabels = useGetStatusLabels();

  // GET Team Members By Location Fetch error
  if (isApiError(error as Error)) {
    notifyBugsnag(error);
    return <GenericError />;
  }

  // GET Assigned Team Members Fetch error
  if (isApiError(assignedTeamMemberError)) {
    notifyBugsnag(assignedTeamMemberError);
    return <GenericError />;
  }

  return (
    <>
      <StyledContent>
        <LoadingOverlay isOpen={isFetching} />
        <FilterAndSortMobile
          filterLocations={filterLocations}
          statusLabels={statusLabels}
        />
        <TeamMembersList
          assignedTeamMembersList={assignedTeamMembersList}
          filterLocations={filterLocations}
          isFoodSafety={isFoodSafety}
          isLicenseePlan={isLicenseePlan}
          refetchAssignedTeamMembers={refetchAssignedTeamMembers}
          statusLabels={statusLabels}
          stepCount={stepCount}
        />
      </StyledContent>
      <PrintReport
        assignedTeamMembersList={assignedTeamMembersList}
        filterLocations={filterLocations}
        isFetching={isFetching}
        isFoodSafety={isFoodSafety}
        isLicenseePlan={isLicenseePlan}
        plan={plan}
        stepCount={stepCount}
      />
    </>
  );
};

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  max-width: 100%;
`;

const ReportsPlansViewWithRoles = withRoles(
  ReportsPlansView,
  'reporting.plans',
);

export default ReportsPlansViewWithRoles;
