import styled from 'styled-components';
import { IconButton } from 'cfa-react-components';
import React, { ReactNode } from 'react';

interface SquareIconButtonProps {
  children: ReactNode;
  color?: string;
  disabled?: boolean;
  onClick?: () => void;
}

const SquareIconButton: React.FC<SquareIconButtonProps> = ({
  children,
  ...props
}) => {
  return <StyledIconButton {...props}>{children}</StyledIconButton>;
};

const StyledIconButton = styled(IconButton)`
  background-color: ${({ theme }) => theme.primaryPalette.navyBlue};
  color: ${({ theme }) => theme.primaryPalette.white} !important;
  border-radius: 4px;
  padding: 8px;
`;

export default SquareIconButton;
