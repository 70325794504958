import Constants from 'constants/index';
import { validateTextField } from 'utils/validateTextField';
import theme from 'styles/theme';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectUserLanguage } from 'store/user/selectors';
import {
  Button,
  RadioGroup,
  RadioButton,
  TextField,
} from 'cfa-react-components';
import { IconPlus, IconX, IconCheck } from '@tabler/icons-react';

interface Quiz {
  questions: any;
}

export interface BuildQuizzesProps {
  handlePassingGrade?: (grade: string) => void;
  isEditMode?: boolean;
  questionIndex: number;
  questionTemplateType?: { isMultipleChoiceTemplate: boolean };
  quiz: Quiz;
  setQuiz: (state: any) => void;
  setValidationMode: (validate: boolean) => void;
  validationMode: boolean;
}

export interface QuizOption {
  answer: string;
  correct: boolean;
}

interface QuizStyleProps {
  $correctOption: boolean;
}

const BuildQuizzesAnswers: React.FC<BuildQuizzesProps> = ({
  questionIndex,
  questionTemplateType,
  quiz,
  setQuiz,
  setValidationMode,
  validationMode,
}) => {
  const { t } = useTranslation();
  const userLanguage = useSelector(selectUserLanguage) as any;

  const handleOptionText = (value: string, optionIndex: number) => {
    setQuiz((prevState: any) => ({
      ...prevState,
      questions: Object.values({
        ...prevState.questions,
        [questionIndex]: {
          ...prevState.questions[questionIndex],
          answers: Object.values({
            ...prevState.questions[questionIndex].answers,
            [optionIndex]: {
              ...prevState.questions[questionIndex].answers[optionIndex],
              answer: {
                ...prevState.questions[questionIndex].answers[optionIndex]
                  .answer,
                [userLanguage]: value,
              },
            },
          }),
        },
      }),
    }));
  };

  const handleCorrectOption = (optionIndex: number) => {
    setQuiz((prevState: any) => ({
      ...prevState,
      questions: Object.values({
        ...prevState.questions,
        [questionIndex]: {
          ...prevState.questions[questionIndex],
          answers: prevState.questions[questionIndex].answers.map(
            (_: any, index: number) => {
              if (index === optionIndex) {
                return {
                  ...prevState.questions[questionIndex].answers[index],
                  correct: true,
                };
              } else {
                return {
                  ...prevState.questions[questionIndex].answers[index],
                  correct: false,
                };
              }
            },
          ),
        },
      }),
    }));
  };

  const addOption = () => {
    setValidationMode(false);
    setQuiz((prevState: any) => ({
      ...prevState,
      questions: Object.values({
        ...prevState.questions,
        [questionIndex]: {
          ...prevState.questions[questionIndex],
          answers: [
            ...prevState.questions[questionIndex].answers,
            ...[Constants.DEFAULT_QUIZ_ANSWER],
          ],
        },
      }),
    }));
  };

  const deleteOption = (optionIndex: number) => {
    setQuiz((prevState: any) => ({
      ...prevState,
      questions: Object.values({
        ...prevState.questions,
        [questionIndex]: {
          ...prevState.questions[questionIndex],
          answers: prevState.questions[questionIndex].answers.filter(
            (_: any, index: number) => index !== optionIndex,
          ),
        },
      }),
    }));
  };

  return (
    <StyledOptionsWrapper>
      <RadioGroup defaultValue={''} orientation="vertical">
        {quiz.questions[questionIndex]?.answers?.map(
          (option: QuizOption, optionIndex: number) => {
            return (
              <StyledOptionWrapper
                $correctOption={option.correct}
                key={`questionOption-${optionIndex}`}
              >
                <StyledRadioButton
                  $correctOption={option.correct}
                  onClick={() => handleCorrectOption(optionIndex)}
                  value={optionIndex}
                />
                <StyledTextFieldWrapper $correctOption={option.correct}>
                  <TextField
                    data-testid={`${'EnterAnswer'}${optionIndex + 1}`}
                    errorText={
                      !validateTextField(
                        option?.answer?.[userLanguage] ?? '',
                      ) && validationMode
                        ? t('TrainingPlans.buildQuizzes.errorQuestion')
                        : ''
                    }
                    fullWidth
                    label={`${t('TrainingPlans.buildQuizzes.option')} ${
                      optionIndex + 1
                    }`}
                    maxLength={255}
                    onChange={e =>
                      handleOptionText(e.target.value, optionIndex)
                    }
                    placeholder={t('TrainingPlans.buildQuizzes.enterAnswer')}
                    required
                    value={option?.answer?.[userLanguage] ?? ''}
                  />
                  {option.correct && (
                    <IconCheck
                      color={`${theme.semanticColors.success}`}
                      size={20}
                      style={{
                        position: 'absolute',
                        top: 'calc(50% - 10px)',
                        right: '16px',
                      }}
                    />
                  )}
                </StyledTextFieldWrapper>
                {quiz.questions[questionIndex]?.answers?.length > 2 && (
                  <StyledDeleteOption>
                    <IconX
                      color={`${theme.grayScale.gray6}`}
                      onClick={() => deleteOption(optionIndex)}
                      size={32}
                    />
                  </StyledDeleteOption>
                )}
              </StyledOptionWrapper>
            );
          },
        )}
      </RadioGroup>
      {questionTemplateType?.isMultipleChoiceTemplate && (
        <>
          <StyledTextButton
            color="secondary"
            onClick={addOption}
            size="lg"
            variant="text"
          >
            <IconPlus />
            {t('Button.addOption')}
          </StyledTextButton>
        </>
      )}
    </StyledOptionsWrapper>
  );
};

const StyledOptionsWrapper = styled.div`
  margin: 1em 0;
`;

const StyledRadioButton = styled(RadioButton)<QuizStyleProps>`
  display: flex;
  margin-top: 26px;

  .radio {
    border-color: ${({ $correctOption }) =>
      $correctOption === true
        ? `${theme.semanticColors.success}`
        : null} !important;
  }
  .cfa-radiobutton-inner-circle {
    border: ${({ $correctOption }) =>
      $correctOption === true
        ? `6px solid ${theme.semanticColors.success}`
        : null} !important;
  }
`;

const StyledTextFieldWrapper = styled.div<QuizStyleProps>`
  position: relative;
  width: 100%;

  input {
    padding-right: ${({ $correctOption }) =>
      $correctOption === true ? `3em` : null};
  }
`;

const StyledDeleteOption = styled.div`
  display: flex;
  align-items: center;
  margin: 26px 0.5em 0 0.5em;
  height: 49px;
`;

//need !important to override our default button colors
const StyledTextButton = styled(Button)`
  color: ${theme.primaryPalette.navyBlue} !important;
`;

const StyledOptionWrapper = styled.div<QuizStyleProps>`
  background: ${({ $correctOption }) =>
    $correctOption === true ? 'rgba(7, 126, 76, 0.1)' : null};
  border: ${({ $correctOption }) =>
    $correctOption === true
      ? `1px solid ${theme.semanticColors.success}`
      : '1px solid transparent'};
  border-radius: 4px;
  padding: 8px 8px 16px 4px;
  display: flex;
  margin-bottom: 1em;
`;

export default BuildQuizzesAnswers;
