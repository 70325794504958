import Constants from 'constants/index';
import { SecureRoute } from '@okta/okta-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { lazy, memo, PropsWithChildren, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import AssignedPlanView from '../pages/TrainingPlans/MyPlans/AssignedPlanView';
import Admin from '@/pages/Admin/Admin';
import SignalDocument from '@/pages/Document/SignalDocument';
import PrintView from '@/pages/PrintView/PrintView';
import ReportsCompliancePlansTab from '@/pages/Reports/CompliancePlans/ReportsCompliancePlansTab';
import ReportsPlansTab from '@/pages/Reports/Plans/Tab/ReportsPlansTab';
import ReportsPlansView from '@/pages/Reports/Plans/View/ReportsPlansView';
import ReportsTeamMembersTab from '@/pages/Reports/TeamMembers/ReportsTeamMembersTab';
import ReportsTeamMembersView from '@/pages/Reports/TeamMembers/ReportsTeamMembersView';
import SearchResultsPage from '@/pages/SearchResults/SearchResultsPage';
import BuildPlansTab from '@/pages/TrainingPlans/BuildPlans/BuildPlansTab';
import EditPlanView from '@/pages/TrainingPlans/BuildPlans/EditPlanView';
import BuildQuizzesTab from '@/pages/TrainingPlans/BuildQuizzes/BuildQuizzesTab';
import BuildQuizzesView from '@/pages/TrainingPlans/BuildQuizzes/BuildQuizzesView';
import DocumentProxy from '@/pages/TrainingPlans/MyPlans/DocumentProxy';
import MyPlansTab from '@/pages/TrainingPlans/MyPlans/MyPlansTab';
import { useAppSelector } from '@/hooks';
import { TrainingMode } from '@/pages/TrainingPlans/TrainingMode/TrainingMode';
import { selectIsInitialized } from '@/store/app/selectors';
import { getAuthFromNative } from '@/utils/auth';
import TeamMemberProgressView from '@/pages/TrainingPlans/ManagePlans/Tab/TeamMemberProgressView';
import ManagePlansTab from '@/pages/TrainingPlans/ManagePlans/Tab/ManagePlansTab';
import ManagePlanView from '@/pages/TrainingPlans/ManagePlans/View/ManagePlanView';

const NotFoundPage = lazy(() => import('../pages/404/404'));
const OperationsSubcategoryPage = lazy(
  () => import('../pages/Explore/Operations/SubcategoryPage'),
);
const SettingsPage = lazy(() => import('../pages/Settings/SettingsPage'));
const DocumentRouteHandler = lazy(
  () => import('../pages/Document/DocumentRouteHandler'),
);
const LeadershipTab = lazy(
  () => import('../pages/Explore/Leadership/LeadershipTab'),
);
const OperationsTab = lazy(
  () => import('../pages/Explore/Operations/OperationsTab'),
);

const Placeholder: React.FC = () => <span />;

// App.js has Bugsnag boundary that shows Generic error popup
const NotFoundSwitch: React.FC<PropsWithChildren> = ({ children }) => (
  <Switch>
    {children}
    <Route path="*">
      <NotFoundPage />
    </Route>
  </Switch>
);

// Native does not use the okta <Security> wrapper but checks for auth via useOktaAuth hook
const SecureRoutesHandledByNative: React.FC = () => {
  const {
    ascendNav: featureflagAscendNav,
    plansRedesign: plansRedesignFeatureFlag,
  } = useFlags();
  return (
    <Suspense fallback={<Placeholder />}>
      <NotFoundSwitch>
        <Route exact path="/category/:categoryId">
          <OperationsSubcategoryPage />
        </Route>
        <Route exact path={`/${Constants.ROUTE_PATH_NAMES.PROFILE_PATH_NAME}`}>
          <SettingsPage />
        </Route>
        <Route exact path={`/${Constants.ROUTE_PATH_NAMES.SEARCH_PATH_NAME}`}>
          <SearchResultsPage />
        </Route>
        <Route exact path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}`}>
          <MyPlansTab />
        </Route>
        {/* remove route when plans redesign is finalized */}
        {!plansRedesignFeatureFlag && (
          <Route
            exact
            path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.BUILD_PATH_NAME}`}
          >
            <BuildPlansTab />
          </Route>
        )}
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PLAN_PATH_NAME}/:planId`}
        >
          <EditPlanView />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.ASSIGNED_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.TO_COMPLETE_PATH_NAME}`}
        >
          <DocumentProxy />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.ASSIGNED_PATH_NAME}/:planId`}
        >
          <AssignedPlanView />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.TEAM_PATH_NAME}`}
        >
          <ManagePlansTab />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.QUIZZES_PATH_NAME}`}
        >
          <BuildQuizzesTab />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.QUIZZES_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.BUILD_PATH_NAME}`}
        >
          <BuildQuizzesView />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.QUIZ_PATH_NAME}/:quizId`}
        >
          <BuildQuizzesView />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/:id/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PROGRESS_PATH_NAME}/:teamMemberId`}
        >
          <TeamMemberProgressView />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/:id/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PROGRESS_PATH_NAME}/:teamMemberId`}
        >
          <TeamMemberProgressView />
        </Route>
        <Route
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/:id/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}/:compliancePlan?`}
        >
          <ManagePlanView />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.TRAINING_MODE_PATH_NAME}`}
        >
          <TrainingMode />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.HEADLESS_PATH_NAME}/:documentId`}
        >
          <SignalDocument />
        </Route>
        {/* remove route when plans redesign is finalized */}
        {!plansRedesignFeatureFlag && (
          <Route
            exact
            path={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.COMPLIANCE_PATH_NAME}`}
          >
            <ReportsCompliancePlansTab />
          </Route>
        )}
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PLANS_PATH_NAME}`}
        >
          <ReportsPlansTab />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PLANS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PROGRESS_PATH_NAME}/:id/:compliancePlan?`}
        >
          <ReportsPlansView />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}`}
        >
          <ReportsTeamMembersTab />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PROGRESS_PATH_NAME}/:id`}
        >
          <ReportsTeamMembersView />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.DOCUMENT_PATH_NAME}/:documentId`}
        >
          <DocumentRouteHandler />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.LEADERSHIP_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.DOCUMENT_PATH_NAME}/:documentId/:isCompliance`}
        >
          <DocumentRouteHandler />
        </Route>
        <Route
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.COMPLIANCE_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.DOCUMENT_PATH_NAME}/:documentId/:isCompliance`}
        >
          <DocumentRouteHandler />
        </Route>
        {featureflagAscendNav && (
          <Route
            exact
            path={`/${Constants.ROUTE_PATH_NAMES.LEADERSHIP_PATH_NAME}`}
          >
            <LeadershipTab />
          </Route>
        )}
        <Route exact path="/">
          <OperationsTab />
        </Route>
      </NotFoundSwitch>
    </Suspense>
  );
};

// Web uses the okta <Security> wrapper to check for auth
const SecureRouteHandledByWeb: React.FC = () => {
  const {
    ascendNav: featureflagAscendNav,
    plansRedesign: plansRedesignFeatureFlag,
  } = useFlags();

  return (
    <Suspense fallback={<Placeholder />}>
      <NotFoundSwitch>
        <SecureRoute exact path="/category/:categoryId">
          <OperationsSubcategoryPage />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.PROFILE_PATH_NAME}`}
        >
          <SettingsPage />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.SEARCH_PATH_NAME}`}
        >
          <SearchResultsPage />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.BINARY_PATH_NAME}/:documentId`}
        >
          <PrintView />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}`}
        >
          <MyPlansTab />
        </SecureRoute>
        {/* remove route when plans redesign is finalized */}
        {!plansRedesignFeatureFlag && (
          <SecureRoute
            exact
            path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.BUILD_PATH_NAME}`}
          >
            <BuildPlansTab />
          </SecureRoute>
        )}
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PLAN_PATH_NAME}/:planId`}
        >
          <EditPlanView />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.ASSIGNED_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.TO_COMPLETE_PATH_NAME}`}
        >
          <DocumentProxy />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.ASSIGNED_PATH_NAME}/:planId`}
        >
          <AssignedPlanView />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.TEAM_PATH_NAME}`}
        >
          <ManagePlansTab />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.QUIZZES_PATH_NAME}`}
        >
          <BuildQuizzesTab />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.QUIZZES_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.BUILD_PATH_NAME}`}
        >
          <BuildQuizzesView />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.QUIZ_PATH_NAME}/:quizId`}
        >
          <BuildQuizzesView />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/:id/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PROGRESS_PATH_NAME}/:teamMemberId`}
        >
          <TeamMemberProgressView />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/:id/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PROGRESS_PATH_NAME}/:teamMemberId`}
        >
          <TeamMemberProgressView />
        </SecureRoute>
        <SecureRoute
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/:id/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}`}
        >
          <ManagePlanView />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.TRAINING_MODE_PATH_NAME}`}
        >
          <TrainingMode />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.HEADLESS_PATH_NAME}/:documentId`}
        >
          <SignalDocument />
        </SecureRoute>
        {/* remove route when plans redesign is finalized */}
        {!plansRedesignFeatureFlag && (
          <SecureRoute
            exact
            path={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.COMPLIANCE_PATH_NAME}`}
          >
            <ReportsCompliancePlansTab />
          </SecureRoute>
        )}
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PLANS_PATH_NAME}`}
        >
          <ReportsPlansTab />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PLANS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PROGRESS_PATH_NAME}/:id/:compliancePlan?`}
        >
          <ReportsPlansView />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}`}
        >
          <ReportsTeamMembersTab />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PROGRESS_PATH_NAME}/:id`}
        >
          <ReportsTeamMembersView />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.DOCUMENT_PATH_NAME}/:documentId`}
        >
          <DocumentRouteHandler />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.LEADERSHIP_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.DOCUMENT_PATH_NAME}/:documentId/:isCompliance`}
        >
          <DocumentRouteHandler />
        </SecureRoute>
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.COMPLIANCE_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.DOCUMENT_PATH_NAME}/:documentId/:isCompliance`}
        >
          <DocumentRouteHandler />
        </SecureRoute>
        {featureflagAscendNav && (
          <SecureRoute
            exact
            path={`/${Constants.ROUTE_PATH_NAMES.LEADERSHIP_PATH_NAME}`}
          >
            <LeadershipTab />
          </SecureRoute>
        )}
        <SecureRoute
          exact
          path={`/${Constants.ROUTE_PATH_NAMES.ADMIN_PATH_NAME}`}
        >
          <Admin />
        </SecureRoute>
        <SecureRoute exact path="/">
          <OperationsTab />
        </SecureRoute>
      </NotFoundSwitch>
    </Suspense>
  );
};

/**
 * Anything to do with the session cannot be selected here!!
 * It will tear down the entire Routes component and cause problems
 */
const Routes: React.FC = () => {
  const isInitialized = useAppSelector(selectIsInitialized);
  const { isNative } = getAuthFromNative();

  if (!isInitialized) {
    return null; // overlay will be shown
  }

  return isNative ? (
    <SecureRoutesHandledByNative />
  ) : (
    <SecureRouteHandledByWeb />
  );
};

export default memo(Routes);
