import {
  ContentReference,
  Document,
} from '@cfacorp-pathway/xp-api-typescript-client';
import includes from 'lodash/includes';
import { useCallback, useEffect, useState } from 'react';
import { useDeviceInfo } from '../device';
import Constants from '@/constants';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { useIsNative } from '@/hooks/useIsNative';
import { selectDocumentFromDashboardOrResource } from '@/store/document-persisted/selectors';
import {
  selectIsPrinting,
  selectIsTranslating,
} from '@/store/document-transient/selectors';
import { setIsPrinting } from '@/store/document-transient/slice';
import { setSidebarCollapsed } from '@/store/sideBar/slice';

export const useHeightForIframe = (isCompliance = false) => {
  const isMobile = useIsMobile();

  if (isCompliance) {
    if (isMobile) {
      return `calc(100vh - ${Constants.HEIGHT.MOBILE_TOP_NAV})`;
    }
    return '100vh';
  }

  return isMobile
    ? `calc(100vh - ${Constants.HEIGHT.MOBILE_TOP_NAV} - ${Constants.HEIGHT.SEARCHBAR_SUBHEADER_HEIGHT})`
    : `calc(100vh - ${Constants.HEIGHT.SEARCHBAR_SUBHEADER_HEIGHT})`;
};

export const extractBodyContent = (htmlContent?: string) => {
  if (!htmlContent) {
    return '';
  }
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, 'text/html');
  const body = doc.querySelector('body');
  return body ? body.innerHTML : '';
};

/**
 * @param isDesktopView Add optional smaller DesktopView size check (md breakpoint 768px)
 * @returns isMobile
 * if isNative, return true;
 * else if isDesktopView (from Tridion) is true, expect a smaller size 768px or less to return true.
 */
export const useIsMobile = (isDesktopView?: boolean) => {
  const isNative = useIsNative();
  // isDesktopWidth: hardcoded 840px or more
  const { isDesktop: isDesktopWidth } = useDeviceInfo();
  return isNative || (!isDesktopWidth && !isDesktopView);
};

export const fixTridionImageSources = (
  htmlContent?: string,
): string | undefined => {
  if (!htmlContent) {
    return undefined;
  }
  const baseUrl = Constants.XP_API_IMAGE_BASE_URL;
  const html = new DOMParser().parseFromString(htmlContent, 'text/html');
  const images = html.querySelectorAll('img');

  images.forEach(image => {
    const updatedImageSource = image.src.match(/.*\/(binary\/.*)/)?.[1];
    if (updatedImageSource !== undefined) {
      image.src = `${baseUrl}/${updatedImageSource}`;
    }
  });
  return html.body.innerHTML;
};

export const contentReferenceToDocument = (
  contentReference: ContentReference,
  documentId: string,
): Document => {
  return {
    id: documentId,
    contentApiUrl: contentReference.contentApiUrl,
    contentUrl: contentReference.contentUrl,
    lastModified: contentReference.lastModified,
    name: contentReference.name,
    otherContentUrl: contentReference.otherContentUrl,
    otherPrintUrl: contentReference.otherPrintUrl,
    printUrl: contentReference.printUrl,
    sourceSystem: contentReference.sourceSystem,
    tags: contentReference.tags,
  };
};

export const useTridionPrinting = () => {
  const isPrinting = useAppSelector(selectIsPrinting);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isPrinting) {
      window.print();
    }
    dispatch(setIsPrinting(false));
  }, [dispatch, isPrinting]);
};

export const useDocumentErrorListener = () => {
  const [errorMessage, setErrorMessage] = useState<object>();

  const onMessageReceivedFromContent = useCallback(
    (eventMessage: MessageEvent<any>) => {
      if (eventMessage.origin === Constants.PATHWAY_API_URL) {
        // This is a message sent to indicate a successful page load
        if (eventMessage.data === 'ok') {
          return;
        }

        if (eventMessage.data.type === 'Error') {
          setErrorMessage(eventMessage.data.code);
        }
      }
    },
    [],
  );

  useEffect(() => {
    window.addEventListener('message', onMessageReceivedFromContent);

    return () => {
      window.removeEventListener('message', onMessageReceivedFromContent);
    };
  }, [onMessageReceivedFromContent]);

  return errorMessage;
};

export const useWindowScrollEvents = () => {
  useEffect(() => {
    window.updateScrollPosition = (top: any) => {
      const scrollEvent = new MessageEvent('iFrameScroll', {
        data: JSON.stringify(top),
      });
      window.dispatchEvent(scrollEvent);
    };
  }, []);
};

/** Get url for a tridion document based on using the translated url or not. */
export const useTridionUrl = (document: Document) => {
  const isTranslating = useAppSelector(selectIsTranslating);
  const [url, setUrl] = useState<string>();

  useEffect(() => {
    if (isTranslating) {
      setUrl(document.otherContentUrl);
    } else {
      setUrl(document.contentUrl);
    }
  }, [document.contentUrl, document.otherContentUrl, isTranslating]);

  return url;
};

/** Collapse sidebar for certain document types. */
export const useCollapseSidebar = () => {
  const dispatch = useAppDispatch();
  const document = useAppSelector(selectDocumentFromDashboardOrResource);
  const fullViewDocument = includes(
    Constants.EXPANDED_FILE_FORMAT_TYPES,
    document?.type,
  );

  useEffect(() => {
    if (fullViewDocument) {
      dispatch(setSidebarCollapsed());
    }
  }, [dispatch, fullViewDocument]);
};
