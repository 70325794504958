import { FC, useEffect } from 'react';
import { XpApiError } from '@/services/api-types';
import GenericError from '@/components/Error/GenericError';
import PageNotFound404 from '@/pages/404/404';
import useBugsnagNotify from '@/hooks/useBugsnagNotify';

interface DocumentErrorProps {
  error?: XpApiError;
  otherError?: object;
  documentId: string;
}

const DocumentError: FC<DocumentErrorProps> = ({
  error,
  otherError,
  documentId,
}) => {
  const { notifyBugsnag } = useBugsnagNotify();

  useEffect(() => {
    if (error) {
      notifyBugsnag(error);
    }
    if (otherError) {
      notifyBugsnag({ ...otherError, documentId });
    }
  }, [documentId, error, notifyBugsnag, otherError]);

  switch (error?.status ?? otherError) {
    case 404:
      return <PageNotFound404 />;
    default:
      return <GenericError />;
  }
};

export default DocumentError;
