import Constants from 'constants/index';
import { capitalizeFirstLetter, capitalizeWords } from './capitalize';

export const formatDocumentTag = (tag: string) => {
  switch (tag) {
    case Constants.RESTAURANT_CONTENT_TYPES.TEST:
    case Constants.RESTAURANT_CONTENT_TYPES.FRANCHISEE:
      return capitalizeFirstLetter(tag);
    case Constants.RESTAURANT_CONTENT_TYPES.BAY_CENTER:
      return capitalizeWords(tag);
    case Constants.RESTAURANT_CONTENT_TYPES.CFA_SUPPLY:
      return capitalizeWords(tag, true);
    default:
      return tag;
  }
};

export const colorTag = (tag: string) => {
  if (!tag) {
    return 'default';
  }
  return tag.includes(Constants.RESTAURANT_CONTENT_TYPES.STC)
    ? 'darkGreen'
    : tag?.includes(
          capitalizeFirstLetter(Constants.RESTAURANT_CONTENT_TYPES.TEST),
        )
      ? 'orange'
      : 'default';
};
