import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useBreakpoints, useMediaQuery } from 'cfa-react-components';

interface LeftHeaderContentProps {
  onWidth: (width: number) => void;
  otherWidth: number;
}

interface LeftHeaderStyleProps {
  $otherWidth: number;
  $width: number;
}

const LeftHeaderContent: React.FC<LeftHeaderContentProps> = ({
  onWidth,
  otherWidth,
}) => {
  const [width, setWidth] = useState(0);
  const LeftHeaderRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const breakpoints = useBreakpoints();
  const isLgAndUp = useMediaQuery(breakpoints.up('lg'));

  useEffect(() => {
    if (LeftHeaderRef.current) {
      if (
        LeftHeaderRef.current.clientWidth !== width &&
        LeftHeaderRef.current.clientWidth !== otherWidth
      ) {
        setWidth(LeftHeaderRef.current.clientWidth);
      }
    }
    if (LeftHeaderRef.current && LeftHeaderRef.current.clientWidth === 0) {
      setWidth(0);
    }
  }, [LeftHeaderRef, otherWidth, width, location]);

  useEffect(() => {
    if (isLgAndUp) {
      onWidth(width);
    } else {
      onWidth(0);
    }
  }, [onWidth, width, isLgAndUp]);

  return (
    <LeftHeader
      $otherWidth={otherWidth}
      $width={width}
      ref={LeftHeaderRef}
    ></LeftHeader>
  );
};

const LeftHeader = styled.div<LeftHeaderStyleProps>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: ${({ $width, $otherWidth }) =>
    $width >= $otherWidth ? 'auto' : `${$otherWidth}px`};
`;

export default LeftHeaderContent;
