import Constants from 'constants/index';
import { Card, Tag, Typography } from 'cfa-react-components';
import { FC } from 'react';
import styled from 'styled-components';
import { colorTag } from '@/utils/formatDocumentTag';

interface DocumentIconProps {
  icon?: string;
  name: string;
}

const DocumentIcon: FC<DocumentIconProps> = ({ icon = '', name }) => (
  <StyledDocumentIcon
    alt={name}
    src={`${Constants.PATHWAY_CDN_IMG.PROCEDURES}${
      !icon || icon === 'placeholder' ? 'Chickfila' : icon
    }.svg`}
  />
);

interface DocumentCardProps {
  icon?: string;
  name: string;
  onClick: () => void;
  tags?: string[];
  country?: string;
}

const ChooseDocumentCard: FC<DocumentCardProps> = ({
  icon = '',
  name,
  onClick,
  tags = [],
  country,
}) => {
  return (
    <StyledCard elevation={1} onClick={onClick} title={name} variant="default">
      <DocumentIcon icon={icon} name={name} />
      <DocumentTitle variant="body1">{name}</DocumentTitle>
      {!!tags?.length &&
        tags?.map((tag, index) => {
          return (
            <CardTag
              color={colorTag(tag)}
              key={index}
              label={tag}
              variant="filled"
            />
          );
        })}
      {country && (
        <CardTag
          color="secondaryBlue"
          key={country}
          label={country}
          variant="outlined"
        />
      )}
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  margin: 16px 0;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.grayScale.gray2};
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const DocumentTitle = styled(Typography)`
  color: ${props => props.theme.primaryPalette.navyBlue};
`;

const StyledDocumentIcon = styled.img`
  width: 40px;
  display: flex;
  min-width: 40px;
  margin: 0 10px 0 0;
  border-radius: 0;
  padding: 1px;
`;

const CardTag = styled(Tag)`
  margin-left: 10px;
`;

export default ChooseDocumentCard;
