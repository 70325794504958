import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useGetReportsPlansAssignableQuery } from '@/services/pathwayApi';
import { setManagePlans } from '@/store/managePlansFilter/slice';
import {
  isUserLicensee,
  selectUserLicenseeLocations,
  selectUserIsLicenseeStaff,
  selectAllLocationsWithAtLeastTrainer,
} from '@/store/user/selectors';
import { selectPlansToggleLicensee } from '@/store/licensee/selector';
import { selectActiveLicenseeLocation } from '@/store/licenseeLocationPicker/selector';
import { LanguageObject } from '@/types/types';
import { getNameFromLanguage } from '@/utils/language';

// For ReportsPlansTab plans list
const useGetPlansWithLocations = () => {
  const dispatch = useDispatch();
  const { xpApi: xpApiFeatureFlag } = useFlags();

  const userIsLicenseeStaff = useSelector(selectUserIsLicenseeStaff);
  const isLicenseeUser = useSelector(isUserLicensee);
  const isLicenseePlans = useSelector(selectPlansToggleLicensee);
  const userLicenseeLocations = useSelector(selectUserLicenseeLocations);
  const activeLicenseeLocation: { name: string; number: string } = useSelector(
    selectActiveLicenseeLocation,
  );
  const locationsWithAtLeastTrainerPermission = useSelector(
    selectAllLocationsWithAtLeastTrainer,
  );

  const planLocations = useMemo(() => {
    if (userIsLicenseeStaff || isLicenseeUser) {
      return activeLicenseeLocation?.number
        ? [activeLicenseeLocation?.number]
        : [];
    }
    if (isLicenseePlans) {
      const licenseeLocations =
        userLicenseeLocations?.map(location => location.number) ?? [];
      return licenseeLocations as string[];
    }
    return locationsWithAtLeastTrainerPermission;
    // eslint-disable-next-line
  }, [isLicenseePlans, activeLicenseeLocation]);

  const {
    data: oldUnOrderedPlans,
    isFetching: isFetchingPlansOld,
    isSuccess: isSuccessPlansOld,
  } = useGetReportsPlansAssignableQuery(
    {
      locations: planLocations,
    },
    {
      skip:
        !!xpApiFeatureFlag ||
        ((userIsLicenseeStaff || isLicenseeUser) &&
          !activeLicenseeLocation?.number),
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    data: xpUnOrderedPlans,
    isFetching: isFetchingPlansXp,
    isSuccess: isSuccessPlansXp,
  } = useGetReportsPlansAssignableQuery(
    // TODO proxy does not exist yet, replace with proxy EP when available P-606
    {
      locations: planLocations,
    },
    {
      skip:
        !xpApiFeatureFlag ||
        ((userIsLicenseeStaff || isLicenseeUser) &&
          !activeLicenseeLocation?.number),
      refetchOnMountOrArgChange: true,
    },
  );

  const isSuccessPlans: boolean = xpApiFeatureFlag
    ? isSuccessPlansXp
    : isSuccessPlansOld;

  const isFetchingPlans: boolean = xpApiFeatureFlag
    ? isFetchingPlansXp
    : isFetchingPlansOld;

  const unOrderedPlans = xpApiFeatureFlag
    ? xpUnOrderedPlans
    : oldUnOrderedPlans;

  // Set Plans
  useEffect(() => {
    if (isSuccessPlans) {
      const storePlansWithName = unOrderedPlans?.checklists?.map(plan => {
        return {
          ...plan,
          planName: getNameFromLanguage(
            plan?.checklist?.name as LanguageObject,
          ).toLowerCase(),
        };
      });
      if (isLicenseeUser || isLicenseePlans) {
        dispatch(setManagePlans({ plans: storePlansWithName ?? [] }));
      } else {
        dispatch(
          setManagePlans({
            plans:
              storePlansWithName?.concat(unOrderedPlans?.courses as any) ?? [],
          }),
        );
      }
    }
  }, [
    dispatch,
    isLicenseePlans,
    isLicenseeUser,
    isSuccessPlans,
    unOrderedPlans,
  ]);

  return {
    planLocations,
    isFetchingPlans,
  };
};

export default useGetPlansWithLocations;
