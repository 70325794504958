import Constants from 'constants/index';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { selectFilters } from '@/store/teamMembersFilter/selector';

const useGetStatusLabels = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { search } = location;
  const isCompliance = search.includes('compliancePlan');

  const currentFilters = useSelector(selectFilters);
  const statusLabels = !isCompliance
    ? {
        [Constants.TRAINING_PLANS.COMPLETED]: {
          translationString: t('TrainingPlans.statusOptions.completed'),
          value: !!(currentFilters as string[]).includes(
            Constants.TRAINING_PLANS.COMPLETED,
          ),
        },
        [Constants.TRAINING_PLANS.NOT_COMPLETED]: {
          translationString: t('TrainingPlans.statusOptions.notCompleted'),
          value: !!(currentFilters as string[]).includes(
            Constants.TRAINING_PLANS.NOT_COMPLETED,
          ),
        },
      }
    : {
        [Constants.LEARN_UPON_TRAINING_PLANS.COMPLETED]: {
          translationString: t('TrainingPlans.statusOptions.completed'),
          value: !!(currentFilters as string[]).includes(
            Constants.LEARN_UPON_TRAINING_PLANS.COMPLETED,
          ),
        },
        [Constants.LEARN_UPON_TRAINING_PLANS.NOT_COMPLETED]: {
          translationString: t('TrainingPlans.statusOptions.notCompleted'),
          value: !!(currentFilters as string[]).includes(
            Constants.LEARN_UPON_TRAINING_PLANS.NOT_COMPLETED,
          ),
        },
      };
  return statusLabels;
};

export default useGetStatusLabels;
