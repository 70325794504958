import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Typography } from 'cfa-react-components';
import { OperatorDTO } from '@cfacorp-pathway/xp-api-typescript-client';
import RadioButtonList from '@/components/RadioButton/RadioButtonList';
import StepWizardPopUp from '@/components/popups/StepWizardPopUp';

interface CopyTemplatePopupProps {
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  operators: OperatorDTO[];
  planName: string;
  submitHandler: ({
    assignedOperator,
    name,
  }: {
    assignedOperator: OperatorDTO;
    name: string;
  }) => void;
  reopenTemplates: () => void;
}

const CopyTemplatePopup: React.FC<CopyTemplatePopupProps> = ({
  isOpen,
  setOpen,
  operators,
  planName,
  reopenTemplates,
  submitHandler,
}) => {
  const ref = useRef<HTMLInputElement>();
  const isMultiOperator = operators?.length > 1;
  const initialStep = isMultiOperator ? 1 : 2;
  const [operator, setOperator] = useState<OperatorDTO>();
  const [operatorSelected, setOperatorSelected] = useState(false);
  const { t } = useTranslation();
  const [stepIndex, setStepIndex] = useState(initialStep);
  const [stepWizard, setStepWizard] = useState<{
    nextStep: () => void;
    previousStep: () => void;
  }>();
  const [input, setInput] = useState('');
  const operatorsNameArray = operators.flat().map(({ name }) => name);

  useEffect(() => {
    if (stepIndex === 2) {
      ref?.current?.focus();
    }
  }, [stepIndex]);

  useEffect(() => {
    if (operators.length) {
      if (operators.length === 1) {
        setOperator(operators[0]);
      }
    }
  }, [operators]);

  useEffect(() => {
    if (isOpen) {
      setInput(`${planName} ${t('TrainingPlans.duplicatePlanInputCopy')}`);
    } else {
      if (isMultiOperator) {
        setOperator({});
      }
      setStepIndex(initialStep);
      setOperatorSelected(false);
    }
  }, [initialStep, isMultiOperator, isOpen, planName, stepIndex, t]);

  const closePopup = () => {
    setStepIndex(initialStep);
    setOpen(false);
  };

  const selectOperator = (operatorName: string) => {
    const selectedOperator = operators.find(
      ({ name }) => name === operatorName,
    );
    setOperator(selectedOperator);
    setOperatorSelected(true);
  };

  const Step1 = () => (
    <>
      <DialogHeader variant="h3">
        {t('TrainingPlans.addPlanMenu.chooseOperator')}
      </DialogHeader>
      <RadioButtonList
        defaultValue={''}
        listItems={operatorsNameArray as string[]}
        name={'operatorList'}
        setValue={selectOperator}
      />
    </>
  );

  const Step2 = () => (
    <>
      <DialogHeader variant="h3">
        {t('Generic.giveName', { object: t('Generic.plan') })}
      </DialogHeader>
      <input
        autoFocus
        className={'form-control'}
        name={'input'}
        onChange={e => setInput(e.target.value)}
        onKeyDown={e =>
          e.key === 'Enter' &&
          submitHandler({
            assignedOperator: operator as OperatorDTO,
            name: input,
          })
        }
        placeholder={t('TrainingPlans.planNamePlaceholder')}
        // @ts-ignore
        ref={ref}
        value={input}
      />
      {!input.length && (
        <span className="error-w-icon">{`${t('Generic.planName')} ${t(
          'TrainingPlans.requiredValue',
        )}`}</span>
      )}
    </>
  );

  const stepsData = {
    1: {
      labelButtonDeny: t('Button.cancel'),
      labelButtonNext: t('Button.next'),
      onClickBack: () => {
        closePopup();
        reopenTemplates();
      },
      onClickNext: () => {
        stepWizard && stepWizard.nextStep();
      },
      isDisabledButtonNext: !operatorSelected,
      ContentData: Step1,
    },
    2: {
      labelButtonDeny: isMultiOperator ? t('Button.back') : t('Button.cancel'),
      labelButtonNext: t('Button.create'),
      onClickBack: () => {
        isMultiOperator
          ? stepWizard && stepWizard.previousStep()
          : closePopup();
      },
      onClickNext: () => {
        submitHandler({
          assignedOperator: operator as OperatorDTO,
          name: input,
        });
      },
      isDisabledButtonNext: !input,
      ContentData: Step2,
    },
  };

  // @ts-ignore
  const step = stepsData[stepIndex];

  return (
    <StepWizardPopUp
      closePopup={closePopup}
      initialStep={initialStep}
      open={isOpen}
      setStepIndex={setStepIndex}
      setStepWizard={setStepWizard}
      step={step}
      steps={stepsData}
    />
  );
};

const DialogHeader = styled(Typography)`
  margin-bottom: 16px;
`;

export default CopyTemplatePopup;
