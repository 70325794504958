import Constants from 'constants/index';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  selectUsersHighestPermissionLevel,
  selectUserCountry,
} from 'store/user/selectors';
import { selectSubcategoryReducerState } from 'store/subcategory/selectors';
import { AccordionItem, Accordion } from 'cfa-react-components';
import { DocumentDTO } from '@cfacorp-pathway/xp-api-typescript-client';
import ConfirmationModals from './ConfirmationModals';
import Header from './Header';
import SubtitleDragAndDrop from './Subtitle';
import AddSection from './AddSection';
import {
  IntlCategoryDTO,
  IntlSubcategoryDTO,
} from '@/services/content-api-types';
import { LanguageObject } from '@/types/types';
import EditSection from './EditSection';

interface SubcategoryAccordionProps {
  adminCategoryId: null | string;
  cancelEditSection: boolean;
  category: IntlCategoryDTO;
  countryName: string;
  handleEditSection: ({
    index,
    language,
    name,
    subtitleIndex,
  }: {
    index: any;
    language: string;
    name: LanguageObject | string;
    subtitleIndex: any;
  }) => void;
  handleSubcategoryVisibility: (subcategory: string) => void;
  index: any;
  isAddProcedurePopUp?: boolean;
  isCanada: boolean;
  isEditingSubcategory: boolean;
  onSaveSubtitle: (
    pendingSectionMutation: { name: LanguageObject },
    index: string,
  ) => void;
  onSaveSubtitles: (updatedSubtitles: IntlSubcategoryDTO[], index: any) => void;
  onShowToast: (name: string) => void;
  openInNewTab?: boolean;
  refetchCategories: () => void;
  RenderDocumentEndContent?: () => void;
  setIsEditingSubcategory: (isEditing: true) => void;
  setSubcategory: (isSubcategory?: boolean) => void;
  showAdminFunctionality: boolean;
  subcategory: { name: LanguageObject; subtitles: IntlSubcategoryDTO[] };
  subcategoryIsVisible: boolean;
  triggerCancelEditSectionState: () => void;
  triggerEnableCancelButton: () => void;
}

export interface SubtitleProps {
  index: any;
  name: LanguageObject;
  documents?: DocumentDTO[];
  enabled?: boolean;
  visibleDocuments?: DocumentDTO[];
}

export const SubcategoryAccordion: React.FC<SubcategoryAccordionProps> = ({
  adminCategoryId,
  cancelEditSection,
  category,
  countryName,
  handleEditSection,
  handleSubcategoryVisibility,
  index,
  isAddProcedurePopUp,
  isCanada,
  isEditingSubcategory,
  onSaveSubtitle,
  onSaveSubtitles,
  onShowToast,
  openInNewTab,
  refetchCategories,
  RenderDocumentEndContent,
  setIsEditingSubcategory,
  setSubcategory,
  showAdminFunctionality,
  subcategory,
  subcategoryIsVisible = true,
  triggerCancelEditSectionState,
  triggerEnableCancelButton,
}) => {
  const { name: subcategoryName, subtitles } = subcategory;
  const selectedSubcategoryId = useSelector(selectSubcategoryReducerState);
  const selectedCountry: any = useSelector(selectUserCountry);
  const [showToggleSubcategoryVisibility, setShowToggleSubcategoryVisibility] =
    useState(false);
  const [showToggleResourceVisibility, setShowToggleResourceVisibility] =
    useState(false);
  const [showDeleteResource, setShowDeleteResource] = useState(false);
  const [showDeleteSubcategory, setShowDeleteSubcategory] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [editSection, setEditSection] = useState(false);
  const initialAddSectionState = {
    name: { en: '', es: '' },
  };
  const [pendingSectionMutation, setPendingSectionMutation] = useState(
    initialAddSectionState,
  );
  const [mutatingSubcategorySection, setMutatingSubcategorySection] =
    useState(false);
  const [addSectionFormIsValid, setAddSectionFormIsValid] = useState(false);
  const [subtitle, setSubtitle] = useState<SubtitleProps>();

  let userIsAdmin = false;
  const userPermissionLevel = useSelector(selectUsersHighestPermissionLevel);
  if (showAdminFunctionality) {
    userIsAdmin = Constants.USER_PERMISSIONS.ADMIN === userPermissionLevel;
  }

  useEffect(() => {
    if (isCanada && pendingSectionMutation.name?.en.length) {
      setAddSectionFormIsValid(true);
    } else if (
      pendingSectionMutation.name?.en.length &&
      pendingSectionMutation.name?.es.length
    ) {
      setAddSectionFormIsValid(true);
    } else {
      setAddSectionFormIsValid(false);
    }
  }, [pendingSectionMutation, selectedCountry.LABEL_KEY, isCanada]);

  useEffect(() => {
    setPendingSectionMutation({
      name: { en: '', es: '' },
    });
    setMutatingSubcategorySection(false);
  }, [selectedSubcategoryId, setMutatingSubcategorySection]);

  useEffect(() => {
    if (cancelEditSection) {
      setEditSection(false);
      triggerCancelEditSectionState();
    }
  }, [cancelEditSection, triggerCancelEditSectionState]);

  const onHandleDeleteSection = (idx: any, name: LanguageObject) => {
    setSubtitle({
      index: idx,
      name,
    });
    setShowDeleteSection(true);
  };

  const onHandleEditSection = (
    idx: string,
    name: LanguageObject,
    documents: DocumentDTO[],
    visibleDocuments: DocumentDTO[],
  ) => {
    setEditSection(true);
    setSubtitle({
      index: idx,
      name,
      documents,
      visibleDocuments,
    });
  };

  const handleAddSectionCancel = () => {
    setMutatingSubcategorySection(false);
    setPendingSectionMutation(initialAddSectionState);
  };

  const handleAddSection = () => {
    setPendingSectionMutation(initialAddSectionState);
    setMutatingSubcategorySection(true);
  };

  const onAddSection = () => {
    setSubtitle({
      ...subtitle,
      documents: [],
      visibleDocuments: [],
    } as SubtitleProps);
    handleAddSection();
  };

  return (
    <>
      <Accordion
        data-testid={`Subcategory${index}`}
        defaultActiveItemKey={null}
        elevation={1}
        key={index.toString()}
        onClick={() =>
          (isEditingSubcategory &&
            selectedSubcategoryId.id !== index &&
            !isAddProcedurePopUp) ||
          (!isEditingSubcategory && !isAddProcedurePopUp)
            ? setSubcategory()
            : null
        }
        variant="surface"
      >
        {/** @ts-ignore */}
        <AccordionItem
          data-testid={`SubTitle${index}`}
          header={
            <Header
              countryName={countryName}
              index={index}
              isEditingSubcategory={isEditingSubcategory}
              selectedSubcategoryId={selectedSubcategoryId?.id}
              setIsEditingSubcategory={setIsEditingSubcategory}
              setShowDeleteSubcategory={setShowDeleteSubcategory}
              setShowToggleSubcategoryVisibility={
                setShowToggleSubcategoryVisibility
              }
              setSubcategory={setSubcategory}
              showAdminFunctionality={showAdminFunctionality}
              subcategoryIsVisible={subcategoryIsVisible}
              subcategoryName={subcategoryName}
              triggerEnableCancelButton={triggerEnableCancelButton}
              userIsAdmin={userIsAdmin}
            />
          }
          onClick={e => e.stopPropagation()}
        >
          <SubtitleDragAndDrop
            adminCategoryId={adminCategoryId!}
            editSection={editSection}
            index={index}
            onHandleDeleteSection={onHandleDeleteSection}
            onHandleEditSection={({
              index: idx,
              name,
              documents,
              visibleDocuments,
            }: SubtitleProps) =>
              onHandleEditSection(
                idx,
                name,
                documents as DocumentDTO[],
                visibleDocuments as DocumentDTO[],
              )
            }
            onSaveSubtitles={onSaveSubtitles}
            openInNewTab={openInNewTab!}
            RenderDocumentEndContent={RenderDocumentEndContent!}
            selectedCountry={selectedCountry.id}
            setShowDeleteResource={setShowDeleteResource}
            setShowToggleResourceVisibility={setShowToggleResourceVisibility}
            showAdminFunctionality={showAdminFunctionality}
            subtitles={subtitles}
            triggerEnableCancelButton={triggerEnableCancelButton}
            userIsAdmin={userIsAdmin}
          />
          {!!userIsAdmin && !!showAdminFunctionality && (
            <>
              {!mutatingSubcategorySection && !editSection ? (
                <AddSection onAddSection={onAddSection} />
              ) : (
                <EditSection
                  addSectionFormIsValid={addSectionFormIsValid}
                  adminCategoryId={adminCategoryId!}
                  countryName={countryName}
                  editSection={editSection}
                  handleAddSectionCancel={handleAddSectionCancel}
                  handleEditSection={handleEditSection}
                  index={index}
                  onSaveSubtitle={onSaveSubtitle}
                  openInNewTab={openInNewTab!}
                  RenderDocumentEndContent={RenderDocumentEndContent!}
                  pendingSectionMutation={pendingSectionMutation}
                  setEditSection={setEditSection}
                  setMutatingSubcategorySection={setMutatingSubcategorySection}
                  setPendingSectionMutation={setPendingSectionMutation}
                  setShowDeleteResource={setShowDeleteResource}
                  setShowToggleResource={setShowToggleResourceVisibility}
                  setSubtitle={setSubtitle}
                  subtitle={subtitle!}
                />
              )}
            </>
          )}
        </AccordionItem>
      </Accordion>
      <ConfirmationModals
        category={category}
        countryName={countryName}
        editSection={editSection}
        handleSubcategoryVisibility={handleSubcategoryVisibility}
        index={index}
        onShowToast={onShowToast}
        refetchCategories={refetchCategories}
        setShowDeleteResource={setShowDeleteResource}
        setShowDeleteSection={setShowDeleteSection}
        setShowDeleteSubcategory={setShowDeleteSubcategory}
        setShowToggleResourceVisibility={setShowToggleResourceVisibility}
        setShowToggleSubcategoryVisibility={setShowToggleSubcategoryVisibility}
        setSubtitle={setSubtitle}
        showDeleteResource={showDeleteResource}
        showDeleteSection={showDeleteSection}
        showDeleteSubcategory={showDeleteSubcategory}
        showToggleResourceVisibility={showToggleResourceVisibility}
        showToggleSubcategoryVisibility={showToggleSubcategoryVisibility}
        subcategory={subcategory}
        subcategoryIsVisible={subcategoryIsVisible}
        subtitle={subtitle!}
      />
    </>
  );
};
