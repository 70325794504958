import Constants from 'constants/index';
import toPairs from 'lodash/toPairs';
import { Location } from 'history';

export const encodeQueryString = (pairs: object) =>
  toPairs(pairs)
    .map(
      ([key, value]: string[]) =>
        encodeURIComponent(key!) + '=' + encodeURIComponent(value!),
    )
    .join('&');

export const getKeyValuePairsFromHash = (location: { hash: string }) => {
  const hash = location.hash || '';
  if (hash.length) {
    const hashString = hash.substr(1);
    const replaceChars = decodeURI(hashString)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"');
    try {
      return JSON.parse(`{"${replaceChars}"}`);
    } catch (e) {
      console.error('Error in getKeyValuePairsFromHash:', e);
      return {};
    }
  }
  return {};
};

export const objectToQueryString = (obj: { [key: string]: any }) =>
  Object.keys(obj)
    .map(key => [key, obj[key]].map(encodeURIComponent).join('='))
    .join('&');

interface LocationProps {
  pathname: string;
  state: {
    isCompliance: boolean;
  };
}

interface PathMatcherProps {
  location: Location;
  isAdminPath?: boolean;
  isAdminRootPath?: boolean;
  isCategoriesPath?: boolean;
  isCompliancePath?: boolean;
  isComplianceDocumentPath?: boolean;
  isDetailsPath?: boolean;
  isDocumentPath?: boolean;
  isExplorePath?: boolean;
  isLeadershipPath?: boolean;
  isLeadershipDocumentPath?: boolean;
  isReportsPath?: boolean;
  isReportsCompliancePlansPath?: boolean;
  isReportsPlansPath?: boolean;
  isReportsPlansViewPath?: boolean;
  isReportsTeamMembersPath?: boolean;
  isReportsTeamMembersViewPath?: boolean;
  isReportsTeamMemberProgressViewPath?: boolean;
  isRootPath?: boolean;
  isSearchPath?: boolean;
  isSettingsPath?: boolean;
  isSignalPath?: boolean;
  isTeamMemberPlan?: boolean;
  isTrainingAssignedPath?: boolean;
  isTrainingBuildPath?: boolean;
  isTrainingBuildQuizzesPath?: boolean;
  isTrainingEditQuizzesPath?: boolean;
  isTrainingModePath?: boolean;
  isTrainingMyPlansPath?: boolean;
  isTrainingPath?: boolean;
  isTrainingPlanEditPath?: boolean;
  isTrainingPlanPath?: boolean;
  isTrainingQuizzesPath?: boolean;
  isTrainingTeamMemberProgressPath?: boolean;
  isTrainingTeamMembersPath?: boolean;
  isTrainingTeamPath?: boolean;
  isTrainingToCompletePath?: boolean;
}

export const isCurrentPath = ({
  location,
  isAdminPath,
  isAdminRootPath,
  isCategoriesPath,
  isCompliancePath,
  isComplianceDocumentPath,
  isDetailsPath,
  isDocumentPath,
  isExplorePath,
  isLeadershipPath,
  isLeadershipDocumentPath,
  isReportsPath,
  isReportsCompliancePlansPath,
  isReportsPlansPath,
  isReportsPlansViewPath,
  isReportsTeamMembersPath,
  isReportsTeamMembersViewPath,
  isReportsTeamMemberProgressViewPath,
  isRootPath,
  isSearchPath,
  isSettingsPath,
  isSignalPath,
  isTeamMemberPlan,
  isTrainingAssignedPath,
  isTrainingBuildPath,
  isTrainingBuildQuizzesPath,
  isTrainingEditQuizzesPath,
  isTrainingModePath,
  isTrainingMyPlansPath,
  isTrainingPath,
  isTrainingPlanEditPath,
  isTrainingPlanPath,
  isTrainingQuizzesPath,
  isTrainingTeamMemberProgressPath,
  isTrainingTeamMembersPath,
  isTrainingTeamPath,
  isTrainingToCompletePath,
}: PathMatcherProps): boolean => {
  const currPath = location.pathname.split('/').filter((p: string) => p) ?? [];
  const routePaths = Constants.ROUTE_PATH_NAMES;

  if (isCategoriesPath) {
    return currPath[0] === routePaths.CATEGORY_PATH_NAME;
  } else if (isCompliancePath) {
    return currPath[0] === routePaths.COMPLIANCE_PATH_NAME;
  } else if (isLeadershipPath) {
    return currPath[0] === routePaths.LEADERSHIP_PATH_NAME;
  } else if (isSignalPath) {
    return currPath[0] === routePaths.HEADLESS_PATH_NAME;
  } else if (isTrainingPath) {
    return currPath[0] === routePaths.TRAINING_PATH_NAME;
  } else if (isTrainingBuildPath) {
    return !!currPath.length && currPath[1] === routePaths.BUILD_PATH_NAME;
  } else if (isTrainingModePath) {
    return (
      !!currPath.length && currPath[1] === routePaths.TRAINING_MODE_PATH_NAME
    );
  } else if (isTrainingTeamPath) {
    return !!currPath.length && currPath[1] === routePaths.TEAM_PATH_NAME;
  } else if (isTrainingBuildQuizzesPath) {
    return (
      !!currPath.length &&
      currPath[1] === routePaths.QUIZZES_PATH_NAME &&
      currPath[2] === routePaths.BUILD_PATH_NAME
    );
  } else if (isTrainingEditQuizzesPath) {
    return !!currPath.length && currPath[1] === routePaths.QUIZ_PATH_NAME;
  } else if (isTrainingQuizzesPath) {
    return !!currPath.length && currPath[1] === routePaths.QUIZZES_PATH_NAME;
  } else if (isTeamMemberPlan) {
    return (
      currPath.length >= 4 && currPath[1] === routePaths.TEAM_MEMBERS_PATH_NAME
    );
  } else if (isTrainingTeamMembersPath) {
    return (
      currPath.length === 3 && currPath[2] === routePaths.TEAM_MEMBERS_PATH_NAME
    );
  } else if (isTrainingTeamMemberProgressPath) {
    return (
      currPath.length === 5 &&
      currPath[0] === routePaths.TRAINING_PATH_NAME &&
      currPath[2] === routePaths.TEAM_MEMBERS_PATH_NAME
    );
  } else if (isTrainingMyPlansPath) {
    return (
      currPath[0] === routePaths.TRAINING_PATH_NAME && currPath.length === 1
    );
  } else if (isTrainingAssignedPath) {
    return !!currPath.length && currPath[1] === routePaths.ASSIGNED_PATH_NAME;
  } else if (isTrainingToCompletePath) {
    return (
      !!currPath.length &&
      currPath[1] === routePaths.ASSIGNED_PATH_NAME &&
      currPath[2] === routePaths.TO_COMPLETE_PATH_NAME
    );
  } else if (isTrainingPlanEditPath) {
    return (
      currPath[0] === routePaths.TRAINING_PATH_NAME &&
      currPath[1] === routePaths.PLAN_PATH_NAME
    );
  } else if (isReportsPath) {
    return currPath[0] === routePaths.REPORTS_PATH_NAME;
  } else if (isReportsCompliancePlansPath) {
    return !!currPath.length && currPath[1] === routePaths.COMPLIANCE_PATH_NAME;
  } else if (isReportsPlansPath) {
    return !!currPath.length && currPath[1] === routePaths.PLANS_PATH_NAME;
  } else if (isReportsPlansViewPath) {
    return (
      !!currPath.length &&
      currPath[0] === routePaths.REPORTS_PATH_NAME &&
      currPath[1] === routePaths.PLANS_PATH_NAME &&
      currPath[2] === routePaths.PROGRESS_PATH_NAME
    );
  } else if (isReportsTeamMembersPath) {
    return (
      !!currPath.length && currPath[1] === routePaths.TEAM_MEMBERS_PATH_NAME
    );
  } else if (isReportsTeamMembersViewPath) {
    return (
      !!currPath.length &&
      currPath[0] === routePaths.REPORTS_PATH_NAME &&
      currPath[1] === routePaths.TEAM_MEMBERS_PATH_NAME &&
      currPath[2] === routePaths.PROGRESS_PATH_NAME
    );
  } else if (isReportsTeamMemberProgressViewPath) {
    return (
      !!currPath.length &&
      currPath[0] === routePaths.REPORTS_PATH_NAME &&
      currPath[2] === routePaths.TEAM_MEMBERS_PATH_NAME &&
      currPath[3] === routePaths.PROGRESS_PATH_NAME
    );
  } else if (isSettingsPath) {
    return currPath[0] === routePaths.PROFILE_PATH_NAME;
  } else if (isComplianceDocumentPath) {
    return (
      currPath.includes(routePaths.DOCUMENT_PATH_NAME) &&
      currPath.includes(routePaths.COMPLIANCE_PATH_NAME)
    );
  } else if (isLeadershipDocumentPath) {
    return (
      currPath.includes(routePaths.DOCUMENT_PATH_NAME) &&
      currPath[0] === routePaths.LEADERSHIP_PATH_NAME
    );
  } else if (isDocumentPath) {
    return currPath.includes(routePaths.DOCUMENT_PATH_NAME);
  } else if (isExplorePath) {
    return (
      !currPath.length ||
      currPath[0] === routePaths.CATEGORY_PATH_NAME ||
      (currPath[0] === routePaths.DOCUMENT_PATH_NAME &&
        !(location as LocationProps).state?.isCompliance)
    );
  } else if (isDetailsPath) {
    return currPath[0] === routePaths.DOCUMENT_PATH_NAME;
  } else if (isSearchPath) {
    return currPath[0] === routePaths.SEARCH_PATH_NAME;
  } else if (isRootPath) {
    return !currPath.length;
  } else if (isTrainingPlanPath) {
    return !!currPath.length && currPath[1] === routePaths.PLAN_PATH_NAME;
  } else if (isAdminPath) {
    return currPath[0] === routePaths.ADMIN_PATH_NAME;
  } else if (isAdminRootPath) {
    return currPath[0] === routePaths.ADMIN_PATH_NAME;
  }

  return false;
};

export const getClickableLink = (link: string) => {
  if (!link) {
    return;
  }

  return link.startsWith('http://') || link.startsWith('https://')
    ? link
    : `http://${link}`;
};

export const isAdminPath = (location: Location) =>
  isCurrentPath({
    location,
    isAdminPath: true,
  });
export const isCategoriesPath = (location: Location) =>
  isCurrentPath({
    location,
    isCategoriesPath: true,
  });
export const isCompliancePath = (location: Location) =>
  isCurrentPath({
    location,
    isCompliancePath: true,
  });
export const isComplianceDocumentPath = (location: Location) =>
  isCurrentPath({
    location,
    isComplianceDocumentPath: true,
  });
export const isDocumentPath = (location: Location) =>
  isCurrentPath({
    location,
    isDocumentPath: true,
  });
export const isLeadershipDocumentPath = (location: Location) =>
  isCurrentPath({
    location,
    isLeadershipDocumentPath: true,
  });
export const isExplorePath = (location: Location) =>
  isCurrentPath({
    location,
    isExplorePath: true,
  });
export const isLeadershipPath = (location: Location) =>
  isCurrentPath({
    location,
    isLeadershipPath: true,
  });
export const isReportsPath = (location: Location) =>
  isCurrentPath({
    location,
    isReportsPath: true,
  });
export const isReportsPlansPath = (location: Location) =>
  isCurrentPath({
    location,
    isReportsPlansPath: true,
  });
export const isReportsPlansViewPath = (location: Location) =>
  isCurrentPath({
    location,
    isReportsPlansViewPath: true,
  });
export const isReportsTeamMembersPath = (location: Location) =>
  isCurrentPath({
    location,
    isReportsTeamMembersPath: true,
  });
export const isReportsTeamMembersViewPath = (location: Location) =>
  isCurrentPath({
    location,
    isReportsTeamMembersViewPath: true,
  });
export const isReportsTeamMemberProgressViewPath = (location: Location) =>
  isCurrentPath({
    location,
    isReportsTeamMemberProgressViewPath: true,
  });
export const isReportsCompliancePlansPath = (location: Location) =>
  isCurrentPath({
    location,
    isReportsCompliancePlansPath: true,
  });
export const isSettingsPath = (location: Location) =>
  isCurrentPath({
    location,
    isSettingsPath: true,
  });
export const isRootPath = (location: Location) =>
  isCurrentPath({
    location,
    isRootPath: true,
  });
export const isTeamMemberPlan = (location: Location) =>
  isCurrentPath({
    location,
    isTeamMemberPlan: true,
  });
export const isTrainingAssignedPath = (location: Location) =>
  isCurrentPath({
    location,
    isTrainingAssignedPath: true,
  });
export const isTrainingBuildPath = (location: Location) =>
  isCurrentPath({
    location,
    isTrainingBuildPath: true,
  });
export const isTrainingModePath = (location: Location) =>
  isCurrentPath({
    location,
    isTrainingModePath: true,
  });
export const isSignalPath = (location: Location) =>
  isCurrentPath({
    location,
    isSignalPath: true,
  });
export const isTrainingMyPlansPath = (location: Location) =>
  isCurrentPath({
    location,
    isTrainingMyPlansPath: true,
  });
export const isTrainingPath = (location: Location) =>
  isCurrentPath({
    location,
    isTrainingPath: true,
  });
export const isTrainingPlanPath = (location: Location) =>
  isCurrentPath({
    location,
    isTrainingPlanPath: true,
  });
export const isTrainingTeamPath = (location: Location) =>
  isCurrentPath({
    location,
    isTrainingTeamPath: true,
  });
export const isTrainingQuizzesPath = (location: Location) =>
  isCurrentPath({
    location,
    isTrainingQuizzesPath: true,
  });
export const isTrainingBuildQuizzesPath = (location: Location) =>
  isCurrentPath({
    location,
    isTrainingBuildQuizzesPath: true,
  });
export const isTrainingEditQuizzesPath = (location: Location) =>
  isCurrentPath({
    location,
    isTrainingEditQuizzesPath: true,
  });
export const isTrainingTeamMembersPath = (location: Location) =>
  isCurrentPath({
    location,
    isTrainingTeamMembersPath: true,
  });
export const isTrainingTeamMemberProgressPath = (location: Location) =>
  isCurrentPath({
    location,
    isTrainingTeamMemberProgressPath: true,
  });
export const isTrainingToCompletePath = (location: Location) =>
  isCurrentPath({
    location,
    isTrainingToCompletePath: true,
  });
export const isTrainingPlanEditPath = (location: Location) =>
  isCurrentPath({
    location,
    isTrainingPlanEditPath: true,
  });
export const isSearchPath = (location: Location) =>
  isCurrentPath({
    location,
    isSearchPath: true,
  });
