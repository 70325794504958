import Constants from 'constants/index';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { UserChecklistResultDTO } from '@cfacorp-pathway/xp-api-typescript-client';
import FilterWeb from './FilterWeb';
import SortTeamMembers from './SortTeamMembers';
import NoMessage from '@/components/NoMessage/NoMessage';
import { Label } from '@/components/Cards/StickyFilterCard/CheckboxFilterSection';
import {
  selectFilters,
  selectPagination,
  selectSortedAndFilteredAndPaginatedTeamMembers,
} from '@/store/teamMembersFilter/selector';
import { loadMoreTeamMembers } from '@/store/teamMembersFilter/slice';
import { getNameFromLanguage } from '@/utils/language';
import { LanguageObject } from '@/types/types';
import { getTimeSpentOnPlan } from '@/utils/time';
import LoadMorePaginator from '@/components/LoadMorePaginator/LoadMorePaginator';
import TeamMemberCard from '@/components/PlanCards/TeamMemberCard';

interface TeamMembersListProps {
  assignedTeamMembersList: UserChecklistResultDTO | undefined;
  filterLocations: string[];
  isFoodSafety: boolean;
  refetchAssignedTeamMembers: () => void;
  statusLabels: Label;
  stepCount: number;
  isLicenseePlan?: boolean;
}

const TeamMembersList: React.FC<TeamMembersListProps> = ({
  assignedTeamMembersList,
  filterLocations,
  isFoodSafety,
  isLicenseePlan,
  refetchAssignedTeamMembers,
  statusLabels,
  stepCount,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const { id: planId } = params as { id: string };
  const currentFilters = useSelector(selectFilters);
  const { showing, total } = useSelector(selectPagination);
  const teamMembers = useSelector(
    selectSortedAndFilteredAndPaginatedTeamMembers,
  );
  const { search } = location;
  const isCompliance = search.includes('compliancePlan');

  const plan = useMemo(
    () => assignedTeamMembersList?.checklist ?? {},
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [assignedTeamMembersList?.checklist?.id],
  );

  return (
    <PlanCardList>
      <FilterWeb
        filterLocations={filterLocations}
        isLicenseePlan={isLicenseePlan}
        statusLabels={statusLabels}
      />
      <PlanCardsContainer>
        <SortTeamMembers statusLabels={statusLabels} />
        <PlanCardsList>
          {!!teamMembers?.length && (
            <>
              {teamMembers &&
                (teamMembers as any).map((member: any, index: number) => {
                  return (
                    <TeamMemberCard
                      completedStepCount={
                        assignedTeamMembersList?.status?.find(
                          userStatus => userStatus.userId === member.userId,
                        )?.stepsComplete || 0
                      }
                      completionDate={
                        isCompliance
                          ? member.completedDate
                          : member.completionDate
                      }
                      expirationDate={member.expirationDate}
                      finalScore={member.finalScore}
                      id={member.userId ?? ''}
                      isComplete={
                        member?.status === Constants.TRAINING_PLANS.COMPLETED ||
                        member?.status ===
                          Constants.LEARN_UPON_TRAINING_PLANS.COMPLETED ||
                        member?.status ===
                          Constants.LEARN_UPON_TRAINING_PLANS.PASSED
                      }
                      isCompliance={isCompliance}
                      isFailed={
                        member?.status ===
                        Constants.LEARN_UPON_TRAINING_PLANS.FAILED
                      }
                      isFoodSafety={isFoodSafety}
                      isReportsPlansView={true}
                      key={index}
                      name={member.name ?? ''}
                      onRefetch={() => {
                        refetchAssignedTeamMembers();
                      }}
                      percentComplete={member?.percentComplete}
                      planId={planId}
                      planName={
                        getNameFromLanguage(plan?.name as LanguageObject) ?? ''
                      }
                      selectedDueDate={new Date(member.dueDate).getTime()}
                      stepCount={stepCount}
                      timeSpentOnPlan={
                        getTimeSpentOnPlan(member, isCompliance)!
                      }
                    />
                  );
                })}
              <LoadMorePaginator
                onClick={() => dispatch(loadMoreTeamMembers())}
                showing={showing}
                showingText={t('TrainingPlans.showingXOfYTeamMembers', {
                  showing: showing ?? 0,
                  total: total ?? 0,
                })}
                total={total ?? 0}
              />
            </>
          )}
          {!teamMembers?.length && !currentFilters?.length && (
            <NoMessage message={t('TrainingPlans.noTeamMembersAssigned')} />
          )}
          {!!currentFilters?.length && !total && (
            <NoMessage message={t('TrainingPlans.noTeamMembersResults')} />
          )}
        </PlanCardsList>
      </PlanCardsContainer>
    </PlanCardList>
  );
};

const PlanCardList = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  position: relative;
  flex-grow: 1;
  max-width: 100%;
`;

const PlanCardsContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow: hidden;
`;

const PlanCardsList = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  max-width: 100%;
`;

export default TeamMembersList;
