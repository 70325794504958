import Constants from 'constants/index';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Document,
  SearchResponse,
} from '@cfacorp-pathway/xp-api-typescript-client';

export interface ReduxSearch {
  currentPage: number;
  hasNextPage: boolean;
  numFound: number;
  result: SearchResponse | null;
  pageSize: number;
  searchResults: Document[];
  showingResults: number;
  terms: string[];
  query: string;
  queryId: string;
}

export const slice = createSlice({
  name: 'search',
  initialState: {
    currentPage: 1,
    hasNextPage: false,
    numFound: 0,
    pageSize: Constants.DEFAULT_PAGING_SIZE,
    result: null,
    searchResults: [],
    showingResults: 0,
    terms: [],
    query: '',
    queryId: '',
  } as ReduxSearch,
  reducers: {
    setQueryId: (state, action) => {
      state.queryId = action.payload;
    },
    setStoredQuery: (state, action) => {
      state.query = action.payload;
    },
    addSearchResults: (state, action) => {
      state.searchResults = [...state.searchResults, ...action.payload];
    },
    removeSearchTerm: (state, action) => {
      state.terms = state.terms.filter(st => st !== action.payload);
    },
    resetSearchState: state => {
      state.currentPage = 1;
      state.hasNextPage = false;
      state.numFound = 0;
      state.pageSize = Constants.DEFAULT_PAGING_SIZE;
      state.result = null;
      state.searchResults = [];
      state.showingResults = 0;
      state.query = '';
      state.queryId = '';
    },
    saveSearchTerm: (state, action) => {
      const termExistsIndex = state.terms.findIndex(
        term => action.payload === term,
      );
      if (termExistsIndex >= 0) {
        if (termExistsIndex === 0) {
          return;
        } else {
          const termsWithExistingTermRemoved = [...state.terms].filter(
            (_term, idx) => idx !== termExistsIndex,
          );
          state.terms = [action.payload, ...termsWithExistingTermRemoved];
        }
      } else {
        state.terms = [action.payload, ...state.terms].slice(0, 5);
      }
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setHasNextPage: (state, action) => {
      state.hasNextPage = action.payload;
    },
    setNumFound: (state, action) => {
      state.numFound = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setResult: (state, action) => {
      state.result = action.payload;
    },
    setSearchResults: (
      state,
      action: PayloadAction<Document[] | undefined>,
    ) => {
      state.searchResults = action.payload ?? [];
    },
    setShowingResults: (state, action) => {
      state.showingResults = action.payload;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  setQueryId,
  setStoredQuery,
  setSearchResults,
  addSearchResults,
  setResult,
  setHasNextPage,
  setNumFound,
  setCurrentPage,
  setPageSize,
  resetSearchState,
  setShowingResults,
  saveSearchTerm,
  removeSearchTerm,
} = slice.actions;
export default slice.reducer;
