import { isAccessDeniedError } from 'utils/error';
import { LoginCallback } from '@okta/okta-react';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import ConfirmationModal from 'components/popups/ConfirmationModal';
import GenericError from '../components/Error/GenericError';
import Constants from '@/constants';
import { useAmplitudeTrack } from '@/amplitude/useAmplitude';
import { CustomError } from '@/types';

const CustomLoginCallback: React.FC = () => {
  const track = useAmplitudeTrack();

  useEffect(() => {
    if (track !== null) {
      track('LoginSuccess', {});
    }
  }, [track]);

  return <LoginCallback errorComponent={RenderError} />;
};

const RenderError: React.FC<{ error: Error }> = ({ error }) => {
  const { t } = useTranslation();

  return isAccessDeniedError(error as CustomError) ? (
    <ConfirmationModal
      bodyText={t('UserNotAssignedToAppError.errorParagraph')}
      headerText={t('GenericError.unauthorized')}
      isError={true}
      isOpen={true}
      onClose={() => (window.location.href = Constants.CFA_HOME_URL!)}
      primaryButtonHandler={() =>
        (window.location.href = Constants.CFA_HOME_URL!)
      }
      primaryButtonText={t('Button.returnToCfa')}
    />
  ) : (
    <GenericError />
  );
};

export default CustomLoginCallback;
