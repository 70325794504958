import Constants from 'constants/index';
import React from 'react';
import { ChecklistDTO } from '@cfacorp-pathway/xp-api-typescript-client';
import PlanCard from '../../PlanCard';
import PlanCardMenu from './PlanCardMenu';
import { getNameFromLanguage } from '@/utils/language';
import { mapCategoryToAliasTranslation } from '@/utils/categoryUtils';
import { LanguageObject } from '@/types';
import PlanCardContent from '../../PlanCardContent/PlanCardContent';
import PlanCardLabel from '../../PlanCardComponents/PlanCardLabel';
import PlanCardTitle from '../../PlanCardComponents/PlanCardTitle';
import PlanCardSubHeader from '../../PlanCardComponents/PlanCardSubHeader';
import PlanCardDate from '../../PlanCardComponents/PlanCardDate';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

interface ManagedPlansCardProps {
  assignedUsers: number;
  assignPlan: (plan: ChecklistDTO) => void;
  completedUsers: number;
  deletePlan: (plan: ChecklistDTO) => void;
  duplicatePlan: (plan: ChecklistDTO) => void;
  editTranslations: (plan: ChecklistDTO) => void;
  plan: ChecklistDTO;
  printReport: (plan: ChecklistDTO) => void;
  renamePlan: (plan: ChecklistDTO) => void;
  sendPlanToParent: (plan: ChecklistDTO) => void;
}

const ManagedPlansCard: React.FC<ManagedPlansCardProps> = ({
  assignedUsers,
  assignPlan,
  completedUsers,
  deletePlan,
  duplicatePlan,
  editTranslations,
  plan,
  printReport,
  renamePlan,
  sendPlanToParent,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const percentComplete = (completedUsers / assignedUsers) * 100;

  const navigateToEditPlan = () => {
    history.push(
      `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PLAN_PATH_NAME}/${plan?.id}`,
    );
  };

  return (
    !!plan && (
      <PlanCard
        buttons={
          <PlanCardMenu
            assignPlan={() => assignPlan(plan)}
            deletePlan={() => deletePlan(plan)}
            duplicatePlan={() => duplicatePlan(plan)}
            editTranslations={() => editTranslations(plan)}
            navigateToEditPlan={navigateToEditPlan}
            printReport={() => printReport(plan)}
            renamePlan={() => renamePlan(plan)}
            sendPlanToParent={() => sendPlanToParent(plan)}
          />
        }
        category={plan?.category}
        dataTestId={'TeamPlanCard'}
        link={`/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${plan?.id}/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}`}
        progress={percentComplete}
      >
        <PlanCardContent>
          <PlanCardLabel>
            {t(mapCategoryToAliasTranslation(plan?.category))}
          </PlanCardLabel>
          <PlanCardTitle>
            {getNameFromLanguage(plan?.name as LanguageObject)}
          </PlanCardTitle>
          {!!assignedUsers && completedUsers === assignedUsers ? (
            <PlanCardSubHeader className="success" dataTestId="PlanTaskCount">
              {t('Generic.completedByAll')}
            </PlanCardSubHeader>
          ) : (
            <PlanCardSubHeader dataTestId="PlanTaskCount">
              {`${completedUsers}/${assignedUsers} ${t(
                'Generic.teamMembersCompleted',
              )}`}
            </PlanCardSubHeader>
          )}
        </PlanCardContent>
        <PlanCardDate date={plan?.created!} />
      </PlanCard>
    )
  );
};

export default ManagedPlansCard;
