import Constants from 'constants/index';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { format as dateFnsFormat } from 'date-fns';
import { getCurrentLanguage } from 'i18n/language';
import locales from './locales';
import { isCurrentEnvironment } from '@/utils/environment';

const debug = isCurrentEnvironment(Constants.DEV);

export const initializeTranslation = async () => {
  if (!getCurrentLanguage()) {
    console.error(
      'Language must be initialized before initializing translation',
    );
    return;
  }

  const language = getCurrentLanguage();

  await i18n.use(initReactI18next).init({
    debug,
    lng: language,
    fallbackLng: Constants.LANGUAGE.ENGLISH_LANGUAGE_CODE,
    load: 'currentOnly',
    keySeparator: '.',
    interpolation: {
      escapeValue: false, // react already protects against XSS
      format: function (value, format) {
        if (format === 'locale-string' && value?.toLocaleString()) {
          return value.toLocaleString();
        } else if (format === 'date-full') {
          return dateFnsFormat(value, 'MM/dd/yyyy');
        } else if (format === 'date-long') {
          return dateFnsFormat(value, 'MMMM do, yyyy');
        } else if (format === 'date-voice') {
          return dateFnsFormat(value, 'MMMM do yyyy');
        }
        return value;
      },
    },
    // Note: We were using the http backend but ran into issues due to translation files being cached.
    // Dynamic webpack imports might be possible but Loren could not quickly solve so went with static for now.
    resources: locales,
    saveMissing: true,
    missingKeyHandler: function (lng, ns, key, fallbackValue) {
      console.warn('Missing Translation Key', lng, ns, key, fallbackValue);
    },
    missingInterpolationHandler: function (text, value) {
      console.warn('Missing Interpolation', text, value);
    },
  });
};
