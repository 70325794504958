import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Label } from '@/components/Cards/StickyFilterCard/CheckboxFilterSection';
import ClearFiltersHeader from '@/components/ClearFiltersHeader/ClearFiltersHeader';
import SortFilterHeader from '@/components/SortFilterHeader/SortFilterHeader';
import { useSortOptions } from '@/hooks/useSortOptions';
import {
  selectFilters,
  selectPagination,
  selectSort,
} from '@/store/teamMembersFilter/selector';
import {
  clearTeamMembersCheckboxFilters,
  removeTeamMembersFilter,
  setTeamMembersSort,
} from '@/store/teamMembersFilter/slice';

interface SortTeamMembersProps {
  statusLabels: Label;
}

const SortTeamMembers: React.FC<SortTeamMembersProps> = ({ statusLabels }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentFilters = useSelector(selectFilters);
  const { total } = useSelector(selectPagination);
  const sort = useSelector(selectSort);
  const sortOptions = useSortOptions({ reportsPlansView: true });

  return (
    <>
      <SortFilterHeader
        label={t('TrainingPlans.filtering.sortBy')}
        onChange={option => {
          dispatch(setTeamMembersSort({ sort: option?.value }));
        }}
        onClear={() => {
          dispatch(clearTeamMembersCheckboxFilters());
        }}
        options={sortOptions}
        showClear={false}
        showCompletedPlansOption={false}
        showMyCompletedPlansOnlySwitchValue={false}
        text={`${total ?? 0} ${t('Generic.teamMembers')}`}
        value={sortOptions?.find(option => option.value === sort)}
      />

      <ClearFiltersHeader
        aliases={statusLabels}
        clearAllFilters={() => {
          dispatch(clearTeamMembersCheckboxFilters());
        }}
        clearFilter={value => {
          dispatch(removeTeamMembersFilter({ filter: value }));
        }}
        filters={currentFilters}
      />
    </>
  );
};

export default SortTeamMembers;
