import styled from 'styled-components';
import { PopoverMenuItem } from 'cfa-react-components';
import React from 'react';

interface PopoverMenuButtonItemProps {
  dataTestId?: string;
  icon: React.ReactNode;
  isDestructive?: boolean;
  isDisabled?: boolean;
  isVisible?: boolean;
  onClick: () => void;
  text: string;
}

const PopoverMenuButtonItem: React.FC<PopoverMenuButtonItemProps> = ({
  dataTestId = 'PopoverMenuItem',
  icon,
  isDisabled,
  isDestructive,
  isVisible = true,
  onClick,
  text,
}) => {
  const handleClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onClick();
  };

  return (
    <>
      {isVisible && (
        <MenuItem
          $isDestructive={isDestructive}
          $isDisabled={isDisabled}
          data-testid={dataTestId}
          onClick={handleClick}
        >
          {icon}
          {text}
        </MenuItem>
      )}
    </>
  );
};

const MenuItem = styled(PopoverMenuItem)`
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 500;
  color: ${({ theme, $isDestructive, $isDisabled }) =>
    $isDestructive
      ? theme.semanticColors.error
      : $isDisabled
        ? theme.grayScale.gray2
        : theme.primaryPalette.navyBlue} !important;
  &:first-child {
    padding-top: 24px !important;
  }
  &:last-child {
    padding-bottom: 24px !important;
  }
`;

export default PopoverMenuButtonItem;
