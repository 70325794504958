import Constants from 'constants/index';
import { generateProgressText } from 'utils/generateProgressText';
import { getProgressBarText } from 'utils/generateProgressBarText';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Ribbon } from 'icons';
import {
  IconAward,
  IconCalendar,
  IconPrinter,
  IconRefresh,
  IconUserMinus,
} from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  useUpdateChecklistStatusMutation,
  useResetTrainingPlanMutation,
} from 'services/pathwayApi';
import { useSelector } from 'react-redux';
import { selectAllLocationsWithAtLeastLeaderPermissions } from 'store/user/selectors';
import PopoverMenuButton from 'components/PopoverMenuButton/PopoverMenuButton';
import PopoverMenuButtonItem from 'components/PopoverMenuButton/PopoverMenuButtonItem';
import {
  Card,
  CardContent,
  Tooltip,
  Typography,
  useBreakpoints,
  useMediaQuery,
} from 'cfa-react-components';
import ConfirmationModal from 'components/popups/ConfirmationModal';
import useBugsnagNotify from 'hooks/useBugsnagNotify';
import { ChecklistStatusUpdate } from '@cfacorp-pathway/xp-api-typescript-client';
import ProgressBar from '@/components/ProgressBar/ProgressBar';
import PlanCardSubHeader from '@/components/Cards/PlanCard/PlanCardComponents/PlanCardSubHeader';
import { FailedIconAlertTriangleFilled } from '@/components/Cards/PlanCard/PlanCards/MyPlansComplianceCard';

interface TeamMemberCardProps {
  completedStepCount: number;
  completionDate?: string;
  expirationDate?: string;
  finalScore?: number;
  id: string;
  isComplete: boolean;
  isCompliance?: boolean;
  isFailed?: boolean;
  isFoodSafety?: boolean;
  isReports?: boolean;
  isReportsPlansView?: boolean;
  name: string;
  onUpdateDueDate?: (id: string, name: string) => void;
  onPrintReport?: (id: string, name: string) => void;
  onRefetch?: () => void;
  onUnassign?: (id: string, name: string) => void;
  percentComplete?: number;
  planId?: string;
  planName?: string;
  selectedDueDate?: number;
  startDate?: string;
  stepCount: number;
  timeSpentOnPlan?: string;
}

const TeamMemberCard: React.FC<TeamMemberCardProps> = ({
  completedStepCount,
  completionDate,
  expirationDate,
  finalScore,
  id,
  isComplete,
  isCompliance,
  isFailed,
  isFoodSafety,
  isReports,
  isReportsPlansView,
  name,
  onPrintReport,
  onRefetch,
  onUnassign,
  onUpdateDueDate,
  percentComplete,
  planId,
  planName,
  selectedDueDate,
  startDate,
  stepCount,
  timeSpentOnPlan,
}) => {
  const { notifyBugsnag } = useBugsnagNotify();

  const { t } = useTranslation();
  const [updateChecklistStatus] = useUpdateChecklistStatusMutation();
  const [resetTrainingPlan] = useResetTrainingPlanMutation();
  const [showMarkCompletedPopup, setShowMarkCompletedPopup] = useState(false);
  const [showRestartTrainingPopup, setShowRestartTrainingPopup] =
    useState(false);
  const breakpoints = useBreakpoints();
  const isSmAndDown = useMediaQuery(breakpoints.down('sm'));

  const locationsWithAtLeastLeaderPermission = useSelector(
    selectAllLocationsWithAtLeastLeaderPermissions,
  );

  const handleUnassign = () => {
    onUnassign?.(id, name);
  };

  const handleUpdateDueDate = () => {
    onUpdateDueDate?.(id, name);
  };

  const handleMarkComplete = () => {
    setShowMarkCompletedPopup(true);
  };

  const onMarkCompleteCancel = () => {
    setShowMarkCompletedPopup(false);
  };

  const onMarkCompleteConfirm = () => {
    setShowMarkCompletedPopup(false);
    updateChecklistStatus({
      checklist: planId!,
      status: Constants.TRAINING_PLANS
        .COMPLETED as ChecklistStatusUpdate['status'],
      // @ts-ignore
      userIds: [id],
    })
      .unwrap()
      .then(() => {
        onRefetch?.();
      })
      .catch(err => {
        notifyBugsnag(err);
      });
  };

  const handleRestartTraining = () => {
    setShowRestartTrainingPopup(true);
  };

  const onRestartPlanConfirm = () => {
    setShowRestartTrainingPopup(false);
    resetTrainingPlan({
      checklistId: planId,
      userIds: [id],
    })
      .unwrap()
      .then(() => {
        onRefetch?.();
      })
      .catch(err => {
        notifyBugsnag(err);
      });
  };

  const onRestartPlanCancel = () => {
    setShowRestartTrainingPopup(false);
  };

  return (
    <>
      {isCompliance ? (
        <StyledCard>
          <Tooltip
            content={t('Reports.compliancePlanTooltip')}
            placement="bottom"
            showOnElementDelay={{
              showDelay: !isSmAndDown ? 500 : undefined,
            }}
          >
            <StyledCardContent>
              <StyledCardLeft>
                <StyledCardName
                  data-testid="PlanName"
                  fontWeight="bold"
                  variant="body1"
                >
                  {name}
                </StyledCardName>
                {!!isComplete && !!finalScore && (
                  <PlanCardSubHeader
                    className="success"
                    dataTestId="PlanTaskCount"
                  >
                    {generateProgressText({
                      finalScore: finalScore.toString(),
                      isCompliance,
                      isComplete,
                      isFailed,
                      isFoodSafety,
                      timeSpentOnPlan: timeSpentOnPlan!,
                    })}
                  </PlanCardSubHeader>
                )}
                {!!isComplete && !finalScore && (
                  <PlanCardSubHeader
                    className="success"
                    dataTestId="PlanTaskCount"
                  >
                    {generateProgressText({
                      isCompliance,
                      isComplete,
                      isFailed,
                      isFoodSafety,
                      timeSpentOnPlan: timeSpentOnPlan!,
                    })}
                  </PlanCardSubHeader>
                )}
                {!!isFailed && (
                  <PlanCardSubHeader
                    className="bold"
                    dataTestId="PlanTaskCount"
                  >
                    {generateProgressText({
                      completedStepCount,
                      finalScore: finalScore?.toString(),
                      isCompliance,
                      isComplete,
                      isFailed,
                      isFoodSafety,
                      timeSpentOnPlan: timeSpentOnPlan!,
                      stepCount,
                    })}
                  </PlanCardSubHeader>
                )}
                {!isComplete && !isFailed && (
                  <ProgressBar
                    current={percentComplete!}
                    isComplete={isComplete}
                    selectedDueDate={selectedDueDate}
                    showDate={true}
                    text={getProgressBarText({
                      isReports,
                      isComplete,
                      completedStepCount,
                      stepCount,
                      timeSpentOnPlan: timeSpentOnPlan!,
                      isCompliance,
                      percentComplete,
                    })}
                    total={isCompliance ? 100 : stepCount}
                  />
                )}
                {!!isComplete && (
                  <PlanCardSubHeader className="bold">
                    {expirationDate ? (
                      t('TrainingPlans.certificationExpires', {
                        expirationDate: new Date(
                          expirationDate,
                        ).toLocaleDateString(),
                      })
                    ) : (
                      <>
                        <FailedIconAlertTriangleFilled />
                        <span style={{ marginLeft: 4 }}>
                          {t('TrainingPlans.noExpirationDate')}
                        </span>
                      </>
                    )}
                  </PlanCardSubHeader>
                )}
              </StyledCardLeft>
              <StyledCardRight>
                {isComplete && (
                  <Tooltip
                    content={
                      <>
                        <strong>
                          {t('TrainingPlans.statusOptions.completed')}:
                        </strong>{' '}
                        {new Date(completionDate!).toLocaleDateString()}
                      </>
                    }
                    placement="top"
                    showOnElementEvents={['hover']}
                  >
                    <StyledRibbon />
                  </Tooltip>
                )}
                <PopoverMenuButton hideMenu={isReportsPlansView}>
                  {isCompliance ? (
                    <PopoverMenuButtonItem
                      icon={<IconUserMinus />}
                      onClick={handleUnassign}
                      text={t('Button.unassign')}
                    />
                  ) : (
                    <>
                      <PopoverMenuButtonItem
                        icon={<IconUserMinus />}
                        onClick={handleUnassign}
                        text={t('Button.unassign')}
                      />
                      {!isComplete && (
                        <PopoverMenuButtonItem
                          dataTestId="UpdateDueDate"
                          icon={<IconCalendar />}
                          onClick={handleUpdateDueDate}
                          text={t('Button.updateDueDate')}
                        />
                      )}
                      {!isComplete &&
                        !!locationsWithAtLeastLeaderPermission.length && (
                          <PopoverMenuButtonItem
                            dataTestId="MarkCompleted"
                            icon={<IconAward />}
                            onClick={() => handleMarkComplete()}
                            text={t('Button.markCompleted')}
                          />
                        )}
                      {(completedStepCount !== 0 || isComplete) &&
                        !!locationsWithAtLeastLeaderPermission.length && (
                          <PopoverMenuButtonItem
                            dataTestId="RestartTraining"
                            icon={<IconRefresh />}
                            onClick={() => handleRestartTraining()}
                            text={t('Button.restartTraining')}
                          />
                        )}
                    </>
                  )}
                </PopoverMenuButton>
              </StyledCardRight>
            </StyledCardContent>
          </Tooltip>
        </StyledCard>
      ) : (
        <Link
          to={
            isReports
              ? `/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PROGRESS_PATH_NAME}/${id}`
              : isReportsPlansView
                ? `/${Constants.ROUTE_PATH_NAMES.REPORTS_PATH_NAME}/${planId}/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PROGRESS_PATH_NAME}/${id}`
                : `/${Constants.ROUTE_PATH_NAMES.TRAINING_PATH_NAME}/${planId}/${Constants.ROUTE_PATH_NAMES.TEAM_MEMBERS_PATH_NAME}/${Constants.ROUTE_PATH_NAMES.PROGRESS_PATH_NAME}/${id}`
          }
        >
          <StyledCard>
            <StyledCardContent>
              <StyledCardLeft>
                <StyledCardName
                  data-testid="PlanName"
                  fontWeight="bold"
                  variant="body1"
                >
                  {name}
                </StyledCardName>
                {!!isComplete && isReports ? (
                  <>
                    <PlanCardSubHeader
                      className="success"
                      dataTestId="PlanTaskCount"
                    >
                      {generateProgressText({
                        completedStepCount,
                        isCompliance,
                        isComplete,
                        isFailed,
                        isFoodSafety,
                        timeSpentOnPlan: timeSpentOnPlan!,
                        stepCount,
                      })}
                    </PlanCardSubHeader>
                    {!!startDate && (
                      <PlanCardSubHeader dataTestId="PlanTaskCount">
                        {`${t('Reports.filtering.startDate')}: ${new Date(
                          startDate,
                        ).toLocaleDateString()}`}
                      </PlanCardSubHeader>
                    )}
                  </>
                ) : (
                  <ProgressBar
                    completionDate={completionDate}
                    current={completedStepCount}
                    isComplete={isComplete}
                    isReports={isReports}
                    selectedDueDate={selectedDueDate}
                    showDate={true}
                    startDate={startDate}
                    text={getProgressBarText({
                      isReports,
                      isComplete,
                      completedStepCount,
                      stepCount,
                      timeSpentOnPlan: timeSpentOnPlan!,
                      isCompliance,
                      percentComplete,
                    })}
                    total={isCompliance ? 100 : stepCount}
                  />
                )}
              </StyledCardLeft>
              <StyledCardRight>
                {!!isComplete && isReports && <StyledRibbon />}
                {isReports ? (
                  <StyledPrinterIcon
                    onClick={e => {
                      e.preventDefault();
                      onPrintReport?.(id, name);
                    }}
                  />
                ) : (
                  <PopoverMenuButton
                    dataTestId="TeamMemberCardMenu"
                    hideMenu={isReportsPlansView}
                  >
                    {isCompliance ? (
                      <PopoverMenuButtonItem
                        icon={<IconUserMinus />}
                        onClick={handleUnassign}
                        text={t('Button.unassign')}
                      />
                    ) : (
                      <>
                        <PopoverMenuButtonItem
                          dataTestId="UnassignTeamMember"
                          icon={<IconUserMinus />}
                          onClick={handleUnassign}
                          text={t('Button.unassign')}
                        />
                        {!isComplete && (
                          <PopoverMenuButtonItem
                            dataTestId="UpdateDueDate"
                            icon={<IconCalendar />}
                            onClick={handleUpdateDueDate}
                            text={t('Button.updateDueDate')}
                          />
                        )}
                        {!isComplete &&
                          !!locationsWithAtLeastLeaderPermission.length && (
                            <PopoverMenuButtonItem
                              dataTestId="MarkCompleted"
                              icon={<IconAward />}
                              onClick={() => handleMarkComplete()}
                              text={t('Button.markCompleted')}
                            />
                          )}
                        {(completedStepCount !== 0 || isComplete) &&
                          !!locationsWithAtLeastLeaderPermission.length && (
                            <PopoverMenuButtonItem
                              dataTestId="RestartTraining"
                              icon={<IconRefresh />}
                              onClick={() => handleRestartTraining()}
                              text={t('Button.restartTraining')}
                            />
                          )}
                      </>
                    )}
                  </PopoverMenuButton>
                )}
              </StyledCardRight>
            </StyledCardContent>
          </StyledCard>
        </Link>
      )}
      <ConfirmationModal
        bodyText={`${t('TrainingPlans.markCompleted1')} <b>${planName}</b> ${t(
          'TrainingPlans.markCompleted2',
        )}`}
        headerText={t('Generic.markCompleted')}
        isOpen={!!showMarkCompletedPopup}
        onClose={onMarkCompleteCancel}
        primaryButtonHandler={onMarkCompleteConfirm}
        primaryButtonText={t('Button.yes')}
        secondaryButtonHandler={onMarkCompleteCancel}
        secondaryButtonText={t('Button.cancel')}
      />

      <ConfirmationModal
        bodyText={`${t('TrainingPlans.restartPlan1')} <b>${planName}</b> ${t(
          'TrainingPlans.restartPlan2',
        )}`}
        headerText={t('Generic.restartTraining')}
        isOpen={!!showRestartTrainingPopup}
        onClose={onRestartPlanCancel}
        primaryButtonHandler={onRestartPlanConfirm}
        primaryButtonText={t('Button.yes')}
        secondaryButtonHandler={onRestartPlanCancel}
        secondaryButtonText={t('Button.cancel')}
      />
    </>
  );
};

const StyledCard = styled(Card)`
  animation: fadeIn linear 0.3s;
  margin: 8px 0;
  flex-direction: row;
  padding: 0 !important;
  height: auto;
  position: relative;
  overflow: visible !important;
`;

const StyledCardContent = styled(CardContent)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px !important;
`;

const StyledCardLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: 'flex-start';
  flex-grow: 1;
  max-width: 100%;
  overflow: hidden;
  margin: 0 15px;
`;
const StyledCardRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  gap: 16px;
`;
const StyledCardName = styled(Typography)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  min-width: 0;
  max-width: 100%;
`;

const StyledRibbon = styled(Ribbon)`
  height: 25px;
  width: 25px;
`;

const StyledPrinterIcon = styled(IconPrinter)`
  pointer-events: auto;
  color: ${({ theme }) => theme.grayScale.gray2};
`;

export default TeamMemberCard;
