import styled from 'styled-components';
import { Typography } from 'cfa-react-components';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';
import { ReactNode } from 'react';
import DocumentHead from '@/components/document/DocumentHead';

interface PageTitleProps {
  className?: string;
  dataTestId?: string;
  children?: ReactNode;
}

const PageTitle: React.FC<PageTitleProps> = ({
  className,
  dataTestId,
  children,
}) => {
  return (
    <PageHeaderContainer className={className} data-testid={dataTestId}>
      {children && (
        <>
          <DocumentHead pageTitle={startCase(toLower(children as string))} />
          <PageTitleText variant="h2">{children}</PageTitleText>
        </>
      )}
    </PageHeaderContainer>
  );
};

const PageHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const PageTitleText = styled(Typography)``;

export default PageTitle;
