import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'cfa-react-components';
import styled from 'styled-components';
import { Document } from '@cfacorp-pathway/xp-api-typescript-client';
import {
  useGetAdminCategoryByIdQuery,
  useGetSubcategoriesQuery,
} from 'services/pathwayApi';
import StyledDocumentCheckbox from './StyledDocumentCheckbox';
import { PopupDocument } from './stepTypes';
import { LanguageObject } from '@/types/types';
import Constants from '@/constants';
import SubcategoryAccordion from '@/pages/Explore/Operations/components/SubcategoryAccordion';
import SubcategoryDocument from '@/pages/Explore/Operations/components/SubcategoryDocument';
import PageHeader from '@/components/PageHeader/PageHeader';
import { BaseReferenceDTO } from '@/services/content-api-types';
import { getNameFromLanguage, itemByLanguage } from '@/utils/language';

interface Step2Props {
  categorySelected: string | null;
  selectedDocuments: PopupDocument[];
  setSelectedDocuments: (documents: PopupDocument[]) => void;
  onDocumentClick: (document: Document | BaseReferenceDTO) => void;
  selectedCountryCode?: string;
}

const StepTwoSubcategories: React.FC<Step2Props> = ({
  categorySelected,
  selectedDocuments,
  setSelectedDocuments,
  onDocumentClick,
  selectedCountryCode,
}) => {
  const { t } = useTranslation();

  const [preservedSubcategoryIds, setPreservedSubcategoryIds] = useState(['']);

  useEffect(() => {
    const storedSubcategory = localStorage.getItem(
      'preservedAddProcedureSubcategoryId',
    );
    if (storedSubcategory) {
      setPreservedSubcategoryIds([storedSubcategory]);
    }
    localStorage.setItem('preservedAddProcedureSubcategoryId', '');
  }, []);

  const { data: categoryData, isFetching: categoryIsFetching } =
    useGetSubcategoriesQuery(categorySelected!, {
      skip: !!selectedCountryCode || !categorySelected,
    });

  const { data: adminCategoryData, isFetching: adminCategoryIsFetching } =
    useGetAdminCategoryByIdQuery(
      {
        id: categorySelected!,
        countryCode: selectedCountryCode!,
      },
      { skip: !selectedCountryCode || !categorySelected },
    );

  const data = categoryData || adminCategoryData;
  const isFetching = categoryIsFetching || adminCategoryIsFetching;

  const categoryName = getNameFromLanguage(data?.name!);
  const subcategories = data?.subcategories ?? [];

  const breadcrumbs = [
    { label: t('Browse.categories') },
    { label: categoryName ?? '' },
  ];

  const handleAccordionClick = (subcategoryId: string) => {
    localStorage.setItem('preservedAddProcedureSubcategoryId', subcategoryId);
    setPreservedSubcategoryIds(
      preservedSubcategoryIds?.includes(subcategoryId)
        ? [...preservedSubcategoryIds.filter(id => id !== subcategoryId)]
        : [...preservedSubcategoryIds, subcategoryId],
    );
  };

  if (isFetching) {
    return 'Loading...';
  }

  return (
    <>
      <PageHeader breadcrumbs={breadcrumbs} title={categoryName} />
      {subcategories?.map((subcategory, index) => (
        <SubcategoryAccordion
          data-testid={`SubcategoryAccordion${index}`}
          defaultActiveItemKey={preservedSubcategoryIds}
          itemKey={subcategory?.id ?? index}
          key={subcategory?.id ?? index}
          name={getNameFromLanguage(subcategory.name as LanguageObject)}
          onClick={() =>
            handleAccordionClick(subcategory?.id ?? index.toString())
          }
        >
          {subcategory?.subtitles?.map(
            ({ name: sectionName, documents }, index) => (
              <div key={index}>
                <Typography variant="overline3">
                  {getNameFromLanguage(sectionName)}
                </Typography>
                {documents?.map((documentItem, index) => {
                  const reference = getNameFromLanguage(
                    documentItem?.references!,
                  ) as any;
                  const newDocument = {
                    id: documentItem.id!,
                    type: Constants.STEP_TYPES.DOCUMENT,
                    reference: {
                      id: documentItem.id!,
                    },
                    name: documentItem.name!,
                  } as PopupDocument;
                  return (
                    <DocumentContainer key={documentItem?.id ?? index}>
                      <SubcategoryDocument
                        icon={reference?.icon || 'Chickfila'}
                        id={reference?.documentId ?? ''}
                        name={reference?.name ?? ''}
                        onClick={() => {
                          const ref = itemByLanguage(documentItem.references);
                          if (!ref) {
                            console.error('No reference found for language');
                            return;
                          }
                          onDocumentClick(ref);
                        }}
                      />
                      <StyledDocumentCheckbox
                        documentId={documentItem.id!}
                        newDocument={newDocument}
                        selectedDocuments={selectedDocuments}
                        setSelectedDocuments={setSelectedDocuments}
                      />
                    </DocumentContainer>
                  );
                })}
              </div>
            ),
          )}
        </SubcategoryAccordion>
      ))}
    </>
  );
};

const DocumentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export default StepTwoSubcategories;
