import { Document } from '@cfacorp-pathway/xp-api-typescript-client';
import { IconChevronUp } from '@tabler/icons-react';
import { useBreakpoints, useMediaQuery } from 'cfa-react-components';
import parse from 'html-react-parser';
import { FC, memo, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from '../../../styles/document.module.css';
import { BackToTopCircle, DocumentContainer } from '../common';
import {
  DesktopView,
  FullscreenLoading,
  MobileView,
  TridionError,
} from '../tridion-components';
import { PageWrapper, StyledContentWrapper } from '../wrappers';
import theme from '@/styles/theme';
import { selectSearchTerms } from '@/store/search/selectors';
import { selectIsPrinting } from '@/store/document-transient/selectors';
import { RoutedFrom } from '@/store/document-persisted/slice';
import { useAppSelector } from '@/hooks';
import DocumentHead from '@/components/document/DocumentHead';
import {
  removeTopElementStickyClass,
  useBreadcrumbs,
  useIsMobile,
  useTableOfContents,
  useTridionDocument,
  useTridionPrinting,
  useTridionUrl,
} from '@/utils/document-utils';

interface TridionProps {
  /** Tridion document. */
  document: Document;
  icon?: string;
  routedFrom: RoutedFrom;
}

/** Tridion document. */
const Tridion: FC<TridionProps> = ({ document, icon, routedFrom }) => {
  const history = useHistory();
  const searchTerm = useAppSelector(selectSearchTerms)[0];
  const isPrinting = useAppSelector(selectIsPrinting);
  const [isScrolling, setIsScrolling] = useState(false);
  const [isTocItemClicked, setIsTocItemClicked] = useState(false);
  const url = useTridionUrl(document);
  const { htmlContent, error } = useTridionDocument(url);
  useTridionPrinting();
  const documentContent: React.ReactNode = useMemo(
    () => (htmlContent ? parse(htmlContent) : null),
    [htmlContent],
  );
  const breakpoints = useBreakpoints();
  const isSmAndDown = useMediaQuery(breakpoints.down('md'));
  const isMobileView = isSmAndDown && !isPrinting;
  const isMobile = useIsMobile();

  const { tocItems } = useTableOfContents(isTocItemClicked, htmlContent);

  const title = document.name ?? '';

  const goBack = (isSearch = false) => {
    if (isSearch) {
      history.push(`/search?query=${searchTerm}`);
    } else {
      history.goBack();
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolling(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleTocItemClick = () => {
    removeTopElementStickyClass();
    setIsTocItemClicked(true);
    setTimeout(() => {
      setIsTocItemClicked(false);
    }, 2000);
  };

  const breadcrumbs = useBreadcrumbs(
    goBack,
    routedFrom === 'search',
    routedFrom === 'link',
  );

  if (!htmlContent) {
    return <FullscreenLoading />;
  }

  if (error) {
    return <TridionError url={document.contentUrl} />;
  }

  return (
    <DocumentContainer $isPrinting={isPrinting} $isTridion={true} fluid>
      <DocumentHead pageTitle={document.name ?? ''} />
      <StyledContentWrapper $isPrinting={isPrinting}>
        <PageWrapper $isMobile={isMobileView} $isPrinting={isPrinting}>
          {isMobileView ? (
            <MobileView
              breadcrumbs={breadcrumbs}
              documentContent={documentContent}
              handleTocItemClick={handleTocItemClick}
              icon={icon}
              lastModified={document.lastModified}
              styles={styles}
              tags={document.tags ?? []}
              title={title}
              tocItems={tocItems}
            />
          ) : (
            <DesktopView
              breadcrumbs={breadcrumbs}
              documentContent={documentContent}
              handleTocItemClick={handleTocItemClick}
              icon={icon}
              lastModified={document.lastModified}
              styles={styles}
              tags={document.tags ?? []}
              title={title}
              tocItems={tocItems}
            />
          )}
        </PageWrapper>
      </StyledContentWrapper>
      {isScrolling && isMobile && <BackToTop />}
    </DocumentContainer>
  );
};

const BackToTop: FC = () => (
  <BackToTopCircle>
    <IconChevronUp
      color={theme.primaryPalette.navyBlue}
      onClick={() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }}
    />
  </BackToTopCircle>
);

export default memo(Tridion);
