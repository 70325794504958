import { initializeNativeUser, initializeWebUser } from './initializeUser';
import { subscribeToAuthStateChange } from '@/okta/oktaConfig';
import { initializeLanguage } from '@/i18n/language';
import store from '@/store';
import { initialized } from '@/store/app/slice';
import { getAuthFromNative } from '@/utils/auth';
import Constants from '@/constants';
import { initializeUserFromAuth } from '@/store/user/slice';
import { mockedAuthData } from '@/utils/testData/loadDefaultAuthUser';

export const initializeApp = async () => {
  initializeLanguage();

  const nativeAuth = getAuthFromNative();

  if (nativeAuth.isNative) {
    await initializeNativeUser(nativeAuth);
    store.dispatch(initialized(true));
    return;
  }

  if (Constants.IS_IN_CYPRESS_TEST_LOCAL || Constants.IS_IN_JEST_TEST) {
    store.dispatch(initializeUserFromAuth(mockedAuthData));
    store.dispatch(initialized(true));
    return;
  }

  subscribeToAuthStateChange(async ({ authState }) => {
    await initializeWebUser(authState);
    store.dispatch(initialized(true));
  });
};
