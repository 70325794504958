import { useEffect, useState } from 'react';
import { initializeTranslation } from '@/i18n/translation';

let started = false;

export const useInitializeTranslation = () => {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (started) {
      return;
    }
    (async () => {
      started = true;
      await initializeTranslation();
      setReady(true);
    })();
  }, []);

  return ready;
};
