import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import uniqBy from 'lodash/uniqBy';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
  ComplianceCourseEnrollmentDTO,
  UserChecklistResponse,
  UserChecklistResultDTO,
  UserChecklistStatusResponse,
} from '@cfacorp-pathway/xp-api-typescript-client';

import FilterAndSortTeamMembersMobile from './FilterAndSortTeamMembersMobile';
import TeamMembersList from './TeamMembersList';
import Constants from '@/constants';
import {
  useGetReportsPlansByUserQuery,
  useGetReportsTeamMembersQuery,
} from '@/services/pathwayApi';
import { setHeader } from '@/store/header/slice';
import { selectActiveLicenseeLocation } from '@/store/licenseeLocationPicker/selector';
import {
  selectAllLocationsWithAtLeastTrainer,
  isUserLicensee,
  selectUserLicenseeLocations,
  selectUserIsLicenseeStaff,
} from '@/store/user/selectors';
import { selectFilters } from '@/store/teamMembersFilter/selector';
import {
  clearTeamMembersCheckboxFilters,
  clearTeamMembersSearchFilter,
  setTeamMembers,
} from '@/store/teamMembersFilter/slice';
import {
  useGetReportsUserTrainingPlansQuery,
  useGetReportsTrainingPlanStatusesQuery,
} from '@/services/xpApi';
import { generateReportAndMessageReactNative } from '@/utils/generateReport';
import { withRoles } from '@/components/ConfirmationModal/withRoles';
import { selectPlansToggleLicensee } from '@/store/licensee/selector';
import { getNameFromLanguage } from '@/utils/language';
import { convertDateForReports } from '@/utils/date';

const ReportsTeamMembersTab = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { xpApi: xpApiFeatureFlag } = useFlags();
  const locations: string[] = useSelector(selectAllLocationsWithAtLeastTrainer);
  const currentFilters: string[] = useSelector(selectFilters);
  const userIsLicenseeStaff = useSelector(selectUserIsLicenseeStaff);
  const isLicenseeUser = useSelector(isUserLicensee);
  const activeLicenseeLocation: { name: string; number: string } = useSelector(
    selectActiveLicenseeLocation,
  );
  const isLicenseePlans = useSelector(selectPlansToggleLicensee);
  const userLicenseeLocations = useSelector(selectUserLicenseeLocations);
  const [reportData, setReportData] = useState({
    id: '',
    name: '',
    report: [] as (UserChecklistResultDTO | ComplianceCourseEnrollmentDTO)[],
  });
  const licenseeLocations = isLicenseePlans
    ? (userLicenseeLocations?.map(location => location.number!) ?? [])
    : undefined;

  const getPlanLocations = () => {
    if (userIsLicenseeStaff || isLicenseeUser) {
      return activeLicenseeLocation?.number
        ? [activeLicenseeLocation?.number]
        : [];
    }
    if (isLicenseePlans) {
      return licenseeLocations;
    }
    return locations;
  };

  const planLocations = getPlanLocations();
  // Team Members
  const {
    data: oldReportsTeamMembersData,
    isFetching: oldReportsTeamMembersIsFetching,
    isSuccess: oldIsReportsTeamMembersDataSuccess,
  } = useGetReportsTeamMembersQuery(
    { locations: planLocations },
    {
      skip:
        !!xpApiFeatureFlag ||
        ((userIsLicenseeStaff || isLicenseeUser) &&
          !activeLicenseeLocation?.number),
      refetchOnMountOrArgChange: true,
    },
  );

  const {
    data: xpReportsTeamMemberStatuses,
    isFetching: xpReportsTeamMembersStatusesIsFetching,
    isSuccess: xpReportsTeamMembersStatusesIsSuccess,
  } = useGetReportsTrainingPlanStatusesQuery(
    {
      locations: planLocations ?? ([] as string[]),
    },
    {
      refetchOnMountOrArgChange: true,
      skip:
        !xpApiFeatureFlag ||
        ((userIsLicenseeStaff || isLicenseeUser) &&
          !activeLicenseeLocation?.number),
    },
  );
  const reportsTeamMembersData: UserChecklistStatusResponse = xpApiFeatureFlag
    ? xpReportsTeamMemberStatuses
    : (oldReportsTeamMembersData as any);
  const reportsTeamMembersIsFetching = xpApiFeatureFlag
    ? xpReportsTeamMembersStatusesIsFetching
    : oldReportsTeamMembersIsFetching;
  const isReportsTeamMembersDataSuccess = xpApiFeatureFlag
    ? xpReportsTeamMembersStatusesIsSuccess
    : oldIsReportsTeamMembersDataSuccess;

  // Reports (Plans)
  const { data: oldReportsData, isSuccess: isSuccessOldReports } =
    useGetReportsPlansByUserQuery(
      {
        userId: reportData.id,
      },
      {
        skip: !reportData.id || !!xpApiFeatureFlag,
        refetchOnMountOrArgChange: true,
      },
    );

  const { data: xpReportsData, isSuccess: isSuccessXpReports } =
    useGetReportsUserTrainingPlansQuery(
      {
        userId: reportData.id,
      },
      {
        skip: !reportData.id || !xpApiFeatureFlag,
        refetchOnMountOrArgChange: true,
      },
    );

  const reportsData: UserChecklistResponse | undefined = xpApiFeatureFlag
    ? xpReportsData
    : (oldReportsData as any);
  const isSuccessReports = xpApiFeatureFlag
    ? isSuccessXpReports
    : isSuccessOldReports;

  // Clear Team Members Filters
  useEffect(() => {
    dispatch(clearTeamMembersCheckboxFilters());
    dispatch(clearTeamMembersSearchFilter());
  }, [dispatch]);

  // Set Header
  useEffect(() => {
    dispatch(setHeader(t('Generic.reports')));
  }, [dispatch, t]);

  // Set Team Members
  useEffect(() => {
    if (isReportsTeamMembersDataSuccess) {
      dispatch(
        setTeamMembers({
          teamMembers: uniqBy(
            reportsTeamMembersData?.users!.map(user => {
              return {
                ...user.user,
                plansAssigned: user.plansAssigned,
                plansCompleted: user.plansCompleted,
              };
            }),
            'adId',
          ),
        }),
      );
    }
  }, [reportsTeamMembersData, dispatch, isReportsTeamMembersDataSuccess]);

  // Print Report
  useEffect(() => {
    let plans: (UserChecklistResultDTO | ComplianceCourseEnrollmentDTO)[] = [];
    let courses: ComplianceCourseEnrollmentDTO[] = [];

    // Non-Compliance Plans
    if (isSuccessReports && reportsData?.checklistResults) {
      plans = reportsData.checklistResults;
    }
    // Compliance Plans
    if (isSuccessReports && reportsData?.coursesResults?.[0]?.enrollments) {
      courses = Object.keys(reportsData?.coursesResults?.[0]?.enrollments).map(
        key => reportsData?.coursesResults?.[0]?.enrollments?.[key]!,
      );
    }

    const combinedPlans = plans.concat(courses);
    onPrintReport(combinedPlans);
    setReportData({ ...reportData, report: combinedPlans });
    //eslint-disable-next-line
  }, [dispatch, reportsData]);

  const getTimeString = (time: number) => {
    const timeSpentHrs = Math.floor(time / 60);
    const timeSpentMins = time % 60;
    return `${
      timeSpentHrs > 0 ? timeSpentHrs + ' ' + t('Generic.hour') : ''
    } ${timeSpentMins} ${t('Generic.mins')}`;
  };

  const isChecklist = (
    plan: UserChecklistResultDTO | ComplianceCourseEnrollmentDTO,
  ): plan is UserChecklistResultDTO => {
    return (plan as UserChecklistResultDTO).checklist !== undefined;
  };

  const onPrintReport = (
    plans: (UserChecklistResultDTO | ComplianceCourseEnrollmentDTO)[],
  ) => {
    const statusReportToPrint = plans
      .filter(report => {
        if (isChecklist(report) && currentFilters?.length) {
          return currentFilters.includes(
            report.checklist?.locations?.at(0) ?? '',
          );
        }
        return report;
      })
      .sort((planA, planB) => {
        return isChecklist(planA)
          ? getNameFromLanguage(planA?.checklist?.name!).localeCompare(
              getNameFromLanguage(
                (planB as UserChecklistResultDTO)?.checklist?.name!,
              ),
            )
          : getNameFromLanguage(planA?.courseName!).localeCompare(
              getNameFromLanguage(
                (planB as ComplianceCourseEnrollmentDTO)?.courseName!,
              ),
            );
      })
      .map(plan => {
        return {
          [Constants.REPORT_TABLE.PLAN]: isChecklist(plan)
            ? getNameFromLanguage(plan.checklist?.name!)
            : getNameFromLanguage(plan.courseName!),
          [Constants.REPORT_TABLE.ITEMS_COMPLETED]: isChecklist(plan)
            ? `${plan.status?.at(0)?.stepsComplete}/${
                plan.checklist?.stepsTotal
              }`
            : `${plan.percentComplete}%`,
          [Constants.REPORT_TABLE.TOTAL_TIME_SPENT_ON_PLAN]: !isChecklist(plan)
            ? getTimeString(Math.ceil(plan?.duration! / 60))
            : plan.checklist
              ? `${getTimeString(
                  plan.status
                    ?.at(0)
                    ?.steps?.reduce(
                      (acc, step) =>
                        acc + Math.round((step?.stepDuration ?? 0) / 60),
                      0,
                    ) ?? 0,
                )}`
              : t('Generic.na'),
          [Constants.REPORT_TABLE.STATUS]: isChecklist(plan)
            ? t(
                `TrainingPlans.statusOptions.${
                  Constants.TRAINING_PLANS_STATUSES[
                    plan.status?.at(0)
                      ?.status! as keyof typeof Constants.TRAINING_PLANS_STATUSES
                  ]
                }`,
              )
            : t(
                `TrainingPlans.statusOptions.${
                  Constants.LEARN_UPON_TRAINING_PLAN_STATUSES[plan?.status!]
                }`,
              ),
          [Constants.REPORT_TABLE.COMPLETION_DATE]:
            isChecklist(plan) && plan.status![0]!.completionDate
              ? convertDateForReports(plan.status![0]!.completionDate)
              : getNameFromLanguage(
                    (plan as ComplianceCourseEnrollmentDTO)?.courseName!,
                  )?.includes(Constants.FOOD_SAFETY_COURSE_NAME) &&
                  plan.status === Constants.LEARN_UPON_TRAINING_PLANS.PASSED
                ? convertDateForReports(
                    (plan as ComplianceCourseEnrollmentDTO).completedDate!,
                  )
                : (plan as ComplianceCourseEnrollmentDTO).courseName &&
                    (plan as ComplianceCourseEnrollmentDTO).completedDate &&
                    !getNameFromLanguage(
                      (plan as ComplianceCourseEnrollmentDTO)?.courseName!,
                    )?.includes(Constants.FOOD_SAFETY_COURSE_NAME)
                  ? convertDateForReports(
                      (plan as ComplianceCourseEnrollmentDTO).completedDate!,
                    )
                  : t('Generic.na'),
          [Constants.REPORT_TABLE.EXPIRATION_DATE]:
            !isChecklist(plan) &&
            plan?.certificate?.expiration &&
            plan?.status !== Constants.LEARN_UPON_TRAINING_PLAN_STATUSES.failed
              ? convertDateForReports(plan?.certificate?.expiration)
              : t('Generic.na'),
        };
      });

    const complianceReport = statusReportToPrint
      .filter(teamMember =>
        teamMember[Constants.REPORT_TABLE.ITEMS_COMPLETED]?.includes('%'),
      )
      .map(user => {
        return {
          ...user,
          [Constants.REPORT_TABLE.COMPLIANCE_PLAN_NAME]:
            user[Constants.REPORT_TABLE.PLAN],
          [Constants.REPORT_TABLE.EXPIRATION_DATE]:
            user[Constants.REPORT_TABLE.EXPIRATION_DATE],
        };
      });

    const storeReport = statusReportToPrint.filter(
      teamMember =>
        !teamMember[Constants.REPORT_TABLE.ITEMS_COMPLETED]?.includes('%'),
    );

    const reportToGenerate = {
      category: t('Generic.teamMember'),
      isTeamMember: true,
      name: reportData.name,
      complianceReport: complianceReport.map(teamMember => [
        teamMember['Compliance Plan Name'],
        teamMember[Constants.REPORT_TABLE.TOTAL_TIME_SPENT_ON_PLAN],
        teamMember[Constants.REPORT_TABLE.COMPLETION_DATE],
        teamMember[Constants.REPORT_TABLE.EXPIRATION_DATE],
      ]),
      complianceTableHead: Constants.COMPLIANCE_TABLE_HEAD,
      reportToPrint: storeReport.map(teamMember => [
        teamMember[Constants.REPORT_TABLE.PLAN],
        teamMember[Constants.REPORT_TABLE.ITEMS_COMPLETED],
        teamMember[Constants.REPORT_TABLE.TOTAL_TIME_SPENT_ON_PLAN],
        teamMember[Constants.REPORT_TABLE.STATUS],
        teamMember[Constants.REPORT_TABLE.COMPLETION_DATE],
      ]),
      tableHead: Constants.TABLE_HEAD,
    };

    /**
     * There is a minor timing gap when a user clicks the printer icon to
     * when the report loads because we are first setting the ID of the
     * report to print, waiting on the query to get the data, then calling
     * the print function aferward.  We wait for the success status to be
     * returned so that we don't show empty data
     */
    if (isSuccessReports) {
      generateReportAndMessageReactNative(
        reportToGenerate,
        statusReportToPrint,
        Constants.TABLE_HEAD,
        true,
      );
    }
  };

  return (
    <StyledContent>
      <FilterAndSortTeamMembersMobile
        isFetching={reportsTeamMembersIsFetching}
      />
      <TeamMembersList
        isFetching={reportsTeamMembersIsFetching}
        onPrintReport={(id: string, name: string) => {
          reportData?.id === id
            ? onPrintReport(reportData?.report)
            : setReportData({ ...reportData, id, name });
        }}
      />
    </StyledContent>
  );
};

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  max-width: 100%;
`;

const ReportsTeamMembersTabWithRoles = withRoles(
  ReportsTeamMembersTab,
  'reporting.teamMember',
);

export default ReportsTeamMembersTabWithRoles;
