import Constants from 'constants/index';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  ChecklistDTO,
  UserChecklistResultDTO,
} from '@cfacorp-pathway/xp-api-typescript-client';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { generateReportAndMessageReactNative } from '@/utils/generateReport';
import PrintReportModal from '@/components/popups/PrintReportModal';
import CheckboxList from '@/components/Checkbox/CheckboxList';
import { mapCategoryToAliasTranslation } from '@/utils/categoryUtils';
import { arrayToCommaString } from '@/utils/arrayToCommaString';
import { removeUnderscoreAndCapitalizeString } from '@/utils/removeUnderscoreAndCapitalizeString';
import { getNameFromLanguage } from '@/utils/language';
import { LanguageObject } from '@/types/types';
import { selectFilters } from '@/store/teamMembersFilter/selector';
import { hidePrintReport } from '@/store/printReport/slice';
import { printReport } from '@/store/printReport/selectors';
import useGetCourseReportData from '@/utils/reportPrint/useGetCourseReportData';
import useGetStoreReportToPrint from '@/utils/reportPrint/useGetStoreReportToPrint';

interface PrintReportProps {
  assignedTeamMembersList: UserChecklistResultDTO | undefined;
  filterLocations: string[];
  isFoodSafety: boolean;
  plan: ChecklistDTO;
  stepCount: number;
  isLicenseePlan?: boolean;
  isFetching?: boolean;
}

const PrintReport: React.FC<PrintReportProps> = ({
  assignedTeamMembersList,
  isFoodSafety,
  filterLocations,
  plan,
  stepCount,
  isLicenseePlan,
  isFetching: isTeamMembersFetching,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const { id: planId } = params as { id: string };

  const currentFilters = useSelector(selectFilters);
  const isPrintReportOpen = useSelector(printReport);

  const [showChooseReportLocationsPopup, setShowChooseReportLocationsPopup] =
    useState(false);
  const [selectedReportLocations, setSelectedReportLocations] =
    useState<string[]>();
  const [noLocationsSelected, setNoLocationsSelected] = useState(false);
  const [showDoBChecked, setShowDoBChecked] = useState(false);

  const { search } = location;
  const isCompliance = search.includes('compliancePlan');

  const { isStatusReportFetching, statusReportData, statusReportToPrint } =
    useGetCourseReportData(
      planId,
      selectedReportLocations as string[],
      Boolean(isCompliance && selectedReportLocations?.length),
    );

  const storeReportToPrint = useGetStoreReportToPrint(
    stepCount,
    selectedReportLocations,
    assignedTeamMembersList,
    true, // show REPORT_TABLE.ITEMS_COMPLETED
  );

  const handleCurrentFilteredLocations = useCallback(() => {
    if (!plan?.id && !isCompliance) {
      return;
    }
    if (isLicenseePlan) {
      setSelectedReportLocations([plan?.ownerId!]);
      return;
    }
    if (!currentFilters.length) {
      setSelectedReportLocations(filterLocations);
    } else {
      const currentFilteredLocations = currentFilters.filter(filter => {
        const isNumber = /^\d+$/.test(filter);
        return isNumber;
      });
      if (!currentFilteredLocations.length) {
        setSelectedReportLocations(filterLocations);
      } else {
        setSelectedReportLocations(currentFilteredLocations);
      }
    }
    if (!!noLocationsSelected) {
      setNoLocationsSelected(false);
    }
    setShowDoBChecked(false);
  }, [
    plan,
    isCompliance,
    isLicenseePlan,
    currentFilters,
    filterLocations,
    noLocationsSelected,
  ]);

  useEffect(() => {
    handleCurrentFilteredLocations();
  }, [handleCurrentFilteredLocations]);

  // Print Report
  useEffect(() => {
    if (isPrintReportOpen) {
      onPrintReport();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPrintReportOpen]);

  const reportDataToPrint = isCompliance
    ? statusReportToPrint
    : storeReportToPrint;

  const tableHead = useMemo(
    () => [
      [
        Constants.REPORT_TABLE.NAME,
        ...(showDoBChecked ? [Constants.REPORT_TABLE.DATE_OF_BIRTH] : []),
        ...(isCompliance
          ? [
              Constants.REPORT_TABLE.COMPLETION_DATE,
              Constants.REPORT_TABLE.EXPIRATION_DATE,
            ]
          : [
              Constants.REPORT_TABLE.ITEMS_COMPLETED,
              Constants.REPORT_TABLE.TOTAL_TIME_SPENT_ON_PLAN,
              Constants.REPORT_TABLE.STATUS,
              Constants.REPORT_TABLE.COMPLETION_DATE,
            ]),
      ],
    ],
    [showDoBChecked, isCompliance],
  );

  const reportToGenerate = {
    category: isCompliance
      ? t(mapCategoryToAliasTranslation(Constants.PLAN_CATEGORIES.COMPLIANCE))
      : t(mapCategoryToAliasTranslation(plan?.category ?? '')),
    foodSafetyImage: (statusReportData?.foodSafetyLetters?.[0] as any)?.content,
    location: `${t('Generic.at')} ${
      selectedReportLocations?.length === 0
        ? filterLocations
        : !selectedReportLocations?.length
          ? selectedReportLocations?.toString()
          : arrayToCommaString(selectedReportLocations, t('Generic.and'))
    }`,
    name: isCompliance
      ? removeUnderscoreAndCapitalizeString(statusReportData?.courseName ?? '')
      : getNameFromLanguage(plan?.name as LanguageObject),
    reportToPrint: reportDataToPrint.map(teamMember =>
      tableHead?.[0]?.map(headerItem => teamMember?.[headerItem]),
    ),
    tableHead,
  };

  const handlePrintReport = () => {
    if (!selectedReportLocations?.length && !!filterLocations.length) {
      setNoLocationsSelected(true);
    } else {
      setShowChooseReportLocationsPopup(false);
      generateReportAndMessageReactNative(
        reportToGenerate,
        reportDataToPrint,
        tableHead,
      );
      handleCurrentFilteredLocations();
    }
  };

  const onPrintReport = () => {
    dispatch(hidePrintReport());
    !isLicenseePlan && (filterLocations?.length > 1 || isFoodSafety)
      ? setShowChooseReportLocationsPopup(true)
      : handlePrintReport();
  };

  const onPrintReportCancel = () => {
    setShowChooseReportLocationsPopup(false);
    handleCurrentFilteredLocations();
  };

  const handleToggleDoBCheckbox = () => {
    setShowDoBChecked(!showDoBChecked);
  };

  return (
    <PrintReportModal
      bodyText={t('TrainingPlans.chooseWhatToInclude')}
      children={
        filterLocations.length > 1
          ? filterLocations.map((id, idx) => (
              <StyledCheckboxList
                id={id}
                idx={idx}
                key={idx}
                selectedLocations={selectedReportLocations as string[]}
                setSelectedLocations={setSelectedReportLocations}
              />
            ))
          : null
      }
      handleToggleDoBCheckbox={handleToggleDoBCheckbox}
      headerText={t('Generic.printReport')}
      isFoodSafety={isFoodSafety}
      isOpen={showChooseReportLocationsPopup}
      isPrintDisabled={isStatusReportFetching || isTeamMembersFetching}
      noLocationsSelected={noLocationsSelected}
      onClose={onPrintReportCancel}
      primaryButtonHandler={handlePrintReport}
      primaryButtonText={t('Button.print')}
      secondaryButtonHandler={onPrintReportCancel}
      secondaryButtonText={t('Button.cancel')}
      selectedReportLocations={selectedReportLocations ?? []}
      showDoBChecked={showDoBChecked}
    />
  );
};

const StyledCheckboxList = styled(CheckboxList)`
  padding: 6px 0;
`;

export default PrintReport;
