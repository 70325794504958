import styled from 'styled-components';
import PageTitle from 'components/PageTitle/PageTitle';
import BreadcrumbLinks from 'components/BreadcrumbLinks/BreadcrumbLinks';
import { useDispatch } from 'react-redux';
import { ReactNode, useEffect } from 'react';
import { setHeader } from '@/store/header/slice';
import { Breadcrumb } from '@/types/types';

interface PageHeaderProps {
  breadcrumbs: Breadcrumb[];
  children?: ReactNode;
  className?: string;
  dataTestId?: string;
  header?: string;
  title?: string;
}

const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  breadcrumbs,
  header,
  className,
  dataTestId,
  children,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (header !== '') {
      dispatch(setHeader(header));
    }
  }, [dispatch, header]);

  return (
    <PageHeaderContainer className={className} data-testid={dataTestId}>
      <PageHeaderLeft>
        {breadcrumbs && <BreadcrumbLinks breadcrumbs={breadcrumbs} />}
        {title && <PageHeaderTitle>{title}</PageHeaderTitle>}
      </PageHeaderLeft>
      <PageHeaderRight>{children}</PageHeaderRight>
    </PageHeaderContainer>
  );
};

const PageHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 24px;
`;

const PageHeaderLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const PageHeaderRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const PageHeaderTitle = styled(PageTitle)`
  margin-top: 8px;
`;
export default PageHeader;
