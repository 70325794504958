import React from 'react';
import styled from 'styled-components';
import {
  IconSquareRoundedCheckFilled,
  IconSquareRoundedPlus,
} from '@tabler/icons-react';
import { PopupDocument } from './stepTypes';

interface StyledDocumentCheckboxProps {
  selectedDocuments: PopupDocument[];
  setSelectedDocuments: (documents: PopupDocument[]) => void;
  newDocument: PopupDocument;
  documentId: string;
}

const StyledDocumentCheckbox: React.FC<StyledDocumentCheckboxProps> = ({
  selectedDocuments,
  setSelectedDocuments,
  newDocument,
  documentId,
}) => {
  const isSelected = Boolean(
    selectedDocuments.find(
      selectedDocument => selectedDocument.id === documentId,
    ),
  );

  const onChangeCheckbox = () => {
    const newSelectedDocuments = isSelected
      ? selectedDocuments.filter(
          selectedDocument => selectedDocument.id !== documentId,
        )
      : [...selectedDocuments, newDocument];
    setSelectedDocuments(newSelectedDocuments);
  };

  return (
    <StyledDocumentCheckboxWrapper>
      <StyledProcedureBoxFiled>
        {isSelected ? (
          <StyledIconSquareRoundedCheckFilled
            data-testid="documentCheckboxSelected"
            onClick={onChangeCheckbox}
          />
        ) : (
          <StyledIconSquareRoundedPlus
            data-testid="documentCheckbox"
            onClick={onChangeCheckbox}
          />
        )}
      </StyledProcedureBoxFiled>
    </StyledDocumentCheckboxWrapper>
  );
};

const StyledDocumentCheckboxWrapper = styled.div`
  display: flex;
  justify-content: end;
`;

const StyledProcedureBoxFiled = styled.div`
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  padding: 0 1em;
`;

const StyledIconSquareRoundedCheckFilled = styled(IconSquareRoundedCheckFilled)`
  color: ${props => props.theme.semanticColors.success};
  cursor: pointer;
`;

const StyledIconSquareRoundedPlus = styled(IconSquareRoundedPlus)`
  color: ${props => props.theme.primaryPalette.navyBlue};
  cursor: pointer;
`;

export default StyledDocumentCheckbox;
