import Constants from 'constants/index';
import Bugsnag, { Event } from '@bugsnag/js';
import BugsnagPluginReact, {
  BugsnagErrorBoundary,
} from '@bugsnag/plugin-react';
import store from 'store';
import { bugsnagInitialized } from 'store/app/slice';
import { getUserInfo } from '@/utils/user';
import GenericErrorBoundary from '@/components/Error/GenericErrorBoundary';
import { selectBugsnagInitialized } from '@/store/app/selectors';
import { selectMonitoringEnabled } from '@/store/user/selectors';
import React from 'react';

export let ErrorBoundary: BugsnagErrorBoundary | typeof GenericErrorBoundary =
  GenericErrorBoundary;

let bugsnagEnabled = false;

export function setMetadata({
  event,
  section,
  info,
}: {
  event: Event;
  section: string;
  info: Record<string, unknown>;
}) {
  if (!bugsnagEnabled || !info) {
    return;
  }
  return event.addMetadata(section, { ...info });
}

export function setupBugsnag() {
  bugsnagEnabled = true;

  const releaseStage = import.meta.env.DEV ? 'development' : Constants.ENV;
  const baseConfig = {
    apiKey: Constants.BUGSNAG_API_KEY!,
    plugins: [new BugsnagPluginReact()],
  };

  Bugsnag.start({
    ...baseConfig,
    appVersion: Constants.APP_VERSION,
    releaseStage,
    maxBreadcrumbs: 100,
    maxEvents: 100,
    trackInlineScripts: false,
    onError: function (event) {
      const userMetadata = getUserInfo(
        JSON.parse(localStorage.getItem('okta-token-storage')!)?.accessToken
          ?.claims,
      );
      // mark as handled until we determine if it has hit the error boundary
      // only errors that crash the app should be marked as unhandled
      // commenting out as this was causing the stability tracing not to work

      // event.unhandled = false;

      setMetadata({ event, section: 'user', info: userMetadata });

      // group events by message for unhandled, otherwise by context

      event.groupingHash =
        event?.unhandled && event?.errors?.[0]?.errorMessage
          ? event.errors[0].errorMessage
          : event.context;

      return sendErrorFilter(event);
    },
  });
  Bugsnag.startSession();

  ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React as any);
  store.dispatch(bugsnagInitialized(true));
}

// TODO: set this up properly testing display in bugsnag with error class and message
export function formatBugsnagErrorMessage(error: unknown) {
  return error ? JSON.stringify(error) : '';
}

function sendErrorFilter(event: Event) {
  const excludeErrorMessagesWithTheseLabels = ['non-error'];

  // Exclude errors loading tracking scripts
  const excludeDomains = [
    'googleadservices.com',
    'ads-twitter.com',
    'sc-static.net',
    'doubleclick.net',
    'adsrvr.org',
    'cookielaw.org',
    'googletagmanager.com',
    'localhost',
  ];
  // Sometimes tracking seems to get blamed for real issues, so only exclude these ones
  const trackingDomainExcludeErrors = ['Unexpected token'];
  let sendStatus = true;
  if (!event.errors?.length) {
    // no errors found
    sendStatus = false;
  } else if (Constants.IS_IN_CYPRESS_TEST) {
    sendStatus = false;
  } else if (event.errors?.some(error => !error.errorMessage)) {
    sendStatus = false;
  } else if (
    event.errors.some(error =>
      excludeErrorMessagesWithTheseLabels.find(msg =>
        error.errorMessage?.includes(msg),
      ),
    )
  ) {
    sendStatus = false;
  } else if (
    event.errors.some(
      error =>
        excludeDomains.find(domain =>
          error?.stacktrace?.some(trace => trace?.file?.includes(domain)),
        ) &&
        trackingDomainExcludeErrors.find(msg =>
          error.errorMessage?.includes(msg),
        ),
    )
  ) {
    sendStatus = false;
  }
  return sendStatus;
}

/**
 * Pathway Bugsnag notify.
 *
 * This should be used in all places of the app over Bugsnag.notify.
 */
export const bugsnagNotify = (err: unknown) => {
  const error = typeof err === 'string' ? new Error(err) : err;
  const monitoringEnabled = selectMonitoringEnabled(store.getState());
  const bugsnagReady = selectBugsnagInitialized(store.getState());

  console.error(error);

  if (monitoringEnabled && bugsnagReady) {
    Bugsnag.notify(formatBugsnagErrorMessage(error));
  }
};
