import { useBreakpoints, useMediaQuery } from 'cfa-react-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CopyrightFooter as CopyrightFooterStyled,
  CopyrightFooterText,
} from '@/components/document/wrappers';
import { Breadcrumb } from '@/types/types';
import { currentYear } from '@/utils/date';
import BreadcrumbLinks from '@/components/BreadcrumbLinks/BreadcrumbLinks';

interface CopyrightFooterProps {
  isPrinting?: boolean;
  lastPublishedDate: string;
  breadcrumbs: Breadcrumb[];
}

export const CopyrightFooter: FC<CopyrightFooterProps> = ({
  isPrinting = false,
  lastPublishedDate,
  breadcrumbs,
}) => {
  const { t } = useTranslation();
  const breakpoints = useBreakpoints();
  const isSmAndDown = useMediaQuery(breakpoints.down('md'));
  const isLargeAndDown = useMediaQuery(breakpoints.down('xl'));

  if (isPrinting) {
    return null;
  }

  return (
    <CopyrightFooterStyled $isExtraLg={!isLargeAndDown} $isMobile={isSmAndDown}>
      <BreadcrumbLinks breadcrumbs={breadcrumbs} />
      <CopyrightFooterText variant="body2">
        {t('Generic.documentFooter', {
          currentYear,
          lastPublishedDate: lastPublishedDate || null,
        })}
      </CopyrightFooterText>
    </CopyrightFooterStyled>
  );
};
